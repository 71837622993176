import { AllTools } from '@/models/BillMgr/Tools';
import { AllTariff } from '@/models/BillMgr/AllTariff';
const sortOrders = ['asc', 'desc'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      // state.list = list.map(i => new AllTariff(i));
      state.list = list;
    } catch (e) {
      console.error(e);
    }
  },
  SET_LIST_BLOCK: (state, listPaymentBlock) => {
    try {
      // state.list = list.map(i => new AllTariff(i));
      state.listPaymentBlock = listPaymentBlock;
    } catch (e) {
      console.error(e);
    }
  },
  SET_ITEMS: (state, tariff) => {
    try {
      // state[tariff.elid] = list.map(i => new AllTariff(i));
      state[tariff.elid] = new AllTariff(tariff);
      // state.list = list;
    } catch (e) {
      console.error(e);
    }
  },
  SET_PROLONG: (state, payload) => {
    // console.log(payload.slist);
    try {
      // state[tariff.elid] = list.map(i => new AllTariff(i));
      state[payload.id].slist = payload.slist;
      // state.list = list;
    } catch (e) {
      console.error(e);
    }
  },
  SET_STATE: (state, payload) => {
    try {
      state.state = payload;
    } catch (e) {
      console.error(e);
    }
  },
  SET_FLAG: (state, payload) => {
    // console.log('setFlag', payload);
    try {
      state.flag = payload;
    } catch (e) {
      console.error(e);
    }
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new AllTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  paymethod: (state, payload) => {
    state.paymethod = payload;
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
};
