import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import Vue from 'vue';
export default {
  mixins: [billMgrToolActions],
  data() {
    return {
      detailModal: null,
    };
  },
  computed: {
    darkTheme() {
      return this.$store.state.moduleApp.theme === 'dark';
    },
    tools() {
      return this.$store.state.moduleNotifications.tools;
    },
    viewTool() {
      if (!this.tools) return null;
      return this.tools.edit;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleNotifications/fetchList');
    },
    viewDetail(item) {
      if (!this.viewTool || !this.viewTool.isEnable(item)) return;
      const params = {
        func: this.viewTool.func + '.result',
        elid: item.id,
      };
      this.$modals.open({
        name: 'NotificationView',
        component: BaseLoader,
        sizeTransition: false,
        onOpen: inst => (this.detailModal = inst),
        onClose: () => (this.detailModal = null),
      });
      this.fetchBillMgrAction(params).then(data => {
        if (!data || !data.model || !data.model.body) return;
        const content = this.getContentFromFuckingHtml(data.model.body);
        const title = this.getHtmlString(
          item.subject.includes('popup_') ? item.subject.replace('popup_', '') : item.subject,
          'big-title'
        );
        Vue.set(this.detailModal, 'size', 'large');
        this.fetchList();
        Vue.set(this.detailModal, 'component', this.getComponent('noticeDetail', title + content));
      });
    },
    getContentFromFuckingHtml(data) {
      const element = document.createElement('html');
      element.innerHTML = data;
      const content = element.querySelector('#content');
      const reformat = content ? null : data;
      const format =
        reformat && this.darkTheme
          ? reformat
              .replaceAll('#FDF7F1', '#000000')
              .replaceAll('bgcolor="#ffffff"', 'bgcolor="#000000"')
          : reformat;
      // #FDF7F1 - ну а как по другому та
      // bgcolor="#ffffff"
      return content ? content.innerHTML : format;
    },
    getHtmlString(title, classes, tag = 'div') {
      return `<${tag} class="${classes}">${title}</${tag}>`;
    },
    getComponent(id, content, tag = 'div') {
      return Vue.component(id, {
        render: createElement =>
          createElement(tag, {
            class: 'billmgr-html-injection',
            domProps: { innerHTML: content },
          }),
      });
    },
  },
};
