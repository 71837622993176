import { GETTERS } from '../../utils/getters';
import { STATE } from '../../utils/state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';

const moduleDomainsDomains = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
};

export default moduleDomainsDomains;
