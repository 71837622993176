import Vue from 'vue';
import { BasketItem } from '@/models/BillMgr/Basket';
import BillMgrApi from '@/services/api/BillMgrApi';
import { parseCost } from '@/utils/parsers/costParser';

/**
 * @typedef moduleBasketState
 * @prop {Array} list
 * @prop {Object} model
 * @prop {Boolean} isLoading
 */

/**
 * Function returns new module's state object
 * @returns {moduleBasketState}
 */

/**
 * Function returns new module's state object
 * @returns {moduleBasketState}
 */
const state = () => ({
  list: [],
  shadow: {},
  isLoading: false,
  model: {},
  promo: '',
  cost: 0,
});

const mutations = {
  /**
   * Set list to current state
   * @param {moduleBasketState} state - current context state
   * @param {Array} list - array to set
   */
  setList(state, list) {
    state.list = list.map(item => new BasketItem(item));
  },
  /**
   * Sel model from server
   * @param {moduleBasketState} state - current context state
   * @param {Object} obj - model object
   */
  setModel(state, obj) {
    state.model = obj;
    state.cost = obj.total_sum;
  },
  setCost(state, cost) {
    state.cost = cost;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setShadow(state, list) {
    state.shadow = {};
    const keys = Object.keys(list).filter(val => val !== 'itemlist' && val !== 'button_list');
    keys.forEach(key => {
      const billorderitems = [];
      let billorder = '';
      list[key].forEach(item => {
        if (item['item.id']) billorderitems.push(new BasketItem(item));
        else billorder = item.id;
      });
      Vue.set(state.shadow, billorder, billorderitems);
    });
  },
  reset(state) {
    state.list = [];
    state.shadow = {};
    state.isLoading = false;
    state.model = {};
    state.cost = 0;
  },
};

const getters = {
  shadowList: state => {
    return Object.values(state.shadow).reduce((list, item) => {
      list.push(...item);
      return list;
    }, []);
  },
  listWithShadow: (state, getters) => {
    return [...state.list, ...getters.shadowList];
  },
  getCost: state => {
    return state.cost;
  },
  getModel: state => {
    return state.model;
  },
};

const actions = {
  /**
   * Get basket data from api, commit into the state
   * @param {Object} ctx - current store context
   * @returns {Promise}
   */
  fetchBasket({ commit }) {
    const params = {
      newface: 'on',
      func: 'basket',
      show_metadata: 'on',
    };
    commit('setIsLoading', true);
    return BillMgrApi.get('', { params })
      .then(data => {
        if (data.ok && data.ok.type === 'form' && data.ok.v.includes('basket.empty'))
          commit('reset');
        if (data.model) commit('setModel', data.model);
        if (data.list && data.list.itemlist) {
          const itemlist = data.list.itemlist.filter(i => !!i['item.id']);
          commit('setList', itemlist);
          commit('setShadow', data.list);
        }
      })
      .finally(() => commit('setIsLoading', false));
  },
  /**
   * Function carries api-request with params to delete something from basket
   * @param {Object} _ctx - current store context
   * @param {String|Number} id - id of item to delete
   * @returns {Promise}
   */
  removeFromBasket({ getters, dispatch }, payload) {
    const basketItem =
      payload instanceof BasketItem
        ? payload
        : getters.listWithShadow.find(i => i['item.id'] == payload);
    if (!~basketItem) throw new Error(`Service with id '${payload}' not found in the basket`);
    if (basketItem) {
      const params = {
        func: 'basket',
        clicked_button: 'delete',
        sok: 'ok',
        id: basketItem.id,
      };
      return dispatch('sendBillMgrToolAction', params, { root: true });
    }
  },

  removeFromBasketErrorPromocode({ dispatch }, id) {
    const params = {
      func: 'basket',
      clicked_button: 'delete',
      sok: 'ok',
      id: id,
    };
    return dispatch('sendBillMgrToolAction', params, { root: true });
  },
  sendPromo({ dispatch }, payload) {
    const params = {
      newface: 'on',
      func: 'basket',
      show_metadata: 'on',
      clicked_button: 'setpromocode',
      promocode: payload,
    };
    return dispatch('sendBillMgrToolAction', params, { root: true });
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
