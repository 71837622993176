import { GETTERS } from './getters.js';
import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';

const moduleAll = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
};

export default moduleAll;
