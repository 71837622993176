import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrStatus, BillMgrStatusSpecial } from './status';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';
import { baseColors } from '../base/baseColors';
import { transformPeriodToMore } from './periods';

const nsColors = {
  alert: baseColors.error,
};

export class DnsHostTariff extends BaseConfigurator {
  constructor(args) {
    super(args, BillMgrAddon);
    // this._info = null;
    // this._addonsExt = null;
    // this._serverInfo = null;
    // this._history = [];
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = args.id;
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    this.autosuspend = args.autosuspend || 'off';
    this.change_pricelist = args.change_pricelist || args.change_pl;
    this.name = args.name;
    this.costsuffix = args.cost_suffix;
    this.pricelist = args.pricelist;
    this.cost = parseCost(args.cost);
    this.period = args.period || args.period_id;
    this.period_cost = args.period_cost || '';
    this.datacentername = args.datacentername;
    this.expiredate = Date.parse(args.expiredate) || parseDateRu(args.real_expiredate_s);
    this._createdate = Date.parse(args.createdate) || null;
    this.in_pay = args.in_pay;
    this.prolong_available = args.prolong_available || 'off';
    // this.status = new BillMgrStatus(args.status);
    this.status = new BillMgrStatus(
      this.employeesuspend === 'on' ? 8 : this.abusesuspend === 'on' ? 7 : args.status
    );
    this.specialstatus = new BillMgrStatusSpecial(args.service_status);
    this.transition = args.transition;
    this._auth = '';
    // autoprolong
    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_period = args.autoprolong_period || args.autoprolong_id || 'null';
    // this.autoprolong_id = args.autoprolong_id || 'null';
    this.autoprolong_name = args.autoprolong || args.autoprolong_name || '';
    this.promise = args.promise || 'null';
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    return {
      cost: this.cost,
      period: this.period,
      periodTransformed: transformPeriodToMore(this.period),
      expiredate: this.expiredate,
    };
  }

  get title() {
    return this.name;
  }

  get createdate() {
    if (this._createdate) return this._createdate;
    else if (this.info && this.info.model && this.info.model.createdate)
      return Date.parse(this.info.model.createdate);
    else return null;
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : 'off';
  }

  // get prolongAvailable() {
  //   if (
  //     this.employeesuspend === 'on' ||
  //     this.abusesuspend === 'on' ||
  //     this.allow_processing_prolong === 'off'
  //   )
  //     return false;
  //   return this.prolong_available ? this.prolong_available === 'on' : false;
  // }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }
  // get autoprolongAvailable() {
  //   if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
  //   console.log(this.autoprolong_available ? this.autoprolong_available === 'on' : false);
  //   return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  // }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }

  set info({ customfields, slist, model, details }) {
    this._info = {
      customfields,
      slist,
      model,
      details: null,
    };
    // console.log(this._info);
    if (slist && slist.autoprolong[2].cost) this.period_cost = slist.autoprolong[2].cost;
    if (details) {
      const addons = details.addon.reduce((acc, i) => {
        acc[`addon_${i.id}`] = i;
        return acc;
      }, {});
      this._info.details = {
        main: details.main,
        addons,
      };
    }
  }

  get info() {
    return this._info;
  }

  get addons() {
    if (!this.info || !this.info.details || !this.info.details.addons) return null;
    return this.info.details.addons;
  }

  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }

  set auth(val) {
    this._auth = val;
  }
  get auth() {
    return this._auth;
  }
}
