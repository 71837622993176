import axios from 'axios';
import { bmRusonyx } from '@/layouts/LoginPage/mixins/currentProvider';

const MyRsxExtApi = axios.create({
  baseURL: `${bmRusonyx}/extapi/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

MyRsxExtApi.interceptors.request.use(
  config => {
    if (config.method == 'post') {
      return config;
    } else {
      if (!config.params) config.params = {};
      const token = localStorage.getItem('token');
      if (token) config.params = { ...config.params, auth: token };
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

// TODO
// узнать, как ошибки приходят, и скорректировать интерцептор
MyRsxExtApi.interceptors.response.use(
  response => {
    if (response.data.error) {
      const data = response.data.error;
      if (response.data.warnings) data.warnings = response.data.warnings;
      return Promise.reject(data);
    } else return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default MyRsxExtApi;
