export default {
  GET_PROFILE_INFO: state => {
    return state.profile;
  },
  balance: state => {
    return state.profile.balance || 0;
  },
  avatar: (state, _getters, rootState) => {
    let str = '';
    if (state.profile && state.profile.avatar_src && rootState.moduleApp.host) {
      str = rootState.moduleApp.host + state.profile.avatar_src;
    }
    return str;
  },
  phone: state => {
    return state.profile.phone;
  },
  need_phone_validate: state => {
    return (
      state.profile.need_phone_validate === 'true' || state.profile.need_phone_validate === true
    );
  },
  partners: state => {
    return state.partners;
  },
  referals: state => {
    return state.referals;
  },
};
