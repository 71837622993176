<template>
  <div v-if="config.check !== 'money'" class="text-block">
    <base-input v-bind="changeConfigMoney" :label="label" :hint="changeHint" @change="onChange">
      <!--    <base-input v-bind="config" :label="label" :hint="changeHint" @change="onChange">-->
      <template v-if="$slots.before" #before>
        <slot name="before" />
      </template>
      <template v-if="$slots.fieldBefore" #fieldBefore>
        <slot name="fieldBefore" />
      </template>
      <template v-if="$slots.fieldAfter" #fieldAfter>
        <slot name="fieldAfter" />
      </template>
      <template v-if="$slots.after" #after>
        <slot name="after" />
      </template>
    </base-input>
  </div>
  <div v-else class="text-block">
    <div class="number">
      <label class="typo__label medium-title number-top">{{ label }}</label>
      <InputNumber
        v-model="changeValue"
        mode="currency"
        :max="9999999"
        currency="RUB"
        class="p-inputtext-lg"
        locale="ru-RU"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import InputNumber from 'primevue/inputnumber';
import TextComponent from '@/models/base/components/text';
export default {
  name: 'TextBlock',
  components: { BaseInput, InputNumber },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof TextComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refactoringCost: 0,
    };
  },
  computed: {
    changeHint() {
      return this.hint === 'Дата в формате гггг-мм-дд' ? 'Дата в формате дд.мм.гггг' : this.hint;
    },
    changeValue: {
      get() {
        return this.config.value === '' ? null : +this.config.value;
      },
      set(value) {
        this.config.value = value === '' ? null : +value;
        // },
      },
    },

    changeConfigMoney() {
      if (this.config.check === 'money') {
        const newConfig = Object.assign({}, this.config);
        // newConfig.mask = '9{1,10}';
        newConfig.value = this.config.value.includes(',')
          ? this.config.value.replace(',', '.')
          : this.config.value;
        return newConfig;
      } else return this.config;
    },
  },
  watch: {
    'config.value': function (event) {
      this.$emit('change', this.config);
    },
  },
  mounted() {},
  methods: {
    onChange(e) {
      this.config.value = e.target.value;
      this.$emit('change', this.config);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.number {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  &-top {
    margin-bottom: 0.5rem
  }
}
</style>
