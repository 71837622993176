<template>
  <div class="container">
    <div class="amount">
      <div class="amount-block standart-text">
        <div class="amount-block__label big-title">
          {{ label + ':' }}
        </div>
        <!--        <div-->
        <!--          v-if="getModel.full_discount > 0"-->
        <!--          class="amount-block__value big-title error-color discount"-->
        <!--        >-->
        <!--          {{ $n(+fullCost, 'currency') }}-->
        <!--        </div>-->
        <div class="amount-block__value big-title">
          {{ $n(config.value, 'currency') }}
        </div>
        <plain-button
          v-if="hint"
          v-tooltip="{ content: hint, placement: 'top-start', container: false }"
          icon="help"
          size="small"
          color="dim"
          tabindex="-1"
          class="amount-block__hint"
        />
      </div>
    </div>
    <div v-if="needToShowNotification && view" class="amount-block"><br />{{ $t('alert') }}</div>
  </div>
</template>

<script>
import AmountComponent from '@/models/base/components/amount';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'AmountBlock',
  mixins: [showErrorModal],
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof AmountComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    needToShowNotification: {
      type: Boolean,
      default: false,
    },
    isViewNotification: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    },
    // fullCost: {
    //   type: Number,
    //   default: 0,
    // },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    getAmount() {
      return this.$store.getters['moduleBasket/getCost'] || this.config.value;
    },
    getModel() {
      return this.$store.getters['moduleBasket/getModel'];
    },
    fullCost() {
      return this.getModel.fullcost;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "promo": "Промокод",
    "alert": "Обратите внимание, при включенном автопродлении услуги, Ваша карта будет привязана к аккаунту",
    "confirm": "Применить"
    }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  align-content: flex-start;

}
  .discount {
    flexy(flex-start, center);
    text-decoration: line-through;
  }
.amount-block {
  flexy(flex-start, center);

  &__value {
    margin-left: 0.5em;
  }

  &__hint {
    margin-left: 0.5rem;
  }
}
</style>
