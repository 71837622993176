import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';
import DnsMgrApi from '@/services/api/DnsMgrApi';
import { parse } from 'qs';

function makeAuthParamsAsyncGen(list, func) {
  return {
    from: 0,
    to: list.length,
    async *[Symbol.asyncIterator]() {
      for (let i = this.from; i < this.to; i++) {
        const params = {
          elid: list[i].id,
          func,
        };
        const data = await BillMgrApi.get('', { params });
        if (data && data.ok && data.ok.type && data.ok.type === 'url' && data.ok.v) {
          const v = data.ok.v.split('?');
          const p = parse(v[1]);
          yield { id: list[i].id, params: p };
        }
      }
    },
  };
}

export const ACTIONS = {
  fetchListBillMrg({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/dnshost';
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_DNS_LIST', data.elem);
          commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchListDnsMgr({ state, commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    const billMgrDnsHost = state.dnsList.find(item => item.id === payload.id);
    delete payload.id;
    return new Promise((resolve, reject) => {
      const params = {
        func: 'domain',
        show_metadata: 'on',
        p_num: 1,
        p_cnt: 1000,
        ...payload,
      };
      DnsMgrApi.get('/dnsmgr', { params })
        .then(data => {
          const mutationName = state.list.length ? 'UPDATE_LIST' : 'SET_LIST';
          if (data.elem) {
            const list = data.elem.map(item => {
              return {
                ...item,
                billMgrDnsHost,
              };
            });
            commit(mutationName, list);
          }
          const tools = data.metadata.toolbtndata || null;
          if (tools) commit('SET_DNSMGR_TOOLS', tools);
          resolve(data);
        })
        .catch(e => reject(e))
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  async fetchList({ state, commit, dispatch }, payload = {}) {
    const silent = payload.silent || false;
    try {
      if (payload.hasOwnProperty('silent')) delete payload.silent;
      if (!silent) commit('SET_IS_LOADING', true);
      await dispatch('fetchListBillMrg', { silent: true });
      const { dnsList, tools } = state;
      if (tools.gotoserver) {
        const innerList = dnsList.filter(item => tools.gotoserver.isEnable(item));
        const authGen = makeAuthParamsAsyncGen(innerList, tools.gotoserver.func);
        for await (let item of authGen) {
          const { id, params } = item;
          const { model } = await DnsMgrApi.get('/dnsmgr', { params });
          commit('SET_OTHER_INFO_ABOUT_TARIFF', { id, prop: 'auth', value: model.auth });
          if (!state.auth) commit('SET_DNS_AUTH', model.auth);
          await dispatch('fetchListDnsMgr', { auth: model.auth, id, silent: true });
        }
      }
      if (!silent) commit('SET_IS_LOADING', false);
      return Promise.resolve();
    } catch (e) {
      if (!silent) commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
  updateList({ state, dispatch }, payload = {}) {
    const params = {
      ...payload,
    };
    const head = state.dnsList[0]; //TODO здесь походу отвечает за выгрузку только одной пачки днс при нескольких услугах
    if (!params.id && head) params.id = head.id;
    if (!params.auth && head) params.auth = head.auth;
    return dispatch('fetchListDnsMgr', params);
  },
  updateDnsList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/dnshost';
      const params = {
        show_metadata: 'on',
        out: 'sjson',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          if (data.elem && data.elem.length) commit('UPDATE_DNS_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async (context, domain) => {
    try {
      await context.commit('SET_CURRENT', domain);
      const tariff = await context.getters['GET_CURRENT']('domain');
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetailBillMgr({ state, commit }, id) {
    const tools = state.tools;
    const params = {
      elid: id,
      func: tools.edit.func,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { customfields, slist, model } = data;
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: { customfields, slist, model },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchDetailDnsMgr({ state, getters, commit }, domain) {
    const tools = state.dnsMgrTools;
    const current = getters['GET_CURRENT']('domain');
    const params = {
      elid: domain,
      func: tools.edit.func,
      auth: current.auth,
      show_metadata: 'on',
    };
    return new Promise((resolve, reject) => {
      DnsMgrApi.get('/dnsmgr', { params })
        .then(data => {
          const { fields, slist, model, hidefields } = data;
          const value = { customfields: fields, model };
          if (slist) value.slist = slist;
          if (hidefields) value.hidefields = hidefields;
          commit('SET_OTHER_INFO_ABOUT_DOMAIN', { prop: 'info', value, id: domain });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  async fetchDetail({ dispatch }, { id, domain }) {
    try {
      await dispatch('fetchDetailBillMgr', id);
      if (domain) await dispatch('fetchDetailDnsMgr', domain);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'dnshost.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchBillMgrToolAction(_ctx, payload) {
    const params = {
      show_metadata: 'on',
      newface: 'on',
      ...payload,
    };
    if (payload.id) params.elid = payload.id;
    return BillMgrApi.get('/billmgr', { params });
  },
  sendBillMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchDnsMgrToolAction({ state }, payload) {
    const params = {
      show_metadata: 'on',
      ...payload,
    };
    if (!payload.auth) params.auth = state.dnsAuth;
    return DnsMgrApi.get('/dnsmgr', { params });
  },
  sendDnsMgrToolAction({ state }, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.auth) params.auth = state.dnsAuth;
    const formData = formDataParser(params);
    return DnsMgrApi.post('/dnsmgr', formData);
  },
  // rest store
  reset({ commit }) {
    commit('RESET');
  },
};
