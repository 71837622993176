import addonsModule from './modules/addons.js';
import ACTIONS from './actions.js';
import GETTERS from './getters.js';
import STATE from './state.js';
import MUTATIONS from './mutations.js';

export default {
  actions: ACTIONS,
  getters: GETTERS,
  mutations: MUTATIONS,
  state: STATE,
  modules: {
    addonsModule,
  },
};
