import { DataLoad } from '@/store/utils/DataLoad.js';

const RESOURCE = new DataLoad('my-rusonyx');
const URL = '/api2/billmgr/pricelist.export';

export default {
  SET_ALL_TARIFFS: async (context, payload) =>
    await RESOURCE.GET(
      URL,
      payload,
      {},
      undefined,
      [context.commit.bind(undefined, 'SET_ALL_TARIFFS')],
      [data => data]
    ),
  SET_ALL_VIRTUALS: async (context, payload) =>
    await RESOURCE.GET(
      URL,
      payload,
      {},
      undefined,
      [context.commit.bind(undefined, 'SET_ALL_VIRTUALS')],
      [data => data]
    ),
  //   itemtype: pleskshared
  // onlyavailable: on
};
