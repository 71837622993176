export default {
  GET_ALL_TARIFFS: ({ tariffs }) => {
    let bundle = [];
    try {
      const { pricelist } = tariffs;
      bundle = pricelist.map(
        ({
          id,
          name: title,
          price,
          trial,
          filter_info: { disc_value: memory, mem_value: ram, ncpu_value: cpu, cost },
          price: { period },
        }) => {
          return {
            id,
            title,
            CPU: cpu[0].def,
            MEMORY: memory[0].def,
            RAM: ram[0].def,
            cost,
            price,
            trial,
            // filter,
            period,
          };
        }
      );
    } catch (err) {
      //console.error(err);
    }

    return bundle;
  },
  GET_ALL_VIRTUALS: ({ virtuals }) => {
    let bundle = undefined;
    try {
      const { pricelist } = virtuals;
      bundle = pricelist.map(({ name, id, filter_info: { cost }, tariffpage }) => {
        return {
          title: name,
          id,
          cost,
          tariffpage,
        };
      });
    } catch (err) {
      bundle = [];
    }
    return bundle;
  },
};

// action: "order"
// billprorata: "off"
// certificate_subscription: false
// dashboard: false
// datacenter: 1
// description: ""
// filter_info: { cost: "300.00" }
// id: 1455
// image: "/manimg/common/img/mb-service.png"
// minperiodlen: ""
// minperiodtype: "0"
// name: "Plus"
// orderpriority: -65
// price: {, … }
// quickorder: false
// siteinfo: ""
// tariffpage: [{ id: 21, intname: "basic" }]
// trial: "0"
// trial_expiredate: "2020-12-04"
// trial_expiredate_l: "4 декабря 2020 г."
