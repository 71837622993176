import { baseListState } from '../utils/baseListState';
import { baseListGetters } from '../utils/baseListGetters';
import { baseListMutations } from '../utils/baseListMutations';
import BillMgrApi from '@/services/api/BillMgrApi';
import NoticeItem from '@/models/BillMgr/Notice';
import { BillMgrTools } from '@/models/BillMgr/Tools';

const state = () => ({
  ...baseListState(),
  itemClass: NoticeItem,
  toolsClass: BillMgrTools,
});

const getters = {
  ...baseListGetters,
  unreaded: state => state.list.filter(item => item.readed === 'off'),
};

const mutations = {
  ...baseListMutations,
};

const actions = {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        func: 'notification',
        show_metadata: 'on',
        newface: 'on',
        //p_cnt: 1000,
        //out: 'sjson',
        ...payload,
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          if (data.elem) commit('SET_LIST', data.elem);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
};

const moduleNotifications = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default moduleNotifications;
