<template>
  <div class="header-profile">
    <action-popup :active.sync="isActive">
      <avatar
        :id="profile.account"
        slot="activator"
        shape="circle"
        :src="src"
        class="header-profile__avatar"
      />
      <div class="header-profile__popup-content">
        <div class="header-profile__info">
          <div class="header-profile__realname standart-title">
            {{ profile.realname }}
          </div>
          <div class="header-profile__account">
            {{ $t('account') + ` #${profile.account}` }}
          </div>
          <div class="header-profile__account">
            {{ $t('login') + ` ${profile.name}` }}
          </div>
        </div>
        <div class="header-profile__nav">
          <router-link
            v-for="item in isNav"
            :key="item.key"
            :to="isAstra(item)"
            class="header-profile__nav-link standart-text"
          >
            {{ $t(`nav.${item.key}`) }}
          </router-link>
          <nav-simple
            v-if="provider === '6' && isReseller && linkTo"
            :list="asideNav[0]"
            direction="vertical"
            class="header-profile__nav-link standart-text"
          />
          <router-link :to="{ name: 'Logout' }" class="header-profile__nav-link standart-text">
            {{ $t(`nav.exit`) }}
          </router-link>
        </div>
      </div>
    </action-popup>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue';
import ActionPopup from '@/components/ActionPopup/ActionPopup';
import NavSimple from '@/components/NavSimple/NavSimple.vue';
export default {
  name: 'HeaderProfile',
  components: { NavSimple, Avatar, ActionPopup },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    asideNav() {
      const arr = [];
      arr.push([
        {
          key: 'partners',
          target: '_blank',
          href: this.linkTo,
        },
      ]);
      return arr;
    },
    isReseller() {
      return !!(
        this.$store.state.moduleProfile.partners_group &&
        this.$store.state.moduleProfile.partners_group.affiliate &&
        this.$store.state.moduleProfile.partners_group.affiliate.find(x =>
          x.v.includes('Партнерская программа')
        )
      );
    },
    linkTo() {
      return this.$store.state.moduleProfile.referals && this.$store.state.moduleProfile.referals.v
        ? this.$store.state.moduleProfile.referals.v
        : '';
    },
    provider() {
      return this.$store.state.moduleProviders.current;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    nav() {
      return this.$store.getters['moduleRoutes/miniProfileNav'];
    },
    isNav() {
      return this.provider === '6' && this.isReseller
        ? this.nav.filter(x => x.name !== 'Partners')
        : this.nav;
    },
    src() {
      return this.$store.getters['moduleProfile/avatar'];
    },
    route() {
      return this.$route.path;
    },
  },
  watch: {
    route() {
      if (this.isActive) this.isActive = false;
    },
  },
  methods: {
    isAstra(item) {
      return this.provider === '6' && item.key === 'partners' ? { name: 'partnersAstra' } : item.to;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "account": "Код клиента",
      "login": "Логин: "
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.header-profile {
  flexy(flex-end, center);


  &__avatar {
    size(30px);
    background: var(--avatar-bg);
    transition: background $anim-base-duration ease;
    cursor: pointer;

    +breakpoint(sm-and-up) {
      size(40px);
    }
  }

  &__info {
    padding: 0.5rem 1rem 1rem;
    border-bottom 1px solid var(--border);
  }

  &__account {
    margin-top: 0.25rem;
  }

  &__nav {
    padding: 0.5rem 0.5rem 0;

    &-link {
      display: block;
      text-decoration: none;
      padding: 0.5rem;
      color: var(--text);

      &:hover {
        color: $primary-color;
      }

      & + & {
        margin-top: 0.25rem;
      }
    }
  }
}
</style>
