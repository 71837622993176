<template>
  <div ref="container" class="base-tabs standart-text" :class="classes">
    <template v-if="list.length">
      <div class="base-tabs__container">
        <div
          v-for="(item, i) in list"
          :key="i"
          :ref="
            item.to && item.to.name && item.to.name === activeRouteName
              ? 'activeElement'
              : 'element'
          "
          class="base-tabs__element"
          :class="{
            ['base-tabs__element--active']:
              item.to && item.to.name && item.to.name === activeRouteName,
          }"
        >
          <slot name="item" :item="item" />
        </div>
      </div>
      <main-label v-if="id" color="primary" class="layout-title__status">
        {{ `Подписка #${id}` }}
      </main-label>
      <!--      <div v-if="id" class="base-tabs__id">{{ `Подписка #${id}` }}</div>-->
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
import MainLabel from '@/components/Labels/MainLabel';

export default {
  name: 'Tabs',
  components: { MainLabel },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'default',
      validator: val => ['default', 'pills'].includes(val),
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      const base = 'base-tabs';
      return {
        [`${base}--${this.theme}`]: !!this.theme,
      };
    },
    activeRouteName() {
      return this.$route.name;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth() {
      if (this.theme === 'default') {
        setTimeout(() => this.scrollToActiveIfNeed());
      }
    },
  },
  mounted() {
    if (this.theme === 'default') {
      setTimeout(() => this.scrollToActiveIfNeed(), 100);
    }
  },
  methods: {
    scrollToActiveIfNeed() {
      const container = this.$refs.container || null;
      const active = this.$refs.activeElement ? this.$refs.activeElement[0] : null;
      if (!container || !active) return;
      const offset = container.firstChild.offsetLeft;
      const endPos = container.offsetWidth - 2 * offset;
      const activeEnd = active.offsetLeft + active.offsetWidth;
      if (activeEnd > endPos) {
        container.scrollTo({ left: active.offsetLeft - offset });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-tabs {
  position: relative;
  //flexy(flex-start, baseline);
  flexy(space-between, baseline);
  overflow-x: auto;

  &__container {
    flexy(flex-between, baseline);
  }

  &--default {
    flex-wrap: wrap
    padding: 0.25rem $gutter-ms 0.5rem;
    margin: -0.75rem (-1 * $gutter-ms) -0.5rem;

    +breakpoint(sm-and-up) {
      padding: 0.25rem $gutter-sm 0.5rem;
      margin: -0.75rem (-1 * $gutter-sm) -0.5rem;
    }
    +breakpoint(md-and-up) {
      padding: 0.25rem $gutter-md 0.5rem;
      margin: -0.75rem (-1 * $gutter-md) -0.5rem;
    }
    +breakpoint(lg-and-up) {
      padding: 0.25rem $gutter 0.5rem;
      margin: -0.75rem (-1 * $gutter) -0.5rem;
    }
  }

  &--pills {
    padding: 0.25rem 0 0.75rem;
    //margin: -0.25rem 0 -0.75rem;
  }

  &__element {

    ^[0]--default & {
      padding: 10px 0;
      color: var(--text);
      /*+breakpoint(ms-and-down) {
        margin-left: 2.5rem;
      }

      &--active {
        +breakpoint(ms-and-down) {
          order: -1;
          margin-left: 0;
        }
      }*/
    }

    ^[0]--default & + & {
      margin-left: 2.5rem;
      +breakpoint(sm-and-up) {
        margin-left: 2.5rem;
      }
    }

    ^[0]--pills & {
      color: $note-color;
    }

    ^[0]--pills & + & {
      margin-left: 1.25rem;
    }

    > div,
    > a,
    > span {
      text-decoration: none;
      white-space: nowrap;
      transition: border-color $anim-base-duration ease, color $anim-base-duration ease;

      ^[0]--default & {
        color: var(--text);
        padding: 0.5rem 0;
        border-bottom: 2px solid transparent;

        &.active {
          border-bottom-color: $primary-color;
        }
      }

      ^[0]--pills & {
        color: $note-color;
        padding: 0.25em 0.5em;
        border: 2px solid transparent;
        border-radius: $border-radius-smaller;

        &.active {
          font-weight: $font-weight-medium;
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  }
  &__id {
  }
}
</style>
