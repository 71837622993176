<template>
  <!--  <svg class="svg-icon" :class="classes" xmlns="http://www.w3.org/2000/svg">-->
  <svg :class="classes" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="`#${name}`" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'base',
      validator: value => ['base', 'icons', 'os'].includes(value),
    },

    title: {
      type: String,
      default: null,
    },

    stroked: {
      type: Boolean,
      default: false,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: null,
      validator: value => !value || ['primary', 'secondary'].includes(value),
    },
  },

  computed: {
    classes() {
      const prefix =
        this.size === 'icons' ? 'svg-icon-l' : this.size === 'os' ? 'svg-icon-xl' : 'svg-icon';
      return {
        [`${prefix}`]: true,
        [`${prefix}--${this.name}`]: true,
        [`${prefix}--filled`]: this.filled,
        [`${prefix}--stroked`]: this.stroked,
        [`${prefix}--${this.color}`]: this.color,
      };
    },
  },

  watch: {
    name: async function (value) {
      await this.fetch(value);
    },
  },

  async beforeMount() {
    await this.fetch(this.name);
  },

  methods: {
    async fetch(name) {
      await require(`@/assets/icons/${name}.svg`);
    },
  },
};
</script>

<style lang="stylus">
/*
@require '~@/assets/stylus/vars/variables';
$icon-themes = {
  light: $theme-light.base,
  dark: $theme-dark.base,
  primary: $theme-primary.base,
  secondary: $theme-secondary.base,
}
*/
.svg-icon {
  font-size: inherit;
  width: 1em;
  height: 1em;

  &--filled {
    fill: currentColor;
  }

  &--stroked {
    stroke: currentColor;
  }
  /*
  for $key, $val in $icon-themes {
    &--{$key} {
      color: $val !important;
    }
  }
  */
}

.svg-icon-l {
  font-size: inherit;
  width: 1.5em;
  height: 1.5em;

  &--filled {
    fill: currentColor;
  }

  &--stroked {
    stroke: currentColor;
  }
  /*
  for $key, $val in $icon-themes {
    &--{$key} {
      color: $val !important;
    }
  }
  */
}
.svg-icon-xl {
  font-size: inherit;
  width: 2.5em;
  height: 2.5em;

  &--filled {
    fill: currentColor;
  }

  &--stroked {
    stroke: currentColor;
  }
  /*
  for $key, $val in $icon-themes {
    &--{$key} {
      color: $val !important;
    }
  }
  */
}
</style>
