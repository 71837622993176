import container from './container.vue';
import containerNotify from './containerNotify.vue';
import Main from './Main/Main.vue';

import Security from './Main/views/Security.vue';
import Notifications from './Main/views/Notifications.vue';
import PersonalSettings from './Main/views/PersonalSettings.vue';
import Confidencial from './Main/views/Confidencial.vue';
import Partners from './Main/views/Partners.vue';
import Telegram from './Main/views/Telegram.vue';
import AllowedUsers from './Main/views/AllowedUsers.vue';
export const RouteProfile = {
  path: '/iam',
  name: 'Profile',
  component: container,
  redirect: {
    name: 'ProfileMain',
  },
  meta: {
    isShow: true,
    isLink: false,
    name: 'Профиль',
    navigation: {
      account: {
        key: 'profile',
        icon: 'el-icon-user-solid',
        order: 2,
      },
      miniProfile: {
        key: 'profile',
        order: 1,
      },
    },
    access: ['customer'],
  },
  children: [
    {
      path: '',
      name: 'ProfileMain',
      redirect: {
        name: 'ProfilePersonal',
      },
      meta: {
        access: ['customer'],
      },
      component: Main,
      props: true,
      children: [
        {
          name: 'ProfilePersonal',
          path: 'personal',
          component: PersonalSettings,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Личные настройки',
            access: ['customer.usrparam', 'mainmenutool.user.edit'],
          },
        },
        {
          name: 'ProfileSecurity',
          path: 'security',
          component: Security,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Безопасность',
            access: ['customer.usrparam'],
          },
        },
        {
          name: 'containerNotify',
          path: 'notifications',
          component: containerNotify,
          redirect: { name: 'ProfileNotify' },
          meta: {
            isShow: true,
            isLink: true,
            name: 'Уведомления',
            access: ['customer.usrparam'],
          },
          children: [
            {
              name: 'ProfileNotify',
              path: 'notifications',
              component: Notifications,

              meta: {
                isShow: true,
                isLink: false,
                name: 'Уведомления',
                access: ['customer.usrparam'],
              },
            },
            {
              name: 'ProfileNotifyConfig',
              path: 'config',
              component: Telegram,

              meta: {
                isShow: true,
                isLink: false,
                name: 'Настройка уведомлений',
                access: ['customer.usrparam'],
              },
            },
          ],
        },
        {
          name: 'ProfileConf',
          path: 'conf',
          component: Confidencial,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Конфиденциальность',
            access: ['customer.usrparam'],
          },
        },
        {
          name: 'Partners',
          path: 'partners',
          component: Partners,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Партнерам',
            access: ['finance.rsnx_partners'],
            navigation: {
              miniProfile: {
                key: 'partners',
                order: 4,
              },
            },
          },
        },
        {
          name: 'AllowedUsers',
          path: 'allowed-users',
          component: AllowedUsers,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Пользователи',
            access: ['customer.user', 'mainmenutool.user.edit'],
          },
        },
      ],
    },
  ],
};
