var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout__breadcrumbs-path"},[_c('div',{staticClass:"main-layout__breadcrumbs-item"},_vm._l((_vm.computedPath),function(pathitem,i){return _c('div',{key:i},[(
          i + 2 < _vm.computedPath.length && pathitem.meta.isLink && pathitem.name === 'Main-Layout'
        )?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_c('plain-button',{staticClass:"main-layout__breadcrumbs-path-home",attrs:{"icon":"home-icon","color":"primary","size":"small"}}),_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):_vm._e(),(
          _vm.$route.path === '/domains' ||
          _vm.$route.path === '/domains/order' ||
          _vm.$route.path === '/domains/contact' ||
          _vm.$route.path === '/domains/'
        )?_c('div',[(
            i + 3 < _vm.computedPath.length && pathitem.meta.isLink && pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(!pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):(_vm.$route.path.includes('stack'))?_c('div',[(
            i + 2 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            (_vm.$route.path === '/stack' || _vm.$route.path === '/stack/') &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(
            i + 2 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            _vm.$route.path !== '/stack' &&
            _vm.$route.path !== '/stack/' &&
            pathitem.name !== 'Main-Layout' &&
            pathitem.name !== 'Balancer'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(
            i + 2 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            _vm.$route.path.includes('server/') &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(
            i + 1 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            (_vm.$route.path.includes('network') || _vm.$route.path.includes('service')) &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{
              name: _vm.$route.path.includes('/network/private/')
                ? 'viewStackPrivate'
                : _vm.$route.path.includes('/network/public/')
                ? 'viewStackPublic'
                : _vm.$route.path.includes('/service/balancer/')
                ? 'viewBalancer'
                : _vm.$route.path.includes('/network/router/')
                ? 'viewStackRouter'
                : _vm.$route.path.includes('/network/firewall/')
                ? 'viewStackFirewall'
                : pathitem.link
                ? pathitem.redirect
                : pathitem.redirect,
            },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(!pathitem.meta.isLink && pathitem.name === 'Rule')?_c('div',[(_vm.inProgress)?_c('div',{staticClass:"main-layout__breadcrumbs-path-load"},[_c('plain-button',{attrs:{"to":{
                name: _vm.$route.path.includes('/service/balancer/')
                  ? 'containerRuleStart'
                  : pathitem.link
                  ? pathitem.redirect
                  : pathitem.redirect,
              },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e(),_vm._v(" "+_vm._s(pathitem.meta.name)+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):(!_vm.inProgress)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_c('plain-button',{attrs:{"to":{
                name: _vm.$route.path.includes('/service/balancer/')
                  ? 'containerRuleStart'
                  : pathitem.link
                  ? pathitem.redirect
                  : pathitem.redirect,
              },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e(),_vm._v(" "+_vm._s(pathitem.meta.name)+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):(
            i + 1 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            (_vm.$route.path.includes('stat') || _vm.$route.path.includes('quotas')) &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(
            !pathitem.meta.isLink &&
            (pathitem.name === 'Server' ||
              pathitem.name === 'Disk' ||
              pathitem.name === 'FirewallView' ||
              pathitem.name === 'RouterView' ||
              pathitem.name === 'PortPublicView' ||
              pathitem.name === 'SubnetPublicView' ||
              pathitem.name === 'PortView' ||
              pathitem.name === 'SubnetView' ||
              pathitem.name === 'BalancerView' ||
              pathitem.name === 'containerRuleStart' ||
              pathitem.name === 'RuleServers' ||
              pathitem.name === 'RuleAlgorithm' ||
              pathitem.name === 'RuleCheck' ||
              pathitem.name === 'RuleConnect' ||
              pathitem.name === 'RuleHeaders' ||
              pathitem.name === 'PoolView')
          )?_c('div',[(_vm.inProgress)?_c('div',{staticClass:"main-layout__breadcrumbs-path-load"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 2 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e(),_vm._v(" "+_vm._s(pathitem.meta.name)+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):(!_vm.inProgress)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 2 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e(),_vm._v(" "+_vm._s(pathitem.meta.name)+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):(!pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):(_vm.$route.path.includes('support'))?_c('div',[(
            i + 3 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            _vm.$route.path !== '/support/tickets/' &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):_vm._e(),(
            i + 1 < _vm.computedPath.length &&
            pathitem.meta.isLink &&
            (_vm.$route.path.includes('/support/tickets/') ||
              _vm.$route.path.includes('/support/archive/')) &&
            pathitem.name !== 'Main-Layout'
          )?_c('div',[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(
            !pathitem.meta.isLink &&
            (_vm.$route.path.includes('/support/tickets/') ||
              _vm.$route.path.includes('/support/archive/'))
          )?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}),_vm._v(" "+_vm._s(_vm.ticketId)+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):(!pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):(_vm.$route.path === '/all' || _vm.$route.name === 'PartnersAstra')?_c('div',[(i + 1 < _vm.computedPath.length && pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_c('plain-button',{staticClass:"main-layout__breadcrumbs-path-home",attrs:{"icon":"home-icon","color":"primary","size":"small"}}),_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(!pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()]):_c('div',[(
            i + 2 < _vm.computedPath.length && pathitem.meta.isLink && pathitem.name !== 'Main-Layout'
          )?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[_c('plain-button',{attrs:{"to":{ name: pathitem.link ? pathitem.redirect : pathitem.redirect },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pathItemLink(pathitem))+" ")]),_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}})],1):(!pathitem.meta.isLink)?_c('div',{staticClass:"main-layout__breadcrumbs-path-item"},[(_vm.computedPath.length === 2)?_c('plain-button',{staticClass:"main-layout__breadcrumbs-path-home",attrs:{"disabled":"true","icon":"home-icon","color":"primary","size":"small"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.pathItem(pathitem))+" "),(i + 1 !== _vm.computedPath.length)?_c('plain-button',{attrs:{"icon":"right-icon","size":"minimal"}}):_vm._e()],1):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }