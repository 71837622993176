import clickOutside from 'vue-click-outside';
export default {
  directives: { clickOutside },
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  methods: {
    togglePopoverState() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    closePopoverOnOutside(e) {
      if (
        this.isPopoverOpen &&
        !e.target.className.includes('popover-inner') &&
        !e.target.className.includes('context-menu')
      ) {
        this.isPopoverOpen = false;
      }
    },
  },
};
