import { baseListState } from '@/store/utils/baseListState';
import { baseListGetters } from '@/store/utils/baseListGetters';
import { baseListMutations } from '@/store/utils/baseListMutations';
import actions from './actions';
import { BillingProfile } from '@/models/BillMgr/Billing';
import { BillingProfileTools } from '@/models/BillMgr/Tools';

const getters = {
  payers(state) {
    if (!state.typeOfPayers) return null;
    return state.typeOfPayers;
  },
};

const modulePayers = {
  namespaced: true,
  state: {
    ...baseListState(),
    typeOfPayers: null,
    itemClass: BillingProfile,
    toolsClass: BillingProfileTools,
  },
  getters: { ...baseListGetters, ...getters },
  mutations: baseListMutations,
  actions,
};

export default modulePayers;
