<template>
  <div class="card-block">
    <div class="card-block__head">
      <div v-if="title || ($slots && $slots.title)" class="card-block__title medium-title">
        <slot name="title">{{ title }}</slot>
      </div>
      <div v-if="$slots.headEnd" class="card-block__head-end">
        <slot name="headEnd" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardBlock',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.card-block {
  &__head {
    margin-bottom: 1.25rem;
    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }
  }
  &__title {
    flex: 1 1 auto;
  }
  &__head-end {
    margin-top: 1rem;
    +breakpoint(sm-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
</style>
