import { VMState } from './state';

// const vzpanelBase = 'https://myvm.rusonyx.ru/vzpanel/instance/manage'

export class VpsServer {
  constructor(args) {
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return !!args.Uuid && !!args.State;
  }

  _init(args) {
    for (let prop in args) {
      this[prop.charAt(0).toLowerCase() + prop.slice(1)] = args[prop];
    }
    this.state = args.State;
  }

  set state(str) {
    this._state = new VMState(str);
  }

  get state() {
    return this._state;
  }
}
