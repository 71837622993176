import container from './container.vue';
// import Main from './pages/Main/Main.vue';
// import Information from './pages/Information/Information.vue';
// import View from './pages/Information/views/View.vue';
// import Roots from './pages/Information/views/Roots.vue';
// import CreatedBackups from './pages/Information/views/CreatedSaas.vue';
// import Detail from './pages/Information/views/InDetail.vue';
export const RouteBackup = {
  path: '/backups',
  name: 'BackupPage',
  component: container,
  redirect: { name: 'BackupMain' },
  meta: {
    // navigation: {
    //   service: {
    //     key: 'backups',
    //     icon: 'el-icon-copy-document',
    //     order: 5,
    //   },
    // },
  },
  children: [
    {
      path: '',
      name: 'BackupMain',
      component: () => import(/* webpackChunkName: "backup-main" */ './pages/Main/Main.vue'),
    },
    {
      path: ':uuid',
      name: 'InDetail',
      props: true,
      component: () =>
        import(/* webpackChunkName: "snapshot-info" */ './pages/Information/views/InDetail.vue'),
    },
    // {
    //   path: 'info/:backup',
    //   name: 'BackupInfo',
    //   component: Information,
    //   props: true,
    //   redirect: { name: 'BackupView' },
    //   children: [
    //     {
    //       path: 'view',
    //       name: 'BackupView',
    //       component: View,
    //     },
    //     {
    //       path: 'roots',
    //       name: 'BackupRoots',
    //       component: Roots,
    //     },
    //     {
    //       path: 'created',
    //       name: 'BackupCreated',
    //       component: CreatedBackups,
    //     },
    //     {
    //       path: 'detail',
    //       name: 'BackupDetail',
    //       component: Detail,
    //     },
    //   ],
    // },
  ],
};
