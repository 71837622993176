import axios from 'axios';
import router from '@/router';
import store from '@/store';

const DnsMgrApi = axios.create({
  baseURL: 'https://mydns.rusonyx.ru',
  headers: {
    'Content-Type': 'application/json',
  },
});

DnsMgrApi.interceptors.request.use(
  config => {
    if (config.method == 'post') {
      return config;
    } else {
      if (!config.params) config.params = {};
      config.params.out = 'bjson';
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

DnsMgrApi.interceptors.response.use(
  response => {
    if (response.headers['content-type'] === 'text/html') {
      return Promise.reject({ status: 520, msg: 'Server error' });
    } else if (response.data.error) {
      const data = response.data.error;
      if (response.data.warnings) data.warnings = response.data.warnings;
      return Promise.reject(data);
    } else return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default DnsMgrApi;
