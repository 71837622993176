export const STATE = {
  list: [],
  current: '',
  isLoading: true,
  sort: {
    prop: 'status',
    order: 'asc',
  },
  mode: 'grid',
  tools: {},
};
