<template>
  <div class="base-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="base-context-menu__group">
      <component
        :is="item.attrs ? (item.attrs.to ? 'router-link' : 'a') : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="base-context-menu__item standart-title"
        :class="{ [`base-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { IspTools } from '@/models/base/IspTools';
import contextMenuHandler from '@/mixins/contextMenuHandler';
export default {
  name: 'BaseContextMenu',
  mixins: [contextMenuHandler],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    menu: {
      type: Array,
      default: () => [],
    },
    tools: {
      type: IspTools,
      required: true,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  computed: {
    current() {
      return this.tariff;
    },
  },
  methods: {
    onClick(item) {
      this.runTool(item);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "ns": "Серверы имён (NS)",
      "whois": "Whois",
      "dns": "DNS записи",
      "gotoserver": "перейти в панель",
      "history": "история",
      "remove": "удалить",
      "delete": "удалить",
      "list": "Список позиций счета",
      "removeOrder": "удалить заказ",
      "edit": "изменить",
      "reconfigure": "Настроить повторно",
      "pay": "Оплатить",
      "download": "Скачать",
      "print": "Печать",
      "request": "Запросить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg}. Вы уверены?",
        "confirm": "Да, уверен"
      },
      "res": {
        "success": "Запрос выполняется. Пожалуйста, дождитесь обновления данных",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.base-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.base-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
