export function hasPermission(access, route) {
  if (route.meta && route.meta.access) {
    return !route.meta.access || route.meta.access.some(i => access[i]);
  }
  return true;
}

export function filterRoutesForNav(routes, nav, access) {
  return routes.reduce((acc, route) => {
    if (route.meta && route.meta.navigation)
      if (
        route.meta &&
        route.meta.navigation &&
        route.meta.navigation[nav] &&
        hasPermission(access, route)
      ) {
        acc.push({
          name: route.name,
          to: route.name ? { name: route.name } : { path: route.path },
          ...route.meta.navigation[nav],
          access: route.meta.access,
        });
      }
    if (route.children && route.children.length) {
      const children = filterRoutesForNav(route.children, nav, access);
      acc = [...acc, ...children];
    }
    return acc;
  }, []);
}
