const { isNaN, inRange } = require('lodash');

export const mpsList = [
  {
    name: 'visa',
    image: 'visa.svg',
    range: [{ from: 400000, to: 499999 }],
  },
  {
    name: 'mastercard',
    image: 'mastercard.svg',
    range: [
      { from: 222100, to: 272099 },
      { from: 510000, to: 559999 },
    ],
  },
  {
    name: 'mir',
    image: 'mir.svg',
    range: [{ from: 220000, to: 220499 }],
  },
];

export function getBin(str) {
  return parseInt(str.substring(0, 6));
}

export function getMps(bin) {
  if (!bin || bin.length < 6) return null;
  return mpsList.find(mps => mps.range.some(range => inRange(bin, range.from, range.to)));
}

export function getFormattedCardNumber(name) {
  const isBin = !isNaN(getBin(name));
  if (!isBin) return name;
  const s1 = name.substring(0, 4);
  const s2 = name.substring(4, 8);
  const s3 = name.substring(8, 12);
  const s4 = name.substring(12, 16);
  return s1 + ' ' + s2 + ' ' + s3 + ' ' + s4;
}
