import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';
import MyRsxExtApi from '@/services/api/MyRsxExtApi';
const actions = {
  fetchPricelist({ commit, dispatch }, payload = {}) {
    commit('setLoading', true);
    const params = {
      onlyavailable: 'on',
      itemtype: 'pleskshared',
      tariffpage: 'basic',
      ...payload,
    };
    return BillMgrApi.get('api2/billmgr/pricelist.export', { params })
      .then(data => {
        if (data.pricelist) commit('addToList', data.pricelist);
        dispatch('fetchWizard');
      })
      .finally(() => commit('setLoading', false));
  },
  fetchWizard({ commit }, payload = {}) {
    const params = {
      func: 'pleskshared.order',
      ...payload,
    };
    return BillMgrApi.get('billmgr', { params }).then(data => {
      if (data.wizard) commit('setWizard', data.wizard);
      if (data.model) commit('setModel', data.model);
    });
  },
  fetchParams({ commit, state, dispatch }, { id = null, period = 1 }) {
    const innerId = id || state.current;
    const params = {
      ...state.model,
      period,
      sok: 'ok',
      pricelist: innerId,
      newface: 'on',
      func: state.wizard.params,
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData)
      .then(data => {
        commit('setItemProp', {
          id: id,
          prop: 'info',
          value: data,
        });
      })
      .then(() => {
        //dispatch('fetchAddonsExt', id);
      })
      .catch(e => console.error(e));
  },
  fetchAddonsExt({ commit }, id) {
    return MyRsxExtApi.get(`addons/${id}`)
      .then(data => {
        commit('setItemProp', { prop: 'addonsExt', value: data });
      })
      .catch(e => console.error(e));
  },
  fetchCalc(_ctx, model) {
    const params = {
      func: 'pricelist.calculate',
      out: 'bjson',
      auth: localStorage.getItem('token'),
      ...model,
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData);
  },
  sendOrder({ state }, model) {
    const params = {
      func: state.wizard.order,
      out: 'bjson',
      auth: localStorage.getItem('token'),
      ...model,
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData);
  },
  setCurrent({ commit }, id) {
    commit('setCurrent', id);
  },
  reset({ commit }) {
    commit('resetState');
  },
};

export default actions;
