import { BillMgrStatus } from './status';
import { VpsTariff } from './VpsTariff';
import { AllTariff } from './AllTariff';
import { CloudTariff } from './CloudTariff';
import { VirtualTariff } from './VirtualTariff';
import { RentTariff } from './RentTariff';
import { AllowedUser } from './UserProfile';
import { DomainTariff } from './DomainTariff';
import { DnsHostTariff } from './DnsHostTariff';
import { IspTool, IspTools } from '../base/IspTools';
import BillMgrApi from '@/services/api/BillMgrApi';
import { BillingProfile } from './Billing';
import { Mo365Tariff } from './Mo365Tariff';
import { ColoTariff } from './ColoTariff';
import { AntiDdosTariff } from './AntiDdosTariff';
import { AcronisTariff } from './AcronisTariff';
import { PrivateTariff } from './PrivateTariff';
import { SSLTariff } from './SSLTariff';
import { VhostTariff } from './VhostTariff';
import { ZmailTariff } from './ZmailTariff';
import { LicenseTariff } from './LicenseTariff';
import { DedicTariff } from './DedicTariff';
import { SaasTariff } from './SaasTariff';
import { IdprotectTariff } from './IdprotectTariff';
import { BitrixTariff } from '@/models/BillMgr/BitrixTariff';
import { PleskTariff } from '@/models/BillMgr/PleskTariff';
import { VirtuozzoTariff } from '@/models/BillMgr/VirtuozzoTariff';
import { MicrosoftTariff } from '@/models/BillMgr/MicrosoftTariff';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { OPTariff } from '@/models/OP/OPTariff';
import { CDNTariff } from '@/models/BillMgr/CDNTariff';
import { QualityTariff } from '@/models/BillMgr/QualityTariff';

/**
 * Class for billmanager's action
 */
export class BillMgrTool extends IspTool {
  constructor(args) {
    super(args);
  }

  /**
   * Special check for status condition
   * @param {BillMgrStatus|string} status
   * @param {string|number} value
   * @private
   */
  _checkStatus(status, value) {
    return status instanceof BillMgrStatus ? status.toNumber() === value : status == value;
  }

  /**
   * Overrides parent's condition-check
   * @param {IspToolCondition} cond
   * @param {Object} instance
   * @private
   */
  checkCondition(cond, instance) {
    if (cond.name === 'status') return this._checkStatus(instance[cond.name], cond.value);
    else return instance[cond.name] == cond.value;
  }
}

/**
 * Abstract class for collection of billmanager's actions
 */
export class BillMgrTools extends IspTools {
  constructor(args) {
    super(args);
    this.api = BillMgrApi;
    this.url = '/billmgr';
  }

  /**
   * Override IspTools's init
   * @param {Object} args arguments
   */
  _init(args) {
    for (let prop in args) {
      this[prop] = new BillMgrTool(args[prop]);
    }
  }
}
/**
 * Class for collection of billmanager's actions for AllServices
 */
export class AllTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['edit', 'prolong'];
    this._type = AllTariff;
  }
}
/**
 * Class for collection of OP actions for clouds
 */
export class OPTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = [
      'ask',
      'changepricelist',
      'edit',
      'gotoserver',
      'history',
      'new',
      'panel_button',
      'prolong',
    ];
    this._type = OPTariff;
  }
}
/**
 * Class for collection of billmanager's actions for colocations
 */
export class ColoTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = [
      'ask',
      'changepricelist',
      'edit',
      'gotoserver',
      'history',
      'new',
      'panel_button',
      'prolong',
    ];
    this._type = ColoTariff;
  }
}
/**
 * Class for collection of billmanager's actions for colocations
 */
export class SaasTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = [
      'ask',
      'changepricelist',
      'edit',
      'gotoserver',
      'history',
      'new',
      'panel_button',
      'prolong',
    ];
    this._type = SaasTariff;
  }
}
/**
 * Class for collection of billmanager's actions for vps
 */
export class VpsTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = [
      'ask',
      'changepricelist',
      'edit',
      'gotoserver',
      'history',
      'new',
      'panel_button',
      'prolong',
    ];
    this._type = VpsTariff;
  }
}
/**
 * Class for collection of billmanager's actions for SSL
 */
export class SSLTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = SSLTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Zmail
 */
export class ZmailTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = ZmailTariff;
  }
}
/**
 * Class for collection of billmanager's actions for CDN
 */
export class CDNTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = CDNTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Idprotect
 */
export class IdprotectTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = IdprotectTariff;
  }
}
/**
 * Class for collection of billmanager's actions for SSL
 */
export class LicTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = LicenseTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Bitrix licenses
 */
export class BitrixTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = BitrixTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Plesk licenses
 */
export class PleskTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = PleskTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Plesk licenses
 */
export class SoftTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = SoftTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Virtuozzo licenses
 */
export class VirtuozzoTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = VirtuozzoTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Microsoft licenses
 */
export class MicrosoftTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'history', 'new', 'filter', 'prolong'];
    this._type = MicrosoftTariff;
  }
}
/**
 * Class for collection of billmanager's actions for clouds
 */
export class CloudTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'start', 'stop', 'stat'];
    this._type = CloudTariff;
  }
}
/**
 * Class for collection of billmanager's actions for openStack
 */
export class StackTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'start', 'stop', 'stat'];
    this._type = StackTariff;
  }
}
/**
 * Class for collection of billmanager's actions for virtuals
 */
export class VirtualTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = VirtualTariff;
  }
}
/**
 * Class for collection of billmanager's actions for quality
 */
export class QualityTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = QualityTariff;
  }
}
/**
 * Class for collection of billmanager's actions for vhost
 */
export class VhostTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = VhostTariff;
  }
}
/**
 * Class for collection of billmanager's actions for microsoft office
 */
export class Mo365Tools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = Mo365Tariff;
  }
}
/**
 * Class for collection of billmanager's actions for antiDdosGcore
 */
export class AntiDdosTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = AntiDdosTariff;
  }
}
/**
 * Class for collection of billmanager's actions for privateCloud
 */
export class PrivateTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = PrivateTariff;
  }
}
/**
 * Class for collection of billmanager's actions for acronis backup
 */
export class AcronisTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = AcronisTariff;
  }
}
/**
 * Class for collection of billmanager's actions for rentServers
 */
export class RentTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = RentTariff;
  }
}
/**
 * Class for collection of billmanager's actions for Dedic
 */
export class DedicTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['ask', 'edit', 'gotoserver', 'history', 'new', 'prolong'];
    this._type = DedicTariff;
  }
}
/**
 * Class for collection of billmanager's actions for user profile
 */
export class AllowedUserTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['new', 'edit', 'delete', 'resume', 'suspend', 'rights'];
    this._type = AllowedUser;
  }
}

/**
 * Class for collection of billmanager's actions for domains
 */
export class DomainTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['register', 'edit', 'delete', 'history', 'ns', 'prolong', 'whois'];
    this._type = DomainTariff;
  }
}
/**
 * Class for collection of billmanager's actions for dns-host-tariff
 */
export class DnsHostTariffTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['edit', 'delete', 'history', 'gotoserver'];
    this._type = DnsHostTariff;
  }
}

/**
 * Class for collection of billmanager's actions for BillingProfile
 */
export class BillingProfileTools extends BillMgrTools {
  constructor(args) {
    super(args);
    this._required = ['new', 'edit', 'delete', 'reconciliation'];
    this._type = BillingProfile;
  }
}
