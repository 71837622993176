<template>
  <div class="notification-settings">
    <!--    <div class="notifications-settings__desc standart-text">-->
    <!--      {{ $t('desc') }}-->
    <!--    </div>-->
    <div class="notification-settings__form">
      <div v-for="item in list" :key="item.key" class="notification-settings__group standart-text">
        <div class="notification-settings__group-title standart-title">
          {{ $t(`type.${item.key}`) }}
        </div>
        <div class="notification-settings__group-fields">
          <base-checkbox
            v-for="field in item.list"
            :key="field.name"
            :name="field.name"
            :value="formData[field.name]"
            :true-value="field.trueValue"
            :false-value="field.falseValue"
            class="notification-settings__checkbox medium-text"
            @change="onChange(field, $event)"
          >
            {{ $t(`label.${field.key}`) }}
          </base-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
export default {
  name: 'NotificationSettings',
  components: {
    BaseCheckbox,
  },
  props: {
    list: {
      type: Array,
      required: true,
      validaror: arr => arr.every(item => item.key && item.list),
    },
  },
  data() {
    return {
      formData: {},
      initialBackupData: {},
      isReady: false,
      formLoading: false,
    };
  },
  watch: {
    list: function (val) {
      this.fillInitialData(val);
    },
  },
  mounted() {
    this.fillInitialData(this.list);
  },
  methods: {
    onChange(item, value) {
      this.formData[item.name] = value;
      this.$emit('change', this.formData);
    },
    fillInitialData(list) {
      this.formData = list.reduce((acc, item) => {
        item.list.forEach(field => {
          acc[field.name] = field.value;
          this.initialBackupData[field.name] = field.value;
        });
        return acc;
      }, {});
      this.isReady = true;
      this.$emit('ready', this.initialBackupData);
      this.$emit('change', this.formData);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Настройка уведомлений",
      "desc": "Мы всегда присылаем только самые необходимые сообщения и обещаем вообще никогда вам не звонить. Однако вы можете настроить приходящие уведомления под себя",
      "label": {
        "ntemail": "Email",
        "ntsms": "SMS",
        "ntmessenger": "Telegram"
      },
      "type": {
        "finance_notice": "Финансовые уведомления",
        "news_notice": "Новостные уведомления",
        "service_notice": "Уведомления по услугам",
        "support_notice": "От службы поддержки"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.notification-settings {
  &__form {
    margin-top: 1.5rem;
  }

  &__group {
    &-fields {
      margin-top: 1rem;
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;
    }
  }

  &__checkbox {
    & + & {
      margin-left: 1rem;

      +breakpoint(sm-and-up) {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
