<template>
  <div class="base-basket">
    <transition name="fade" mode="out-in">
      <base-loader v-if="(loading && !list.length) || sending" />
      <div v-else-if="list.length" class="base-basket__list">
        <base-basket-item
          v-for="item in list"
          :key="item.id"
          :item="item"
          :mode="mode"
          :editable="editable"
          :deletable="deletable"
          class="base-basket__item"
          @remove="onRemove"
          @edit="onEdit"
        />
      </div>
      <div v-else class="base-basket__empty standart-text">
        {{ $t('empty') }}
      </div>
    </transition>
  </div>
</template>

<script>
import { BasketItem } from '@/models/BillMgr/Basket';
import BaseBasketItem from './BaseBasketItem.vue';
export default {
  name: 'BaseBasket',
  components: { BaseBasketItem },
  props: {
    list: {
      type: Array,
      default: () => [],
      validator: arr => !arr.length || arr.every(i => i instanceof BasketItem),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sending: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'collapsed',
      validator: val => ['collapsed', 'expanded'].includes(val),
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onRemove(item) {
      this.$emit('remove', item);
    },
    onEdit(params) {
      this.$emit('edit', params);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Корзина",
    "total": "Итого",
    "more": "Подробнее",
    "empty": "Корзина пуста"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.base-basket {
  &__item {
    & + & {
      margin-top: 1.75rem;
    }
  }
}
</style>
