import { OPState } from '@/models/OP/OPState';
import { OPTask } from '@/models/OP/OPTask';
import { OPBalancerStatusOperating, OPBalancerStatusProvisioning } from '@/models/OP/OPBalancer';

export class OPTariff extends Object {
  /*************
   *
   * i - чтобы можно было отличить и добавить валидацию на сущность, если это понадобится в дальнейшем
   * _source - отвечает за это
   *
   * *****************/
  constructor(args, i) {
    super(args);
    this._init(args, i);
  }
  /*
   * из разных модулей опенстака прилетает разный формат даты
   *     .000000 -> не отображает нормально часовой пояс
   *     Z -> отображает нормально
   * поэтому приводим к единому виду
   *
   * */
  parseDate(args) {
    let date = args ? args.split('.') : null;
    let parsedDate;
    if (date) {
      parsedDate = date && date.length > 1 ? date[0] + 'Z' : date[0];
    } else parsedDate = null;
    return parsedDate ? new Date(parsedDate) : null;
  }

  _init(args, i) {
    this._source = i;
    Object.keys(args).forEach(k => {
      if (args[k] === args.created) {
        this[k] = this.parseDate(args[k]);
      } else if (args[k] === args.created_at) {
        this[k] = this.parseDate(args[k]);
      } else if (args[k] === args.provisioning_status) {
        this[k] = new OPBalancerStatusProvisioning(args[k].split('-').join(''));
      } else if (args[k] === args.operating_status) {
        this[k] = new OPBalancerStatusOperating(args[k].split('-').join(''));
      } else if (args[k] === args.container_ref) {
        // console.log('container_ref', args[k], args[k].split('/').at(-1));
        this[k] = args[k];
        this.id = args[k].split('/').at(-1);
      } else if (args[k] === args.secret_ref) {
        // console.log('secret_ref', args[k], args[k].split('/').at(-1));
        this[k] = args[k];
        this.id = args[k].split('/').at(-1);
      } else if (args[k] === args.updated_at) {
        this[k] = args[k] ? this.parseDate(args[k]) : null;
      } else if (args[k] === args.updated) {
        this[k] = this.parseDate(args[k]);
      } else if (args[k] !== args.status && args[k] !== args['OS-EXT-STS:task_state']) {
        this[k] = args[k];
      } else if (args[k] !== args['OS-EXT-STS:task_state']) {
        this.status = new OPState(args[k].toLowerCase().split('-').join(''));
      } else if (args[k]) {
        this.task = new OPTask(args[k]);
      } else {
        this.task = new OPTask('нет'); //оставшийся вариант когда пустое поле - значит нет таски
      }

      // args[k] !== args.status && args[k] !== args['OS-EXT-STS:task_state']
      //   ? (this[k] = args[k])
      //   : args[k] !== args['OS-EXT-STS:task_state']
      //   ? (this.status = new OPState(args[k].toLowerCase().split('-').join('')))
      //   : args[k]
      //   ? (this.task = new OPTask(args[k]))
      //   : (this.task = new OPTask('none'));
    });
  }

  updateResources(list) {
    list.forEach(i => {
      // console.log(i);
      if (this[i.intname]) this[i.intname].value = i.value;
    });
  }

  get secretsId() {
    if (this._source !== 'containers') return null;
    if (this.secret_refs.length === 0) return null;
    return this.secret_refs.map(x => x.secret_ref.split('/').at(-1));
  }

  update(args) {
    this._init(args);
  }

  get server() {
    // console.log('server');
    if (this._source !== 'servers') return null;
    let network = [];
    let names = Object.keys(this.addresses).map(address => address);
    names.forEach(name => {
      // const port = this.addresses[name].filter(item => item['OS-EXT-IPS:type'] === 'fixed')[0];
      const port = this.addresses[name].filter(item => item['OS-EXT-IPS:type'] === 'fixed'); //.flat();
      port.network = name;
      network.push(port);
    });

    const server = {
      id: this.id,
      name: this.name,
      flavor: this.flavor,
      protocol: 80,
      // port: this.addresses,
      ports: network.flat(),
      weight: 1,
      currentPort: network.flat()[0],
      // port: this.addresses[Object.keys(this.addresses)].filter(
      //   x => x['OS-EXT-IPS:type'] === 'fixed'
      // ), //['.map(x => x.server)'] === 'fixed'),
      // network: this.addresses.map(
      // network: Object.keys(this.addresses).map(address => address),
      // owier: this.$store.state.moduleStack.networks,

      // network: this.$store.state.moduleStack.networks.find(x => x.name===)
    };
    // console.log(this.$store.state.moduleStack.networks);
    return server;
    // return 'server';
  }

  // get port() {
  getPort(id) {
    // console.log(id);
    if (this._source !== 'ports') return null;
    if (this.device_owner !== 'compute:nova') return null;
    if (this.name.startsWith('octavia-')) return null;
    const port = {
      server: this.device_id,
      network: this.network_id,
      // ips: this.fixed_ips,
      // subnetId:
      // port: this.fixed_ips[0],
      port: this.fixed_ips,
    };
    return port;
  }

  // get configurator() {
  //   let list = [];
  //   console.log('config');
  //   if (this.info) {
  //     const { customfields, groups, slist } = this._info;
  //     const group = groups.resources
  //       ? [...groups.resources, ...groups.default]
  //       : [...groups.default];
  //     list = group.reduce((acc, i) => {
  //       const item = customfields[i];
  //       if (!item) return acc;
  //       const addonDetails = this.info.details.addons[i];
  //       const addonExt = this.addonsExt ? this.addonsExt.find(i => i.intname === item.intname) : -1;
  //       const { addonlimit, cost } = addonDetails || {};
  //       if (item && item.name !== 'serverid') {
  //         const props = {};
  //         if (addonDetails && addonlimit) props.limit = addonlimit;
  //         if (addonDetails && cost) props.addonSum = cost;
  //         if (item.type === 'select' && slist[item.name]) props.options = slist[item.name];
  //         if (~addonExt) props.ext = addonExt;
  //         if (this.in_pay) props.readonly = 'yes';
  //         // acc.push(
  //         //   new OpenStackAddon({
  //         //     ...item,
  //         //     ...props,
  //         //   })
  //         // );
  //       }
  //       return acc;
  //     }, []);
  //   }
  //   return list;
  // }
}
