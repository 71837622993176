<template>
  <div class="error-layout">
    <div class="error-layout__content">
      <div class="error-layout__code">
        {{ code }}
      </div>
      <div class="error-layout__title">
        {{ $t(code) }}
      </div>
      <base-button class="error-layout__back" @click="$router.back()">
        {{ $t('back') }}
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorLayout',
  props: {
    code: {
      type: String,
      default: '404',
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "401": "Кажется, у вас нет доступа к этой странице",
      "404": "Такой страницы не существует",
      "back": "Назад"
    },
    "en": {
      "401": "Access denied",
      "404": "Page is not found",
      "back": "back"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.error-layout {
  position: absolute;
  size(100%);
  flexy(center, center);
  background: radial-gradient(100% 100% at 100% 0%, #1D2126 0%, #0C0E12 100%);
  color: $color-light.white;
  text-align: center;

  &__code {
    font-size: ($font-size-large * 2);
    font-weight: $font-weight-bold;

    +breakpoint(sm-and-up) {
      font-size: ($font-size-large * 3);
    }
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-medium;
    margin-top: 0.5em;
  }

  &__back {
    margin-top: 2rem;
  }
}
</style>
