import { sortFuncsByOrder } from '@/utils/sortFunctions';

export const GETTERS = {
  GET_SORTED_LIST: state => {
    const list = [...state.list];
    const { prop, order } = state.sort;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_IP_LIST: state => {
    return state.ipList;
  },
  GET_CURRENT: state => {
    return state.current;
  },
  GET_CURRENT_VPS: state => {
    return state.current ? state.list.find(i => i.id === state.current) : {};
  },
  GET_CURRENT_SERVER_ID: state => {
    return state.current ? state.list.find(i => i.id === state.current)._serverInfo.envID : {};
  },
  getVpsCount: state => {
    return state.vpsBackup;
  },
  getNamesVps: state => {
    return state.vpsName;
  },
  getModels: state => {
    return state.model;
  },
  isPaymentCard: state => {
    return state.isPaymentCard;
  },
  // getNodeList: state => {
  //   if (!state.nodeList) return null;
  //   return this.state.nodeList;
  // },
  current(state) {
    if (!state.current) return null;
    // const instance = state.list.find(i => i.id === state.current);
    const instance = state.ip;
    if (!~instance) return null;
    else return instance;
  },
};
