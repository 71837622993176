import { sortFuncsByOrder } from '@/utils/sortFunctions';

export const GETTERS = {
  // GET_SORTED_LIST: state => {
  //   console.log(state.fetchAllTickets);
  //   const allTickets = state.fetchAllTickets ? [...state.fetchAllTickets] : [];
  //   console.log(allTickets);
  //   const { prop, order } = state.sort;
  //   allTickets ? allTickets.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop])) : [];
  //   return allTickets;
  // },

  GET_ALL_TICKETS: state => {
    return state.allTickets;
  },
  // unreaded: state => state.newTickets,
  unreaded: state => state.allTickets.filter(t => t.tstatus_orig === '100_client'),
  incedents: state => state.allTickets.filter(t => t.abuse_id).length,
  GET_ALL_TICKETS_ARHIVE: state => {
    return state.allTicketsArhive || [];
  },
  GET_ALL_INCIDENT: state => {
    return state.allIncident || [];
  },
  GET_ALL_NOTIFICATIONS: state => {
    return state.AllNotifications;
  },
  GET_CURRENT_TICKET_ID: state => {
    return state.currentTicketID;
  },
  GET_CURRENT_STATUS_COLOR: state => {
    if (state.currentStatus === '6') {
      return 'success';
    } else if (state.currentStatus === '1') {
      return 'primary';
    } else {
      return 'error';
    }
  },
  GET_FILES: state => {
    return state.files;
  },
  GET_SERVICE_LIST: state => {
    return state.serviceList ? state.serviceList : '';
  },
  GET_DEPARTMENT: state => {
    return state.department ? state.department : '';
  },
};
