import axios from 'axios';
// import Vue from 'vue';

const defs = {
  // baseURL: 'https://api-os.rusonyx.ru',
  baseURL: 'https://cloudapi.rusonyx.ru',
  // identity: 'identity/v3',
  // compute: 'compute/v2.1',
  // image: 'image/v2',
  // network: 'network/v2.0',
  // volume: 'volume/v3',
  // port: '8080',
};

const OpenStackApi = axios.create({
  baseURL: defs.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: false,
});

OpenStackApi.interceptors.request.use(
  config => {
    // if (config.method === 'put') {
    //   console.log(OpenStackApi);
    //   console.log('fdfdfdf');
    //   console.log('config.headers', config.headers);
    //   config.headers['Content-Type'] = 'application/octet-stream';
    //   config.headers.common['Accept'] = 'application/octet-stream';
    // }
    // console.log(OpenStackApi.interceptors.request);
    // if (config.method === 'put') {
    //   const key = localStorage.getItem('stack');
    //   if (key) {
    //     config.headers['x-auth-token'] = key;
    //     config.headers['OpenStack-API-Version'] = 'volume 3.68, compute 2.90';
    //     config.headers.config.baseURL = `${defs.baseURL}`;
    //     return config;
    //   }
    // }
    // console.log(config);
    // const ls = localStorage.getItem('vuex');
    // const parsed = JSON.parse(ls);
    // console.log('store', this.$store.state.moduleVPS.key);
    // const key = parsed.moduleVPS.key;
    const key = localStorage.getItem('stack');
    if (key) {
      config.headers['x-auth-token'] = key;
      config.headers['OpenStack-API-Version'] = 'volume 3.68, compute 2.90, octavia 2.5';
      config.baseURL = `${defs.baseURL}`;
    }

    // console.log(config);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

OpenStackApi.interceptors.response.use(
  response => {
    // return response.data;
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default OpenStackApi;
