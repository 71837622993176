<template>
  <base-table-row :columns="columns" v-bind="item" col-class="allowed-users__item">
    <template #name>
      <span class="allowed-users__item-name standart-text">
        {{ item.name }}
      </span>
    </template>
    <template #realname>
      <span class="allowed-users__item-realname standart-text">
        {{ item.realname }}
      </span>
    </template>
    <template #email>
      <span class="allowed-users__item-email standart-text">
        {{ item.email }}
      </span>
    </template>
    <template #access>
      <div class="allowed-users__item-access standart-text">
        {{ $t(`access.${item.access}`) }}
      </div>
    </template>
    <template #state>
      <main-label :color="item.state.color" theme="plain">
        {{ $t(`status.${item.state.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="allowed-users__popover"
      >
        <plain-button icon="more" class="allowed-users__more" @click="togglePopoverState" />
        <allowed-user-context-menu
          slot="popover"
          :list="getNav(item)"
          class="allowed-users__context-menu"
          @click="onContextClick"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import AllowedUserContextMenu from '../components/AllowedUserContextMenu.vue';
import popoverFix from '@/mixins/popoverFix';
import { BillMgrTools } from '@/models/BillMgr/Tools';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'AllowedUsersTableRow',
  components: {
    BaseTableRow,
    MainLabel,
    AllowedUserContextMenu,
  },
  mixins: [popoverFix, showErrorModal],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    tools: {
      type: BillMgrTools,
      required: true,
    },
    menuTools: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
    };
  },
  methods: {
    setWarnings(warnings) {
      this.warnings = warnings.reduce((acc, warn) => {
        acc[warn.elid] = warn.msg;
        return acc;
      }, {});
    },
    getNav(user) {
      return this.menuTools.reduce((acc, item) => {
        if (this.tools[item.key] && this.tools[item.key].isEnable(user)) {
          acc.push({
            user,
            ...this.tools[item.key],
            ...item,
            disabled: item.key === 'rights' && user.access === 'on',
          });
        }
        return acc;
      }, []);
    },
    onContextClick(item) {
      if (item.askSure) {
        this.askSure({
          title: this.$t(`actions.${item.key}.title`),
          msg: this.$t(`actions.${item.key}.text`, { name: item.user.realname }),
          confirm: this.$t(`actions.${item.key}.confirm`),
        })
          .then(() => {
            if (item.callback) item.callback(item);
            else {
              this.updateUser({ id: item.user.id, func: item.func }).catch(e => {
                if (e.warnings && e.warnings.length) {
                  // this.showError(e.warnings[0]);
                  this.showError('Удалить пользователя невозможно');
                }
              });
            }
          })
          .catch(e => {
            if (e.warnings && e.warnings.length) {
              this.setWarnings(e.warnings);
            } else this.showError(e);
          })
          .finally(() => this.$modals.close());
      } else {
        if (item.callback) item.callback(item);
        else console.log(item.user.realname, item.func);
      }
    },
    askSure({ title, msg, confirm }) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'AskSure',
          title: title || null,
          text: msg || this.$t('askSure.default'),
          onClose: reject,
          footer: {
            confirm: {
              props: { title: confirm || this.$t('submit') },
              on: { click: resolve },
            },
            cancel: { on: { click: reject } },
          },
        });
      });
    },
    updateUser(payload) {
      return this.$store.dispatch('moduleProfile/updateAllowedUser', payload);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "access": {
        "on": "Да",
        "off": "Нет"
      },
      "askSure": "Вы уверены?",
      "actions": {
        "suspend": {
          "title": "Отключение доверенного пользователя",
          "text": "{name} не сможет входить в ваш личный кабинет пока доступ не будет восстановлен",
          "confirm": "Да, отключить"
        },
        "resume": {
          "title": "Включить доступ доверенного пользователя",
          "text": "{name} получит доступ в ваш личный кабинет",
          "confirm": "Да, включить"
        },
        "delete": {
          "title": "Удаление доверенного пользователя",
          "text": "{name} не сможет больше входить в ваш личный кабинет",
          "confirm": "Да, удалить"
        }
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-users {
  >>> &__item {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__item {
    &-name,
    &-realname,
    &-email {
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      padding: 0.75rem;
      margin: -0.75rem;
      box-sizing: content-box;
      max-width: 100%;

      &::after {
        +breakpoint(ml-and-up) {
          content: '';
          display: block;
          size(2.5em, 100%);
          absolute(0px, 100%);
          position absolute;
          top: 0;
          left: calc(100% - 0.75rem);
          background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
          opacity: 0;
        }
      }
      &:hover::after {
        +breakpoint(ml-and-up) {
          opacity: 1;
        }
      }
    }
  }
}
</style>
