import container from '@/layouts/Clouds/container';

export const RouteClouds = {
  name: 'Clouds',
  path: '/cloud',
  component: container,
  redirect: {
    name: 'cloudMain',
  },
  meta: {
    navigation: {
      service: {
        // icon: 'el-icon-upload',
        icon: 'el-icon-cloudy',
        key: 'cloud-services',
        order: 3,
      },
    },
    // access: ['mainmenuservice.vdc2', 'support.clientticket'],
    access: ['mainmenuservice.vdc2'],
  },
  children: [
    {
      path: '',
      name: 'cloudMain',
      component: () => import(/* webpackChunkName: "cloud-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'CloudsOrder',
      component: () =>
        import(/* webpackChunkName: "clouds-order" */ './pages/Order/CloudsOrder.vue'),
      children: [
        {
          path: '/clouds/order',
          redirect: { name: 'cloudsOrder' },
        },
        {
          path: '/clouds/order/:id/:period',
          name: 'cloudOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "clouds-order-details" */ './pages/Order/CloudsOrderDetails.vue'
            ),
        },
        {
          // костыль для project=3  - нужен во всем проекте - для русоникса - исправить на загрузку из стора
          path: '/cloudsBackup/order/:id/:period/3',
          redirect: { name: 'acronisOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'tariffClouds',
      component: () =>
        import(/* webpackChunkName: "cloud-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewClouds' },
      props: true,
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "cloud-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewClouds',
        },
        {
          path: 'config',
          component: () =>
            import(
              /* webpackChunkName: "cloud-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configClouds',
        },
        {
          path: 'history',
          component: () =>
            import(
              /* webpackChunkName: "cloud-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyClouds',
        },
      ],
    },
  ],
};
