import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';

export const ACTIONS = {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'dashboard.items',
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchListBlock({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'dashboard.items',
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST_BLOCK', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    // if (!payload.func) params.func = 'licvz.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchItem({ commit }, payload) {
    const silent = payload.silent || false;
    const needSaveState = payload.state || false;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = `/api2/billmgr/${payload.item}`;
      const params = {
        filter: 'on',
        out: 'none',
        metadata: 'on',
        id: payload.id,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_ITEMS', data.elem);
          commit('SET_TOOLS', data.metadata.toolbtndata);
          if (needSaveState) commit('SET_STATE', '');
          resolve(data.elem[0]);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  setAction({ commit }, payload) {
    const state = payload.state || false;
    const current = payload.current || false;
    const flag = payload.flag || false;
    if (state) commit('SET_STATE', state === 'clear' ? '' : state);
    if (flag) commit('SET_FLAG', flag !== 'clear');
    if (current) commit('SET_CURRENT', current === 'clear' ? '' : current);
  },
  fetchItemDetail({ commit }, payload) {
    const silent = payload.silent || false;
    const needSaveState = payload.state || false;
    if (!silent) commit('SET_IS_LOADING', true);
    const params = {
      elid: payload.id,
      func: `${payload.item}.edit`,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { slist, model } = data;
          commit('SET_ITEMS', model);
          if (needSaveState) commit('SET_STATE', model.autoprolong ? 'on' : 'off');
          if (needSaveState) commit('SET_FLAG', true);
          if (needSaveState) commit('SET_CURRENT', model.id);
          commit('SET_PROLONG', { id: model.id, slist: slist.autoprolong });
          resolve(data);
        })
        .catch(err => reject(err))
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  paymethod({ commit }, payload) {
    commit('paymethod', payload);
  },

  // reset store
  reset({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_LIST', []);
        commit('SET_CURRENT', '');
        commit('paymethod', '');
        commit('SET_IS_LOADING', true);
        resolve();
      }, delay);
    });
  },
};
