export const BillMgrPeriods = {
  0: 'infinite',
  1: 'month',
  3: 'three_months',
  6: 'six_months',
  12: 'one_year',
  24: 'month',
  36: 'three_year',
  48: 'month',
  60: 'month',
  120: 'month',
  '-50': 'day',
  '-100': 'trial',
  '-500': 'transfer',
  '-1000': 'hour',
};

export const BillMgrPeriodsLess = {
  year: 'month',
};

export const BillMgrPeriodsMore = {
  month: 'year',
};

export const BillMgrPeriodsRelation = {
  year: 12,
};

export function transformPeriodToMore(periodLen, periodType = null) {
  periodType = periodType || BillMgrPeriods[periodLen];
  let periodMore = BillMgrPeriodsMore[periodType] || null;
  let relation = periodMore ? BillMgrPeriodsRelation[periodMore] || null : null;
  if (periodMore && relation && periodLen % relation === 0) {
    periodType = periodMore;
    periodLen = periodLen / relation;
  }
  return { periodLen, periodType };
}
