<template>
  <div class="base-empty" :class="classes">
    <div v-if="$slots.title || title" class="base-empty__title medium-title">
      <slot name="title">{{ title }}</slot>
    </div>
    <div v-if="$slots.default || text" class="base-empty__text standart-text">
      <slot>
        <p>
          {{ text }}
          <plain-button v-if="textlink" :href="textlink" target="_blank" color="primary">
            {{ textlink }}
          </plain-button>
        </p>
      </slot>
    </div>
    <div v-if="stacklink1" class="base-empty__text2 standart-text">
      <slot>
        <p>
          {{ stacklink1.text }}
          <!--            :href="textlink2"-->
          <plain-button v-if="stacklink1" :to="stacklink1.to" target="_blank" color="primary">
            {{ stacklink1.title }}
          </plain-button>
        </p>
      </slot>
    </div>
    <div v-if="stacklink2" class="base-empty__text2 standart-text">
      <slot>
        <p>
          {{ stacklink2.text }}
          <!--            :href="textlink2"-->
          <plain-button v-if="stacklink2" :to="stacklink2.to" target="_blank" color="primary">
            {{ stacklink2.title }}
          </plain-button>
        </p>
      </slot>
    </div>
    <div v-if="stacklink3" class="base-empty__text2 standart-text">
      <slot>
        <p>
          {{ stacklink3.text }}
          <!--            :href="textlink2"-->
          <plain-button v-if="stacklink3" :to="stacklink3.to" target="_blank" color="primary">
            {{ stacklink3.title }}
          </plain-button>
        </p>
      </slot>
    </div>
    <div v-if="text2" class="base-empty__text2 standart-text">
      <slot>
        <p>
          {{ text2 }}
          <plain-button v-if="textlink2" :href="textlink2" target="_blank" color="primary">
            {{ textlink2 }}
          </plain-button>
        </p>
      </slot>
    </div>
    <!--    <plain-button :to="{ name: 'ArchiveRequest' }" icon="arrow-left" color="dim">-->
    <!--      {{ $t('back') }}-->
    <!--    </plain-button>-->
    <div v-if="text3" class="base-empty__text2 standart-text">
      <slot>
        <p>
          {{ text3 }}
          <plain-button v-if="textlink3" :href="textlink3" target="_blank" color="primary">
            {{ textlink3 }}
          </plain-button>
        </p>
      </slot>
    </div>
    <div v-if="$slots.link || link" class="base-empty__link">
      <slot name="link">
        <base-button v-if="link" :disabled="link.disabled" :to="link.to">
          {{ link.title }}
        </base-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseEmpty',
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    text1: {
      type: String,
      default: null,
    },
    textlink: {
      type: String,
      default: null,
    },
    textlink2: {
      type: String,
      default: null,
    },
    textlink3: {
      type: String,
      default: null,
    },
    text2: {
      type: String,
      default: null,
    },
    text3: {
      type: String,
      default: null,
    },
    link: {
      type: Object,
      default: null,
      validator: val => !val || (val.title && val.to),
    },
    stacklink1: {
      type: Object,
      default: null,
      validator: val => !val || (val.title && val.to),
    },
    stacklink2: {
      type: Object,
      default: null,
      validator: val => !val || (val.title && val.to),
    },
    stacklink3: {
      type: Object,
      default: null,
      validator: val => !val || (val.title && val.to),
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const base = 'base-empty';
      return {
        [`${base}--narrow`]: this.narrow,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-empty {
  flexy(flex-start, center, nowrap, column);
  flex: 1 1 auto;
  size(100%);
  max-width: 34em;
  margin: 0 auto;
  padding: 3.75rem 0 2rem;

  +breakpoint(sm-and-up) {
    padding-top: 13.75rem;
  }

  &--narrow {
    padding: 2rem 0;
  }

  &__title {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__text {
    text-align: center;
  }

  &__text2 {
    text-align: center;
    margin-top: 0.75rem;
  }

  &__link {
    margin-top: 1.5rem;
  }
}
</style>
