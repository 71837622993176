<template>
  <h3 class="mini-title">{{ text }}</h3>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.warn('DEPRECATED');
  },
};
</script>

<style lang="scss">
.mini-title {
  // margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--title);
}
</style>
