import Authentification from './components/Authentification.vue';
import Authorization from './components/Authorization.vue';
import Recovery from './components/Recovery.vue';
import RecoveryLogin from './components/RecoveryLogin.vue';
import Support from './components/Support.vue';
import Confirmation from './components/Confirmation.vue';
import Activation from './components/Activation.vue';
import ChangePassword from './components/ChangePassword.vue';

const LoginRoutes = [
  {
    path: 'reg',
    name: 'Reg',
    component: Authentification,
  },
  {
    path: '/',
    name: 'Auth',
    component: Authorization,
  },
  {
    path: 'recovery',
    name: 'Recovery',
    component: Recovery,
  },
  {
    path: 'recovery/login',
    name: 'RecoveryLogin',
    component: RecoveryLogin,
  },
  {
    path: 'support',
    name: 'AuthSupport',
    component: Support,
  },
  {
    path: 'confirmation',
    name: 'Confirmation',
    component: Confirmation,
  },
  {
    path: 'activation',
    name: 'Activation',
    component: Activation,
  },
  {
    path: 'password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
];
export default LoginRoutes;
