<template>
  <div class="services">
    <page-title class="service-main-title" text="Создание виртуального хостинга" />
    <h4 class="service-mini-title">Выберите тариф</h4>
    <transition-group class="service-container-content" name="slide-fade">
      <service-card
        v-for="item in datasetForTariffContainer"
        :ref="item.id"
        :key="item.id"
        :v-p-s="false"
        class="service-container-content__card"
        :config="item"
        @selected="getTariff"
        @click.native="getInfoAboutVirtual"
      >
      </service-card>
    </transition-group>

    <h4 class="service-mini-title">Дополнительно</h4>
    <transition name="slide-fade">
      <div v-if="cache.config">
        <div class="service-container-additions">
          <slider-block
            class="service-slider"
            :config="{
              title: 'Публичные IPv4-адреса',
              label: 'Ipv4',
              cost: '100',
            }"
            :slider-config="{
              min: 1,
              max: 15,
            }"
            @sliderValue="getSliderValueIPv"
          ></slider-block>
          <h4 class="service-mini-title">Итого</h4>
          <view-banner :titles="titles" :values="valuesForView" class="service-view" />

          <div class="service-controllers">
            <div class="service-controllers-row">
              <span class="service-text-strong">000 ₽</span>
              <span class="service-text">При оплате за</span>
              <selector class="service-controllers-row__select"></selector>
            </div>
            <div class="service-controllers-row">
              <main-button :description="`Оплатить ${5}}`" />
              <Summary text="В корзину" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div
        v-if="cache.config"
        class="active-service-card shadow-box service-card serice-card_type_small service-card_position_fixed"
        :class="{ active: isActive }"
      >
        {{ cache.config.title }}
      </div>
    </transition>
  </div>
</template>

<script>
const titles = [
  'Тариф',
  'Дисковое пространство',
  'Количество сайтов',
  'Почтовых ящиков',
  'IP адресов',
];
import getTariff from '../mixins/index.js';
import ServiceCard from '../components/ServiceCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import Selector from '@/components/Select/Select.vue';
import ViewBanner from '@/components/ViewBanner/ViewBanner.vue';
import MainButton from '@/components/Buttons/MainButton.vue';
import Summary from '@/components/Buttons/SummaryButton.vue';

export default {
  name: 'VirtualService',
  components: {
    SliderBlock,
    Selector,
    ServiceCard,
    ViewBanner,
    MainButton,
    Summary,
  },
  mixins: [getTariff],
  data() {
    return {
      titles: titles,
      IPv: 0,
      isActiveValue: false,
    };
  },
  computed: {
    datasetForTariffContainer() {
      // console.log(this.$store.getters["GET_ALL_TARIFFS"]);
      return this.$store.getters['GET_ALL_VIRTUALS'];
    },
    isActive() {
      if (this.cache.config) {
        return true;
      }
      return false;
    },

    valuesForView() {
      return [
        this.cache.config.title || 'Не выбрано',
        'title' || 'title',
        'title' || 'title',
        'title' || 'title',
        'title' || 'title',
      ];
    },
  },
  mounted() {
    this.$store.dispatch('SET_ALL_VIRTUALS', {
      itemtype: 'pleskshared',
      onlyavailable: 'on',
    });
  },
  methods: {
    getSliderValueIPv(value) {
      this.IPv = value;
    },
    getInfoAboutVirtual(value) {
      // console.log(value);
      // console.log(this.$refs);
    },
  },
};
</script>

<style lang="scss">
.serice-card_type_small {
  width: 50px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.service-card_position_fixed {
  position: fixed;
  right: 26px;
  bottom: 100px;
}
.service-slider {
  max-width: 694px;
}
</style>
