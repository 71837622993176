/**
 * Transform stringly boolean value to real boolean
 * @param {string} str 'on' | 'off', 'yes' | 'no', 'true' | 'false'
 */

export function parseBoolFromString(str) {
  if (str === 'on' || str === 'yes' || str === 'true') return true;
  else if (str === 'off' || str === 'no' || str === 'false') return false;
  else
    throw new Error(
      `Unexpected value '${str}'. 'true' | 'false', 'on' | 'off', 'yes' | 'no' are expected`
    );
}
