import Success from './components/Success.vue';
import Error from './components/Error.vue';

const ThinkPageRoutes = [
  {
    path: 'success',
    name: 'Success',
    component: Success,
  },
  {
    path: 'error',
    name: 'Error',
    component: Error,
  },
];
export default ThinkPageRoutes;
