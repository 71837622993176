import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';
import MyRsExtApi from '@/services/api/MyRsxExtApi';

export const ACTIONS = {
  fetchList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vdc2';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST', data.elem);
          commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateList({ commit }, payload) {
    if (!payload.silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vdc2';
      const reqPayload = Object.keys(payload).reduce((acc, k) => {
        if (k !== 'silent') acc[k] = payload[k];
        return acc;
      }, {});
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...reqPayload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('UPDATE_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!payload.silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async (context, id) => {
    try {
      await context.commit('SET_CURRENT', id);
      const tariff = await context.getters['GET_CURRENT_SERVER'];
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetail({ commit, dispatch }, id) {
    const params = {
      elid: id,
      // show_metadata: 'on',
      func: 'vdc2.edit',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          // console.log(data);
          const {
            customfields,
            slist,
            // groups,
            model,
          } = data;
          // console.log(customfields);
          const details = await dispatch('fetchTariffCalc', model);
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              // groups,
              details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'vdc2.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchHistory({ commit }, id) {
    const params = {
      func: 'service.history',
      elid: id,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'history',
            value: data.elem,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchTariffCalc(_ctx, model) {
    const params = {
      ...model,
      func: 'pricelist.calculate',
      auth: localStorage.getItem('token'),
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchBillMgrToolAction(_ctx, payload) {
    const params = {
      ...payload,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchConfig(_ctx, err = null) {
    return MyRsExtApi.get('cloud/params');
  },
  sendOrder(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      sok: 'ok',
      func: 'clientticket.edit',
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  // reset store
  reset({ commit }) {
    setTimeout(() => {
      commit('SET_CURRENT', '');
      commit('SET_LIST', []);
      commit('SET_IS_LOADING', true);
    }, 500);
  },
};
