import container from './container.vue';

export const RouteZmail = {
  path: '/zmail',
  name: 'zmail',
  redirect: { name: 'zmailMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Почты Zmail',
    navigation: {
      service: {
        key: 'zmail',
        // icon: 'el-icon-s-help',
        icon: 'el-icon-message',
        order: 8,
      },
    },
    access: ['mainmenuservice.zmail'],
  },
  children: [
    {
      path: '',
      name: 'zmailMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Почты Zmail',
      },
      component: () => import(/* webpackChunkName: "zmail-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'zmailOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Создание почты Zmail',
      },
      component: () => import(/* webpackChunkName: "zmail-order" */ './pages/Order/ZmailOrder.vue'),
      children: [
        {
          path: '/zmail/order',
          redirect: { name: 'zmailOrder' },
        },
        {
          path: '/zmail/order/:id/:period',
          name: 'zmailOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "zmail-order-details" */ './pages/Order/ZmailOrderDetails.vue'
            ),
        },
        {
          path: '/zmail/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'zmailOrderDetails' },
        },
        {
          path: '/zmail/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'zmailOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'zmailDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "zmail-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewZmail' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "zmail-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewZmail',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "zmail-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configZmail',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "zmail-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupZmail',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },

          component: () =>
            import(
              /* webpackChunkName: "zmail-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyZmail',
        },
      ],
    },
  ],
};
