export const getHtml = () => document.querySelector('body');
export const themeState = {
  state: localStorage,
  get(key) {
    return this.state.getItem(key);
  },
  set(key, val) {
    this.state.setItem(key, val);
  },
};

export const loadThemeColors = () => {
  const darkTheme = themeState.get('dark') || 0;
  if (darkTheme == 1) {
    getHtml().setAttribute('data-theme', 'dark');
  } else {
    getHtml().setAttribute('data-theme', 'light');
  }
};
