<template>
  <div class="cost-label standart-title">
    <span class="cost-label__cost">
      {{ (stack ? '' : cost > 0 ? '+' : '') + $n(cost, 'currency') }}
    </span>
    <span v-if="isNumber(period)" class="cost-label__period">{{ ' / ' + periodText }}</span>
  </div>
</template>

<script>
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import { isNumber } from 'lodash';
export default {
  name: 'CostLabel',
  props: {
    cost: {
      type: Number,
      default: 0,
    },
    stack: {
      type: Boolean,
      default: false,
    },
    period: {
      type: [String, Number],
      default: null,
      validator: val => !val || BillMgrPeriods[val],
    },
  },
  computed: {
    periodText() {
      const periodInt = parseInt(this.period);
      const periodInner = periodInt >= 12 ? periodInt / 12 : periodInt;
      const periodKey =
        periodInt > 12
          ? 'year_c'
          : (periodInt > 1 && periodInt < 3) ||
            (periodInt > 3 && periodInt < 6) ||
            (periodInt > 6 && periodInt < 12)
          ? BillMgrPeriods[this.period] + '_c'
          : BillMgrPeriods[this.period];
      return this.$tc(`period.${periodKey}`, periodInner);
    },
  },
  methods: {
    isNumber(n) {
      return isNumber(parseInt(n));
    },
  },
};
</script>

<style lang="stylus" scoped>
.cost-label {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 1.5rem;
  background: var(--cost-label);
  border-radius: 5px;
  color: var(--text);

  &__period {
    margin-left: 0.25em;
  }
}
</style>
