var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-block"},[_c('base-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
      _vm.config.restrictclientchange && _vm.config.restrictclientchange === 'off'
        ? null
        : _vm.config.disabled
        ? _vm.tooltipSettings
        : null
    ),expression:"\n      config.restrictclientchange && config.restrictclientchange === 'off'\n        ? null\n        : config.disabled\n        ? tooltipSettings\n        : null\n    "}],staticClass:"checkbox-block__checkbox",attrs:{"value":_vm.internalValue,"disabled":(!_vm.filter && _vm.label.includes('root')) || _vm.config.disabled,"true-value":_vm.config.trueValue,"false-value":_vm.config.falseValue,"hint":_vm.hint},on:{"change":_vm.onChange}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.config.required)?_c('span',{staticClass:"checkbox-block__asterisk error-color"},[_vm._v("*")]):_vm._e(),(_vm.config.link)?_c('div',{staticClass:"checkbox-block__link"},[_c('plain-button',{attrs:{"href":_vm.config.link.href,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.config.link.title)+" ")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }