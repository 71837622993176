<template>
  <div class="support">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="support__content">
        <p class="support__desc standart-text">
          {{ $t('desc') }}
          <a :href="`mailto:${supportEmail}`" class="support__link">
            <!--          <a href="mailto:managers@rusonyx.ru" class="support__link" target="_blank">-->
            {{ supportEmail }}
            <!--            managers@rusonyx.ru-->
          </a>
        </p>
        <div v-if="!astra" class="support__info standart-text">
          <p class="support__info-text">
            {{ $t('info.text') }}
            <a
              href="https://support.rusonyx.ru/index.php?/Knowledgebase/Article/View/561/115/vosstnovlenie-dostup-k-lichnomu-kbinetu#EmailOFF"
              class="support__link"
              target="_blank"
            >
              инструкции
            </a>
          </p>

          <!--          <ul class="support__info-list">-->
          <!--            <li v-for="(item, i) in info" :key="i" class="support__info-item">-->
          <!--              {{ $t(`info.list.${item}`) }}-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Support',
  data() {
    return {
      supportEmail: 'managers@rusonyx.ru',
      astra: false,
      info: ['name', 'tel'],
      isLoading: true,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('fetch', 'recovery')
        .then(data => {
          if (data.model && data.model.project && data.model.project === '6') {
            this.supportEmail = 'support@astracloud.ru';
            this.astra = true;
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<!--"list": {-->
<!--"name": "Ваши имя и фамилию.",-->
<!--"tel": "Номер телефона, который Вы указали при регистрации."-->
<!--}-->

<i18n>
  {
    "ru": {
      "desc": "Если вы не помните адрес электронной почты, то напишите запрос на восстановление на ",
      "info": {
        "text": "Подготовьте перечень подтверждающих документов, описанных в "

      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.support {
  &__link {
    text-decoration: none;
    color: $primary-color;

    &:hover,
    &:focus {
      outline: none;
      color: darken($primary-color, 20%);
    }
  }

  &__info {
    &-text {
      margin-bottom: 0.5em;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &-item {
      &::before {
        content: '—';
      }
    }
  }
}
</style>
