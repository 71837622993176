import BillMgrPricelist from './BillMgrPricelist';

export default class DomainPricelist extends BillMgrPricelist {
  constructor(args) {
    super(args);
  }

  get cost() {
    return parseFloat(this.price.period[0].amount) || 0;
  }

  get configurator() {
    return null;
  }
}
