<template>
  <div class="popup-container" @click="closeModalWindow">
    <div class="popup-body-container">
      <div class="modal-window" @click.stop>
        <div class="modal-window-title">
          <slot name="title"></slot>
        </div>

        <close :color="color" class="modal-window__close" @click.native="closeModalWindow"></close>
        <slot name="tooltip"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '../Icons/Close.vue';
export default {
  name: 'Popup',
  components: {
    Close,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    visibility() {
      return this.visible;
    },
    color() {
      return this.getTheme() == 1 ? 'white' : 'black';
    },
  },
  mounted() {
    console.warn('DEPRECATED');
  },
  methods: {
    closeModalWindow() {
      this.$store.commit('moduleApp/SET_IS_MODAL_OPENED', false);
      this.$emit('closeModal', true);
    },
    getTheme() {
      return this.$store.state.moduleApp.theme;
    },
  },
};
</script>

<style lang="scss">
body {
  & .popup {
    &-container {
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      display: block;
      z-index: 9999;
      // opacity: 0.95;
      backdrop-filter: blur(5px);
      opacity: 0.96;
    }
    &-body {
      box-sizing: border-box;
      padding: 40px;
      // opacity: 1;

      &-container {
        display: block;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        // width: 100%;
        // height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--main-theme);

        & div.modal-window {
          margin: auto;
          position: relative;
          background: var(--main-theme);
          &-title {
            margin-bottom: 40px;
          }
          &__close {
            position: absolute;
            cursor: pointer;
            top: -40px;
            right: -40px;
          }
        }
      }
    }
  }
}
</style>
