import BaseConfigurator from '../base/BaseConfigurator';

export class DnsHost extends BaseConfigurator {
  constructor(args) {
    super(args);
    this._required = ['active', 'dnssecstatus', 'name', 'user', 'dtype', 'billMgrDnsHost'];
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed some required props in DnsHost');
  }

  isValid(args) {
    return this._required.every(prop => !!args[prop]);
  }

  _init(args) {
    this.active = args.active;
    this.curruser = args.curruser;
    this.dnssecstatus = args.dnssecstatus;
    this.dtype = args.dtype;
    this.name = args.name;
    this.published = args.published;
    this.user = args.user;
    this.billMgrDnsHost = args.billMgrDnsHost;
  }

  update(args) {
    this._init(args);
  }

  get title() {
    return this.name;
  }

  get domain() {
    return this.name;
  }

  get expiredate() {
    return this.billMgrDnsHost.expiredate;
  }

  get cost() {
    return this.billMgrDnsHost.cost;
  }

  get status() {
    return this.billMgrDnsHost.status;
  }

  get id() {
    return this.billMgrDnsHost.id;
  }

  get pricelist() {
    return this.billMgrDnsHost.title;
  }

  get auth() {
    return this.billMgrDnsHost.auth;
  }

  get specs() {
    return this.billMgrDnsHost.specs;
  }

  get elid() {
    return this.domain;
  }

  get toolParams() {
    return {
      elid: this.elid,
      auth: this.auth,
    };
  }
}
