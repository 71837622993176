<template>
  <div class="avatar-upload">
    <div class="avatar-upload__img">
      <avatar
        :src="intSrc || value"
        shape="circle"
        size="big"
        :loading="isReading"
        class="avatar-upload__avatar"
        :class="{ pointer: !!intSrc }"
        @click="onAvatarClick"
      />
    </div>
    <div class="avatar-upload__info">
      <div class="avatar-upload__label standart-title">
        {{ label ? label : $t('label') }}
      </div>
      <div class="avatar-upload__notes note-text">
        <div
          class="avatar-upload__note avatar-upload__accept"
          :class="{ 'avatar-upload__note--error': hasTypeError }"
        >
          {{ $t('accept') }}
        </div>
        <div
          v-if="size"
          class="avatar-upload__note avatar-upload__size"
          :class="{ 'avatar-upload__note--error': hasSizeError }"
        >
          {{ $t('maxsize') + ' ' + sizeFromBytesToStr }}
        </div>
      </div>
      <div class="avatar-upload__btns">
        <div class="avatar-upload__btn-wrapper">
          <base-button
            theme="outlined"
            size="small"
            class="avatar-upload__btn avatar-upload__btn--choose"
          >
            {{ $t('btn.choose') }}
          </base-button>
          <label :for="inputId" class="avatar-upload__input-label">
            <input
              :id="inputId"
              type="file"
              :size="size"
              :accept="accept.join(', ')"
              class="avatar-upload__input"
              @input="onInput"
            />
          </label>
        </div>
        <base-button
          v-if="showSubmit"
          size="small"
          :disabled="!intValue"
          :loading="loading"
          class="avatar-upload__btn avatar-upload__btn--upload"
          @click="onUpload"
        >
          {{ $t('btn.upload') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue';
import AvatarResizer from '@/components/Avatar/AvatarResizer.vue';
export default {
  name: 'AvatarUpload',
  components: { Avatar },
  props: {
    label: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
      default: null,
      validator: val => !val || parseInt(val),
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intValue: '',
      intFile: '',
      intSrc: '',
      inputId: `avatar-input-${this._uid}`,
      accept: ['image/jpeg', 'image/png', 'image/gif'],
      hasTypeError: false,
      hasSizeError: false,
      isReading: false,
      scale: 1,
      rotation: 0,
    };
  },
  computed: {
    sizeFromBytesToStr() {
      let int = parseInt(this.size);
      if (!int) return '';
      const k = 1024;
      const si = ['b', 'Kb', 'Mb', 'Gb', 'Tb'];
      let i = 0;
      while (int >= Math.pow(k, i + 1) && i < si.length - 1) {
        i++;
      }
      const num = i ? int / Math.pow(k, i) : int;
      const unit = si[i];
      return `${num} ${unit}`;
    },
    isValid() {
      return !this.hasTypeError && !this.hasSizeError;
    },
  },
  watch: {
    value: function () {
      this.clear();
    },
    intFile(val) {
      this.$emit('change', val);
    },
  },
  methods: {
    onInput(e) {
      if (e.target.value) {
        this.intValue = e.target.files[0];
        this.validate(this.intValue, true, false)
          .then(() => {
            this.getImage(this.intValue).then(canvas => this.setValue(canvas));
          })
          .catch(() => this.clearValue());
      } else {
        this.clear();
      }
    },
    onUpload() {
      this.$emit('submit', this.intFile);
    },
    onAvatarClick() {
      if (!this.intSrc) return;
      this.getImage(this.intValue)
        .then(canvas => this.setValue(canvas))
        .catch(() => {});
    },
    getImage(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadstart = e => (this.isReading = true);
        reader.onload = e => {
          this.isReading = false;
          this.getResizedCanvas(e.target.result).then(canvas => {
            const size = this.getBase64Size(canvas.toDataURL());
            this.validate({ size }, false, true).then(resolve(canvas)).catch(reject());
          });
        };
        reader.readAsDataURL(file);
      });
    },
    getResizedCanvas(image) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          component: AvatarResizer,
          closeOnBackdrop: false,
          size: 'small',
          props: {
            width: this.width,
            height: this.height,
            radius: this.width / 2,
            value: image,
            scale: this.scale,
            rotation: this.rotation,
          },
          on: {
            change: file => {
              this.intValue = file;
            },
            save: ({ canvas, scale, rotation }) => {
              this.scale = scale;
              this.rotation = rotation;
              resolve(canvas);
              this.$modals.close();
            },
          },
          onClose: () => reject(),
        });
      });
    },
    getBase64Size(str) {
      return this.getBase64Decoded(str).length;
    },
    getBase64Decoded(str) {
      return atob(str.split(',')[1]);
    },
    validate(file, validateType = true, validateSize = true) {
      if (validateType) this.hasTypeError = !this.isValidType(file.type);
      if (validateSize) this.hasSizeError = !this.isValidSize(file.size);
      if (this.isValid) return Promise.resolve();
      else return Promise.reject();
    },
    isValidType(type) {
      return this.accept.includes(type);
    },
    isValidSize(size) {
      return this.size ? size <= parseInt(this.size) : true;
    },
    setValue(canvas) {
      this.intSrc = canvas.toDataURL();
      canvas.toBlob(blob => {
        this.intFile = new File([blob], this.intValue.name, { type: blob.type });
      });
    },
    clearErrors() {
      this.hasTypeError = false;
      this.hasSizeError = false;
    },
    clearValue() {
      this.intSrc = '';
      this.intValue = '';
      this.intFile = '';
    },
    clear() {
      this.clearErrors();
      this.clearValue();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "label": "Аватарка",
      "accept": "JPG, GIF или PNG",
      "maxsize": "Максимальный размер",
      "btn": {
        "choose": "Выбрать файл",
        "upload": "Загрузить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$au-size = 100px;
$au-gutter = 10px;
$au-gutter-sm = 20px;

.avatar-upload {
  width: 100%;

  +breakpoint(sm-and-up) {
    flexy(flex-start, flex-start);
  }

  &__img {
    flex: 0 0 $au-size;
    width: $au-size;
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }
  }

  &__avatar {
    size($au-size);
    font-size: 40px;
  }

  &__info {
    flex: 1 1 100%;
  }

  &__notes {
    margin: 0.75rem 0 1rem;
  }

  &__note {
    &--error {
      color: $error-color;
    }
  }

  &__btns {
    flexy(flex-start, flex-start, wrap);
    margin: ($au-gutter * -0.5);

    +breakpoint(sm-and-up) {
      margin: ($au-gutter-sm * -0.5);
    }
  }

  &__btn {

    &,
    &-wrapper {
      margin: ($au-gutter / 2);
      width: 100%;
      max-width: 180px;
      flex: 0 0 auto;

      +breakpoint(sm-and-up) {
        margin: ($au-gutter-sm / 2);
      }
    }

    &--upload {
      +breakpoint(sm-and-up) {
        width: auto;
      }
    }

    &-wrapper {
      position: relative;

      & ^[0]__btn {
        margin: 0;
      }
    }
  }

  &__input {
    display: none;

    &-label {
      display: block;
      absolute(0px, 0px);
      size(100%);
      cursor: pointer;
    }
  }
}
</style>
