import { sortFuncsByOrder } from '@/utils/sortFunctions';
const sortOrders = ['asc', 'desc'];

const getState = (propForFilter = 'id', propForSort = 'title') => ({
  list: [],
  sort: {
    prop: propForSort,
    order: 'asc',
  },
  propForFilter,
});

const getters = {
  list: (state, _getters, rootState) => {
    const rootList = rootState.moduleBilling.moduleOrders.list;
    const list = state.list;
    const propForSort = state.sort.prop;
    return list.reduce((acc, item) => {
      const inst = rootList.find(i => i.id == item[state.propForFilter]);
      if (inst) acc.push({ ...item, service: inst, [propForSort]: inst[propForSort] });
      return acc;
    }, []);
  },
  GET_SORTED_LIST: (state, getters) => {
    const list = [...getters.list];
    const { prop, order } = state.sort;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = [...list];
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
};

export default function (propForFilter = 'id', propForSort = 'title') {
  return {
    namespaced: true,
    state: getState(propForFilter, propForSort),
    getters,
    mutations,
  };
}
