import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';

export default {
  fetchList({ state, commit }, payload = {}) {
    // console.log('fetchList');
    // console.log('fetchList');
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      if (!payload.func) params.func = state.func;
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          commit('SET_LIST', data.elem || []);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchListBlock({ state, commit }, payload = {}) {
    // console.log('fetchListBlock');
    // console.log('fetchList');
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      if (!payload.func) params.func = state.func;
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          commit('SET_LIST_BLOCK', data.elem || []);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },

  updateList({ dispatch }, payload = {}) {
    // console.log('updateList', 'Docs');
    return dispatch('fetchList', payload);
  },
  // reset store
  reset({ state, commit }, timeout = 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_LIST', []);
        commit('SET_IS_LOADING', true);
        commit('SET_TOOLS', {});
        commit('SET_SORT', state.defaultSort);
        resolve();
      }, timeout);
    });
  },
};
