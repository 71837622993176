import { VMState } from './state';
import { parseDateRu } from '@/utils/parsers/dateParser';
import { format, parseISO } from 'date-fns';

export class VpsBackup {
  constructor(args) {
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return !!args.Uuid && !!args.State;
  }

  _init(args) {
    this.name = args.Name;
    this.size = args.Size;
    this.os = args.OsTemplate;
    this.serverId = args.UserUuid;
    // this.createTime = args.Date.replace(/\.(.*?)\s/, 'Z ');
    // console.log(args.Date);
    // const date = args.Date.replace(/\.(.*?)\s/, 'Z ');
    // const dateJ = args.Date.split('.')[0] + 'Z';
    // console.log(dateJ, new Date(dateJ));
    // console.log(new Date(date));
    // console.log(Date(args.Date));
    this.createTime = new Date(args.Date.split('.')[0] + 'Z'); // вмрога присылает неудобный формат для мозилы - приходится колхозить
    // console.log(this.createTime);
    // console.log(Date(args.Date));
    // console.log(new Date(args.Date));
    // this.createTime = args.Date;
    // this.createTime = parseISO(args.Date);
    this.state = args.State;
    this.instanceUuid = args.InstanceUuid;
    this.uuid = args.Uuid;
    this.cost = 0;
    this.id = 0;
    this.vpsName = '';
    this.memory = 0;
    this.cpu = 0;
    this.diskSize = 0;
    this.diskType = '';
  }

  set state(str) {
    this._state = new VMState(str);
  }

  set stateVPS(str) {
    this.stateVPS = new VMState(str);
  }

  get state() {
    return this._state;
  }

  // set setVpsDetails(data) {}
}
