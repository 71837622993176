import container from './container.vue';
export const RouteSSL = {
  path: '/ssl',
  name: 'SSL',
  redirect: { name: 'sslMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'SSL сертификаты',
    navigation: {
      service: {
        key: 'ssl-sertificates',
        // icon: 'el-icon-tickets',
        icon: 'el-icon-unlock',
        order: 7,
      },
    },
    access: ['mainmenuservice.dummy_ssl_rsnx_oa_migrate'],
  },
  children: [
    {
      path: '',
      name: 'sslMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'SSL сертификаты',
      },
      // redirect: { name: 'sslBuy' },
      component: () => import(/* webpackChunkName: "ssl-main" */ './pages/Main/Main.vue'),
      // children: [
      //   {
      //     path: 'order',
      //     name: 'sslBuy',
      //     component: () =>
      //       import(/* webpackChunkName: "ssl-buy" */ './pages/Main/views/BuySSL.vue'),
      //   },
      //   {
      //     path: 'my',
      //     name: 'sslMy',
      //     component: () => import(/* webpackChunkName: "ssl-my" */ './pages/Main/views/MySSL.vue'),
      //   },
      // ],
    },
    {
      path: 'order/plans',
      name: 'sslOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Покупка SSL сертификата',
      },
      component: () => import(/* webpackChunkName: "ssl-buy" */ './pages/Order/SslOrder.vue'),
      children: [
        {
          path: '/ssl/order',
          redirect: { name: 'sslOrder' },
        },
        {
          path: '/ssl/order/:id/:period',
          name: 'sslOrderDetails',
          props: true,
          component: () =>
            import(/* webpackChunkName: "ssl-order-buy" */ './pages/Order/SslOrderDetails.vue'),
        },
        {
          path: '/ssl/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'sslOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'sslInfo',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        // import(/* webpackChunkName: "ssl-info" */ './pages/Information/Information.vue'),
        import(/* webpackChunkName: "ssl-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewSsl' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "zmail-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewSsl',
        },
        // {
        //   path: 'files',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "zmail-info-config" */ './pages/Information/views/Files.vue'
        //     ),
        //   name: 'filesSsl',
        // },
        // На случай, если захотим выводить данные сертификата в текстовом виде на странице
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "zmail-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historySsl',
        },
      ],
    },
  ],
};
