<template>
  <div class="shadow-box service-card" @click="getThisTariff">
    <h4 class="service-card__title">{{ config.title }}</h4>
    <div v-if="vps" class="service-card-container">
      <span class="standart-text service-card__text">{{ config.CPU }} CPU</span>
      <span class="standart-text service-card__text"> {{ config.RAM }} RAM </span>
      <span class="standart-text service-card__text"> {{ config.MEMORY }} SSD </span>
    </div>
    <div v-else class="service-card-container">
      <!-- <transition></transition> -->
      <span class="standart-text service-card__text">{{ config.mem }} </span>
      <span class="standart-text service-card__text"> {{ config.sites }}</span>
      <span class="standart-text service-card__text">{{ config.mails }}</span>
      <h4 class="service-card__cost">{{ config.cost }} ₽/месяц</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    vps: {
      type: Boolean,
      default: () => true,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    getThisTariff() {
      this.$emit('selected', this.config);
    },
  },
};
</script>

<style lang="scss">
.service-card {
  cursor: pointer;
  width: 165px;
  height: 186px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  &-container {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.standart-text.service-card__text {
  display: block;
  color: #878b99;
}

.active-service-card {
  & .standart-text.service-card__text,
  .service-card__title,
  .service-card__cost {
    // display: block;
    color: #ffffff;
  }
}
</style>
