export default {
  computed: {
    list() {
      return this.$store.state.moduleAll.list;
    },
    isLoading() {
      return this.$store.state.moduleAll.isLoading;
    },
  },
  methods: {
    async fetchList() {
      return await this.$store
        .dispatch('moduleBilling/modulePayment/updateList')
        .then(async () => await this.$store.dispatch('moduleAll/fetchList'));
      // return this.$store.dispatch('moduleAll/fetchList');
    },
    fetchOrderList() {
      this.$store.dispatch('moduleBilling/moduleOrders/fetchList').catch(e => {
        console.log(e);
      });
    },
    async fetchItems(payload) {
      // return this.$store.dispatch('moduleAll/fetchItem', payload).catch(e => console.log(e));
      // this.loading = true;
      return this.$store.dispatch('moduleAll/fetchItemDetail', payload).catch(e => console.log(e));
      // .finally(() => (this.loading = false));
    },
  },
};
