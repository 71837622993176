<template>
  <div class="base-basket-summary">
    <div class="base-basket-summary__title" :class="`${size}-title`">
      {{ `${$t('total')}: ` }}
    </div>
    <div
      v-if="amount - total > 0"
      class="base-basket-summary__title discount error-color"
      :class="`${size}-title`"
    >
      {{ ` ${$n(amount, 'currency')}` }}
    </div>
    <div class="base-basket-summary__title" :class="`${size}-title`">
      {{ ` ${$n(total, 'currency')}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBasketSummary',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['standart', 'medium', 'big'].includes(val),
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "total": "Итого",
    "more": "Подробнее"
  }
}
</i18n>

<style lang="stylus" scoped>
.base-basket-summary {
  display: flex;
  flex-direction: row;
  &__title{
    padding-right: 0.5rem;
  }
  .discount {
    padding-right: 0.5rem;
    text-decoration: line-through;
  }
}
</style>
