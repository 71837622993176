import BillMgrPricelist from './BillMgrPricelist';
export default class VpsPricelist extends BillMgrPricelist {
  constructor(args) {
    // console.log('VpsPricelist', args);
    super(args);
  }

  get specs() {
    const { disc_value, mem_value, ncpu_value } = this.filter_info;
    const specs = {};
    if (disc_value && disc_value[0] && disc_value[0].def) specs.disc = disc_value[0].def;
    if (mem_value && mem_value[0] && mem_value[0].def) specs.mem = mem_value[0].def;
    if (ncpu_value && ncpu_value[0] && ncpu_value[0].def) specs.ncpu = ncpu_value[0].def;
    return specs;
  }
}
