export default {
  data() {
    return {
      timerId: '',
      updateTime: null,
    };
  },
  computed: {
    payersInfo() {
      return Object.keys(this.$store.state.moduleProfile.payers).length > 0
        ? this.$store.state.moduleProfile.payers
        : null;
      // return this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'].length > 0
      //   ? this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST']
      //   : null;
    },
  },
  beforeDestroy() {
    this.resetTimers();
  },
  methods: {
    updatePayersInfo(needUpdateList = false) {
      const oldPayersInfo = this.payersInfo && this.payersInfo.id ? this.payersInfo.type : 'update';
      if (!this.updateTime) this.updateTime = Date.now();
      this.timerId = setTimeout(() => {
        if (needUpdateList) {
          // this.$store.dispatch('moduleBilling/modulePayers/updateList', { silent: true });
          this.$store.dispatch('moduleProfile/fetchPayersInfo').then(data => {
            console.log(data);
          });
        }
        this.$store.dispatch('moduleProfile/fetchPayersInfo').then(data => {
          const request =
            data && data.model ? this.$store.state.moduleProfile.payers : 'updateData';
          const curTime = Date.now();
          const TIMEOUT = 1000 * 60 * 5;
          if (
            request === 'updateData' &&
            oldPayersInfo === 'update' &&
            curTime - this.updateTime < TIMEOUT
          ) {
            this.updatePayersInfo(needUpdateList);
          } else {
            clearTimeout(this.timerId);
          }
        });
      }, 1000);
    },
    resetTimers() {
      this.updateTime = null;
      if (this.timerId) clearTimeout(this.timerId);
    },
  },
};
