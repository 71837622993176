export default function (a, b) {
  const aIsNumber = !isNaN(a);
  const bIsNumber = !isNaN(b);

  if (aIsNumber && !bIsNumber) {
    return -1;
  } else if (!aIsNumber && bIsNumber) {
    return 1;
  } else if (aIsNumber && bIsNumber) {
    return a - b;
  } else {
    return a.localeCompare(b);
  }
}
