import wizardPay from '@/mixins/billmgr/wizardPay';
import wizard from '@/mixins/billmgr/wizard';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import handleRedirect from './handleRedirect';
import updateBalance from './updateBalance';
import Link from '@/components/Configurator/components/Link';
import redirectLink from '@/mixins/redirectLink';
export default {
  mixins: [wizardPay, wizard, handleRedirect, updateBalance, redirectLink],
  components: { Link },
  data() {
    return {
      startFunc: 'payment.add.method',
      amount: null,
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    list() {
      return this.$store.getters['moduleBilling/modulePayment/cardList'];
    },
  },
  methods: {
    addPayment() {
      if (this.amount) Object.assign(this.startParams, { amount: this.amount });
      this.runWizardPay()
        .then(data => {
          this.handleRedirect(data.ok).then(data => {
            if (data.func === 'redirect' && data.ok === false) this.showModal(data.url);
          });
          const needUpdateList = data.id && data.id.saveoutput && data.id.saveoutput === 'yes';
          this.updateBalance(needUpdateList);
        })
        .then(() => this.$router.push({ name: 'BillingPayments' }))
        .catch(() => {})
        .finally(this.reset);
    },
    reset() {
      this.amount = '';
    },
  },
};
