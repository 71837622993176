import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';
const actions = {
  fetchPricelist({ commit, dispatch }, payload = {}) {
    commit('setLoading', true);
    const params = {
      onlyavailable: 'on',
      itemtype: 'domain',
      tariffpage: 'basic',
      ...payload,
    };
    return BillMgrApi.get('api2/billmgr/pricelist.export', { params })
      .then(data => {
        if (data.pricelist) commit('addToList', data.pricelist);
        dispatch('fetchWizard');
      })
      .finally(() => commit('setLoading', false));
  },
  fetchWizard({ commit }, payload = {}) {
    const params = {
      func: 'domain.order',
      newface: 'on',
      show_metadata: 'on',
      ...payload,
    };
    return BillMgrApi.get('billmgr', { params }).then(data => {
      if (data.wizard) commit('setWizard', data.wizard);
      if (data.model) commit('setModel', data.model);
    });
  },
  checkDomain(_ctx, payload) {
    if (!payload.domain) console.error('Missed required prop "domain"');
    const params = {
      ...payload,
    };
    return BillMgrApi.get('api2/billmgr/domain.check', { params });
  },
  reset({ commit }) {
    commit('resetState');
  },
};

export default actions;
