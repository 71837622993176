import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';

export const ACTIONS = {
  fetchStoredMethods({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/stored_methods_for_item';
      const params = {
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          // console.log(data);
          // commit('SET_LIST', data.elem);
          // commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchList({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = 'api2/billmgr/dummy_ssl_rsnx_oa_migrate';
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST', data.elem);
          commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'dummy_ssl_rsnx_oa_migrate.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchSSL({ commit }) {
    const params = {
      itemtype: 'dummy_ssl_rsnx_oa_migrate',
      show_metadata: 'on',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('api2/billmgr/pricelist.export', { params })
        .then(data => {
          commit('SET_SSL', data);
          rs(data => console.log(data.pricelist, 'data'));
        })
        .catch(err => rj(err));
    });
  },
  fetchHistory({ commit }, id) {
    const params = {
      func: 'service.history',
      elid: id,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'history',
            value: data.elem,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/dummy_ssl_rsnx_oa_migrate';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('UPDATE_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  currentSSL({ commit }, id) {
    commit('SET_IS_LOADING', true);
    const params = {
      elid: id,
      out: 'bjson',
      func: 'dummy_ssl_rsnx_oa_migrate.edit',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(date => {
          commit('SET_CURRENT_SSL', date.model);
          rs(data => console.log(data.model, 'data'));
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  reset({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_LIST', []);
        commit('SET_CURRENT', '');
        commit('SET_IS_LOADING', true);
        resolve();
      }, delay);
    });
  },
  setCurrent: async (context, id) => {
    try {
      await context.commit('SET_CURRENT', id);
      const tariff = await context.getters['GET_CURRENT_HOSTING'];
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetail({ commit, dispatch }, id) {
    const params = {
      elid: id,
      func: 'dummy_ssl_rsnx_oa_migrate.edit',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { customfields, slist, model } = data;
          const details = await dispatch('fetchTariffCalc', model);
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchTariffCalc(_ctx, model) {
    const params = {
      ...model,
      func: 'pricelist.calculate',
      auth: localStorage.getItem('token'),
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchBillMgrToolAction(_ctx, { func, id }) {
    const params = {
      func,
      elid: id,
      newwindow: 'yes',
    };
    return BillMgrApi.get('/billmgr', { params });
  },
};
