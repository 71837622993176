import axios from 'axios';
import Vue from 'vue';

const defs = {
  baseURL: 'https://myvm.rusonyx.ru',
  port: '8080',
};

const VmRougeApi = axios.create({
  baseURL: defs.baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

VmRougeApi.interceptors.request.use(
  config => {
    const ls = localStorage.getItem('vuex');
    const parsed = JSON.parse(ls);
    // console.log('store', this.$store.state.moduleVPS.key);
    // const key = parsed.moduleVPS.key;
    const key = localStorage.getItem('vmrogue');
    // console.log('key', key);
    const isAuth = config.url.includes('get-api-key');
    // console.log('not true key', key);
    if (key && !isAuth) {
      // console.log('true key', key);
      config.headers['X-Access-Key'] = key;
      config.baseURL = `${defs.baseURL}:${defs.port}`;
    }
    // console.log('config.headers', config.headers['X-Access-Key']);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

VmRougeApi.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default VmRougeApi;
