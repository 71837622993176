<template>
  <!--  <div>-->
  <base-loader v-if="isLoading" class="paymethods-list-block" />
  <div v-else class="paymethods-list-block">
    <div v-if="!isTextVerification">
      {{
        this.$store.state.moduleBilling.modulePayers.list[0].profiletype_orig === '1'
          ? $t('verification.fiz')
          : $t('verification.ur')
      }}
      <plain-button
        color="primary"
        class="base-prolong__text tariff-card__prolong"
        @click="urlProfile()"
      >
        {{ $t('verification.profile') }}
      </plain-button>
    </div>
    <div v-if="label || $slots.default" class="paymethods-list-block__label standart-title">
      <slot>{{ label }}</slot>
      <span v-if="$attrs.required" class="paymethods-list-block__label-asterisk">*</span>
      <plain-button
        v-if="hint"
        v-tooltip="{ content: hint, placement: 'top-start', container: false }"
        icon="help"
        size="small"
        color="dim"
        tabindex="-1"
        class="paymethods-list-block__hint"
      />
    </div>
    <div
      v-tooltip="config.disabled ? tooltipSettings : null"
      class="l-flex-1_sm-2 gutter-24 paymethods-list-block__fields"
    >
      <div v-for="opt in options" :key="opt.k" class="l-col">
        <!--      <div v-for="opt in sortOptions()" :key="opt.k" class="l-col">-->
        <base-radio
          v-model="internalValue"
          :value="opt.k"
          :disabled="config.disabled"
          :asset-image="opt.assetImage ? opt.assetImage : null"
          :image="opt.image ? baseImgPath + opt.image : ''"
          theme="tile"
          class="paymethods-list-block__radio"
        >
          {{ opt.label }}
          <div
            v-if="opt.balance || opt.note"
            class="paymethod-list-block__radio-note note-color small-text"
          >
            {{ opt.balance ? $n(opt.balance, 'currency') : opt.note }}
          </div>
        </base-radio>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'PaymethodListBlock',
  components: { BaseRadio, BaseLoader },
  props: {
    config: {
      type: Object,
      required: true,
      // validator: obj => obj instanceof PaymethodListComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    cost: {
      type: Number,
      default: 0,
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => [1, 3, 6, 12].includes(parseInt(val)),
    },
    periodKey: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.config.value,
      isLoading: true,
    };
  },
  computed: {
    tooltipSettings() {
      return {
        content: this.$t('restrictclientchange.on'),
        placement: 'top-start',
      };
    },
    isTextVerification() {
      let verification = false;
      if (
        this.$store.state.moduleBilling.moduleDocs.modulePaymentBlock.listPaymentBlock.find(i => {
          return (
            (i['paymethod_module'] === 'pmruwire' || i['paymethod_module'] === 'pmsberbank') &&
            i.status_orig === '4'
          );
        }) ||
        this.$store.state.moduleProfile.profile.need_phone_validate === false
      )
        verification = true;
      return verification;
    },

    options() {
      let sortedList = this.config.options;
      sortedList.map(option => {
        // if (option.k === '38' || option.k === '34') {
        if (['38', '34', '35'].includes(option.k)) {
          option.state = 1;
          // } else if (option.k === '25' || option.k === '24') {
        } else if (option.label.toLowerCase().includes('банковский перевод')) {
          option.state = 2;
          // } else if (option.k === '39') {
        } else if (['32', '36', '39', '21'].includes(option.k)) {
          option.state = 5;
        } else if (option.k === '40' || option.k === '37') {
          option.state = 6;
        } else if (['18', '20', '24', '25', '47', '49'].includes(option.k)) {
          option.state = 3;
        } else {
          option.state = 4;
        }
        sortedList.sort((a, b) => {
          return a.state - b.state;
        });
      });
      if (window.location.hash === '#/billing/documents/payments') {
        sortedList = sortedList.filter(x => x.paymethod_type !== '0');
      }
      if (
        this.$store.state.moduleBilling.moduleDocs.modulePaymentBlock.listPaymentBlock.find(i => {
          return (
            (i['paymethod_module'] === 'pmruwire' || i['paymethod_module'] === 'pmsberbank') &&
            i.status_orig === '4'
          );
        }) ||
        this.$store.state.moduleProfile.profile.need_phone_validate === false
      ) {
        return sortedList;
      } else {
        return sortedList.filter(
          option =>
            option.label.toLowerCase().includes('банковский перевод') ||
            option.label.toLowerCase().includes('лицевой счет') ||
            (['47', '49'].includes(option.paymethod) &&
              option.label.toLowerCase().includes('банковская карта')) ||
            option.module === 'pmsberbank'
        );
      }
    },
    baseImgPath() {
      return this.$store.state.moduleApp.host || '';
    },
  },
  watch: {
    config: function (newconfig) {
      if (this.internalValue != newconfig.value) this.internalValue = newconfig.value;
    },
    internalValue: function (value) {
      this.onChange(value);
    },
  },
  mounted() {
    this.init();
    this.filterDocs();
  },
  methods: {
    urlProfile() {
      this.$router.push({ name: 'ProfilePersonal' }).catch(() => {});
      return this.$modals.close('StepperModalPay');
    },
    filterDocs() {
      const payload = {
        createdatestart: '2023-01-01',
        createdateend: new Date().toISOString().split('T')[0],
        createdate: 'other',
        func: 'payment.filter',
      };
      this.$store
        .dispatch('moduleBilling/modulePayment/fetchBiilingDocsList', payload)
        .then(data => {
          if (data)
            this.$store
              .dispatch('moduleBilling/moduleDocs/modulePaymentBlock/fetchListBlock')
              .then(() => setTimeout(() => (this.isLoading = false), 300));
        });
    },
    init() {
      // console.log('init');
      const firstPaymethod = this.config.value
        ? this.config.value
        : this.options.map(x => x.paymethod)[0];
      this.internalValue = firstPaymethod;
      this.$emit('change', {
        value: firstPaymethod,
        sum: this.cost,
        // notConfirmed: false,
        // paymethod: this.paymethod,
      });
    },
    // sortOptions() {
    //   // console.log(
    //   // this.options.sort((a, b) => {
    //   //   return a.label.includes('СБП') - b.label.includes('Банковский перевод');
    //   // })
    //   // );
    //   return this.options.sort((a, b) => {
    //     // return a.label.includes('СБП') - b.label.includes('Банковский перевод');
    //     return +b.paymethod - +a.paymethod;
    //   });
    // },
    onChange(value) {
      // console.log(value);
      this.config.value = value;
      this.internalValue = this.config.value;
      this.$emit('change', {
        value: this.config.value,
        sum: this.cost,
        // paymethod: this.paymethod,
      });
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "verification" : {
    "fiz": "Все способы оплаты будут доступны после подтверждения номера телефона либо после оплаты банковским переводом/банковской картой. Пожалуйста, укажите номер телефона в личном кабинете, в разделе ",
    "ur": "Пожалуйста, укажите и подтвердите номер телефона в личном кабинете, в разделе ",
    "profile": "Профиль"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paymethods-list-block {
  &__label {
    flexy(flex-start, baseline);
    margin-bottom: 10px;
    color: var(--text);
    transition: color $anim-base-duration ease, opacity $anim-base-duration ease;

    ^[0]--disabled &,
    ^[0]--readonly & {
      opacity: 0.2;
    }

    &-asterisk {
      color: $error-color;
      margin-left: 0.125rem;
    }
    &-end {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }

  &__fields {
    //flexy(flex-start, center, wrap);
  }

  &__radio {
    height: 100%;
    & + & {
      //margin-left: 1.5rem;
    }
  }
  .base-prolong{
    &__text{
      margin-bottom: 2.5em;
      font-weight: 400;
    }
  }
}
</style>
