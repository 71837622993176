export const pathNameByService = {
  vds: 'vpsDetail',
  vdc2: 'tariffClouds',
  vdc3: 'stackMain',
  pleskshared: 'virtualDetail',
  rps: 'rentDetail',
  licplesk: 'pleskDetail',
  licvz: 'virtuozzoDetail',
  zmail: 'zmailDetail',
  cdn: 'cdnDetail',
  vhost: 'vhostDetail',
  soft: 'softDetail',
  bitrix_license: 'bitrixDetail',
  dummy_ssl_rsnx_oa_migrate: 'sslInfo',
  saas_services: 'saasDetail',
  dedic: 'dedicDetail',
  colocation: 'coloDetail',
  antiddosgcore: 'antiDdosDetail',
  domain: 'domainDetail',
  idprotect: 'idprotectDetail',
  microsoft_office: 'mo365Detail',
  microsoft: 'microsoftDetail',
  private_cloud: 'privateDetail',
  acronis: 'acronisDetail',
  dnshost: 'Domains',
};
export const moduleMain = {
  vds: 'moduleVPS',
  // vdc2: 'tariffClouds',
  // vdc3: 'stackMain',
  pleskshared: 'moduleVirtual',
  rps: 'moduleRent',
  licplesk: 'modulePlesk',
  licvz: 'moduleVirtuozzo',
  zmail: 'moduleZmail',
  cdn: 'moduleCDN',
  vhost: 'moduleVhost',
  soft: 'moduleSoft',
  bitrix_license: 'moduleBitrix',
  dummy_ssl_rsnx_oa_migrate: 'moduleSSL',
  // dummy_ssl_rsnx_oa_migrate: 'dummy_ssl_rsnx_oa_migrate.edit',
  dedic: 'moduleDedic',
  colocation: 'moduleColo',
  antiddosgcore: 'moduleAntiDdos',
  domain: 'moduleDomains.moduleDomainsDomains',
  idprotect: 'moduleIdprotect',
  microsoft_office: 'moduleMo365',
  microsoft: 'moduleMicrosoft',
  private_cloud: 'modulePrivate',
  acronis: 'moduleAcronis',
  saas_services: 'moduleSaas',
  dnshost: 'moduleDomains.moduleDomainsDnsHost',
};
