import { baseColors } from '../base/baseColors';
const statusEnum = {
  1: 'running',
  2: 'success',
  3: 'stopped',
  4: 'error',
  5: 'processing',
  6: 'unknown',
  7: 'pending',
  8: 'finished',
  9: 'failed',
  10: 'paused',
  11: 'reinstalling',
};
const stateColor = {
  running: baseColors.success,
  success: baseColors.success,
  stopped: baseColors.error,
  error: baseColors.error,
  reinstalling: baseColors.error,
  processing: baseColors.warning,
  unknown: baseColors.warning,
  pending: baseColors.warning,
  finished: baseColors.success,
  failed: baseColors.error,
  paused: baseColors.warning,
};

export class VMState {
  /**
   *
   * @param {string} str
   */
  constructor(str) {
    this._keys = Object.keys(stateColor);
    if (this.isValid(str)) this._init(str);
    else throw new Error(`Unknown state '${str}' in VMState`);
  }

  isValid(str) {
    this._isValid = this._keys.includes(str);
    return this._isValid;
  }

  getKeyByState(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  _init(str) {
    const code = this.getKeyByState(statusEnum, str);
    this.code = parseInt(code);
    this.key = statusEnum[code];
  }

  get color() {
    return stateColor[this.key];
  }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this._isValid;
  }
}
