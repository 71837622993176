import container from './container.vue';

// console.log(this.store.state.moduleProviders);
export const RoutePartners = {
  path: '/partners',
  name: 'partnersAstra',
  redirect: { name: 'PartnersAstra' },
  meta: {
    isShow: true,
    isLink: false,
    name: 'Партнёрам',
  },
  component: container,
  children: [
    {
      path: '',
      name: 'PartnersAstra',
      component: () => import(/* webpackChunkName: "zmail-main" */ './Main/views/Partners.vue'),
    },
  ],
};
