<template>
  <div class="card-entry shadow-box">
    <div class="text log-text">
      <span>Перейдите на почту, подтвердите свою регистрацию </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
