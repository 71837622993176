<template>
  <div class="base-prolong-item">
    <div class="base-prolong-item__label">
      <div class="base-prolong-item__title standart-title">
        {{ title ? title : $t('title') }}
      </div>
      <div
        v-if="subtitle"
        :title="subtitle"
        class="base-prolong-item__subtitle standart-text note-color"
      >
        {{ subtitle }}
      </div>
    </div>
    <div class="base-prolong-item__field">
      <base-select
        :options="priceByPeriod"
        :value="periodValue"
        track-by="k"
        label="label"
        :show-labels="false"
        :allow-empty="false"
        :searchable="false"
        class="base-prolong-item__select"
        @input="onChange"
      />
    </div>
    <div class="base-prolong-item__summary">
      <div class="base-prolong-item__cost standart-title">
        {{ $n(value.cost, 'currency') }}
      </div>
      <div v-if="value.cost" class="base-prolong-item__note standart-text note-color">
        {{ $t('payfor') + ' ' + periodValue.label }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
import { isNumber } from 'lodash';
import { transformPeriodToMore } from '@/models/BillMgr/periods';
export default {
  name: 'BaseProlongItem',
  components: { BaseSelect },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
      validator: arr =>
        !arr.length || arr.every(i => i.k && isNumber(parseFloat(i.cost)) && i.label),
    },
    value: {
      type: Object,
      required: true,
      validator: obj => obj.k && isNumber(parseFloat(obj.cost)),
    },
  },
  computed: {
    periodValue() {
      return this.priceByPeriod.find(i => i.k === this.value.k);
    },
    priceByPeriod() {
      return this.list.map(i => ({
        label:
          i.k === 'null'
            ? 'Отключено'
            : this.$tc(
                `period.${transformPeriodToMore(i.k).periodType}_c`,
                transformPeriodToMore(i.k).periodLen
              ),
        cost: i.cost,
        k: i.k,
        v: i.v,
      }));
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление",
    "payfor": "При оплате за"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-prolong-item {
  width: 100%;

  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }

  &__label {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 1.5rem;
      flex: 0 0 12.5rem;
      max-width: 12.5rem;
    }
  }
  &__subtitle {
    +breakpoint(sm-and-up) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  &__field {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 1.5rem;
      flex: 0 0 10rem;
    }
  }
  &__summary {
    +breakpoint(sm-and-up) {
      margin-right: 0;
      margin-left: auto;
      text-align: right;
    }
  }
}
</style>
