<template>
  <div class="page">
    <transition name="slide-fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Support',
};
</script>
<style lang="stylus" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
</style>
