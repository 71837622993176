<template>
  <div class="send">
    <div class="l-col">
      <base-input
        size="small"
        :mask="'+7 (999) 999-99-99'"
        :label="$t('ticket.label')"
        :value="phone"
        use-reactive-validation
        @change="onChange"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
export default {
  name: 'VerifyPhone',
  components: {
    BaseInput,
  },
  mixins: [setFocus],
  props: {
    phoneSend: {
      type: String,
      default: '',
    },
  },
  computed: {
    phone() {
      return this.phoneSend ? this.phoneSend : this.$store.getters['moduleProfile/phone'];
    },
  },
  methods: {
    onChange(event) {
      this.$emit('phone', event.target.value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "ticket": {
      "label": "Подтверждение телефона"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.send {
  text-overflow: unset;
  overflow: visible;
}
</style>
