<template>
  <div class="base-radio" :class="classes">
    <div v-if="$slots.before" class="base-radio__before">
      <slot name="before" />
    </div>
    <div class="base-radio__field">
      <input
        :id="internalId"
        type="radio"
        class="base-radio__input"
        v-bind="$attrs"
        :value="value"
        :checked="state"
        :disabled="disabled"
        @change="onChange"
        @keyup.enter.stop="toggle"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <label :for="internalId" class="base-radio__label">
        <img v-if="theme === 'tile' && !!image" :src="image" class="base-radio__img" />
        <div :class="boxClasses">
          <transition name="fade">
            <div v-show="isChecked" name="check-24" class="base-radio__check" />
          </transition>
        </div>
        <div v-if="label || $slots.default" class="base-radio__label-text standart-text">
          <slot>{{ label }}</slot>
        </div>
      </label>
    </div>
    <div v-if="$slots.after" class="base-radio__after">
      <slot name="after" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseRadio',

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, String, Array],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
      validator: val => ['default', 'tile'].includes(val),
    },
    image: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      internalId: this.id || `uid_${(~~(Math.random() * 1e8)).toString(16)}`,
      isFocused: false,
    };
  },
  computed: {
    state() {
      if (this.modelValue === undefined) {
        return this.checked;
      }
      return this.modelValue === this.value;
    },
    isChecked() {
      return Boolean(this.state);
    },
    classes() {
      const prefix = 'base-radio';
      return {
        [`${prefix}--checked`]: this.isChecked,
        [`${prefix}--disabled`]: this.disabled,
        [`${prefix}--focused`]: this.isFocused,
        [`${prefix}--${this.theme}`]: !!this.theme,
      };
    },
    boxClasses() {
      const prefix = 'base-radio__box';

      return {
        [prefix]: true,
        [`${prefix}--checked`]: this.isChecked,
        [`${prefix}--disabled`]: this.disabled,
      };
    },
  },
  watch: {
    checked(newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    },
  },
  mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  },
  methods: {
    onChange() {
      this.toggle();
    },
    toggle() {
      this.$emit('change', this.state ? '' : this.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-radio {
  color: var(--radio-color);

  &--checked {
    color: var(--radio-active-color);
  }

  &__before {
    margin-bottom: 0.5em;
  }
  &__after {
    margin-top: 0.5em;
  }
  &__field {
    height: 100%;
    position: relative;
  }
  &__input {
    absolute(0px, 0px);
    opacity: 0;
    z-index: 0;
    min-width: 0;
  }

  &__label {
    height: 100%;
    position: relative;
    //z-index: 1;
    z-index: 0;
    cursor: pointer;

    ^[0]--default & {
      flexy(flex-start, flex-start);
      //+breakpoint(xs-only) {
      +breakpoint(ms-and-down) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        //content: '';
        //display: block;
        //size(2.5em, 100%);
        //absolute(0px, 100%);
        //background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }

    ^[0]--tile & {
      flexy(flex-start, center);
      background: var(--debit-card-bg);
      border: 1px solid var(--main-card-border);
      border-radius: $border-radius-small;
      color: var(--main-card-color);
      transition: background $anim-base-duration ease, box-shadow $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
      padding: 0.5rem 1rem;

      &::before {
        content: '';
        display: block;
        size(100%);
        absolute(0px, 0px);
        transition: opacity 0.3s;
        opacity: 0;
        background: var(--debit-card-bg-hover);
        border-radius: inherit;
      }

      &:hover {
        &::before {
          opacity: 0.2;
        }
      }
    }

    ^[0]--tile^[0]--checked & {
      border-color: var(--radio-active-bg, transparent);
      color: var(--radio-active-bg, transparent);
      //background-color: var(--radio-active-bg, transparent);
    }

    ^[0]--disabled & {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__box {
    position: relative;
    circle($line-height-base);
    flexy(center, center);
    box-sizing: border-box;
    border: 1px solid var(--radio-border, transparent);
    background-color: var(--radio-bg, transparent);
    transition: background $anim-base-duration ease, border-color $anim-base-duration ease, opacity $anim-base-duration ease;

    ^[0]--focused & {
      border-color: var(--input-border-focused);
    }

    ^[0]--tile & {
      display: none;
    }

    &--checked {
      border-color: var(--radio-active-border, transparent);
      background-color: var(--radio-active-bg, transparent);

      ^[0]--focused & {
        border-color: var(--input-border-focused);
      }
    }
  }

  &__check {
    circle(0.5rem)
    background: $color-light.white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  }

  &__label-text {
    margin-left: 0.5rem;
    user-select: none;
    +breakpoint(ms-only) {

      margin-left: 0;
      //margin-left: 0.4rem;
    }
    +breakpoint(xs-only) {
      margin-left: 0;
      //margin-left: 0.2rem;
    }
  }

  &__img {
    line-height: 1;
    margin-right: 0.5rem;
  }
}
</style>
