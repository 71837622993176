import Vue from 'vue';

export default {
  methods: {
    showSuccess() {
      this.$modals.open({
        name: 'Success',
        title: this.$t('success.title'),
        text: this.$t('success.text'),
        footer: {
          confirm: {
            props: { title: this.$t('success.ok') },
            on: { click: () => this.$modals.close() },
          },
        },
        onClose: () => {
          this.$router.push({ name: 'Auth' });
        },
      });
    },
    showResModal(text) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(text));
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
  },
};
