export default {
  data() {
    return {
      cache: {
        element: undefined,
        config: undefined,
      },
    };
  },
  methods: {
    getTariff(value) {
      if (this.cache.element) {
        this.cache.element.classList.toggle('active-service-card');
        this.cache.element = this.$refs[value.id][0].$el;
      } else {
        this.cache.element = this.$refs[value.id][0].$el;
        // this.cache.config = value;
      }

      this.cache.element.classList.toggle('active-service-card');
      this.cache.config = value;
      // console.log(this.cache.config);
      // console.log(this.$options.name)
      if (this.$options.name !== 'VirtualService') {
        this.getAddons(value);
      }
    },
  },
};
