import DomainPricelist from '@/models/BillMgr/DomainPricelist';
import actions from './actions';

/**
 * @typedef BillMgrOrderState
 * @prop {DomainPricelist[]} list
 * @prop {Boolean} loading
 * @prop {Number} current
 * @prop {Array} wizard - order wizard
 * @prop {Object} model - data-model for order
 */
const stateDef = {
  list: [],
  loading: false,
  wizard: [],
  model: {},
};
/**
 * Function returns state object
 * @returns {BillMgrOrderState} BillMgrOrderState
 */
const state = () => ({ ...stateDef });

const mutations = {
  /**
   * Add some items to list
   * @param {BillMgrOrderState} state - current context
   * @param {Array} items - array of items to add
   */
  addToList(state, items) {
    try {
      state.list = items.map(i => new DomainPricelist(i));
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * Set loading
   * @param {BillMgrOrderState} state - current context
   * @param {Boolean} value
   */
  setLoading(state, value) {
    state.loading = value;
  },
  /**
   * sSet functions for order steps from wizard
   * @param {BillMgrOrderState} state - current context
   * @param {Array} list - wizard functions list
   */
  setWizard(state, list) {
    state.wizard = list;
  },
  /**
   * Set model for next step from order wizard
   * @param {BillMgrOrderState} state - current context
   * @param {Object} value - model from order wizard
   */
  setModel(state, value) {
    state.model = value;
  },
  /**
   * reset state to default
   * @param {BillMgrOrderState} state - current context
   */
  resetState(state) {
    Object.keys(state).forEach(i => (state[i] = stateDef[i]));
  },
};

const moduleDomainsOrder = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default moduleDomainsOrder;
