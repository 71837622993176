import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store';
import i18n from './i18n';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';

// highlight
import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);
// global ui-libs
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// global styles
import '@/assets/styles/index.styl';

//global components
import '@/components/globalComponents';

import PrimeVue from 'primevue/config';

// import 'primevue/resources/themes/lara-light-teal/theme.css';
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';

// console.log(localStorage.getItem('vuex'));
// console.log(document.body.getAttribute('data-theme'));
// import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
// Vue.directive('tooltip', Tooltip);
Vue.use(PrimeVue, { ripple: true });

//global filters
import { separator } from './utils/separator';
Vue.filter('withSpace', separator);

Vue.config.productionTip = false;
Vue.use(VueMeta);

Vue.use(VueGtm, {
  id: 'GTM-T85RVJK', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  // gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  // gtm_preview: 'env-4',
  // gtm_cookies_win: 'x',
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
