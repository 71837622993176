import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrStatus, BillMgrStatusSpecial } from './status';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';

export class AcronisTariff extends BaseConfigurator {
  constructor(args) {
    super(args, BillMgrAddon);
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = args.id;
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    this.autosuspend = args.autosuspend || 'off';
    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_period = args.autoprolong_period || args.autoprolong_id || 'null';
    this.autoprolong_name = args.autoprolong || args.autoprolong_name || '';
    this.change_pricelist = args.change_pricelist;
    this.name = args.name;
    this.costsuffix = args.cost_suffix;
    this.pricelist = args.pricelist;
    this.cost = parseCost(args.cost);
    this.period = args.period;
    this.datacentername = args.datacentername;
    this.expiredate = Date.parse(args.expiredate) || parseDateRu(args.expiredate);
    this.createdate = Date.parse(args.createdate) || parseDateRu(args.createdate);
    this.in_pay = args.in_pay;
    this.order_disabled = args.order_disabled;
    this.prolong_available = args.prolong_available || 'off';
    this.subaccount = args.subaccount;
    this.transition = args.transition;
    // this.status = new BillMgrStatus(args.status);
    this.status = new BillMgrStatus(
      this.employeesuspend === 'on' ? 8 : this.abusesuspend === 'on' ? 7 : args.status
    );
    this.specialstatus = new BillMgrStatusSpecial(args.specialstatus);
    this._history = [];
    this.domain = '';
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    return {
      datacentername: this.datacentername,
      domain: this.domain,
      cost: this.cost,
    };
  }

  get title() {
    return this.name;
  }

  get hasPanel() {
    if (!this.info || !this.info.customfields) return false;
    const { username, userpassword } = this.info.customfields;
    const vals = { yes: true, no: false };
    return username && vals[username.added] && userpassword && vals[userpassword.added];
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }

  set info({ customfields, slist, model, details }) {
    this._info = {
      customfields,
      slist,
      model,
      details: null,
    };
    if (details) {
      const addons = details.addon.reduce((acc, i) => {
        acc[`addon_${i.id}`] = i;
        return acc;
      }, {});
      this._info.details = {
        main: details.main,
        addons,
      };
    }
  }

  get info() {
    return this._info;
  }

  get link() {
    return this._info.customfields.kblink || null;
  }

  get addons() {
    if (!this.info || !this.info.details || !this.info.details.addons) return null;
    return this.info.details.addons;
  }

  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }
}
