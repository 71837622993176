import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import BaseAddon from '../base/BaseAddon';

export class BillMgrAddon extends BaseAddon {
  constructor(args) {
    super(args);
    if (args.cost) this.cost = parseCost(args.cost);
    this.periodKey = BillMgrPeriods[this.period ? this.period : 1];
    this._currentValue = args.value;
    this._sum = args.addonSum || 0;
  }

  get sum() {
    return this._sum;
  }

  set sum(value) {
    this._sum = value;
  }

  get currentValue() {
    return this._currentValue;
  }

  set currentValue(value) {
    this._currentValue = value;
  }
}
