<template>
  <component :is="to ? 'router-link' : 'div'" :to="to" :class="classes" class="main-card">
    <div v-if="$slots.header || $slots.headerEnd || $slots.headerAction" class="main-card__header">
      <div
        v-if="$slots.header || $slots.headerEnd"
        class="main-card__header-content"
        :class="`align-${headerAlign}`"
      >
        <div v-if="$slots.header" class="main-card__header-start">
          <slot name="header" />
        </div>
        <div v-if="$slots.headerEnd" class="main-card__header-end">
          <slot name="headerEnd" />
        </div>
      </div>
      <div v-if="$slots.headerAction" class="main-card__header-action">
        <slot name="headerAction" />
      </div>
    </div>
    <div
      v-if="$slots.default || $slots.bodyEnd"
      class="main-card__body"
      :class="`align-${bodyAlign}`"
    >
      <div v-if="$slots.default" class="main-card__body-start">
        <slot />
      </div>
      <div v-if="$slots.bodyEnd" class="main-card__body-end">
        <slot name="bodyEnd" />
      </div>
    </div>
    <div
      v-if="$slots.footer || $slots.footerEnd"
      class="main-card__footer"
      :class="`align-${footerAlign}`"
    >
      <div v-if="$slots.footer" class="main-card__footer-start">
        <slot name="footer" />
      </div>
      <div v-if="$slots.footerEnd" class="main-card__footer-end">
        <slot name="footerEnd" />
      </div>
    </div>
  </component>
</template>

<script>
const aligns = ['top', 'bottom', 'baseline', 'middle'];
export default {
  name: 'MainCard',
  props: {
    headerAlign: {
      type: String,
      default: 'top',
      validator: v => aligns.includes(v),
    },
    bodyAlign: {
      type: String,
      default: 'middle',
      validator: v => aligns.includes(v),
    },
    footerAlign: {
      type: String,
      default: 'middle',
      validator: v => aligns.includes(v),
    },
    to: {
      type: [String, Object],
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['small', 'medium'].includes(val),
    },
  },
  computed: {
    classes() {
      const base = 'main-card';
      return {
        [`${base}--${this.size}`]: !!this.size,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$mc-aligns = {
  top: flex-start,
  bottom: flex-end,
  baseline: baseline,
  center: center,
}

.main-card {
  // color theme
  background: var(--main-card-bg);
  border: 1px solid var(--main-card-border);
  color: var(--main-card-color);
  text-decoration: none;
  transition: background $anim-base-duration ease, box-shadow $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
  &:hover {
    box-shadow: var(--main-card-shadow);
  }
  // box
  display: flex;
  flex-direction: column;
  min-height: 100%;
  border-radius: $border-radius-base;

  &--small {
    padding: 0.75rem;
  }
  &--medium {
    padding: 1rem;

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
    }
  }

  &__header {
    flexy(space-between, flex-start);
    margin-top: 0;

    &-content {
      flex: 1 1 auto;
      +breakpoint(sm-and-up) {
        flexy(space-between, flex-start);
      }
      for $key, $value in $mc-aligns {
        &.align-{$key} {
          +breakpoint(sm-and-up) {
            align-items: $value;
          }
        }
      }
    }
    &-start {
      flex: 1 1 auto;
      &:not(:only-child) {
        margin-bottom: 1rem;
        +breakpoint(sm-and-up) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
    &-end {
      flex: 0 0 auto;
      @media screen and (max-width: 1190px) {
        flex: 0 5 auto;
      }
      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
    &-action {
      flex: 0 0 auto;
      margin-right: 0;

      ^[0]__header-content + & {
        margin-left: 10px;
      }
      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }
  &__body {
    width: 100%;
    margin: 0 0 auto;

    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }

    for $key, $value in $mc-aligns {
      &.align-{$key} {
        +breakpoint(sm-and-up) {
          align-items: $value;
        }
      }
    }

    &-start {
      flex: 1 1 100%;
      max-width: 100%;

      &:not(:only-child) {
        margin-right: 1.5rem;
      }
    }

    &-end {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
        margin-right: 0;
        margin-top: unset;
      }
    }

    ^[0]__header + & {
      padding-top: 1.5rem;

      +breakpoint(sm-and-up) {
        padding-top: 1.25rem;
      }
    }
  }
  &__footer {
    margin-bottom: 0;
    //flex-direction: column;
    +breakpoint(sm-and-up) {
      flexy(space-between, flex-start, column);
      //flexy(space-between, center, column);
    flex-direction: column;
    }

    for $key, $value in $mc-aligns {
      &.align-{$key} {
        +breakpoint(sm-and-up) {
          align-items: $value;
        }
      }
    }

    ^[0]__header + &,
    ^[0]__body + & {
      padding-top: 1.5rem;

      +breakpoint(sm-and-up) {
        padding-top: 1.25rem;
      }
    }
    &-start {
      flex: 1 1 auto;
      &:not(:only-child) {
        margin-bottom: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-right: 1.5rem;
          margin-bottom: 0;
        }
      }
    }
    &-end {
      flex: 0 0 auto;
      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }
}
</style>
