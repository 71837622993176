<template>
  <div class="send">
    <div class="l-col">
      {{
        $t('sure.phone', {
          phone: phone,
        })
      }}
    </div>
    <div class="send-code l-col">
      <base-input
        size="big"
        :required="true"
        :mask="'9 9 9 9'"
        :label="$t('ticket.label')"
        use-reactive-validation
        value=""
        @change="onInput"
      >
      </base-input>
    </div>
    <div class="l-col">
      {{ $t('ticket.text') }}
      <plain-button color="primary" @click="addNewTicket()">{{ $t('ticket.link') }} </plain-button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import Vue from 'vue';
import NewTicket from '@/layouts/Support/component/NewTicket';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'SendCodeAdv',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showErrorModal],
  computed: {
    phone() {
      return this.$store.getters['moduleProfile/phone'];
    },
  },
  mounted() {
    this.$store.dispatch('moduleSupport/fetchServiceList');
    this.$store.dispatch('moduleSupport/fetchAllTickets');
    this.$store.dispatch('moduleSupport/resetFiles');
  },
  methods: {
    onInput(event) {
      this.$emit('code', event.target.value);
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
    addNewTicket() {
      this.$modals.close({ name: 'CodeSend' });
      this.showSettingsModal({ ticketThemes: this.$t('sure.ticket') });
    },
    showSettingsModal(props = {}) {
      let self = null;
      this.$modals.open({
        name: 'TicketModal',
        size: 'small',
        component: NewTicket,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('ticket.title'),
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.dispatch('moduleSupport/fetchAllTickets');
          this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
          this.showResModal(this.$t('sure.success'));
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "ticket": {
      "label": "Проверочный код",
      "title": "Новый запрос",
      "text": "Если Вы не получили проверочный код, обратитесь в",
      "link": "техническую поддержку"
    },
    "sure": {
      "phone": "На телефон {phone} отправлено SMS с кодом",
      "title": "Подтверждение номера телефона",
      "success": "Ваш запрос успешно отправлен!",
      "ticket": "Не приходит код подтверждения"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.send {
  text-overflow: unset;
  overflow: visible;
  &-code {
    max-width: 15rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style>
