<template>
  <div class="success">
    <div class="success__form">
      <asset-img :src="'frame.png'" class="success-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.$gtm.trackEvent({
      event: '_event_success', // Event type [default = 'interaction'] (Optional)
      category: 'Pay',
      action: 'click',
      label: 'Success',
      value: 4000,
      noninteraction: false, // Optional
    });
  },
  methods: {},
};
</script>

<i18n>
  {
    "ru": {
    }
  }
</i18n>

<style lang="stylus" scoped>
.success {
  text-align: center;
  &-img {
    width: 10rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
