import { DataLoad } from '@/store/utils/DataLoad.js';

const defaultParams = {
  func: 'vds.order.pricelist',
  out: 'bjson',
  clicked_button: 'order',
  sok: 'ok',
  snext: 'ok',
  newface: 'on',
};
const RESOURCE = new DataLoad('my-rusonyx');

const findAddons = data => {
  let listOfAddons = [];
  for (let item in data.customfields) {
    if (data.customfields[item].intname) {
      listOfAddons.push([item, data.customfields[item].intname]);
    } else {
      listOfAddons.push([item, data.customfields[item].name]);
    }
  }
  // console.log(listOfAddons);
  return listOfAddons;
};

// const getFormDataForBuyTariff = (config) => {
//   const formData = new FormData()
//   Object.keys(config).forEach(item => {
//     formData.append(item, config[item])
//   })
//   return formData
// }

const getParamsForBuyTariff = (addons, payload) => {
  console.log('getParamsForBuyTariff', payload);
  const element = {
    mem: payload.config.RAM,
    disc: payload.config.MEMORY,
    ncpu: payload.config.CPU,
    ip: 'ip',
    pleskpanel: payload.plesk,
    root: 'root',
    cms: payload.cms,
    vpsbackup: payload.backup,
    datacenter: 1,
    pricelist: payload.config.id,
    autoprolong: 1,
    billprorata: 'off',
    func: 'vds.order.param',
    saved_filters: '',
    skipbasket: '',
    stylesheet: '',
    clicked_button: 'basket',
    // ostempl: 'vmrogue - centos8',
    panelid: '',
    itemtype: '',
    newbasket: 'on',
    newface: 'on',
    addon_2145: 'on',
    addon_2146: 'off',
    period: 1,
    domain: '',
    auth: localStorage.getItem('token'),
  };
  const formData = new FormData();
  const configForRequest = {};
  addons.forEach(item => {
    const valueOfAddon = element[item[1]];
    configForRequest[item[0]] = valueOfAddon;
    formData.append(item[0], valueOfAddon);
    if (item[0].includes('addon')) {
      configForRequest[`${item[0]}_current_value`] = valueOfAddon;
      configForRequest[`${item[0]}_used_value`] = 0;
      formData.append(`${item}_current_value`, valueOfAddon);
      formData.append(`${item}_used_value`, 0);
    }
  });
  // console.log(configForRequest);
  return formData;
};

// addon_2140: 20000
// addon_2140_current_value: 20000
// addon_2140_used_value: 0
// addon_2141: 2048
// addon_2141_current_value: 2048
// addon_2141_used_value: 0
// addon_2142: 2
// addon_2142_current_value: 2
// addon_2142_used_value: 0
// addon_2143: 1
// addon_2143_current_value: 1
// addon_2143_used_value: 0
// addon_2144: 53

// addon_2149: 0
// addon_2149_current_value: 0
// addon_2149_used_value: 0
// addon_2150: 57
// auth: 35b238c46974addb784e8fe4

// datacenter: 1
// domain:
// expiredate: 2021 - 01 - 01
// expiredate_l: 1 января 2021 г.

//
//
// period: 1
// pricelist: 2139
//
// tax_hidden: [object Object], [object Object], [object Object]
//
// sok: ok
// out: bjson

const destruct = data => {
  let service = Object.keys(data.slist).map(item => {
    return [...data.slist[item]];
  });
  return service;
};

const addonsState = {
  addonsVPS: [[], [], []],
  addonsForBuy: [],
};
const addonsMutations = {
  SET_ADDONS_FOR_TARIFF: (state, payload) => {
    state.addonsVPS = payload;
  },
  SET_ADDONS_FOR_BUY: (state, payload) => {
    state.addonsForBuy = payload;
  },
};

const addonsActions = {
  SET_ADDONS_FOR_TARIFF: async (state, payload) => {
    const params = { period: 1, pricelist: payload.id, datacenter: 1, ...defaultParams };
    const commits = [
      state.commit.bind(undefined, 'SET_ADDONS_FOR_TARIFF'),
      state.commit.bind(undefined, 'SET_ADDONS_FOR_BUY'),
    ];
    const callbacks = [destruct, findAddons];
    await RESOURCE.GET('', params, {}, undefined, commits, callbacks);
  },
  BUY_TARIFF: async ({ state, commit }, payload) => {
    const addons = [...state.addonsForBuy];
    // console.log(payload)
    // console.log(getParamsForBuyTariff(addons, payload));
    await RESOURCE.POST('', getParamsForBuyTariff(addons, payload), {}, undefined).then(data =>
      console.log(data)
    );
  },
};

const addonsGetters = {
  GET_ADDONS_FOR_TARIFF: state => {
    try {
      // console.log(state.addonsVPS)
      return state.addonsVPS;
    } catch (err) {
      console.error(err);
      return [[], [], []];
    }
  },
  GET_ADDONS_FOR_BUY: state => {
    try {
      return state.addonsForBuy;
    } catch (err) {
      console.error(err);
      return [];
    }
  },
};

export default {
  state: addonsState,
  actions: addonsActions,
  getters: addonsGetters,
  mutations: addonsMutations,
};

// datacenter: 1
// pricelist: 2139
// clicked_button: order
// sok: ok
// snext: ok
// newface: on
// out: bjson
// auth: d94b3648023bbc9610adbd0e
