import { parseCost } from '@/utils/parsers/costParser';
import { parseDateRu } from '@/utils/parsers/dateParser';
import { baseColors } from '../base/baseColors';
import { BillMgrStatus } from './status';

export class BillingItem {
  constructor(args, required = ['id']) {
    this._required = required;
    if (this.isValid(args)) this.init(args);
    else console.error('Missed required props in BillingItem');
  }

  isValid(args) {
    // console.log(args);
    return this._required.every(prop => args[prop]);
  }

  _init(args) {
    for (let prop in args) {
      this[prop] = args[prop];
    }
  }

  init(args) {
    this._init(args);
  }

  update(args) {
    this.init(args);
  }

  get toolParams() {
    if (!this.id) return {};
    return {
      elid: this.id,
    };
  }
}

export class BillingOrder extends BillingItem {
  constructor(args) {
    const required = [
      'id',
      'name',
      'createdate',
      'itemtype',
      'pricelist_id',
      'status_orig',
      'cost',
    ];
    super(args, required);
  }

  _init(args) {
    this.id = args.id;
    this.createdate = Date.parse(args.createdate);
    this.itemtype = args.itemtype;
    this.name = args.name;
    this.pricelist_id = args.pricelist_id;
    this.status = new BillMgrStatus(args.status_orig);
    this.status_text = args.status;
    this.cost = parseCost(args.cost);
    this.instruction = args.instruction || 'off';
  }

  get title() {
    return this.name;
  }
}

export class BillingProfile extends BillingItem {
  constructor(args) {
    // console.log(args);
    const required = ['id', 'name', 'profiletype', 'registration_date'];
    super(args, required);
  }

  _init(args) {
    this.id = args.id;
    this.active = args.active;
    this.name = args.name;
    this.phone = args.phone || '';
    this.profiletype = args.profiletype;
    this.profiletype_orig = args.profiletype_orig;
    this.registration_date = Date.parse(args.registration_date) || args.registration_date;
    this.docflow = args.docflow;
    this.company_docflow = args.company_docflow;
    this.need_phone_validate = args.need_phone_validate === 'true';
    this.pneed_activation = args.pneed_activation || 'off';
    this.pneed_manual_vetting = args.pneed_manual_vetting || 'off';
    this.reconciliation = args.reconciliation || 'off';
  }

  get title() {
    return this.name;
  }
}

export class BillingDocs extends BillingItem {
  constructor(args, required = ['number']) {
    super(args, required);
  }

  get title() {
    return this.number;
  }
  get statusColor() {
    const colors = {
      1: baseColors.primary,
      2: baseColors.warning,
      3: baseColors.warning,
      4: baseColors.success,
      5: baseColors.error,
      6: baseColors.error,
      7: baseColors.error,
    };
    return this.status_orig && Object.keys(colors).includes(this.status_orig)
      ? colors[this.status_orig]
      : baseColors.note;
  }
}

export class BillingDocsPayment extends BillingDocs {
  constructor(args) {
    const required = [
      'id',
      'number',
      'create_date',
      'sender_name',
      'paymethodamount_iso',
      'status',
      'status_orig',
    ];
    super(args, required);
  }

  init(args) {
    this._init(args);
    this.create_date = new Date(args.create_date);
    this.paymethodamount_iso = parseCost(args.paymethodamount_iso);
    this.status_orig = args.status_orig || 0;
  }

  get date() {
    return this.create_date;
  }

  get cost() {
    return this.paymethodamount_iso;
  }
}

export class BillingDocsInvoice extends BillingDocs {
  constructor(args) {
    const required = [
      'number',
      'cdate',
      'company',
      'customer',
      'amount_iso',
      'invoice_status',
      'sdate',
    ];
    super(args, required);
  }

  init(args) {
    this._init(args);
    this.cdate = new Date(args.cdate);
    this.amount_iso = parseCost(args.amount_iso);
    this.sdate = new Date(args.sdate);
  }

  get status() {
    return this.invoice_status;
  }

  get status_orig() {
    return this.invoice_status_orig || 0;
  }

  get date() {
    return this.cdate;
  }

  get cost() {
    return this.amount_iso;
  }
}

export class BillingDocsContract extends BillingDocs {
  constructor(args) {
    const required = [
      'number',
      'signdate',
      'client_name',
      'company_name',
      'contract_name',
      'contracttype',
      'status',
    ];
    super(args, required);
  }

  init(args) {
    this._init(args);
    this.signdate = new Date(args.signdate);
    this.status_orig = args.status_orig || 0;
  }

  get date() {
    return this.signdate;
  }

  get dtype() {
    return this.contracttype;
  }
}

export class BillingDocsCustomdocs extends BillingDocs {
  constructor(args) {
    const required = ['date', 'dtype', 'number'];
    super(args, required);
  }

  init(args) {
    this.date = new Date(args.date);
    this.dtype = args.dtype;
    this._number = args.number;
    this.link = args.number;
  }

  get number() {
    return this._link.text;
  }

  get link() {
    return this._link;
  }
  set link(str) {
    this._link = this.parseLink(str);
  }

  parseLink(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const link = doc.getElementsByTagName('a')[0];
    return link;
  }
}

export class BillingHistoryDetails extends BillingItem {
  constructor(args) {
    const required = ['id', /*'payment_number',*/ 'name', 'date', 'sum', 'type'];
    // const required = ['id', /*'payment_number',*/ 'locale_name', 'realdate', 'amount' /*, 'type'*/];
    super(args, required);
  }

  init(args) {
    this._init(args);
    this.date = parseDateRu(args.date);
    // this.date = parseDateRu(args.realdate);
    this.sum = parseCost(args.sum);
    // this.sum = parseCost(args.amount);
  }

  get title() {
    return this.name;
  }

  get number() {
    return this.payment_number;
  }
}

const storedMethodStatusColors = {
  1: baseColors.warning,
  2: baseColors.warning,
  3: baseColors.warning,
  4: baseColors.success,
  5: baseColors.error,
  6: baseColors.warning,
};
export class BillingStoredMethodStatus {
  constructor({ code, msg }) {
    this.code = code;
    this.msg = msg;
    this.color = storedMethodStatusColors[code];
  }
}

export class BillingPaymentMethod extends BillingItem {
  constructor(args) {
    const required = ['module', 'name', 'recurring'];
    super(args, required);
  }

  init(args) {
    this._init(args);
    this.status = new BillingStoredMethodStatus({ code: args.status, msg: args.status_msg });
  }

  get title() {
    return this.name;
  }

  get id() {
    return this.recurring;
  }
}
