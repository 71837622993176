<template>
  <div class="link">
    <plain-button :href="config.value" :target="config.target" color="primary">
      {{ $t('text') }}
    </plain-button>
  </div>
</template>

<script>
export default {
  name: 'LinkBlock',
  props: {
    config: {
      type: Object,
      default: null,
      validator: val => !val || (val.target && val.value),
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "text": "Я прочитал и согласен с условиями предоставления услуг"
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.link {
  flexy(flex-start, nowrap, column);
  flex: 1 1 auto;
  size(100%);
  //max-width: 34em;
  margin: 0 auto;
  padding: 0.25rem 0 1rem;

  +breakpoint(sm-and-up) {
    padding-top: 0.25rem;
  }

  &__link {
    margin-top: 0.25rem;
  }

}
</style>
