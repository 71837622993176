<template>
  <div class="recover-password">
    <div class="recover-password__form" @keyup.enter="submit">
      <div v-for="item in fields" :key="item.key" class="recover-password__field">
        <base-input
          :key="item.name"
          :ref="item.name"
          v-model="item.value"
          :tooltip="item.tooltip || null"
          :type="item.type"
          :name="item.name"
          :required="item.required"
          :custom-error-messages="
            customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
          "
          autocomplete="off"
          use-reactive-validation
          :pattern="item.name === 'confirm' ? (isValid ? formData.password : 'false') : null"
          @change="onChange(item, $event.target.value)"
          @input="onInput"
        >
          <template v-if="item.name === 'password'" #icon>
            <plain-button icon="key" color="dark" tabindex="-1" @click="generatePass(item)" />
          </template>
          <!--          <template v-if="needPhoneValidate && item.type === 'tel'" #after>-->
          <template #fieldAfter>
            <plain-button
              :icon="item.type === 'text' ? 'eye-closed' : 'eye-open'"
              color="dark"
              tabindex="-1"
              @click="showPassword(item)"
            />
          </template>
          {{ $t(`fields.${item.name}.label`) }}
        </base-input>
      </div>
      <div class="recover-password__footer">
        <base-button
          class="recover-password__submit"
          :disabled="!isValid"
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('submit') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import mixin from '@/layouts/Profile/mixins/index';
import showErrorModal from '@/mixins/showErrorModal';
import generatePassword from '@/mixins/generatePasswd';
import store from '@/store';
export default {
  name: 'RecoverPassword',
  components: { BaseInput },
  mixins: [mixin, showErrorModal, generatePassword, billMgrToolActions],
  props: {
    recoveryInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      initialBackup: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('fields.confirm.patternMismatch') },
      },
      fields: [
        {
          name: 'password',
          type: 'password',
          invalid: false,
          value: '',
          tooltip: this.$t('fields.password.tooltip'),
        },
        {
          name: 'confirm',
          type: 'password',
          required: true,
          value: '',
          invalid: false,
          tooltip: this.$t('fields.confirm.tooltip'),
        },
      ],
      isReady: false,
      isLoading: false,
    };
  },
  computed: {
    isValid() {
      return (
        (this.fields[0].value === this.fields[1].value && this.fields[0].value.length > 0) ||
        (this.formData.confirm === this.formData.password && this.ifGenerate)
      );
    },
  },
  watch: {
    fields(val) {
      this.setInitialFormData(val);
    },
    isReady(val) {
      this.$emit('ready-state-change', val);
    },
    hasChanges(val) {
      this.$emit('form-data-change', val);
    },
  },
  mounted() {
    if (this.fields.length) this.setInitialFormData(this.fields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    onInput() {
      this.ifGenerate = false;
    },
    onChange(key, value) {
      this.formData[key.name] = value;
      // const blur = this.fields.filter(x => x.name !== key.name)[0];
      if (this.ifGenerate) {
        this.formData.confirm = this.formData.password;
        this.fields[1].value = this.fields[0].value;
      }
    },
    showPassword(item) {
      if (item.type === 'password') {
        item.type = 'text';
      } else {
        item.type = 'password';
      }
    },
    //
    async sendBillMgrAction(params) {
      return store.dispatch('sendBillMgrToolActionRecover', params);
    },
    setInitialFormData(list) {
      this.initialBackup = '';
      this.formData = list.reduce((acc, item) => {
        acc[item.name] = item.value;
        this.initialBackup += item.value;
        return acc;
      }, {});
      this.isReady = true;
    },
    submit(e) {
      e.preventDefault();
      if (this.isValid) {
        this.isLoading = true;
        const params = {
          clicked_button: 'on',
          sok: 'ok',
          project: localStorage.getItem('project') || '3',
          password: this.formData.password,
          confirm: this.formData.confirm,
          ...this.recoveryInfo,
        };
        this.sendBillMgrAction(params)
          .then(data => {
            if (data && data.func === 'auth' && data.ok) {
              localStorage.setItem('token', data.auth);
              // this.clear();
              this.showSuccess(this.$t('success'));
              setTimeout(() => this.$router.push({ name: 'start' }), 500);
            } else {
              console.error('error', data);
              // this.showError(data);
            }
            // this.$emit('submit', this.formData);
            // this.saveCredentials(this.profile.name, pass);
          })
          .catch(e => this.showError(e))
          .finally(() => (this.isLoading = false));
      }
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        // title: this.$t('success'),
        text: msg,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    // saveCredentials(name, password) {
    //   if ('PasswordCredential' in window) {
    //     const credential = new PasswordCredential({
    //       id: name,
    //       name,
    //       password,
    //     });
    //     navigator.credentials.store(credential).then(
    //       () => {
    //         console.info("Credential stored in the user agent's credential manager.");
    //       },
    //       err => {
    //         console.error('Error while storing the credential: ', err);
    //       }
    //     );
    //   }
    // },
    clear() {
      this.formData = {};
      this.initialBackup = '';
      this.setInitialFormData(this.fields);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields": {
        "password": {
          "label": "Новый пароль",
          "tooltip": "Сгенерировать надежный пароль"
        },
        "confirm": {
          "label": "Подтверждение пароля",
          "tooltip": "Подтверждение пароля",
          "patternMismatch": "Пароли не совпадают"
        }
      },
      "submit": "Обновить пароль",
      "ok": "Понятно",
      "error": "Ошибка",
      "success": "Ваш пароль успешно узменен."
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.recover-password {

  &__form {
  }

  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }

  //&__submit {
  //  width: 100%;
  //  text-align: center;
  //  white-space: nowrap;
  //  margin-top: 2.5rem;
  //  min-width: 165px;
  //
  //}
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
}
</style>
