import { IspTools } from '@/models/base/IspTools';
import Payers from '@/layouts/Billing/pages/Main/views/Payers';

const sortOrders = ['asc', 'desc'];
export const baseListMutations = {
  SET_LIST: (state, list) => {
    // console.log(state, list);
    try {
      if (state.itemClass) {
        const Item = state.itemClass;
        state.list = list.map(i => new Item(i));
      } else state.list = [...list];
    } catch (e) {
      console.error(e);
    }
  },
  SET_LIST_BLOCK: (state, listPaymentBlock) => {
    // console.log(list);
    try {
      if (state.itemClass) {
        const Item = state.itemClass;
        state.listPaymentBlock = listPaymentBlock.map(i => new Item(i));
      } else state.listPaymentBlock = [...listPaymentBlock];
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_LIST: (state, list) => {
    if (state.itemClass) {
      // console.log(state.itemClass);
      const Item = state.itemClass;
      const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
      const filteredOldList = state.list.reduce((acc, item) => {
        const compare = compareList.find(i => i.id == item.id);
        if (!!compare) {
          item.update(compare.data);
          acc.push(item);
          compare.isset = true;
        }
        return acc;
      }, []);
      const newList = compareList.filter(i => !i.isset).map(i => new Item(i.data));
      state.list = [...filteredOldList, ...newList];
    } else state.list = [...list];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_TOOLS: (state, value) => {
    const toolsClass = state.toolsClass ? state.toolsClass : IspTools;
    try {
      state.tools = new toolsClass(value);
    } catch (e) {
      console.error(e);
    }
  },
  setViewCardList: (state, payload) => {
    console.log('1');
    try {
      state.viewAddCard = payload;
    } catch (e) {
      console.error(e);
    }
  },
  SET_PAYERS_INFO: (state, value) => {
    try {
      state.typeOfPayers = value;
    } catch (e) {
      console.error(e);
    }
  },
};
