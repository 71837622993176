import container from './container.vue';

export const RouteCDN = {
  path: '/cdn',
  name: 'cdn',
  redirect: { name: 'cdnMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'CDN',
    navigation: {
      service: {
        key: 'cdn',
        // icon: 'el-icon-s-help',
        icon: 'el-icon-share',
        order: 15,
      },
    },
    access: ['mainmenuservice.cdnedge'],
  },
  children: [
    {
      path: '',
      name: 'cdnMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'CDN',
      },
      component: () => import(/* webpackChunkName: "cdn-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'cdnOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ CDN',
      },
      component: () => import(/* webpackChunkName: "cdn-order" */ './pages/Order/CDNOrder.vue'),
      children: [
        {
          path: '/cdn/order',
          redirect: { name: 'cdnOrder' },
        },
        {
          path: '/cdn/order/:id/:period',
          name: 'cdnOrderDetails',
          props: true,
          component: () =>
            import(/* webpackChunkName: "cdn-order-details" */ './pages/Order/CDNOrderDetails.vue'),
        },
        {
          path: '/cdn/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'cdnOrderDetails' },
        },
        {
          path: '/cdn/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'cdnOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'cdnDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "cdn-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewCDN' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "cdn-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewCDN',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "cdn-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configCDN',
        },
        // {
        //   path: 'backup',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "cdn-info-backup" */ './pages/Information/views/Backup.vue'
        // ),
        // name: 'backupCDN',
        // },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "cdn-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyCDN',
        },
      ],
    },
  ],
};
