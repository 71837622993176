import container from './container.vue';

export const RoutePrivate = {
  path: '/private_cloud',
  name: 'private',
  redirect: { name: 'privateMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Частное облако',
    navigation: {
      service: {
        key: 'private',
        icon: 'el-icon-mobile',
        order: 12,
      },
    },
    access: ['mainmenuservice.private_cloud'],
  },
  children: [
    {
      path: '',
      name: 'privateMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Частное облако',
      },
      component: () => import(/* webpackChunkName: "private-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'privateOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ частного облака',
      },
      component: () =>
        import(/* webpackChunkName: "private-order" */ './pages/Order/PrivateOrder.vue'),
      children: [
        {
          path: '/private_cloud/order',
          redirect: { name: 'privateOrder' },
        },
        {
          path: '/private_cloud/order/:id/:period',
          name: 'privateOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "private-order-details" */ './pages/Order/PrivateOrderDetails.vue'
            ),
        },
        {
          path: '/private_cloud/order/:id/:period/2', // костыль для project=2  - караван
          redirect: { name: 'privateOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'privateDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "private-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewPrivate' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "private-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewPrivate',
        },
        {
          path: 'config',
          meta: {
            name: 'Конфигуратор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "private-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configPrivate',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "private-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyPrivate',
        },
      ],
    },
  ],
};
