<template>
  <div class="base-tumbler" :class="classes" @click="changeState">
    <div class="base-tumbler__circle"></div>
  </div>
</template>

<script>
import { themeState, getHtml, loadThemeColors } from '@/utils/darkSwitch.js';
export default {
  name: 'BaseTumbler',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasAnimation: false,
    };
  },
  computed: {
    classes() {
      const base = 'base-tumbler';
      return {
        [`${base}--has-animation`]: this.hasAnimation,
        [`${base}--left`]: this.value,
      };
    },
  },
  methods: {
    changeState() {
      if (!this.hasAnimation) this.hasAnimation = true;
      this.$emit('change', !this.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@keyframes swipeRight {
  from {
    left: 3px;
  }
  to {
    left: 32px;
  }
}
@keyframes swipeLeft {
  from {
    left: 32px;
  }
  to {
    left: 3px;
  }
}

.base-tumbler {
  cursor: pointer;
  position: relative;
  padding: 3px;
  width: 56px;
  height: 26px;
  border-radius: 20px;
  background-color: var(--tumbler-background);

  &__circle {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 4px solid var(--tumbler-border);
    background-color: var(--tumbler-background);
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

    ^[0]--has-animation & {
      animation: swipeLeft 0.5s ease;
      animation-fill-mode: backwards;
      animation-fill-mode: forwards;
    }

    ^[0]--left & {
      left: 32px;
    }
    ^[0]--has-animation^[0]--left & {
      animation: swipeRight 0.5s ease;
      animation-fill-mode: backwards;
      animation-fill-mode: forwards;
    }
  }
}
</style>
