<template>
  <div class="security-totp-enable standart-text">
    <div class="security-totp-enable__desc">
      {{ $t('desc') }}
    </div>
    <div class="security-totp-enable__qr">
      <img :src="qrSrc" alt="" class="security-totp-enable__qr-img" />
      <p class="security-totp-enable__desc">
        {{ $t('qrHint') }}
      </p>
    </div>
    <div class="security-totp-enable__field security-totp-enable__field--login">
      <div class="security-totp-enable__label standart-title">
        {{ $t('account') }}
      </div>
      <div class="security-totp-enable__value standart-text">
        {{ login }}
      </div>
    </div>
    <div ref="secret" class="security-totp-enable__field security-totp-enable__field--secret">
      <div class="security-totp-enable__label standart-title">
        {{ $t('key') }}
      </div>
      <div
        v-clipboard="secret"
        v-clipboard:success="clipboardSuccessHandler"
        v-clipboard:error="clipboardErrorHandler"
        v-tooltip="secretTooltipOptions"
        class="security-totp-enable__value standart-text clippy"
        @mouseenter="secretTooltipState.show = true"
        @mouseleave="secretTooltipState.show = secretTooltipState.wait || false"
      >
        {{ secret }}
      </div>
    </div>
    <div class="security-totp-enable__field security-totp-enable__field--code">
      <base-input
        ref="input"
        :value="formData.code"
        required
        use-reactive-validation
        :tooltip="$t('codeHint')"
        minlength="6"
        maxlength="6"
        type="tel"
        :custom-error-messages="{ tooShort: $tc('tooShort', 6) }"
        class="security-totp-enable__input"
        @input="onCodeChange"
        @keyup.enter="submit"
      >
        {{ $t('code') }}
      </base-input>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { UserProfile } from '@/models/BillMgr/UserProfile';
export default {
  name: 'SecurityTotpEnable',
  components: { BaseInput },
  props: {
    profile: {
      type: UserProfile,
      required: true,
    },
    qrimage: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
    login: {
      type: String,
      required: true,
    },
    doSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clipboardState: 'default',
      secretTooltipState: {
        show: false,
        wait: false,
      },
      formData: {
        code: '',
      },
    };
  },
  computed: {
    qrSrc() {
      return this.profile.host + this.qrimage + `&auth=${localStorage.getItem('token')}`;
    },
    secretTooltipOptions() {
      return {
        content: this.$t(`clipboard.${this.clipboardState}`),
        placement: 'top-end',
        trigger: 'manual',
        show: this.secretTooltipState.show,
        classes: 'standart-text',
        container: false,
      };
    },
    isValid() {
      return !!this.formData.code && this.formData.code.length === 6;
    },
  },
  watch: {
    isValid(val) {
      this.$emit('ready', val);
    },
    doSubmit(val) {
      if (val) this.submit();
    },
  },
  mounted() {
    this.$refs.input.$el.querySelector('input').focus();
  },
  methods: {
    clipboardSuccessHandler() {
      this.clipboardChangeHandler('success');
    },
    clipboardErrorHandler() {
      this.clipboardChangeHandler('error');
    },
    clipboardChangeHandler(state) {
      this.clipboardState = state;
      this.secretTooltipState.show = true;
      this.secretTooltipState.wait = true;
      setTimeout(() => {
        this.secretTooltipState.wait = false;
        this.secretTooltipState.show = false;
        this.clipboardState = 'default';
      }, 3000);
    },
    onCodeChange(value) {
      this.formData.code = value;
    },
    submit() {
      if (this.isValid) {
        this.$emit('submit', this.formData.code);
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Настройка двухэтапной аутентификации",
      "desc": "Для активации двухэтапной аутентификации отсканируйте штрих-код с помощью приложения Google Authenticator.  Убедитесь, что разница во времени на сервере и на телефоне составляет менее, чем 1 минуту",
      "qrHint": "Если штрих-код не распознается, то для настройки аккаунта в приложении Google Authenticator введите ключ вручную",
      "account": "Название аккаунта",
      "key": "Ключ",
      "code": "Код подтверждения",
      "codeHint": "6-значный числовой пароль, сгенерированный приложением Google Authenticator",
      "tooShort": "Введите 0 цифр | Введите 1 цифру | Введите {count} цифры | Введите {count} цифр"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.security-totp-enable {
  &__qr {
    margin-top: 1.5rem;

    &-img {
      display: block;
      margin: 0 auto 1.5rem;
    }
  }
  &__field {
    margin-top: 1.5rem;
  }
  &__label {
    margin-bottom: 0.5rem;
  }
  &__value {
    position: relative;
    padding: 0.5em 1em;
    background: var(--highlight);
    border-radius: $border-radius-small;
    word-break: break-all;
    transition: background $anim-base-duration ease, color $anim-base-duration ease;

    &.clippy {
      cursor pointer;

      &:hover {
        background: var(--highlight-hover);
      }
    }
  }
}
</style>
