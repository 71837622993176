import { filterRoutesForNav } from '../utils/permissions';

const state = () => ({
  routes: [],
  last: null,
});

const getters = {
  routes: state => state.routes,
  filteredRoutesForNavByKey: (state, _getters, root) => key => {
    return filterRoutesForNav(state.routes, key, root.moduleProfile.access).sort((a, b) =>
      a.order && b.order ? a.order - b.order : -1
    );
  },
  serviceNav: (_state, getters) => getters.filteredRoutesForNavByKey('service'),
  accountNav: (_state, getters) => getters.filteredRoutesForNavByKey('account'),
  miniProfileNav: (_state, getters) => getters.filteredRoutesForNavByKey('miniProfile'),
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = routes;
  },
  SET_LAST: (state, value) => {
    state.last = value;
  },
};

const actions = {
  async generateNavs({ commit }, routes) {
    await commit('SET_ROUTES', routes);
    return Promise.resolve(routes);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
