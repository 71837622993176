import MUTATIONS from './mutations';
import STATE from './state.js';
import ACTIONS from './actions';
import GETTERS from './getters';

const ModuleProfile = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
  getters: GETTERS,
};

export default ModuleProfile;

// out: bjson
// auth: c5ebb56d1ecd24bba598be3a
// func: desktop
