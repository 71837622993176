<template>
  <main-card class="security-totp">
    <div class="standart-text">
      {{ totp.desc }}
    </div>
    <base-button slot="bodyEnd" @click="onClick">
      {{ $t(`btn.${totp.state.action}`) }}
    </base-button>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import SecurityTotpEnable from './SecurityTotpEnable';
import TotpCode from '@/components/TotpCode/TotpCode';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import { UserProfile } from '@/models/BillMgr/UserProfile';
import showErrorModal from '@/mixins/showErrorModal';
import qs from 'qs';
import Vue from 'vue';
export default {
  name: 'SecurityTotp',
  components: { MainCard },
  mixins: [showErrorModal],
  props: {
    profile: {
      type: UserProfile,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      handlers: {
        on: this.turnOn,
        off: this.turnOff,
      },
      components: {
        on: SecurityTotpEnable,
        off: TotpCode,
      },
      modal: null,
    };
  },
  computed: {
    totp() {
      return this.profile.totp || null;
    },
  },
  methods: {
    onClick() {
      this.handlers[this.totp.state.action]();
    },
    turnOn() {
      this.initModal();
      const params = qs.parse(this.totp.enable.value);
      this.$store
        .dispatch('moduleProfile/fetchUser', params)
        .then(data => {
          const { model } = data;
          Vue.set(this.modal, 'props', { profile: this.profile, ...model });
          this.modal.on = {
            ready: val => Vue.set(this.modal.footer.confirm.props, 'disabled', !val),
            submit: val => {
              this.modal.footer.confirm.props.loading = true;
              this.modal.props.doSubmit = false;
              const payload = {
                id: this.profile.id,
                data: { ...data.model, qrcode: val },
                ...params,
              };
              this.setTotp(payload);
            },
          };
          Vue.set(this.modal, 'component', this.components[this.totp.state.action]);
        })
        .catch(e => this.showError(e));
    },
    turnOff() {
      this.initModal({ desc: this.$t(`${this.totp.state.field}.desc`) });
      this.modal.on = {
        ready: val => Vue.set(this.modal.footer.confirm.props, 'disabled', !val),
        submit: val => {
          Vue.set(this.modal.footer.confirm.props, 'loading', true);
          Vue.set(this.modal.props, 'doSubmit', false);
          const payload = {
            id: this.profile.id,
            data: {
              ...this.profile.usrparamModel,
              [this.totp.disable.name]: val,
            },
            func: 'usrparam',
          };
          this.setTotp(payload);
        },
      };
      Vue.set(this.modal, 'component', this.components[this.totp.state.action]);
    },
    initModal(props = {}) {
      this.$modals.open({
        name: 'SecurityTotp',
        component: BaseLoader,
        title: this.$t(`${this.totp.state.field}.title`),
        props,
        onOpen: instance => (this.modal = instance),
        onClose: () => (this.modal = null),
        footer: {
          confirm: {
            props: { title: this.$t('submit'), disabled: true },
            on: {
              click: () => {
                Vue.set(this.modal.props, 'doSubmit', true);
              },
            },
          },
          cancel: {
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    setTotp(payload) {
      return this.$store
        .dispatch('moduleProfile/setTotp', payload)
        .then(data => {
          if (data && data.ok && data.ok !== true) {
            const v = qs.parse(data.ok.v);
            if (v.func && v.func === 'usrparam') this.fetchUserParams();
            else this.setTotp(v);
          } else {
            this.fetchUserParams();
          }
          this.$modals.close();
        })
        .catch(e => {
          this.modal.footer.confirm.props.loading = false;
          this.showError(e.msg);
        });
    },
    fetchUserParams() {
      return this.$store.dispatch('moduleProfile/fetchUserParams');
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "btn": {
        "on": "Включить",
        "off": "Выключить"
      },
      "enable": {
        "title": "Настройка двухэтапной аутентификации"
      },
      "disable": {
        "title": "Отключение двухэтапной аутентификации",
        "desc": "Для отключения двухэтапной аутентификации введите 6-значный числовой код, сгенерированный приложением Google Authenticator"
      }
    }
  }
</i18n>

<style></style>
