<template>
  <div class="activation">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="activation__content">
        <p class="activation__desc standart-text">
          {{ $t('desc') }}
          {{ getUserMail }}
          {{ $t('descafter') }}
        </p>
        <div class="activation__info standart-text">
          <p class="activation__desc standart-text">
            {{ $t('textSupport') }}
            <a :href="`mailto:${supportEmail}`" class="activation__link">
              {{ supportEmail }}
            </a>
            {{ $t('textSupportAfter') }}
          </p>
        </div>
        <div class="activation__footer">
          <base-button
            :disabled="!isValid"
            :loading="isSending"
            class="activation__submit"
            @click="sendActivationLink(',kf,fkfdfdf')"
            @keyup.enter.stop
          >
            {{ $t('submit') }}
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import showSuccess from '../mixins/showSuccess';
import makeModal from '../mixins/showSuccess';
import showResModal from '../mixins/showSuccess';
export default {
  name: 'Activation',
  mixins: [setFocus, showSuccess, makeModal, showResModal],
  data() {
    return {
      isLoading: true,
      isSending: false,
      supportEmail: 'help@rusonyx.ru',
      fields: [
        {
          name: 'email',
          type: 'email',
          invalid: false,
          value: '',
        },
      ],
      error: '',
    };
  },
  computed: {
    isValid() {
      return this.fields.every(i => !i.required || (i.required && i.value));
    },
    getUserMail() {
      return this.$store.state.moduleApp.mail;
    },
  },
  mounted() {
    // this.fetch();
    this.sendActivationLink();
  },
  beforeDestroy() {
    localStorage.removeItem('tokenRecover');
  },
  methods: {
    sendActivationLink(text = '') {
      this.isLoading = true;
      this.$store
        .dispatch('send', {
          func: 'register.activationsend',
          clicked_button: 'on',
          auth: localStorage.getItem('tokenRecover'),
        })
        .then(data => {
          // console.log(data);
          if (text) this.showResModal(text);
        })
        .catch(data => {
          if (data.type && data.msg && data.type === 'actnotnow') {
            this.showResModal(this.$t('text', { text: data.msg }));
            this.$router.push({ name: 'Login' }).catch(() => {});
            // console.log(data);
          }
        })
        .finally(() => {
          localStorage.removeItem('token');
          this.isLoading = false;
        });
    },
    setRequiredFields(obj) {
      this.fields.forEach(i => {
        if (obj[i.name] && obj[i.name] === 'true') i.required = true;
      });
    },
    submit() {
      if (!this.isValid) return false;
      this.isSending = true;
      const formData = this.fields.reduce((acc, i) => {
        acc[i.name] = i.value;
        return acc;
      }, {});
      const payload = {
        ...formData,
        sok: 'ok',
        func: 'register.activationsend',
      };
      this.$store
        .dispatch('send', payload)
        .then(() => {
          this.showSuccess();
        })
        .catch(e => {
          this.error = e.msg;
          this.fields.forEach(i => {
            if (i.name === e.object) i.invalid = true;
            else i.invalid = false;
          });
        })
        .finally(() => (this.isSending = false));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "desc": "На e-mail ",
      "descafter": " выслано письмо со ссылкой на подтверждение e-mail адреса",
      "submit": "Отправить повторно",
      "textSupport": "Если письмо не получено, обратитесь в нашу техническую поддержку по адресу ",
      "textSupportAfter": ", либо на электронный адрес провайдера",
      "text": "Ваша учетная запись не активирована. Вам было выслано ранее письмо с активационной ссылкой. {text}",
      "success": {
        "title": "Запрос успешно отправлен",
        "text": "Для восстановления пароля следуйте инструкциям в письме, отправленном на почту, указанную при регистрации.",
        "ok": "Хорошо"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.activation {
  &__desc {
    margin-bottom: 1.5rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
