/**
 * Transform object to string '?{key}={value}&{key}={value}...'
 * @param {Object} payload Object with params
 */
export default function (payload) {
  if (typeof payload !== 'object')
    throw new Error(`Serialize is failed, '${payload}' is not an object`);
  return Object.keys(payload).reduce((acc, cur, i) => {
    if (i === 0) acc += '?';
    else acc += '&';
    acc += `${cur}=${payload[cur]}`;
    return acc;
  }, '');
}
