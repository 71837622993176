<template>
  <div class="container">
    <div class="all">
      <div class="all-info">
        <div class="all-info__content">
          <page-title class="all-info__title">
            {{ $t('title') }}
          </page-title>
        </div>
      </div>
      <transition name="fade" mode="out-in" appear>
        <!--        <div v-if="isLoading" class="all-info__loader">-->
        <!--          <base-loader class="all-info__loader-item" />-->
        <!--        </div>-->
        <!--        <div v-else class="all-content">-->
        <div class="all-content">
          <transition name="fade" mode="out-in">
            <div class="all-content__list">
              <div v-if="loading" class="all-info__loader">
                <base-loader class="all-info__loader-item" />
              </div>
              <base-table
                v-else
                :columns="tableHead"
                :list="paginList"
                class="tariffs-table__table"
              >
                <tariffs-table-row
                  v-for="item in paginList"
                  :id="item.id"
                  :key="item.id"
                  :columns="tableHead"
                  :item="item"
                  @edit="updateList(item, $event)"
                  @event="updateList(item, $event)"
                />
              </base-table>
              <NewPagination
                :step="pagin.step"
                :start="pagin.start || 0"
                :count="list.length"
                type="finance"
                @set-step="setStep"
                @new-pagination="changePagination"
              />
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <div v-if="reformatOrders.length" class="tariffs">
      <div class="all-info">
        <div class="all-info__content">
          <page-title class="all-info__title">
            {{ $t('titleStop') }}
          </page-title>
        </div>
      </div>
      <transition name="fade" mode="out-in" appear>
        <div v-if="loading" class="all-info__loader">
          <base-loader class="all-info__loader-item" />
        </div>
        <div v-else class="all-content">
          <transition name="fade" mode="out-in">
            <div class="all-content__list">
              <table-item-orders :columns="tableHeadOrders" />
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import storeMixin from './mixins/index';
import NewPagination from '@/components/Pagination/NewPagination';
import TariffsTableRow from '@/layouts/All/component/TariffsTableRow';
import TableItemOrders from '@/layouts/All/component/TableItemOrders';

export default {
  name: 'All',
  components: { TariffsTableRow, TableItemOrders, NewPagination, BaseTable },
  mixins: [storeMixin],
  data() {
    return {
      stepOptions: [10, 25, 50, 100, 'all'],
      checkedList: [],
      checkedAll: false,
      // singleProlongComponent: ProlongSingle,
      pagin: {
        start: 0,
        step: 4,
      },
      tableHead: [
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            maxWidth: '100px',
            width: '100px',
            // minWidth: '100px',
            // verticalAlign: 'baseline',
          },
        },
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            maxWidth: '800px',
            width: '700px',
            // minWidth: '900px',
            // verticalAlign: 'baseline',
          },
        },
        {
          label: this.$t('tableHead.ap'),
          key: 'autoprolong',
          style: {
            width: '44px',
          },
        },
        {
          key: 'payment',
          label: this.$t('tableHead.payment'),
          style: {
            width: '150px',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '126px',
          },
          // sort: {
          //   prop: 'status',
          //   order: 'asc',
          // },
        },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            maxWidth: '180px',
            width: '180px',
            // verticalAlign: 'baseline',
          },
        },

        {
          key: 'prolongcost',
          label: this.$t('tableHead.prolongcost'),
          style: {
            maxWidth: '200px',
            width: '200px',
            // verticalAlign: 'baseline',
          },
        },
      ],
      tableHeadOrders: [
        {
          key: 'date',
          label: this.$t('tableHead.dateCreate'),
          style: {
            width: '100px',
          },
          // sort: {
          //   prop: 'createdate',
          //   order: 'asc',
          // },
        },
        {
          key: 'title',
          label: this.$t('tableHead.title'),
          style: {
            width: '1050px',
            maxWidth: '1600px',
          },
          // sort: {
          //   prop: 'title',
          //   order: 'asc',
          // },
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '100px',
          },
          // sort: {
          //   prop: 'cost',
          //   order: 'asc',
          // },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '100px',
          },
          // sort: {
          //   prop: 'status',
          //   order: 'asc',
          // },
        },
        /*{
          key: 'docs',
          label: this.$t('tableHead.docs'),
          style: {
            width: '100px',
          },
        },*/
      ],
      loading: true,
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleAll/GET_SORTED_LIST'];
    },
    orders() {
      return this.$store.getters['moduleBilling/moduleOrders/GET_SORTED_LIST'];
    },
    reformatList() {
      const list = [];
      this.list.forEach(item => {
        this.orders.forEach(order => {
          if (item.id === order.id) {
            item.itemtype = order.itemtype;
            item.status = Object.assign({}, order.status);
            item.status.text = order.status_text;
            list.push(item);
          }
        });
      });
      return list;
    },
    reformatOrders() {
      return this.orders
        .filter(x => !this.list.map(id => id.id).includes(x.id))
        .filter(x => x.status.code === 3);
    },
    paginList() {
      if (this.pagin.step === 'all') return this.reformatList;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.reformatList.slice(start, end);
    },
  },
  watch: {
    paginList: {
      handler: async function (value) {
        let res = await this.loadingData(value).then(async data => {
          // console.log(data.length, data[0]);
          this.countLoadingEvent = this.paginList.length;
        });
        this.loading = false;
      },
      immediate: true,
    },
    // async paymethodInState(event) {
    //   console.log(event);
    //   if (event) await this.loadingData([event]);
    // },
  },
  mounted() {
    this.$store.dispatch('moduleBilling/modulePayment/updateList');
    if (!this.list.length) {
      this.fetchList().catch(e => {
        console.log(e);
      });
    }
    if (!this.orders.length) {
      this.fetchOrderList();
    }
  },
  methods: {
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
    catchEvent(item, event) {
      // console.log(item, event);
    },
    async updateList(item, event) {
      // console.log(item.id, event.stored_method);
      await this.loadingData([event]);
    },
    async loadingData(value) {
      // console.log(value);
      return new Promise((resolve, reject) => {
        value.forEach(async item => {
          await this.fetchItems({ item: item.itemtype, id: item.id })
            .then(async data => {
              // console.log(data);
              // console.log(this.paginList.find(x => x.id === item.id));
              // console.log(this.paginList.find(x => x.id === item.id).autoprolong_name);
              this.paginList.find(x => x.id === item.id).autoprolong_name =
                data.model.autoprolong || data.model.autoprolong_name || null;
              this.paginList.find(x => x.id === item.id).employeesuspend =
                data.model.employeesuspend;
              this.paginList.find(x => x.id === item.id).abusesuspend = data.model.abusesuspend;
              this.paginList.find(x => x.id === item.id).stored_method = data.model.stored_method;
              this.paginList.find(x => x.id === item.id).autoprolong_available =
                data.model.autoprolong_available;
              this.paginList.find(x => x.id === item.id).autoprolong_period =
                data.model.autoprolong_period || data.model.autoprolong_id || null;

              this.paginList.find(x => x.id === item.id).autoprolongSettings = {
                list: data.slist.autoprolong,
                state:
                  (data.model.autoprolong ||
                    data.model.autoprolong_period ||
                    data.model.autoprolong_id) &&
                  (data.model.autoprolong ||
                    data.model.autoprolong_period ||
                    data.model.autoprolong_id) !== 'null'
                    ? 'on'
                    : 'off',
                value:
                  data.model.autoprolong ||
                  data.model.autoprolong_period ||
                  data.model.autoprolong_id ||
                  null,
                period:
                  data.autoprolong ||
                  data.model.autoprolong_id ||
                  data.model.autoprolong_period ||
                  null,
              };
              if (this.paginList.find(x => x.id === item.id).employeesuspend)
                return this.paginList.find(x => x.id === item.id).employeesuspend;
              else return 'false';
            })
            .then(data => {
              return data;
            })
            .catch(e => reject(e));
        });
        resolve((this.loading = false));
      });
      // return (this.loading = false);
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Активные услуги",
    "titleStop": "Остановленные услуги",
    "tableHead": {
      "name": "Название услуги",
      "title": "Название услуги",
      "id": "#id",
      "ap": "Автопродление",
      "cost": "Стоимость",
      "payment": "Способ оплаты",
      "date": "Действует до",
      "dateCreate": "Дата создания",
      "status": "Статус",
      "prolongcost": "Продлить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.container {
  display: flex;
  flex-direction: column;
  max-width: 85rem;
  margin: auto;
}
.all {
  display: flex;
  flex-direction: column;
  //max-width: 90rem;
  //min-size(100%);
  //max-width: 1650px;
  margin-bottom: 1.5rem;
  flex: 1 1 100%;
  //width: 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
  .tariffs-table {
    width: 100%;
  }
  .tariffs-table__pagination {
    margin-top: 1.5rem;
  }
    .tariffs{
      width: 100%;
    }
</style>
