import BaseAddon from './BaseAddon';
import { isEmpty } from 'lodash';
import { BillMgrPeriods } from '@/models/BillMgr/periods';

export default class BaseConfigurator {
  constructor(args, addonClass = BaseAddon) {
    this.addonClass = addonClass;
    this.blockingvalues = ['finalblock', 'blocking'];
    this.stamp = performance.now();
    this._info = null;
    this._addonsExt = null;
    this._hidefields = [];
    this._pages = [];
    this._init(args);
  }

  _init(args) {
    this.info = args;
    if (args.hidefields) this.hidefields = args.hidefields;
    if (args.pages) this.pages = args.pages;
  }

  set info({ customfields = {}, slist = {}, model = {}, list = {} }) {
    const hiddenTypes = ['data'];
    if (!this._info) {
      const keys = this.getAddonsKeys(customfields);
      const fields = keys.reduce((acc, i) => {
        const item = customfields[i];
        if (!hiddenTypes.includes(item.type)) acc[i] = item;
        return acc;
      }, {});
      this._info = {
        customfields: fields,
        slist,
        model,
        list,
      };
    } else {
      this._info.slist = slist;
    }
  }
  get info() {
    return this._info;
  }

  set addonsExt(list) {
    this._addonsExt = list;
  }
  get addonsExt() {
    return this._addonsExt;
  }

  set hidefields(list) {
    this._hidefields = list;
  }
  get hidefields() {
    return this._hidefields;
  }

  set pages(list) {
    this._pages = list;
  }

  get pages() {
    return this._pages;
  }

  get configurator() {
    return this.makeConfigurator();
  }

  makeConfigurator() {
    if (!this._info || isEmpty(this._info.customfields)) return [];
    const { customfields, slist = {}, list = {} } = this._info;
    const inner = Object.keys(customfields).reduce((acc, i) => {
      const item = customfields[i];
      if (!item) return acc;
      const props = {};
      if (item.type === 'select' || item.type === 'radio' || item.type === 'multiple') {
        // console.log(item.type === 'select' ? slist[item.name] : 'пусто');
        // console.log(item.type === 'select' ? item.name : 'пусто');

        props.options = slist[item.name] ? slist[item.name] : [];
      }
      if (item.type === 'list' || item.type === 'paymethodlist') {
        props.options = list[item.name] ? list[item.name] : [];
      }
      const addonExt = this.addonsExt ? this.addonsExt.find(i => i.intname === item.intname) : -1;
      if (addonExt) {
        props.ext = addonExt;
        if (addonExt.addonlimit) props.limit = addonExt.addonlimit;
      }
      const cost = item.cost
        ? item.cost
        : addonExt && addonExt.period_cost
        ? addonExt.period_cost
        : 0;
      acc.push(
        new this.addonClass({
          ...item,
          cost,
          ...props,
        })
      );
      return acc;
    }, []);
    return inner;
  }

  getAddonsKeys(fields) {
    return Object.keys(fields);
  }
}
