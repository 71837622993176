<template>
  <div class="error">
    <div class="success__form__form">
      <asset-img :src="'error.png'" class="error-img" />
    </div>
    <div class="success__form__form">
      <div class="standart-title">
        <!--        Напишите на электронную почту <a href="mailto:support@rusonyx.ru">support@rusonyx.ru</a>-->
        <!--        <br />и мы постараемся решить вашу проблему.-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.$gtm.trackEvent({
      event: '_event_error', // Event type [default = 'interaction'] (Optional)
      category: 'Pay',
      action: 'click',
      label: 'Error',
      value: 4000,
      noninteraction: false, // Optional
    });
  },
  methods: {},
};
</script>

<i18n>
{
  "ru": {
  }
}
</i18n>

<style lang="stylus" scoped>
.error {
  text-align: center;
  &-img {
    width: 10rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
