import container from './container.vue';

export const RouteMo365 = {
  path: '/microsoft_office',
  name: 'mo365',
  redirect: { name: 'mo365Main' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Лицензии Офис',
    navigation: {
      service: {
        key: 'microsoft-office',
        icon: 'el-icon-office-building',
        order: 10,
      },
    },
    access: ['mainmenuservice.microsoft_office'],
  },
  children: [
    {
      path: '',
      name: 'mo365Main',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Лицензии Офис',
      },
      component: () => import(/* webpackChunkName: "mo365-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'mo365Order',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Покупка лицензии Офис',
      },
      component: () => import(/* webpackChunkName: "mo365-order" */ './pages/Order/Mo365Order.vue'),
      children: [
        {
          path: '/microsoft_office/order',
          redirect: { name: 'mo365Order' },
        },
        {
          path: '/microsoft_office/order/:id/:period',
          name: 'mo365OrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "mo365-order-details" */ './pages/Order/Mo365OrderDetails.vue'
            ),
        },
        {
          path: '/microsoft_office/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'mo365OrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'mo365Detail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "mo365-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewMo365' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Обзор',
          },
          component: () =>
            import(/* webpackChunkName: "mo365-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewMo365',
        },
        {
          path: 'config',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Конфигуратор',
          },
          component: () =>
            import(
              /* webpackChunkName: "mo365-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configMo365',
        },
        {
          path: 'history',
          meta: {
            isShow: true,
            isLink: false,
            name: 'История',
          },
          component: () =>
            import(
              /* webpackChunkName: "mo365-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyMo365',
        },
      ],
    },
  ],
};
