<template>
  <div class="base-prolong">
    <div v-if="title || $slots.title || subtitle || $slots.subtitle" class="base-prolong__header">
      <div v-if="title || $slots.title" class="base-prolong__title big-title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div v-if="subtitle || $slots.subtitle" class="base-prolong__subtitle medium-text note-color">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
      <div v-if="text || $slots.text" class="base-prolong__text medium-text alert-color">
        <slot name="text">
          {{ text.includes('Продление с даты остановки') ? $t('text') : text }}
        </slot>
      </div>
    </div>
    <div v-if="list.length && formData" class="base-prolong__body">
      <base-prolong-item
        v-for="item in list"
        :key="item.id"
        :title="item.title"
        :subtitle="item.subtitle || ''"
        :list="item.list"
        :value="formData[item.id]"
        class="base-prolong__item"
        @change="onItemChange(item.id, $event)"
      />
    </div>
    <div v-if="showTotal && total" class="base-prolong__footer">
      <div v-if="showTotal && total" class="base-prolong__total medium-title">
        <div class="base-prolong__total-title">
          {{ $t('total') }}
        </div>
        <div class="base-prolong__total-sum">
          {{ $n(total, 'currency') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseProlongItem from './BaseProlongItem';
export default {
  name: 'BaseProlong',
  components: { BaseProlongItem },
  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
      validator: arr => !arr.length || arr.every(i => i.id && i.title && i.value && i.list),
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: null,
    };
  },
  computed: {
    total() {
      if (!this.formData) return 0;
      return Object.values(this.formData).reduce((acc, item) => {
        // return Object.values(this.list).reduce((acc, item) => {
        //   console.log(acc, item);
        acc += parseFloat(item.cost);
        return acc;
      }, 0);
    },
  },
  watch: {
    list: function () {
      this.initFormData();
    },
    total: function (value) {
      this.$emit('change-total', value);
    },
  },
  mounted() {
    this.initFormData();
  },
  methods: {
    initFormData() {
      this.formData = this.list.reduce((acc, i) => {
        // console.log(i.cost);
        acc[i.id] = i.value;
        // console.log(acc[i.id].cost);
        if (i.cost) acc[i.id].cost = i.cost; //TODO для ISP лицензий
        return acc;
      }, {});
      this.$emit('ready');
    },
    onItemChange(id, value) {
      this.formData[id] = value;
      this.$emit('change', this.formData);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "total": "Итого",
    "text": "Для данной услуги действует ограничение - \"Продление с даты остановки\""
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-prolong {
  &__header {
    +breakpoint(sm-and-up) {
      text-align: center;
    }
  }
  &__title + &__subtitle + &__text {
    margin-top: 1.25rem;
  }

  &__header + &__body {
    margin-top: 2.5rem;
  }

  &__header + &__footer,
  &__body + &__footer {
    margin-top: 1.5rem;
  }

  &__item + &__item {
    margin-top: 1rem;

    +breakpoint(ms-and-down) {
      border-top 1px solid var(--modal-separator);
      padding-top: 1rem;
    }
    +breakpoint(sm-and-up) {
      margin-top: 1.5rem;
    }
  }

  &__total {
    flexy(space-between, center);
    padding-top: 1rem;
    border-top: 1px solid var(--modal-separator);
  }
}
</style>
