export default {
  data() {
    return {
      timerId: '',
      updateTime: null,
    };
  },
  computed: {
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
  },
  beforeDestroy() {
    this.resetTimers();
  },
  methods: {
    updateBalance(needUpdateList = false) {
      const oldBalance = this.balance;
      if (!this.updateTime) this.updateTime = Date.now();
      this.timerId = setTimeout(() => {
        if (needUpdateList) {
          this.$store.dispatch('moduleBilling/modulePayment/updateList', { silent: true });
        }
        this.$store.dispatch('moduleProfile/setProfileInfo').then(data => {
          const curTime = Date.now();
          // TIMEOUT = 5 minutes
          const TIMEOUT = 1000 * 60 * 5;
          if ((data.balance && data.balance == oldBalance) || curTime - this.updateTime < TIMEOUT) {
            this.updateBalance(needUpdateList);
            if (data.balance < oldBalance) {
              this.$gtm.trackEvent({
                event: '_event_success',
                category: 'Pay',
                action: 'pay',
                label: 'Success',
                value: 4000,
                noninteraction: false, // Optional
              });
            }
          } else {
            clearTimeout(this.timerId);
          }
        });
      }, 1000);
    },
    resetTimers() {
      this.updateTime = null;
      if (this.timerId) clearTimeout(this.timerId);
    },
  },
};
