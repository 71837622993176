<template>
  <div class="profile">
    <div class="container-content">
      <div class="profile__title page-title">
        {{ $t('title') }}
      </div>
      <tabs :list="allowedNavFilter" class="profile__tabs medium-title">
        <template v-slot:item="{ item }">
          <router-link exact-active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
      </tabs>
      <div class="profile__content">
        <transition name="slide-fade">
          <router-view :loading="loading"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
export default {
  name: 'MainProfile',
  components: {
    tabs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // nav: [
      //   {
      //     title: this.$t('nav.personal'),
      //     to: { name: 'ProfilePersonal' },
      //     access: ['customer', 'mainmenutool.user.edit'],
      //   },
      //   {
      //     title: this.$t('nav.security'),
      //     to: { name: 'ProfileSecurity' },
      //     access: ['customer.usrparam'],
      //   },
      //   {
      //     title: this.$t('nav.notifications'),
      //     to: { name: 'ProfileNotify' },
      //     access: ['customer.usrparam'],
      //   },
      //   {
      //     title: this.$t('nav.confidential'),
      //     to: { name: 'ProfileConf' },
      //     access: ['customer.usrparam'],
      //   },
      //   {
      //     title: this.$t('nav.users'),
      //     to: { name: 'AllowedUsers' },
      //     access: ['customer.user', 'mainmenutool.user.edit'],
      //   },
      //   {
      //     title: this.$t('nav.partners'),
      //     to: { name: 'Partners' },
      //     access: ['customer.usrparam'],
      //   },
      // ],
    };
  },
  computed: {
    nav() {
      const arr = [];
      arr.push([
        {
          title: this.$t('nav.personal'),
          to: { name: 'ProfilePersonal' },
          access: ['customer', 'mainmenutool.user.edit'],
        },
        {
          title: this.$t('nav.security'),
          to: { name: 'ProfileSecurity' },
          access: ['customer.usrparam'],
        },
        {
          title: this.$t('nav.notifications'),
          to: { name: 'ProfileNotify' },
          access: ['customer.usrparam'],
        },
        {
          title: this.$t('nav.confidential'),
          to: { name: 'ProfileConf' },
          access: ['customer.usrparam'],
        },
        {
          title: this.$t('nav.users'),
          to: { name: 'AllowedUsers' },
          access: ['customer.user', 'mainmenutool.user.edit'],
        },
        {
          title: this.$t('nav.partners'),
          to: { name: 'Partners' },
          access: ['customer.usrparam'],
          key: 'astra',
        },
      ]);
      return arr;
    },
    isAstra() {
      // return this.$store.state.moduleApp.host.includes('astrastack');
      return this.$store.state.moduleProviders.current === '6';
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    allowedNav() {
      return this.nav[0].filter(item => !item.access || item.access.some(i => this.access[i]));
    },
    allowedNavFilter() {
      return this.isAstra ? this.allowedNav.filter(item => item.key !== 'astra') : this.allowedNav;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Профиль",
      "nav": {
        "personal": "Личные настройки",
        "security": "Безопасность",
        "notifications": "Уведомления",
        "confidential": "Конфиденциальность",
        "users": "Пользователи",
        "telegram": "Telegram",
        "partners": "Партнерам"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.profile {
  &__title {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
