import showErrorModal from '@/mixins/showErrorModal';
export default {
  mixins: [showErrorModal],
  methods: {
    fetchBillMgrAction(params) {
      return this.$store.dispatch('fetchBillMgrToolAction', params).catch(e => {
        this.showError(e);
      });
    },
    fetchBillMgrToolActionWithFunction(params) {
      return this.$store
        .dispatch('fetchBillMgrToolActionWithFunction', params)
        .catch(e => this.showError(e));
    },
    sendBillMgrAction(params) {
      if (params['offer_2'] && params['offer_2'] === 'off') params['offer_2'] = 'on';
      if (params['offer_1'] && params['offer_1'] === 'off') params['offer_1'] = 'on';
      if (params['offer_3'] && params['offer_3'] === 'off') params['offer_3'] = 'on';
      if (params['offer_5'] && params['offer_5'] === 'off') params['offer_5'] = 'on';

      return this.$store.dispatch('sendBillMgrToolAction', params).catch(e => {
        this.showError(e);
      });
    },
  },
};
