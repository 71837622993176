import passfather from 'passfather';
import Vue from 'vue';
export default {
  data() {
    return {
      ifGenerate: false,
      secureColors: {
        low: 'error',
        short: 'error',
        normal: 'warning',
        high: 'success',
      },
      secureLevels: {
        ['1']: 'low',
        ['2']: 'normal',
        ['3']: 'high',
      },
      minPassLength: 8,
    };
  },
  computed: {
    secureLevel() {
      const passwd = this.fields.find(i => i.name === 'passwd').value;
      let level = 0;
      if (!passwd) return 'none';
      if (passwd.length < this.minPassLength) {
        return 'short';
      }
      if (passwd.match(/[A-Z]/g)) {
        level++;
      }
      if (passwd.match(/[0-9]/g)) {
        level++;
      }
      if (passwd.match(/[a-z]/g)) {
        level++;
      }
      return this.secureLevels[level];
    },
  },
  methods: {
    generatePass(field) {
      this.ifGenerate = true;
      const pass = passfather({
        numbers: false,
        uppercase: false,
        lowercase: false,
        symbols: false, // Disable symbols
        ranges: [
          [
            [65, 72],
            [74, 78],
            [80, 90],
            [97, 107],
            [109, 122],
          ],
          [[50, 57]],
        ],
        length: 16,
      });
      Vue.set(field, 'value', pass);
      Vue.set(field, 'type', 'text');
      const input = this.$refs.passwd[0].$el.querySelector('input[name=passwd]');
      if (input) {
        input.value = pass;
        input.focus();
      }
    },
    onFocus(field) {
      if (field.name === 'passwd') field.type = 'text';
    },
    onBlur(field) {
      if (field.name === 'passwd') field.type = 'password';
    },
  },
};
