import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import { GETTERS } from './getters.js';
import moduleSSLOrder from './modules/order';

const moduleSSL = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
  getters: GETTERS,
  modules: {
    moduleSSLOrder,
  },
};

export default moduleSSL;
