import { baseColors } from '../base/baseColors';
const statusEnum = {
  1: 'ordered',
  2: 'active',
  3: 'suspended',
  4: 'deleted',
  5: 'processing',
  6: 'inpay',
  7: 'abusesuspend',
  8: 'employeesuspend',
};
const statusColor = {
  ordered: baseColors.warning,
  active: baseColors.success,
  running: baseColors.success,
  suspended: baseColors.error,
  deleted: baseColors.error,
  abusesuspend: baseColors.error,
  employeesuspend: baseColors.error,
  processing: baseColors.warning,
  inpay: baseColors.warning,
};

export class BillMgrStatus {
  constructor(code) {
    if (this.isValid(code)) this._init(code);
  }

  isValid(code) {
    this.isValid = !!statusEnum[code];
    return this.isValid;
  }

  _init(code) {
    this.code = parseInt(code);
    this.key = statusEnum[code];
    this.color = statusColor[this.key];
  }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this.isValid;
  }
}

const specialStatusEnum = {
  0: 'running',
  1: 'processing',
  2: 'suspended',
};

export class BillMgrStatusSpecial extends BillMgrStatus {
  constructor(code) {
    super(code);
  }

  isValid(code) {
    this.isValid = !!specialStatusEnum[code];
    return this.isValid;
  }

  _init(code) {
    this.code = parseInt(code);
    this.key = specialStatusEnum[code];
    this.color = statusColor[this.key];
  }
}
