<template>
  <transition name="fade">
    <!--    <div v-show="opened" class="base-alert" :class="classes">-->
    <div v-show="opened">
      <div class="base-alert__inner">
        <div v-if="$slots.icon || icon" class="base-alert__icon">
          <slot name="icon"><svg-icon :name="icon" /></slot>
        </div>
        <div class="base-alert__content">
          <div v-if="$slots.title || title" class="base-alert__title medium-title">
            <slot name="title">{{ title }}</slot>
          </div>
          <br />
          <br />
          <div class="base-alert__text standart-text">
            <slot>
              <p>
                Просим вас проверить свои контактные данные и обновить их в случае необходимости.
              </p>
              <p>
                Для этого в Личном Кабинете в меню слева выберите раздел "Аккаунт" - "Профиль" —
                "Личные настройки" (ФИО, телефон, e-mail). Или нажмите на кнопку - "Актуализировать"
                ниже.
              </p>
              <p>
                Если у Вас возникнут вопросы, просим направить письмо через раздел
                <plain-button :to="{ name: 'Support' }" color="primary"> Поддержка </plain-button>
              </p>
              <!--              <p>{{ text }}</p>-->
            </slot>
          </div>
        </div>
        <plain-button v-if="closable" icon="close" class="base-alert__close" @click="onClose" />
      </div>
    </div>
  </transition>
</template>

<script>
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'BaseAlertNotify',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'error',
      validator: val => ['error', 'warning', 'primary', 'success', 'note'].includes(val),
    },
    opened: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    middle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const base = 'base-alert';
      return {
        [`${base}--${this.color}`]: !!this.color,
        [`${base}--closable`]: this.closable,
        [`${base}--centered`]: this.centered,
        [`${base}--middle`]: this.middle,
      };
    },
    support() {
      return IS_PROD
        ? `${window.location.href.split('#')[0]}#/support/tickets`
        : 'http://localhost:8080/#/support/tickets';
    },
  },
  methods: {
    onClose() {
      if (this.closable) {
        this.$emit('update:opened', false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$ba-primary = {
  base: $primary-color,
}
$ba-warning = {
  base: $warning-color,
}
$ba-error = {
  base: $error-color,
}
$ba-success = {
  base: $success-color,
}
$ba-note = {
  base: $note-color,
  bg: transparent,
}

$ba-colors = {
  primary: $ba-primary,
  warning: $ba-warning,
  error: $ba-error,
  success: $ba-success,
  note: $ba-note,
}

.base-alert {
  border: 1px solid;
  padding: 1rem;
  border-radius: $border-radius-small;

  for $key, $color in $ba-colors {
    &--{$key} {
      background: $color.bg ? $color.bg : rgba($color.base, 0.3);
      border-color: $color.base;

      & ^[0]__icon,
      & ^[0]__close {
        color: $color.base;
      }
      & ^[0]__close:hover {
        color: darken($color.base, 10%);
      }
    }
  }

  &__inner {
    position: relative;
    flexy(flex-start, flex-start);

    ^[0]--centered & {
      justify-content: center;
    }
    ^[0]--middle & {
      align-items: center;
    }
  }

  &__title + &__text {
    margin-top: 0.5rem;
  }

  &__title {
    ^[0]--closable & {
      +breakpoint(ms-and-down) {
        padding-right: 2rem;
      }
    }
  }

  &__close {
    margin: 0 0 auto auto;

    +breakpoint(ms-and-down) {
      absolute(0px);
      right: 0;
    }
  }

  &__icon {
    margin-right: 1rem;
    font-size: 2rem;
    flex: 0 0 1em;
    display: block;
    line-height: 1;

    +breakpoint(ms-and-down) {
      absolute(0px, 0px);
    }

    +breakpoint(sm-and-up) {
      align-self: center;
    }

    & + ^[0]__content ^[0]__title {
      +breakpoint(ms-and-down) {
        padding-left: 3rem;
      }
     }
  }

}
</style>
