import { parseCost } from '@/utils/parsers/costParser';
import SliderComponent from '@/models/base/components/slider';
import CheckboxComponent from '@/models/base/components/checkbox';
import SelectComponent from '@/models/base/components/select';
import TextComponent from '@/models/base/components/text';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import MultipleComponent from '@/models/base/components/multiple';

function getComponentByType(type) {
  const components = {
    slider: SliderComponent,
    checkbox: CheckboxComponent,
    select: SelectComponent,
    text: TextComponent,
    multiple: MultipleComponent,
  };
  if (components[type]) return components[type];
  else throw new Error(`Unknown component type: "${type}"`);
}

export class VpsAddon {
  constructor(args) {
    for (let prop in args) {
      if (prop !== 'addonSum') this[prop] = args[prop];
    }
    this.cost = this._getCost(args);
    this.label = this.label ? this.label.replace('RUB', '₽') : '';
    this.periodKey = BillMgrPeriods[this.period ? this.period : 1];
    let component = getComponentByType(args.type);
    this.config = new component(args);
    this._currentValue = this.value;
    this._sum = parseCost(args.addonSum);
    if (this.intname === 'pleskpanel' && this.config.value === '38') this.config.disabled = true; // 2342169
  }

  _getCost(args) {
    let cost = 0;
    if (args.cost) cost = parseCost(args.cost);
    else if (!args.cost && args.ext && args.ext.period_cost) cost = parseCost(args.ext.period_cost);
    return cost;
  }

  get sum() {
    return this._sum;
  }

  set sum(value) {
    this._sum = value;
  }

  get currentValue() {
    return this._currentValue;
  }

  set currentValue(value) {
    this._currentValue = value;
  }
}

export class VpsResourceAddon extends VpsAddon {
  constructor(args) {
    super(args);
    this.config = new SliderComponent(args);
  }
}

export class VpsSoftwareAddon extends VpsAddon {
  constructor(args) {
    super(args);
    let component = getComponentByType(args.type);
    this.config = new component(args);
  }
}
