import DnsMgrApi from '@/services/api/DnsMgrApi';
import { IspTool, IspTools } from '../base/IspTools';
import { DnsHost } from './DnsHost';
import { DnsRecord } from './DnsRecord';

/**
 * Class for dnsmanager's action
 */
export class DnsMgrTool extends IspTool {
  constructor(args) {
    super(args);
  }
}

/**
 * Abstract class for collection of dnsmanager's actions
 */
export class DnsMgrTools extends IspTools {
  constructor(args) {
    super(args);
    this.api = DnsMgrApi;
    this.url = '/dnsmgr';
  }

  /**
   * Override IspTool's init
   * @param {Object} args arguments
   */
  _init(args) {
    for (let prop in args) {
      this[prop] = new DnsMgrTool(args[prop]);
    }
  }
}

/**
 * Class for collection of billmanager's actions for dns-host-tariff
 */
export class DnsHostTools extends DnsMgrTools {
  constructor(args) {
    super(args);
    this._required = ['edit', 'delete', 'new'];
    this._type = DnsHost;
  }
}
/**
 * Class for collection of billmanager's actions for dns-host
 */
export class DnsRecordTools extends DnsMgrTools {
  constructor(args) {
    super(args);
    this._required = ['edit', 'delete', 'new'];
    this._type = DnsRecord;
  }
}
