import { sortFuncsByOrder } from '@/utils/sortFunctions';

export const baseListGetters = {
  GET_SORTED_LIST: state => {
    const list = [...state.list];
    const { prop, order } = state.sort;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_SORTED_LIST_BLOCK: state => {
    console.log(5);
    const listPaymentBlock = [...state.listPaymentBlock];
    const { prop, order } = state.sort;
    listPaymentBlock.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return listPaymentBlock;
  },
  GET_CURRENT:
    state =>
    (prop = 'id') => {
      return state.current ? state.list.find(i => i[prop] === state.current) : {};
    },
};
