<template>
  <div class="shadow-box card" :class="sizeCard">
    <slot name="body"></slot>
    <slot></slot>
  </div>
</template>

<script>
// import func from "vue-temp/vue-editor-bridge";
export default {
  name: 'Card',
  props: {
    size: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    sizeCard() {
      return this.size ? `card_width_${this.size}px` : '';
    },
  },
  mounted() {
    console.warn('DEPRECATED');
  },
};
</script>

<style lang="scss">
.card {
  margin-top: 20px;
  display: flex;
  // max-width: 840px;
  flex-direction: column;
}
div.card.card_width_546px {
  // max-width: 546px;
  min-width: 546px;
}

@media (min-width: 1200px) {
  .card {
    min-width: 840px;
  }
}
</style>
