export class DnsRecord {
  constructor(args) {
    this._requred = [
      'name',
      'rtype',
      'rtype_hidden',
      'rtype_orig',
      'ttl',
      'value',
      'rkey',
      'dnsHost',
    ];
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed some required props in DnsRecord');
  }

  isValid(args) {
    return this._requred.every(prop => args.hasOwnProperty(prop));
  }

  _init(args) {
    Object.keys(args).forEach(prop => {
      this[prop] = args[prop];
    });
  }

  update(args) {
    this._init(args);
  }

  get title() {
    return this.name;
  }

  get itemType() {
    return this.value;
  }

  get toolParams() {
    return {
      plid: this.dnsHost.name,
      elid: this.rkey,
      auth: this.dnsHost.auth,
    };
  }
}
