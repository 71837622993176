<template>
  <component
    :is="tagInt"
    v-tooltip="tooltipInner"
    :to="to"
    :class="classes"
    class="base-btn"
    v-on="$listeners"
  >
    <div class="base-btn__content">
      <span v-if="$slots.default || title" class="base-btn__text">
        <slot>{{ title }}</slot>
      </span>
      <svg-icon v-if="icon" :name="icon" class="base-btn__icon" />
    </div>
    <base-loader v-if="loading" class="base-btn__loader" />
  </component>
</template>

<script>
export default {
  name: 'BaseButton',

  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
      validator: value =>
        ['default', 'dark', 'light', 'primary', 'error', 'success', 'warn'].includes(value),
    },
    theme: {
      type: String,
      default: 'filled',
      validator: value => ['filled', 'outlined'].includes(value),
    },
    size: {
      type: String,
      default: 'big',
      validator: value => ['smallest', 'small', 'medium', 'big'].includes(value),
    },
    to: {
      type: [String, Object],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    watchActive: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: null,
    },
    tooltip: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    classes() {
      const base = 'base-btn';
      return {
        [`${base}--${this.theme}`]: !!this.theme,
        [`${base}--${this.color}`]: !!this.color,
        [`${base}--${this.size}`]: !!this.size,
        [`${base}--disabled`]: this.$attrs.disabled,
        [`${base}--active`]: this.watchActive && this.active,
        [`${base}--inactive`]: this.watchActive && !this.active,
        [`${base}--nowrap`]: this.nowrap,
        [`${base}--loading`]: this.loading,
      };
    },
    tagInt() {
      return this.tag ? this.tag : this.to ? 'router-link' : 'button';
    },
    tooltipInner() {
      if (!this.tooltip) return null;
      let options = {
        classes: 'standart-text',
        container: false,
      };
      if (typeof this.tooltip === 'string') options.content = this.tooltip;
      else options = { ...options, ...this.tooltip };
      return options;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$bb-primary = {
  base: $primary-color,
  alt: $color-light.white,
  hover-base: $color-blue.medium,
  trans: transparent,
}
$bb-warn = {
  base: $warn-color,
  alt: $color-light.white,
  hover-base: $color-light.medium,
  trans: transparent,
}
$bb-success = {
  base: $success-color,
  alt: $color-light.white,
  hover-base: darken($success-color, 10%),
  trans: transparent,
}
$bb-error = {
  base: $error-color,
  alt: $color-light.white,
  hover-base: $color-red.medium,
  trans: transparent,
}
$bb-colors = {
  //dark: $color-dark.black,
  //light: $color-light.white,
  primary: $bb-primary,
  warn: $bb-warn,
  success: $bb-success,
  error: $bb-error,
}
$bb-filled = {
  bg: base,
  text: alt,
  border: trans,
  hover: {
    bg: hover-base,
    text: alt,
    border: trans,
  }
}

$bb-outlined = {
  bg: trans,
  text: base,
  border: base,
  hover: {
    bg: trans,
    text: hover-base,
    border: hover-base,
  }
}

$bb-themes = {
  filled: $bb-filled,
  outlined: $bb-outlined,
}

$bb-big = {
  font: 14px,
  line: 20px,
  weight: 500,
  radius: $border-radius-small,
  border: 1px,
  padding: 1em 1.5rem,
}
$bb-small = {
  font: 14px,
  line: 20px,
  weight: 500,
  radius: $border-radius-small,
  border: 1px,
  padding: 9px 1.5rem,
}
$bb-smallest = {
  font: 12px,
  line: 16px,
  weight: 500,
  radius: $border-radius-small,
  border: 1px,
  padding: 0.5em 0.75rem,
}
$bb-sizes = {
  big: $bb-big,
  small: $bb-small,
  smallest: $bb-smallest,
}
.base-btn {
  position: relative;
  font-family: inherit;
  flexyi(center, center);
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease, opacity $anim-base-duration ease;

  &--nowrap {
    white-space: nowrap;
  }

  &:focus {
    outline: none;
  }

  // sizes
  for $key, $size in $bb-sizes {
    &--{$key} {
      padding: $size.padding;
      border: $size.border solid;
      border-radius: $size.radius;
      font-size: $size.font;
      line-height: $size.line;
      font-weight: $size.weight;

      ^[0]__icon,
      ^[0]__loader {
        font-size: $size.line;
      }
    }
  }

  // default theme colors
  &--default&--outlined {
    background: var(--button-default-bg);
    color: var(--button-default-color);
    border-color: var(--button-default-border);

    &:hover {
      background: var(--button-default-bg-hover);
      color: var(--button-default-color-hover);
      border-color: var(--button-default-border-hover);
    }
  }
  &--default&--filled,
  &--default&--outlined&--active {
    background: var(--button-default-bg-active);
    color: var(--button-default-color-active);
    border-color: var(--button-default-border-active);

    &:hover {
      background: var(--button-default-bg-active-hover);
      color: var(--button-default-color-active);
      border-color: var(--button-default-border-active);
    }
  }
  // themes & colors
  for $key, $theme in $bb-themes {
    for $c, $color in $bb-colors {
      &--{$key}&--{$c} {
        background: $color[$theme.bg];
        color: $color[$theme.text];
        border-color: $color[$theme.border];

        &:hover,
        &:focus {
          background: $color[$theme.hover.bg];
          color: $color[$theme.hover.text];
          border-color: $color[$theme.hover.border];
        }
      }
    }
  }

  &--inactive {
    opacity: 0.2;

    &:hover {
      opacity: 0.4;
    }
  }
  &--active,
  &--inactive&--default&--outlined {
    opacity: 1;
  }

  &__icon {
    display: block;
    line-height: 1;
    pointer-events: none;
    user-select: none;
  }
  &__text {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    pointer-events: none;
    user-select: none;
    margin-right: 5px;
    ^[0]__icon + & {
      margin-left: 0.25rem;
    }
  }

  &__content {
    transition: opacity 0.1s ease;
    display: flex;
    align-items: center;

    ^[0]--loading & {
      opacity: 0;
    }
  }

  &--disabled,
  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
  &--loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.1s ease;

    ^[0]--loading & {
      opacity: 1;
    }
  }
}
</style>
