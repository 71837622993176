<template>
  <div class="header-cross-burger" :class="classes" @click="$emit('click')">
    <div class="header-cross-burger__line header-cross-burger__line--top" />
    <div class="header-cross-burger__line header-cross-burger__line--middle" />
    <div class="header-cross-burger__line header-cross-burger__line--bottom" />
  </div>
</template>

<script>
export default {
  name: 'HeaderCrossBurger',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const base = 'header-cross-burger';
      return {
        [`${base}--open`]: this.open,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$hcb-height = 20px;
$hcb-line-height = 2px;
$hcb-color = $color-light.white;
$hcb-dur = 0.8s;

.header-cross-burger {
  position: relative;
  size(24px, $hcb-height);
  opacity: 0.4;
  transition: opacity $hcb-dur ease;
  cursor: pointer;

  +breakpoint(ml-and-up) {
    transition: opacity $hcb-dur ease, transform $hcb-dur ease;
    transform: rotate(90deg);
    transform-origin: center;
  }

  &--open {
    +breakpoint(md-and-down) {
      opacity: 1;
    }
    +breakpoint(ml-and-up) {
      transform: rotate(0);
    }
  }

  &__line {
    absolute(50%, 0px);
    size(100%, $hcb-line-height);
    margin-top: (-0.5 * $hcb-line-height);
    background: $hcb-color;
    transform-origin: center;
    transition: transform $hcb-dur ease;

    &--top {
      transform: translateY(-0.5 * ($hcb-height - $hcb-line-height));

      ^[0]--open & {
        +breakpoint(md-and-down) {
          transform: translateY(0) rotate(45deg);
        }
      }
    }
    &--bottom {
      transform: translateY(0.5 * ($hcb-height - $hcb-line-height));

      ^[0]--open & {
        +breakpoint(md-and-down) {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }
    &--middle {
      ^[0]--open & {
        +breakpoint(md-and-down) {
          transform: scale(0);
        }
      }
    }
  }
}
</style>
