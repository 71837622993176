import container from './container.vue';

export const RouteRent = {
  path: '/rps',
  name: 'rent',
  redirect: { name: 'rentMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Аренда физических серверов',
    navigation: {
      service: {
        key: 'rent-hosting',
        // icon: 'el-icon-mobile',
        icon: 'el-icon-office-building',
        order: 11,
      },
    },
    access: ['mainmenuservice.rps'],
  },
  children: [
    {
      path: '',
      name: 'rentMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Аренда физических серверов',
      },
      component: () => import(/* webpackChunkName: "rent-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'rentOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ аренды физического сервера',
      },
      component: () => import(/* webpackChunkName: "rent-order" */ './pages/Order/RentOrder.vue'),
      children: [
        {
          path: '/rps/order',
          redirect: { name: 'rentOrder' },
        },
        {
          path: '/rps/order/:id/:period',
          name: 'rentOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "rent-order-details" */ './pages/Order/RentOrderDetails.vue'
            ),
        },
        {
          path: '/rps/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'rentOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'rentDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "rent-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewRent' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "rent-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewRent',
        },
        {
          path: 'config',
          meta: {
            name: 'Конфигуратор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "rent-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configRent',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },

          component: () =>
            import(
              /* webpackChunkName: "rent-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyRent',
        },
      ],
    },
  ],
};
