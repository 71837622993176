export const STATE = () => ({
  list: [],
  current: '',
  id: '',
  model: {},
  isLoading: true,
  sort: {
    prop: 'title',
    order: 'asc',
  },
  tools: {},
});
