import container from './container.vue';

export const RouteBitrix = {
  path: '/bitrix_license',
  name: 'bitrix',
  redirect: { name: 'bitrixMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Лицензии Bitrix',
    navigation: {
      service: {
        key: 'bitrix-license',
        // icon: 'el-icon-s-help',
        icon: 'el-icon-document-copy',
        order: 8,
      },
    },
    access: ['mainmenuservice.bitrix_license'],
  },
  children: [
    {
      path: '',
      name: 'bitrixMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Лицензии Bitrix',
      },
      component: () => import(/* webpackChunkName: "bitrix-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'bitrixOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ лицензии Bitrix',
      },
      component: () =>
        import(/* webpackChunkName: "bitrix-order" */ './pages/Order/BitrixOrder.vue'),
      children: [
        {
          path: '/bitrix_license/order',
          redirect: { name: 'bitrixOrder' },
        },
        {
          path: '/bitrix_license/order/:id/:period',
          name: 'bitrixOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "bitrix-order-details" */ './pages/Order/BitrixOrderDetails.vue'
            ),
        },
        {
          path: '/bitrix_license/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'bitrixOrderDetails' },
        },
        {
          path: '/bitrix_license/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'bitrixOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'bitrixDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "bitrix-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewBitrix' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "bitrix-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewBitrix',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "bitrix-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configBitrix',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "bitrix-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupBitrix',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "bitrix-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyBitrix',
        },
      ],
    },
  ],
};
