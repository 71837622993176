<template>
  <vue-slider
    :value="internalValue"
    v-bind="configInternal"
    :dot-size="18"
    :tooltip="tooltip"
    :tooltip-formatter="formatter"
    :contained="contained"
    :disabled="disabledInternal"
    height="10px"
    class="vue-slider"
    :use-keyboard="true"
    @change="onChange"
  />
</template>

<script>
/**
 * @see https://github.com/NightCatSama/vue-slider-component
 */
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
export default {
  name: 'BaseSlider',
  components: { VueSlider },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    min: {
      type: Number,
      //required: true,
      default: null,
    },
    max: {
      type: Number,
      //required: true,
      default: null,
    },
    value: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      //required: true,
      default: null,
    },
    measure: {
      type: String,
      default: '',
    },
    sliderData: {
      type: Array,
      default: null,
    },
    tooltip: {
      type: String,
      default: 'always',
      validator: val => ['none', 'always', 'hover', 'focus', 'active'].includes(val),
    },
    contained: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    minInternal() {
      return this.min === this.max ? 0 : this.min;
    },
    disabledInternal() {
      return this.disabled || (!this.sliderData && this.min === this.max);
    },
    configInternal() {
      let conf = {};
      if (this.sliderData) {
        conf.data = this.sliderData;
      } else {
        conf.min = this.minInternal;
        conf.max = this.max || this.value;
        conf.interval = this.step || 1;
      }
      return conf;
    },
  },
  watch: {
    value(val) {
      if (val !== this.internalValue) {
        this.internalValue = val;
      }
      // console.log(val);
    },
  },
  methods: {
    formatter(value) {
      let formatted = new Intl.NumberFormat().format(value);
      return this.measure ? `${formatted} ${this.measure}` : formatted;
    },
    onChange(val) {
      this.internalValue = val;
      this.$emit('change', this.internalValue);
    },
  },
};
</script>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vue-slider {
  padding: 0;

  &-rail {
    background: var(--slider-thirdly);
    transition: all $anim-base-duration ease;
  }
  &-process {
    background: var(--slider-primary);
  }
  &-dot {

    &:focus {
      outline: none;
    }
    &-handle {
      box-sizing: border-box;
      background: var(--slider-secondary);
      border: 4px solid var(--slider-opposite);
    }
    &-tooltip-inner {
      background: var(--slider-secondary);
      border-color: var(--slider-secondary);
      color: var(--slider-opposite);
      font-size: 12px;
      font-weight: 500;
      padding: 0.25em 0.5em;

      +breakpoint(sm-and-up) {
        font-size: 14px;
      }

      +breakpoint(md-and-up) {
        padding: 0.5rem;
        //min-width: 6rem;
      }
    }
  }
  &-disabled {
    opacity: 0.2;
  }
}
</style>
