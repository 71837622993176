import billMgrToolActions from './billMgrToolActions';
import { isEmpty } from 'lodash';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import Vue from 'vue';
import BaseConfigurator from '@/models/base/BaseConfigurator';
/**
 * Async stepped form generator. Customizable with override some data options
 * @mixes billMgrToolActions
 * @param {string} startFunc - required! billmgr function requests wizard
 * @param {string=} [startAction] - fetchBillMgrAction | sendBillMgrAction - first step action (get | post)
 * @param {object=} [startParams] - first step params
 * @param {number=} [startStep] - start step number. -1 by default (starts from wizard[0]).
 * @param {object=} [endParams] - last step params
 * @param {string=} [endAction] - fetchBillMgrAction | sendBillMgrAction - last step action (get | post)
 * @param {object=} [showResFunc] - functions to show result. By default - show standart modal
 * @param {function|false=} [showResFunc.success] - default - this.showResModal
 * @param {function|false=} [showResFunc.fail] - default this.showResModal
 * @param {object=} [actionsTitles] - titles for actions buttons
 * @param {object=} [actionsTitles.confirm] - titles for confirm button
 * @param {string=} [actionsTitles.confirm.start] - default 'continue'. confirm button title on first step
 * @param {string=} [actionsTitles.confirm.continue] - default 'continue'. confirm button title on middle steps
 * @param {string=} [actionsTitles.confirm.end] - default 'submit'. confirm button title on last steps
 * @param {object=} [actionsTitles.cancel] - titles for cancel button
 * @param {string=} [actionsTitles.cancel.start] - default 'cancel'. cancel button title on first step
 * @param {string=} [actionsTitles.cancel.continue] - default 'back'. cancel button title on middle steps
 * @param {string=} [actionsTitles.cancel.end] - default 'back'. cancel button title on last steps
 * @param {class=} [configuratorClass] - default BaseConfigurator - configurator class, instance (extends) of BaseConfigurator
 */
export default {
  mixins: [billMgrToolActions],
  data() {
    return {
      modal: null,
      wizard: [],
      wizardData: [],
      startFunc: '',
      startParams: null,
      endParams: null,
      startAction: 'fetchBillMgrAction',
      endAction: 'sendBillMgrAction',
      res: {
        success: 'success',
        fail: 'fail',
        cancel: 'cancel',
        back: 'back',
        next: 'next',
      },
      showResFunc: {
        success: this.showResModal,
        fail: this.showResModal,
      },
      actionsTitles: {
        confirm: {
          start: this.$t('continue'),
          continue: this.$t('continue'),
          end: this.$t('confirm'),
          // end: this.$t('submit'),
        },
        cancel: {
          start: this.$t('cancel'),
          continue: this.$t('back'),
          end: this.$t('back'),
        },
      },
      startStep: -1,
      step: -1,
      configuratorClass: BaseConfigurator,
    };
  },
  beforeMount() {
    this.step = this.startStep;
    // console.log('wizard');
  },
  methods: {
    async runWizard() {
      this.showLoadingModal({ closeOnBackdrop: false });
      async function* generate(ctx) {
        while (ctx.step === ctx.startStep || ctx.step <= ctx.wizard.length - 1) {
          yield await ctx.runStep();
        }
      }
      const stepsGen = generate(this);
      let step;
      for await (step of stepsGen) {
        const { data, res } = step;
        if (res === this.res.next) this.step++;
        else if (res === this.res.back) this.step--;
        else if (res === this.res.cancel) {
          this.resetWizard();
          if (this.modal) this.$modals.close({ name: this.modal.name });
          break;
        } else {
          this.resetWizard();
          // if (this.showResFunc[res]) {
          // console.log(step);
          // console.log(res);                                 модалка перенесена в addPayment
          // console.log(this.showResFunc[res]);
          // this.showResFunc[res](res);
          // } else if (this.modal) this.$modals.close({ name: this.modal.name });
          if (this.modal) this.$modals.close({ name: this.modal.name });
          break;
        }
      }
      if (step.res === this.res.success) return Promise.resolve(step.data);
      else return Promise.reject(step.data || step.res);
    },
    fetchStepData(step = this.step, payload = {}, hasSv = false) {
      // console.log('fetchStepData');
      const isStart = step === this.startStep;
      const fetchStep = isStart ? step + 1 : step;
      const isEnd = fetchStep === this.wizard.length - 1;
      const curfunc = isStart ? this.startFunc : this.wizard[step].name;
      const action = isStart ? this.startAction : isEnd ? this.endAction : 'sendBillMgrAction';
      const formData =
        this.wizardData.length && this.wizardData[fetchStep] && this.wizardData[fetchStep].formData
          ? this.wizardData[fetchStep].formData
          : {};
      // console.log(formData);
      // console.log(this.wizardData[fetchStep].formData);
      if (isStart && this.startParams) Object.assign(formData, this.startParams);
      if (isEnd && this.endParams) Object.assign(formData, this.endParams);
      if (!hasSv && formData.hasOwnProperty('sv_field')) delete formData.sv_field;
      // console.log('formData', formData);
      // console.log('curfunc', curfunc);

      const params = {
        func: curfunc,
        ...formData,
        ...payload,
        show_metadata: 'on',
      };
      // console.log(params);
      return new Promise((resolve, reject) => {
        this[action](params)
          .then(data => {
            // console.log('data', data);
            if (data.error) reject({ res: this.res.fail, data });
            if (data.ok) resolve({ res: this.res.success, data });
            const { func, wizard } = data;
            // console.log('func:', func, 'wizard:', wizard);
            if (wizard) this.wizard = wizard;
            // console.log('---', this.wizard[this.step + 1].msg);
            const stepData = {
              name: func,
              title: this.wizard[this.step + 1].msg || '',
              data,
            };
            // console.log('stepData', stepData);
            resolve({ data: stepData, res: this.res.next });
          })
          .catch(data => {
            // console.log('errorData', data);
            reject({ res: this.res.fail, data });
          });
      });
    },
    async runStep() {
      // console.log('runStep');
      try {
        const { data: stepData, res: fetchRes } = await this.fetchStepData();
        // console.log(stepData);
        // console.log(fetchRes);
        if (fetchRes === this.res.success) return { data: stepData, res: fetchRes };
        const nextStep = this.step + 1;
        if (!this.wizardData[nextStep]) this.wizardData[nextStep] = stepData;
        else Object.assign(this.wizardData[nextStep], stepData);
        const model = this.wizardData[nextStep].formData ? this.wizardData[nextStep].formData : {};
        // console.log(stepData.data);
        const config = this.makeConfigurator(stepData.data, model);
        // console.log('config', config);
        if (config === this.res.fail) return { res: this.res.fail };
        else {
          // console.log('else');
          Vue.set(this.wizardData[nextStep], 'config', config);
        }
        const { data: formData, res: editRes } = await this.showEditModal(
          this.wizardData[nextStep]
        );
        Vue.set(this.wizardData[nextStep], 'formData', formData);
        return { data: formData, res: editRes };
      } catch (e) {
        return e;
      }
    },
    makeModal(props = {}) {
      // console.log('StepperModal_WIZARD');
      this.$modals.open({
        name: 'StepperModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        ...props,
      });
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'title', this.$t('loading'));
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(stepData) {
      const { title, config } = stepData;
      return new Promise((resolve, reject) => {
        if (!config || !(config instanceof BaseConfigurator)) reject({ res: this.res.fail });
        if (!this.modal) this.makeModal();
        const isStart = this.step === this.startStep;
        const isEnd = this.step === this.wizard.length - 2;
        let formData = {};
        let formDataBackup = null;
        Vue.set(this.modal, 'title', title);
        Vue.set(this.modal, 'onDismiss', () => {
          this.$modals.close({ name: this.modal.name });
          reject({ res: this.res.cancel });
        });
        Vue.set(this.modal, 'props', { period: 12, configurator: config });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
            Vue.set(this.modal.footer.confirm.props, 'loading', false);
          },
          ready: data => {
            formData = this.getUpdatedFormData(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            // formData = this.getUpdatedFormData(formData, data);
            // Vue.set(this.modal.footer.confirm.props, 'disabled', true); //TODO разобраться оплата юриками
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            // console.log('formData', formData);
            formData = this.getUpdatedFormData(formData, data);

            // console.log('data', data);
          },
          blockingchange: async data => {
            Object.assign(formData, data);
            Vue.set(this.modal, 'component', BaseLoader);
            //Vue.set(this.modal, { period: 12, configurator: null });
            const step = this.step + 1;
            const params = { ...formData, sok: '' };
            // console.log(params);
            try {
              const { data: stepData, res } = await this.fetchStepData(step, params, true);
              // console.log(stepData);
              // console.log(res);
              if (stepData.data && res === this.res.next) {
                const model = { ...formData };
                if (stepData.data.model) Object.assign(model, stepData.data.model);
                const svField = params.sv_field;
                if (svField) delete params.sv_field;
                if (svField && !model[svField]) model[svField] = params[svField];
                const configData =
                  this.wizardData[step].data && this.wizardData[step].data.slist
                    ? { slist: this.wizardData[step].data.slist }
                    : {};
                const config = this.makeConfigurator(stepData.data, model, configData);
                // console.log(stepData.data);
                // console.log(config);
                Vue.set(this.modal.props, 'configurator', config);
                Vue.set(this.modal, 'component', OrderConfig);
              } else reject({ res: this.res.fail });
            } catch ({ res }) {
              reject(res);
            }
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: {
              title: this.actionsTitles.confirm[isStart ? 'start' : isEnd ? 'end' : 'continue'],
              disabled: true,
              loading: false,
            },
            on: {
              click: () => {
                Vue.set(this.modal.footer.confirm.props, 'loading', true);
                resolve({ data: formData, res: this.res.next });
              },
            },
          },
          cancel: {
            props: {
              title: this.actionsTitles.cancel[isStart ? 'start' : isEnd ? 'end' : 'continue'],
              loading: false,
            },
            on: {
              click: () => {
                Vue.set(this.modal.footer.cancel.props, 'loading', true);
                reject({ res: isStart ? this.res.cancel : this.res.back });
              },
            },
          },
        });
      });
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close'), loading: false },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    makeConfigurator(data, formData = {}, configData = {}) {
      // console.log(data);
      // console.log(formData);
      // console.log(configData);
      if (!data) return this.res.fail;
      let customfields = data.customfields || data.fields || {};
      if (!isEmpty(formData)) {
        Object.keys(formData).forEach(i => {
          if (customfields[i]) customfields[i].value = formData[i];
        });
      }
      const hidefields = data.hidefields || [];
      const pages = data.pages || [];
      const model = data.model || {};
      const slist = data.slist || {};
      const list = data.list || {};
      // console.log(slist);
      if (configData.hidefields) hidefields.push(...configData.hidefields);
      if (configData.pages) pages.push(...configData.pages);
      if (configData.model) Object.assign(model, configData.model);
      if (configData.slist) Object.assign(slist, configData.slist);
      if (configData.list) Object.assign(list, configData.list);
      try {
        const config = new this.configuratorClass({
          customfields,
          model,
          slist,
          hidefields,
          pages,
          list,
        });
        return config;
      } catch (e) {
        return this.res.fail;
      }
    },
    resetWizard() {
      this.step = this.startStep;
      this.wizard = [];
      this.wizardData = [];
    },
    getUpdatedFormData(formData, data) {
      // console.log('getUpdatedFormData');
      // console.log(formData, data);
      let tData = { ...formData };
      Object.assign(tData, data);
      Object.keys(data).forEach(key => {
        if (data[key] == null && tData.hasOwnProperty(key)) {
          delete tData[key];
        }
      });
      // hardcode
      if (tData.clicked_button && tData.clicked_button === 'fromsubaccount') {
        tData.func = 'payment.add.method';
      }
      return { ...tData };
    },
  },
};
