<template>
  <card>
    <div slot="body" class="view-banner-other">
      <div class="view-banner-other-block">
        <span v-for="(item, index) in titles" :key="index">{{ item }}</span>
      </div>
      <div class="view-banner-other-block">
        <span v-for="(item, index) in values" :key="index">{{ item }}</span>
      </div>
    </div>
  </card>
</template>

<script>
import card from '../Card/Card.vue';

export default {
  name: 'ViewBanner',
  components: {
    card,
  },
  props: {
    titles: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.view-banner-other {
  display: flex;
  flex-direction: row;
}

.view-banner-other-block {
  min-width: 170px;
  min-height: 120px;
  // padding: 24px;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  &:nth-child(1) {
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */
      color: var(--title);
    }
  }
  &:nth-child(2) {
    font-size: 14px;
    color: var(--title);
  }
}
</style>
