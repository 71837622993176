import { sortFuncsByOrder } from '@/utils/sortFunctions';

export const GETTERS = {
  GET_SORTED_LIST: state => {
    const list = [...state.list];
    const { prop, order } = state.sort;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_CURRENT: state => {
    return state.current;
  },
  GET_CURRENT_SERVER: state => {
    return state.current ? state.list.find(i => i.id === state.current) : {};
  },
};
