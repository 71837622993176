import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { bmRusonyx, IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

const AUTH = {
  UserName: 'Supervisor',
  UserPassword: 'dsahHKJ721I_X!',
};

const CrmApi = axios.create({
  // baseURL: 'http://31.130.202.5:535',
  // baseURL: 'https://gsttest.ru:535',
  // baseURL: 'http://31.130.202.5:538/',
  baseURL: IS_PROD ? 'https://crm.int.rusonyx.ru/' : 'https://crml.int.rusonyx.ru/',
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    // 'Access-Control-Allow-Headers': 'Content-Type',
  },
  mode: 'same-origin',
  withCredentials: false,
});

CrmApi.interceptors.request.use(
  config => {
    console.log(config);
    // const host = localStorage.getItem('host');
    // if (host && config.baseURL !== host) {
    //   CrmApi.defaults.baseURL = host;
    //   config.baseURL = host;
    // }
    if (config.method == 'post') {
      return config;
    } else {
      if (!config.params) config.params = {};
      // if (!config.params.hasOwnProperty('out')) config.params.out = 'bjson';
      // if (config.params.out && config.params.out === 'none') delete config.params.out;
      const token = localStorage.getItem('token');
      if (token) config.params = { ...config.params, auth: token };
      return config;
    }
  },
  error => {
    // console.log('requestError', error);
    return Promise.reject(error);
  }
);

CrmApi.interceptors.response.use(
  response => {
    console.log('response', response, response.headers);
    if (response.headers['content-type'] === 'text/html') {
      return Promise.reject({ status: 520, msg: 'Server error' });
    } else if (response.data.error) {
      const data = response.data.error;
      // console.log(data);
      if (data.type && data.type === 'auth') {
        store.commit('moduleRoutes/SET_LAST', router.currentRoute);
        localStorage.removeItem('token');
        const currentRoute = router.currentRoute;
        if (currentRoute.name !== 'Login' && currentRoute.name !== 'Auth') {
          router.push({ name: 'Login' }).catch(() => {
            console.log('api error');
          });
        }
      }
      if (response.data.warnings) data.warnings = response.data.warnings;
      return Promise.reject(data);
    } else {
      // console.log(response.data);
      return response.data;
    }
  },
  error => {
    // console.log('error', error.response.data.message);
    if (error && error.response && error.response.data && error.response.data.message) {
      return Promise.reject(error.response.data.message);
      // router.push({ name: 'Login' }).catch(() => {
      //   console.log('api error');
      // });
    }
    return Promise.reject(error);
  }
);

export default CrmApi;
