import { baseListState } from '@/store/utils/baseListState';
import { baseListGetters } from '@/store/utils/baseListGetters';
import { MUTATIONS } from './mutations';
import { ACTIONS } from './actions';
import makeInnerModule from './makeInnerModule';

const moduleOrders = {
  namespaced: true,
  state: baseListState(),
  getters: {
    ...baseListGetters,
    hiddenItems: (state, getters) => {
      const inpaylist = getters['moduleInpaylist/list'];
      const itemlist = getters['moduleItemlist/list'];
      state.list.forEach(item => {
        const maybeInpay = inpaylist.find(i => item.id == i.item_id);
        const maybePayed = itemlist.find(i => item.id == i.id);
        //if (!maybeInpay && !maybePayed) console.log(item.status.key, item.title);
      });
      return [];
    },
  },
  mutations: MUTATIONS,
  actions: ACTIONS,
  modules: {
    moduleInpaylist: makeInnerModule('item_id'),
    moduleItemlist: makeInnerModule(),
  },
};

export default moduleOrders;
