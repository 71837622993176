<template>
  <div class="page-block">
    <div class="page-block__head">
      <div v-if="title || $slots.title" class="page-block__title">
        <slot name="title">
          <h3 :class="`${size}-title`">{{ title }}</h3>
        </slot>
      </div>
      <div v-if="$slots.action" class="page-block__action">
        <slot name="action" />
      </div>
    </div>
    <div class="page-block__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBlock',
  props: {
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['standart', 'medium', 'big', 'bigger'].includes(val),
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.page-block {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__head {
    margin-bottom: 1.25rem;

    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }
  }

  &__title {
    flex: 1 1 auto;

    :not(:only-child) {
      margin-right: 1rem;
    }
  }

  &__action {
    //flex: 0 0 auto;
    display: flex;
    margin-top: 1rem;

    +breakpoint(sm-and-up) {
      margin-top: unset;
    }
  }
}
</style>
