import { parseCost } from '@/utils/parsers/costParser';
import { getBin, getFormattedCardNumber, getMps } from '@/utils/paymentCard';
import BaseAddon from '../base/BaseAddon';
import BaseConfigurator from '../base/BaseConfigurator';

export default class PaymentMethodConfigurator extends BaseConfigurator {
  constructor(args, addonClass = BaseAddon) {
    super(args, addonClass);
  }

  _init(args) {
    const transformedArgs = this._getTransformedArgs(args);
    this.info = transformedArgs;
    if (args.hidefields) this.hidefields = transformedArgs.hidefields;
    if (args.pages) this.pages = transformedArgs.pages;
  }

  _getTransformedArgs(args) {
    const transformedFields = this._getTransformedFields(args.customfields);
    const transformedLists = this._getTransformedLists(args);
    if (args.pages) {
      const methodlistPage = args.pages.find(page => page.name === 'methodlist_page');
      if (methodlistPage) methodlistPage.fields.push(['paymethodlist']);
      else args.pages.push({ name: 'methodlist_page', msg: '', fields: [['paymethodlist']] });
    }
    return { ...args, customfields: transformedFields, list: transformedLists };
  }

  _getTransformedFields(fields = {}) {
    if (fields.amount) {
      fields.amount.setvalues = '';
      if (fields.amount.readonly && fields.amount.readonly === 'yes') fields.amount.type = 'amount';
    }
    if (fields.methodlist) {
      fields.paymethodlist = {
        ...fields.methodlist,
        name: 'paymethodlist',
        k: 'k',
        type: 'paymethodlist',
        required: 'yes',
      };
      fields.methodlist.type = 'hidden';
    }
    if (fields.stored_methodlist) fields.stored_methodlist.type = 'hidden';
    return fields;
  }

  _getTransformedLists(args = {}) {
    const { customfields, list } = args;
    list.paymethodlist = [];
    if (customfields.methodlist && list.methodlist) {
      list.paymethodlist.push(
        ...this._getTransformedMethodlist(customfields.methodlist, list.methodlist)
      );
    }
    if (customfields.stored_methodlist && list.stored_methodlist) {
      list.paymethodlist.push(
        ...this._getTransformedStoredMethodList(
          customfields.stored_methodlist,
          list.stored_methodlist
        )
      );
    }
    list.paymethodlist.sort((a, b) => a.order - b.order);
    return list;
  }

  _getTransformedMethodlist(field, list) {
    const arr = [];
    list.forEach((item, i) => {
      const obj = {
        ...item,
      };
      if (item.paymethod_type === '0') {
        obj.order = 0;
        obj.k = 'fromsubaccount';
        obj.clicked_button = 'fromsubaccount';
        const splittedName = item.name.split('(');
        obj.label = splittedName[0];
        obj.balance = parseCost(splittedName[1].replace('(', '').replace(')', ''));
        obj.formDataName = 'clicked_button';
      } else {
        obj.order = parseInt(item.paymethod_type + i + 0);
        obj.k = item[field.k];
        obj.label = item.name;
        obj.formDataName = field.k;
      }
      arr.push(obj);
    });
    return arr;
  }

  _getTransformedStoredMethodList(field, list) {
    const arr = [];
    list.forEach((item, i) => {
      const obj = {
        ...item,
        order: parseInt(item.paymethod_type + i),
        k: item[field.k],
        clicked_button: 'paystoredmethod',
        formDataName: field.k,
      };
      const bin = getBin(item.name);
      const formattedNumber = getFormattedCardNumber(item.name);
      const mps = getMps(bin);
      obj.label = item.customname ? item.customname : formattedNumber;
      if (item.customname) {
        obj.label = item.customname;
        obj.note = formattedNumber;
      } else obj.label = formattedNumber;
      if (mps && mps.image) obj.assetImage = mps.image;
      arr.push(obj);
    });
    return arr;
  }
}
