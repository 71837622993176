import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import { GETTERS } from './getters.js';
/**
 * @TODO:
 * - разделить на модули открытые тикеты и архивные (они же одинаковые)
 * в модулях получатся одинаковые state, mutations, getters (можно использовать стандартные store/utils/baseList...), сделать одинаковые интерфейсы для модулей (actions, state, mutations, getters) и использовать один соответствующий компонент, передавая ему имя или путь модуля, вместо дублирования компонентов, отличающихся только названиями в сторе
 */
const moduleSupport = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
  getters: GETTERS,
};

export default moduleSupport;
