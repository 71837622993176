import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import { isEqual } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import Vue from 'vue';
import { IspTool } from '@/models/base/IspTools';
import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import Link from '@/components/Configurator/components/Link';
import data from 'inputmask/lib/dependencyLibs/data';
export default {
  mixins: [showErrorModal],
  components: { Link },
  props: {
    redirect: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      modal: null,
      configuratorClass: BaseConfigurator,
    };
  },
  computed: {
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    appHost() {
      return this.$store.state.moduleApp.host;
    },
  },
  mounted() {
    // if (!this.current) console.error('Missed required prop "current"');
    if (!this.moduleMain) console.error('Missed required prop "moduleMain"');
  },
  methods: {
    async runTool(item) {
      // console.log('runTool', item.id);
      if (item.disabled) return;
      const sure = item.askSure ? await this.askSure(item) : true;
      // console.log(sure);
      if (sure) {
        if (item.handler && typeof item.handler === 'function') {
          item.handler(item);
        } else if (item.tool && item.tool instanceof IspTool) {
          const handler =
            item.tool instanceof BillMgrTool
              ? this.handleBillMgrTool
              : item.tool instanceof DnsMgrTool
              ? this.handleDnsMgrTool
              : null;

          // console.log('this.handleBillMgrTool', this.handleBillMgrTool);
          // console.log('this.handler', this.handler);
          if (typeof handler === 'function') {
            this.showLoadingModal();
            const res = await handler(item);
            if (res === 'cancel' && this.modal) this.$modals.close();
            // else if (res.type === 'groupfail_delete') {
            else if (res.warnings) {
              if (res.warnings[0].type === 'access') {
                this.showModal(data.url);
              } else this.showResModalError(res.warnings[0].msg);
            } else this.showResModal(res);
          } else {
            console.error(`Handler for tool "${item.tool.name}" is not a function`);
          }
        } else if (!item.attrs) {
          alert(`id: ${this.current.id}, \r\naction: ${item.title}`);
        }
      }
    },
    handleBillMgrTool(item) {
      // console.log('handleBillMgrTool', item);
      return new Promise(async resolve => {
        if (item.tool.type === 'window') {
          this.goToWindow(item);
          resolve('cancel');
        } else {
          const payload = item.id ? { elid: item.id } : {};
          let params = {
            func: item.tool.func,
            ...payload,
          };
          // console.log('---params---', params);
          if (this.current.toolParams) Object.assign(params, this.current.toolParams);
          if (item.toolParams) Object.assign(params, item.toolParams);
          // console.log('item', item);
          let needSending =
            item.type !== 'edit' &&
            item.tool.name !== 'edit' &&
            params.func !== 'service_profile.delete';
          if (
            item.type === 'edit' ||
            item.tool.name === 'edit' ||
            params.func === 'service_profile.delete' //||
            //      params.func === 'payment.delete'
          ) {
            await this.$store
              .dispatch('fetchBillMgrToolAction', params)
              .then(async data => {
                const { fields, hidefields, model, slist, pages } = data;
                const config = new this.configuratorClass({
                  customfields: fields,
                  hidefields: hidefields || [],
                  model: model || {},
                  slist: slist || {},
                  pages: pages || [],
                });
                if (params.func === 'service_profile.delete') {
                  await this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                  resolve('success');
                }
                const res = await this.showEditModal(config);
                if (res === 'cancel') resolve('cancel');
                else if (res === 'fail') resolve('fail');
                else {
                  params = { ...params, ...res };
                  needSending = true;
                }
              })
              // .catch(() => resolve('fail'));
              .catch(e => this.showError(e));
            // .catch(data => resolve(data));
          }
          if (needSending) {
            // console.log('params', params);
            this.$store
              .dispatch('sendBillMgrToolAction', params)
              .then(data => {
                // console.log('data', data);
                if (data.ok) {
                  this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                  if (
                    data.ok.type &&
                    (data.ok.type === 'blank' || data.ok.type === 'url') &&
                    data.ok.v
                  ) {
                    let token = localStorage.getItem('token');
                    window.open(this.appHost + data.ok.v + `&auth=${token}`);
                  }
                  resolve('success');
                } else resolve('fail');
              })
              .catch(e => {
                if (params.func === 'payment.delete') {
                  // console.log(e);
                  resolve(e);
                } else resolve('fail');
              });
          }
        }
      });
    },
    handleDnsMgrTool(item) {
      let params = {
        ...this.current.toolParams,
        func: item.tool.func,
      };
      return new Promise(async resolve => {
        let needSending = item.tool.name !== 'edit';
        if (item.tool.name === 'edit') {
          await this.$store
            .dispatch(`${this.moduleMainPath}/fetchDnsMgrToolAction`, params)
            .then(async data => {
              const { fields, hidefields, model, slist } = data;
              const config = new BaseConfigurator({
                customfields: fields,
                hidefields,
                model,
                slist,
              });
              const res = await this.showEditModal(config);
              if (res === 'cancel') resolve('cancel');
              else if (res === 'fail') resolve('fail');
              else {
                params = { ...params, ...res };
                needSending = true;
              }
            })
            .catch(() => resolve('fail'));
        }
        if (needSending) {
          this.$store
            .dispatch(`${this.moduleMainPath}/sendDnsMgrToolAction`, params)
            .then(data => {
              if (data.ok) {
                this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                resolve('success');
              } else resolve('fail');
            })
            .catch(() => {
              resolve('fail');
            });
        }
      });
    },
    askSure(item) {
      // console.log(this.current);
      const title = this.current.itemType
        ? this.current.itemType
        : this.current.title || this.current.fullname;
      return new Promise(resolve => {
        const props = {
          text: this.$t('modal.sure.text', {
            msg: this.$t(`actions.${item.key}`) + ' ' + title,
          }),
          footer: {
            confirm: {
              props: {
                title: this.$t('modal.sure.confirm'),
              },
              on: {
                click: () => {
                  resolve(true);
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  this.$modals.close();
                  resolve(false);
                },
              },
            },
          },
        };
        this.makeModal(props);
      });
    },
    makeModal(props) {
      this.$modals.open({
        name: 'ContextMenuModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showLoadingModal() {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(config) {
      return new Promise(resolve => {
        if (!config || !(config instanceof BaseConfigurator)) resolve('fail');
        if (!this.modal) this.makeModal();
        let formData = {};
        let formDataBackup = null;
        Vue.set(this.modal, 'props', { period: 12, configurator: config });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
          },
          ready: data => {
            Object.assign(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            Object.assign(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            Object.assign(formData, data);
            let hasChanges = !isEqual(formData, formDataBackup);
            Vue.set(this.modal.footer.confirm.props, 'disabled', !hasChanges);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: { title: this.$t('submit'), disabled: true },
            on: { click: () => resolve(formData) },
          },
          cancel: {
            props: { title: this.$t('cancel') },
            on: { click: () => resolve('cancel') },
          },
        });
      });
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    showResModalError(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    goToWindow(item) {
      this.$store
        .dispatch('fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.current.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        })
        .catch(e => {
          this.showError(e);
        });
    },
    deleteFromBasket() {
      let modal = null;
      this.$modals.open({
        name: 'RemoveBasketResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleBasket/removeFromBasket', this.current.id)
        .then(() => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'text', this.$t('modal.removeFromBasket.success'));
          Vue.set(modal, 'closable', true);
          this.$store.dispatch(`${this.moduleMainPath}/updateList`);
          if (this.$route.params.id && this.$route.params.id == this.current.id) {
            if (this.redirect) this.$router.push(this.redirect);
          }
        })
        .catch(e => this.showError(e));
    },
    showModal(link) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', Link);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'props', {
        supports: true,
        text: 'Вы не можете удалить автоматически созданный счёт, для удаления обратитесь в поддержку',
      });
      // Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      // Vue.set(this.modal, 'text', text);
      Vue.set(this.modal, 'footer', {
        // confirm: {
        //   props: { title: this.$t('submit'), disabled: true },
        //   on: { click: () => this.$modals.close() },
        // },
        cancel: {
          props: { title: this.$t('ok') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
  },
};
