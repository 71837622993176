import { baseListState } from '@/store/utils/baseListState';
import { baseListGetters } from '@/store/utils/baseListGetters';
import { baseListMutations } from '@/store/utils/baseListMutations';
import { BillingHistoryDetails } from '@/models/BillMgr/Billing';
import BillMgrApi from '@/services/api/BillMgrApi';
import { eachMonthOfInterval, endOfMonth, startOfMonth } from 'date-fns';
import { isDate } from 'lodash';
import { sortFuncsByOrder } from '@/utils/sortFunctions';
import { IspExpense } from '@/models/base/IspExpense';

const state = {
  ...baseListState({ prop: 'date', order: 'desc' }),
  itemClass: BillingHistoryDetails,
  dateFilter: { start: new Date(), end: new Date() },
  periods: ['quarter', 'halfyear', 'year'],
  period: 'quarter',
  expense: [],
};

const getters = {
  ...baseListGetters,
  getGrouppedList: state => {
    const groups = eachMonthOfInterval(state.dateFilter)
      .sort((a, b) => sortFuncsByOrder.desc(a, b))
      .map(from => {
        return {
          list: {
            payment: [],
            expense: [],
          },
          payment: 0,
          expense: 0,
          from,
          to: endOfMonth(from),
        };
      });
    // console.log(state.expense);
    return state.list.reduce((acc, item) => {
      let group = acc.find(g => item.date >= g.from && item.date < g.to);
      // let group = acc.find(g => item.realdate >= g.from && item.realdate < g.to);
      if (group) {
        if (!group.list[item.type]) group.list[item.type] = [];
        if (item && item.sum && item.sum !== 0) {
          // console.log(item);
          item.sum = state.expense.find(x => x.paymentId === item.id)
            ? state.expense.find(x => x.paymentId === item.id).sum
            : item.sum;
          group.list[item.type].push(item);
        } // отсекаем нулевые платежи
        // console.log(item.id);
        // console.log(item);
        // let payment = state.expense.find(x => x.paymentId === item.id).sum;
        // console.log(payment, item.sum);
        // console.log(item.find(x => x.id === state.expense.filter(i => i.id === x.id)));

        // console.log(item);
        group[item.type] += item.sum;
      } else {
        group = {
          list: {
            payment: [],
            expense: [],
          },
          payment: 0,
          expense: 0,
          from: startOfMonth(item.date),
          to: endOfMonth(item.date),
        };
        group.list[item.type].push(item);
        group[item.type] += item.sum;
        acc.push(group);
      }
      return acc;
    }, groups);
  },
};

const mutations = {
  ...baseListMutations,
  setDateFilter: (state, value) => {
    if (value.start && value.end && isDate(value.start) && isDate(value.end))
      state.dateFilter = value;
  },
  SET_EXPENSE: (state, list) => {
    try {
      // state.expense = [...list];
      state.expense = list.map(i => new IspExpense(i));
    } catch (e) {
      console.error(e);
    }
  },
  setPeriod: (state, value) => {
    if (state.periods.includes(value)) state.period = value;
  },
};

const actions = {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get('api2/billmgr/money_all', { params })
        .then(data => {
          commit('SET_LIST', data.elem || []);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchExpenseList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        func: 'expense',
        clickstat: 'yes',
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          commit('SET_EXPENSE', data.elem || [], payload);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setFilter({ dispatch }, payload = {}) {
    const params = {
      func: 'money_all.filter',
      newface: 'on',
      ...payload,
    };
    return dispatch('sendBillMgrToolAction', params, { root: true });
  },
  // reset store
  reset({ commit }, timeout = 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_LIST', []);
        commit('SET_IS_LOADING', true);
        commit('SET_TOOLS', {});
        commit('SET_SORT', { prop: 'title', order: 'asc' });
        resolve();
      }, timeout);
    });
  },
};

const moduleHistory = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default moduleHistory;
