<template>
  <div v-if="text" class="link">
    <div v-if="base" class="l-col">
      <!--      {{ $t('base') }}-->
      <div v-if="html">
        <p v-html="text"></p>
      </div>

      <div v-else>
        {{ text }}
      </div>
    </div>
    <div v-if="link" class="l-col">
      <plain-button :href="link" :target="target" color="primary">
        {{ base ? $t('text') : text }}
      </plain-button>
    </div>
    <div v-if="supports" class="l-col link-center">
      <plain-button color="primary" class="l-col link-center" @click="addNewTicket">
        {{ $t('new') }}
      </plain-button>
    </div>
  </div>
</template>

<script>
import storeMixin from '@/layouts/Support/mixins/index';
export default {
  name: 'Link',
  mixins: [storeMixin],
  props: {
    base: {
      type: Boolean,
      default: true,
    },
    html: {
      type: Boolean,

      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
    supports: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "new_ticket": "Создать запрос",
    "text": "Ссылка на оплату",
    "new": "Написать в поддержку",
    "base": "Сейчас Вы будете перенаправлены на страницу оплаты. Она откроется в новом окне. Если страница не открылась, пожалуйста, перейдите по ссылке"
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.link {
  flexy(flex-start, nowrap, column);
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  //font-size: $font-size-big
  size(100%);
  //max-width: 34em;
  margin: 0 auto;
  padding: 0.25rem 0 1rem;

  +breakpoint(sm-and-up) {
    padding-top: 0.25rem;
  }
  &-text {

  }
  &-center {
margin-top: 1.75rem;
    margin-bottom: -1.75rem;
  }

  &-link {
    margin-top: 0.25rem;
    font-size: $font-size-big

  }

}
</style>
