import { LicenseTariff } from '@/models/BillMgr/LicenseTariff';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
import { LicTools } from '@/models/BillMgr/Tools';

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list.map(i => new LicenseTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new LicTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  licvz: (state, payload) => {
    state.licvz = payload.map(i => new LicenseTariff(i));
    state.licvz.map(x => (x.licname = 'licvz'));
  },
  licplesk: (state, payload) => {
    state.licplesk = payload.map(i => new LicenseTariff(i));
    state.licplesk.map(x => (x.licname = 'licplesk'));
  },
  licaero: (state, payload) => {
    state.licaero = payload.map(i => new LicenseTariff(i));
    state.licaero.map(x => (x.licname = 'licaero'));
  },
  bitrix_license: (state, payload) => {
    state.licbitrix = payload.map(i => new LicenseTariff(i));
    state.licbitrix.map(x => (x.licname = 'bitrix_license'));
  },
  soft: (state, payload) => {
    state.soft = payload.map(i => new SoftTariff(i));
    state.soft.map(x => (x.licname = 'soft'));
  },
  setCurrentLic: (state, payload) => {
    state.currentLic = new LicenseTariff(payload);
  },
  restoreLicense: state => {
    state.currentLic = [];
  },
  setLicName: (state, value) => {
    console.log(state, value);
    state.map(x => (x.licname = value));
  },
};
