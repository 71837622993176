<template>
  <div class="summary-btn">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String || Number,
      default: '',
    },
  },
  mounted() {
    console.warn('DEPRECATED');
  },
};
</script>

<style lang="scss">
$blue: #3d88f2;
.summary-btn {
  background-color: var(--background-theme);
  min-width: 160px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    // font-family: Graphik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: $blue;
  }
}
</style>
