import BillMgrApi from '@/services/api/BillMgrApi';

export default {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    const mutation = payload.update ? 'UPDATE_LIST' : 'SET_LIST';
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (payload.hasOwnProperty('update')) delete payload.update;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        func: 'profile',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          // console.log(data);
          if (data.elem && data.elem.length) commit(mutation, data.elem);
          if (data.elem && data.elem.length)
            commit('SET_PAYERS_INFO', data.elem[0].profiletype_orig);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  updateList({ dispatch }, payload = {}) {
    const params = {
      update: true,
      ...payload,
    };
    return dispatch('fetchList', params);
  },
};
