import Vue from 'vue';
import SvgIcon from './SvgIcon/SvgIcon.vue';
import AssetImg from './AssetImg/AssetImg.vue';
import MiniTitle from './MiniTitle/Title.vue';
import Popup from './PopUp/Popup.vue';
import PageTitle from './PageTitle/PageTitle.vue';
import PageBlock from './PageBlock/PageBlock.vue';
import MainCard from './MainCard/MainCard.vue';
import VueSlideBar from 'vue-slide-bar';
import PlainButton from './Buttons/PlainButton.vue';
import MainButton from './Buttons/MainButton.vue';
import BaseLoader from './BaseLoader/BaseLoader.vue';
import BaseAlert from './BaseAlert/BaseAlert.vue';
import BaseButton from './Buttons/BaseButton.vue';

Vue.component('svg-icon', SvgIcon);
Vue.component('asset-img', AssetImg);
Vue.component('page-title', PageTitle);
Vue.component('page-block', PageBlock);
Vue.component('main-card', MainCard);
Vue.component('base-alert', BaseAlert);
Vue.component('custom-title', MiniTitle);
Vue.component('vue-slide-bar', VueSlideBar);
Vue.component('modal-window', Popup);
Vue.component('plain-button', PlainButton);
Vue.component('main-button', MainButton);
Vue.component('base-loader', BaseLoader);
Vue.component('base-button', BaseButton);

// v-tooltip
// https://github.com/Akryum/v-tooltip
import { VPopover, VTooltip, VClosePopover } from 'v-tooltip';
Vue.component('v-popover', VPopover);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
// Vue.use(VTooltip, { defaultHtml: false }); #TODO надо проверить

// click-outside
import ClickOutside from 'vue-click-outside';
Vue.directive('click-outside', ClickOutside);

// v-clipboard
// https://github.com/euvl/v-clipboard
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

// modals
import { default as Modals, BaseModal } from './BaseModal';
Vue.use(Modals);
Vue.component('base-modal', BaseModal);
