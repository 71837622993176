export default {
  methods: {
    notifyRight() {
      this.$notify.info({
        title: 'Уведомление',
        message: 'Текст уведомления',
      });
    },
    notify(text, type = 'success') {
      this.$message({
        message: text,
        type: type,
        customClass: 'notify',
        offset: 1,
        center: true,
      });
    },
  },
};
