import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const keyCleared = key.replace('./', '').replace('.json', '').split('/');
    const locale = keyCleared.pop();
    if (!messages[locale]) messages[locale] = {};
    if (!keyCleared.length) {
      Object.assign(messages[locale], locales(key));
    } else {
      const deep = { [keyCleared[0]]: locales(key) };
      Object.assign(messages[locale], deep);
    }
  });
  return messages;
}
const dateTimeFormats = {
  ru: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    middle: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    monthYear: {
      year: 'numeric',
      month: 'long',
    },
  },
};
const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  pluralizationRules: {
    /**
     * @param choice {number|string} a choice index | key given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: function (choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      const nounCases = ['i', 'r', 'd', 'v', 't', 'p'];
      if (nounCases.includes(choice)) {
        return nounCases.findIndex(i => i === choice);
      }

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  dateTimeFormats,
  numberFormats,
});
