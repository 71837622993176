import { parseCost } from '@/utils/parsers/costParser';

export class BasketItem {
  constructor(args) {
    this.required = ['id', 'cost', 'item.id', 'item.type', 'item.period', 'pricelist_name'];
    if (this.isValid(args)) this.init(args);
    else {
      const missed = this.required.filter(k => !args[k]).join(', ');
      console.error(`Missed required props: ${missed} in BasketItem constructor`);
    }
  }

  isValid(args) {
    return this.required.every(i => !!args[i]);
  }

  init(args) {
    Object.keys(args).forEach(key => {
      this[key] = args[key];
    });
    this.cost = parseCost(args.cost);
  }

  get pricelistName() {
    return this.pricelist_name;
  }

  get title() {
    return this.pricelistName;
  }

  get service() {
    const values = { on: true, off: false };
    return {
      id: this['item.id'],
      type: this['item.type'],
      period: this['item.period'],
      autoprolong: this['item.autoprolong'] || 'null',
      autoprolongAvailable: values[this['item.autoprolong_available']] || values.off,
    };
  }

  get autoprolong() {
    return this.service.autoprolong;
  }

  get isAutoprolongAvailable() {
    const values = { on: true, off: false };
    return values[this['item.autoprolong_available']] || values.off;
  }

  get addonsFormatted() {
    if (!this.addon) return null;
    return this.addon.map(addon => {
      const nameSplitted = addon.name.split(': ');
      return {
        ...addon,
        cost: parseCost(addon.cost),
        label: nameSplitted[0],
        value: nameSplitted[1],
      };
    });
  }
}
