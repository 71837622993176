export default {
  computed: {
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
  },
  methods: {
    getPreparedFormData(data, profile = this.profile) {
      const obj = {};
      Object.keys(profile.editModel).forEach(key => {
        obj[`${profile.editModelPrefix}-${key}`] = profile.editModel[key];
      });
      Object.keys(profile.usrparamModel).forEach(key => {
        obj[`${profile.usrparamModelPrefix}-${key}`] = profile.usrparamModel[key];
      });
      for (let key in data) {
        if (obj.hasOwnProperty(`${profile.editModelPrefix}-${key}`))
          obj[`${profile.editModelPrefix}-${key}`] = data[key];
        if (obj.hasOwnProperty(`${profile.usrparamModelPrefix}-${key}`))
          obj[`${profile.usrparamModelPrefix}-${key}`] = data[key];
      }
      return obj;
    },
    updateProfileInfo(payload, func = 'user.edit') {
      // console.log(this.profile.id, 'this.profile.id');
      return this.$store.dispatch('moduleProfile/updateProfileInfo', {
        id: this.profile.id,
        data: payload,
        func,
      });
    },
    showResultModal(text) {
      this.$modals.open({
        name: 'Result',
        text,
        size: 'small',
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('ok') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
  },
};
