export default {
  methods: {
    addDataLayerPriceData(list, category) {
      // console.log(list, category);
      window.dataLayer = window.dataLayer || [];
      const impressions = list.map((item, i) => {
        // console.log(item);
        return {
          id: `${item.id}`,
          name: `${item.name}`,
          price: item.price.period.length ? `${item.price.period[0].amount}` : '',
          discount: item.price.period.length ? `${item.price.period[0].discount_amount}` : '',
          category: category,
          list: 'Category',
          position: i + 1,
        };
      });
      const ecommerce = {
        ecommerce: {
          currencyCode: 'RUB',
          impressions: impressions,
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Impressions',
        'gtm-ee-event-non-interaction': 'True',
      };
      if (impressions.length > 0 && !this.$store.state.moduleStart.category.includes(category)) {
        window.dataLayer.push(ecommerce);
        this.$store.state.moduleStart.category.push(category);
      }
    },
    clickToGtm(item, category) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        ecommerce: {
          click: {
            actionField: {
              list: 'Category',
            },
            products: [
              {
                id: `${item.id}`,
                name: `${item.name}`,
                price: item.price.period.length ? `${item.price.period[0].amount}` : '',
                discount: item.price.period.length ? `${item.price.period[0].discount_amount}` : '',
                category: category,
                // position: i + 1,
              },
            ],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Click',
        'gtm-ee-event-non-interaction': 'False',
      });
    },
    viewDetailGtm(item, category) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        ecommerce: {
          detail: {
            actionField: {
              list: 'Category',
            },
            products: [
              {
                id: `${item.id}`,
                name: `${item.name}`,
                price: item.price.period.length ? `${item.price.period[0].amount}` : '',
                discount: item.price.period.length ? `${item.price.period[0].discount_amount}` : '',
                category: category,
                // position: i + 1,
              },
            ],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Details',
        'gtm-ee-event-non-interaction': 'True',
      });
    },
    addToBasketGtm(item, category) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [
              {
                id: `${item.itemdesc[0]['item.id']}`,
                name: `${item.itemdesc[0].main.name}`,
                price: `${item.itemdesc[0].main.cost}`,
                category: category,
                quantity: 1,
              },
            ],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Add Cart',
        'gtm-ee-event-non-interaction': 'False',
      });
    },
    remoteFromBasketGtm(item) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [
              {
                id: `${item.id}`,
                name: `${item.pricelist_name}`,
                price: `${item.cost}`,
                category: `${item['item.type']}`,
                quantity: 1,
              },
            ],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Remove Cart',
        'gtm-ee-event-non-interaction': 'False',
      });
    },
    payFromBasketFirst(list) {
      window.dataLayer = window.dataLayer || [];
      const product = list.map(item => {
        return {
          // products: [
          //   {
          id: `${item.id}`,
          name: `${item.pricelist_name}`,
          price: `${item.cost}`,
          discount: `${item.discount_amount}`,
          category: `${item['item.type']}`,
          quantity: 1,
          // },
          // ],
        };
      });
      dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          checkout: {
            actionField: { step: 1 },
            products: product,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Checkout Step 1',
        'gtm-ee-event-non-interaction': 'False',
      });
    },
    payFromBasketSecond(list) {
      window.dataLayer = window.dataLayer || [];
      const product = list.map(item => {
        return {
          // products: [
          //   {
          id: `${item.id}`,
          name: `${item.pricelist_name}`,
          price: `${item.cost}`,
          discount: `${item.discount_amount}`,
          category: `${item['item.type']}`,
          quantity: 1,
          // },
          // ],
        };
      });
      dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          checkout: {
            actionField: { step: 2 },
            products: product,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Checkout Step 2',
        'gtm-ee-event-non-interaction': 'False',
      });
    },
    puchase(list, order) {
      window.dataLayer = window.dataLayer || [];
      const product = list.map(item => {
        return {
          // products: [
          //   {
          id: `${item['item.id']}`,
          name: `${item.pricelist_name}`,
          price: `${item.total_cost}`,
          discount: `${item.discount_amount}`,
          category: `${item['item.type']}`,
          quantity: 1,
          // },
          // ],
        };
      });
      dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            actionField: {
              id: `${order.billorder}`,
              discount: `${order.full_discount}`,
              revenue: `${order.total_sum}`,
            },
            products: product,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Purchase',
        'gtm-ee-event-non-interaction': 'False',
      });
      // });
    },
  },
};
