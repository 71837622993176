export const STATE = {
  list: [],
  allTickets: [],
  tickets: [],
  quality: {},
  allTicketsArhive: [],
  allIncident: [],
  newTickets: [],
  currentTicket: [],
  AllNotifications: [],
  currentNotification: [],
  currentTicketTheme: '',
  currentTicketID: '',
  firstTicketMessage: '',
  currentStatus: '',
  arhiveTicket: '',
  arhiveTicketTheme: '',
  files: [],
  serviceList: [],
  isLoading: true,
  toArhive: 1,
  department: [],
};
