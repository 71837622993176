export const STATE = {
  currentLic: [],
  isLoading: true,
  licvz: [],
  licplesk: [],
  licaero: [],
  list: [],
  licbitrix: [],
  soft: [],
};
