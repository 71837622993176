<template>
  <!--  <div v-if="!tariff.id" class="tariffs-table-item__loader">-->
  <!--    <base-loader :size="true" class="tariffs-table-item__loader-item" />-->
  <!--  </div>-->
  <!--    v-else-->
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <!--    <template #check>-->
    <!--      <base-checkbox :value="item.id" :model-value="checked" @change="$emit('checked', $event)" />-->
    <!--    </template>-->
    <template #id>
      <!--        :to="{ name: path, params: { id: item.id } }"-->
      <router-link
        :to="{ name: path, params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #name>
      <div class="tariff-table-item__server">
        {{ item.name.replace(/SSL Certificate -/gi, '') }}
      </div>
    </template>
    <template #autoprolong>
      <!--        v-if="item.autoprolong_available === 'on'"-->
      <!--        v-tooltip="isAutoProlong"-->
      <autoprolong
        :tariff="item"
        class="tariffs-table-item__autoprolong"
        @change="changeLoading(item, $event)"
        @edit="editPaymethod(item, $event)"
        @state="watchState(item, $event)"
      />
    </template>
    <template #status>
      <main-label
        v-if="!loading"
        :color="item.status.color"
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{
          isSuspended
            ? 'Остановлен администратором'
            : isAbuseSuspended
            ? 'Остановлен за нарушения'
            : item.status.text
            ? item.status.text
            : $t(`status.${item.status.key}`)
        }}
      </main-label>
    </template>
    <template #payment>
      <base-loader v-if="loading" :size="true" class="vps-autoprolong__trigger"></base-loader>
      <plain-button
        v-else-if="!loading && state === 'on'"
        color="primary"
        @click="paymethodChange(item)"
      >
        <!--        {{ currentPaymethod ? currentPaymethod : paymentMethod }}-->
        {{ viewPaymethod(currentMethod) }}
      </plain-button>
    </template>
    <template #period>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ item.autoprolong_name === '1' ? 'Месяц' : item.autoprolong_name === '12' ? 'Год' : '' }}
      </main-label>
    </template>
    <template #date>
      <div v-if="item.expiredate_type === 'date'" class="tariffs-table-item__date">
        <plain-button
          v-if="warningDate"
          v-tooltip="{
            content: warningDate,
          }"
          icon="error"
          size="medium"
          color="del"
          tabindex="-1"
          class="tariffs-table__prolong"
        />
        <label :class="classes" class="tariff-table-item__server">
          {{
            item.itemtype === 'acronis'
              ? 'Ежемесячное списание'
              : item.itemtype === 'vdc3'
              ? 'Ежедневное списание'
              : formattedEndDate
          }}
        </label>
      </div>
      <div v-else class="tariffs-table-item__date">
        <label :class="classes" class="tariff-table-item__server">
          <!--        {{ item.expiredate }}-->
          {{
            item.itemtype === 'acronis'
              ? 'Ежемесячное списание'
              : item.itemtype === 'vdc3'
              ? 'Ежедневное списание'
              : item.expiredate.split(' ').at(-1)
          }}
        </label>
        <plain-button
          v-if="warningDate"
          v-tooltip="{
            content: warningDate,
          }"
          icon="error"
          size="medium"
          color="del"
          tabindex="-1"
          class="tariffs-table__prolong"
        />
      </div>
    </template>
    <template #prolongcost>
      <div v-if="showLink" class="tariff-table-item__server">
        <plain-button
          color="primary"
          class="tariffs-table__prolong"
          @click="cost === 0 ? singleProlongFree(item) : singleProlong(item)"
        >
          {{
            item.prolongcost === '0.00 RUB / Месяц'
              ? 'Продлить'
              : item.prolongcost === '0.00 RUB / Год'
              ? 'Продлить'
              : item.prolongcost
          }}
        </plain-button>
      </div>
      <!--      <div v-else class="tariff-table-item__server">-->
      <!--        {{ item.prolongcost }}-->
      <!--      </div>-->
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import popoverFix from '@/mixins/popoverFix';
import { format, parseISO } from 'date-fns';
import { parseCost } from '@/utils/parsers/costParser';
import ProlongSingle from '@/layouts/All/component/ProlongSingle';
import MainLabel from '@/components/Labels/MainLabel';
import MainLabel_black from '@/components/Labels/MainLabel_black';
import { pathNameByService } from '@/utils/getServices.js';
import Autoprolong from '@/layouts/All/component/Autoprolong';
import paymethodChange from '@/mixins/billmgr/paymethodChange';

export default {
  name: 'TariffsTableRow',
  components: { BaseTableRow, Autoprolong, /*BaseCheckbox,*/ MainLabel },
  mixins: [prolongSingle, prolongSingleFree, paymethodChange, popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showLink: true,
      currentPaymethod: '',
      state: '',
      // warningDate: '',
      loading: true,
      tariff_1: null,
      // moduleMain: 'moduleVPS',
      singleProlongComponentFirst: ProlongSingle,
      singleProlongComponent: ProlongSingle,
      stopNames: [
        'публичное облако',
        'виртуальный дата-центр',
        'cdn',
        'облачный бекап',
        'OpenStack',
      ],
    };
  },
  computed: {
    isSuspended() {
      return this.item.employeesuspend === 'on';
    },
    isAbuseSuspended() {
      return this.item.abusesuspend === 'on';
    },
    isAutoProlong() {
      // return this.item.autoprolong_id === 'null'
      return this.item.autoprolong_period === 'null'
        ? 'Включить автопродление'
        : 'Автопродление включено';
    },
    paymethodInState() {
      return this.$store.getters['moduleAll/getIdForReload'];
    },
    formattedEndDate() {
      return this.item.expiredate === 'Ежедневное списание'
        ? null
        : format(parseISO(this.item.expiredate.split(' ').at(-1)), 'dd.MM.yyyy');
    },
    path() {
      return pathNameByService[this.item.itemtype];
    },
    tariff() {
      return this.$store.state.moduleAll[this.id];
    },
    dateTariff() {
      return new Date(this.item.expiredate.split(' ').at(-1));
    },
    currentDate() {
      return new Date();
      // return format(new Date(), 'dd.MM.yyyy');
    },
    differenceDate() {
      return Math.floor((this.dateTariff - this.currentDate) / (1000 * 60 * 60 * 24));
    },
    // ostDays() {
    //   // const date = this.item.expiredate.split(' ').at(-1);
    //   if (this.formattedEndDate) {
    //     // console.log(date);
    //     // return (
    //     // this.formattedEndDate - this.currentDate
    //     return Math.floor((new Date(this.formattedEndDate) - new Date()) / (1000 * 60 * 60 * 24));
    //     // );
    //     // (new Date(parseISO(date)) - new Date()) / (1000 * 60 * 60 * 24)
    //     // );
    //     // } else return this.item.expiredate;
    //   } else return null;
    // },
    currentMethod() {
      return this.currentPaymethod ? this.currentPaymethod : this.paymentMethod;
    },
    paymentMethod() {
      if (!this.loading && this.item && this.item.stored_method) {
        return this.item.stored_method;
      } else {
        // console.log('paymentMethod', this.item.stored_method);
        return '';
        /* === '0'
          ? 'Лицевой счет'
          : this.item.stored_method === ''
          ? ''
          : event;*/
      }
    },
    cost() {
      return parseCost(this.item.prolongcost);
    },
    warningDate() {
      if (this.item.expiredate.match(/\(.*?(?=\))/)) {
        const date = this.item.expiredate.match(/\(([^)]+)\)/)?.[1];
        // .split(' ')
        // .at(-1);
        // console.log(date);
        return date;
      } else return null;
    },
    classes() {
      if (this.item.employeesuspend === 'on') return 'error-color';
      if (this.item.abusesuspend === 'on') return 'error-color';
      if (this.item.itemtype === 'vdc3') return 'success-color';
      if (this.warningDate) return 'error-color';
      return this.differenceDate < 20 ? 'error-color' : 'success-color';
    },
  },
  watch: {
    paymentMethod: {
      handler: function (value) {
        // console.log('paymentMethod-watch', value);
      },
    },
    // isSuspected: {
    //   handler: function (value) {
    //     console.log(value);
    //   },
    //   immediate: true,
    // },
    'item.paymethod': function (event) {
      // console.log(event);
      if (event) this.currentPaymethod = this.item.paymethod;
    },
    warningDate: {
      handler: function (event) {
        // console.log(event);
        if (event) this.showLink = false;
        // if (event.expiredate.match(/\(.*?(?=\))/)) {
        //   const date = event.expiredate.match(/\(([^)]+)\)/)?.[1];
        //   // .split(' ')
        //   // .at(-1);
        //   this.warningDate = date;
        //   console.log(date);
        // }
      },
      // immediate: true,
      // deep: true,
    },
    payments(event) {
      // console.log('payments');
      // console.log('payments', event);
      // console.log(this.loading, 'loading');

      // this.currentPaymethod = event === '0' ? 'Лицевой счет' : event === '' ? '' : event;
      this.currentPaymethod = event; /*=== '0' ? 'Лицевой счет' : event === '' ? '' : event;*/
    },
  },
  mounted() {
    this.isLinc();
    // this.currentPaymethod = this.item.paymethod;
    // console.log(this.currentPaymethod);
    // console.log(this.item.stored_method);
  },
  methods: {
    changeLoading(item, event) {
      this.$emit('edit', item);
      // console.log('changeLoading', item, event);
      // console.log(item.stored_method, event);
      if (event === 'true') {
        this.loading = false;
        // console.log(item)
      }
    },
    editPaymethod(item, event) {
      // console.log(item.stored_method, event);
      // console.log('editPaymethod');
      this.currentPaymethod = event;
    },
    viewPaymethod(item) {
      if (item && !this.loading) {
        return item === ''
          ? ''
          : item === '0'
          ? 'Лицевой счет'
          : this.$store.state.moduleBilling.modulePayment.list.find(x => x.recurring === item)
              .fullname;
      }
      // console.log(item, event);
      // this.currentPaymethod = event;
    },
    watchState(item, event) {
      this.state = event;
    },
    isLinc() {
      if (this.item.expiredate_type === 'string') {
        this.showLink = false;
      }
      // else if (this.item.status.code === 3) {
      //   this.showLink = false;
      // }
      else if (this.item.itemtype === 'vdc3' || this.item.itemtype === 'acronis') {
        this.showLink = false;
      } else if (this.item.abusesuspend === 'on' || this.item.employeesuspend === 'on') {
        this.showLink = false;
      } else if (this.item.expiredate_type === 'date') {
        this.stopNames.forEach(stop => {
          if (this.item.name.toLowerCase().includes(stop)) {
            this.showLink = false;
          }
        });
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "activeUnder": "До {date} г.",
      "mb": "{num} МБ",
      "gb": "{num} ГБ",
      "specs": {
        "cpu": "CPU",
        "mem": "RAM",
        "disc": "Disc"
      },
      "prolong": {
        "title": "Продление тарифа",
        "payment": "Смена метода оплаты автопродления",
        "error": "К сожалению, что-то пошло не так",
        "success_basket": "Продление тарифа успешно добавлено в корзину",
        "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
        "confirm": "Оплатить",
        "free": "Активировать",
        "order": "В корзину",
        "cancel": "Отмена",
        "change": "Сменить",
        "result": {
        "title": "Смена метода оплаты",
        "ok": "Хорошо",
        "desc": "Метод оплаты автопродления изменен"
        },
        "action": "Продлить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__autoprolong {
    text-align: center;
  }
  &__date {
    //flexy(center);
    flexy(flex-start, center, nowrap);
  }
  &__link {
    text-decoration: none;
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
  &__server {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    white-space: nowrap;
    background: inherit;

    //&:hover::after {
    //  +breakpoint(ml-and-up) {
    //    content: '';
    //    display: block;
    //    size(2.5em, 100%);
    //    absolute(0px, 100%);
    //    background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    //  }
    //}
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.

.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
