import axios from 'axios';
import { bmRusonyx } from '@/layouts/LoginPage/mixins/currentProvider';

const setDefault = config => {
  const interceptorsData = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      out: 'bjson',
    },
  };
  config.params = { ...config.params, ...interceptorsData.params };
  config.headers.common = { ...config.headers.common, ...interceptorsData.headers };
  return config;
};

axios.interceptors.request.use(
  config => {
    if (config.method == 'post') {
      return config;
    }
    const token = localStorage.getItem('token');
    config.params = { ...config.params, auth: token };
    return setDefault(config);
  },
  error => {
    console.log(error, 'token');
    throw error;
  }
);

export class DataLoad {
  checkError(data) {
    if (data.error) throw (({ msg }) => msg)(data.error);
  }
  thenCallback(data, commit, cb) {
    this.checkError(data);
    if (Array.isArray(commit)) {
      commit.forEach((item, index) => {
        item(cb[index](data));
      });
    } else {
      commit(cb(data));
    }
  }

  constructor(base) {
    this.axiosInstance = {
      default: axios,
      newInstance: axios,
    };
    this.URLS = {
      'my-rusonyx': bmRusonyx,
      'lab-caravan': 'https://my.lab.caravan.ru/mancgi/cgi_test',
      myvm: 'https://myvm.rusonyx.ru',
    };
    this.base = this.URLS[base];
  }
  POST(url = '', body, config, axiosInstanceName = 'default', commit = undefined, cb = undefined) {
    console.warn('DEPRECATED');
    const endURL = this.base + url;
    if (!commit && !cb) {
      // console.log('post in if');
      return this.axiosInstance[axiosInstanceName].post(endURL, body, config);
    } else {
      return this.axiosInstance[axiosInstanceName]
        .post(endURL, { data: body }, config)
        .then(({ data }) => this.thenCallback(data, commit, cb))
        .catch(err => {
          console.log(err);
        });
    }
  }
  GET(
    url = '',
    params,
    config = {},
    axiosInstanceName = 'default',
    commit = undefined,
    cb = undefined
  ) {
    console.warn('DEPRECATED');
    try {
      const endURL = this.base + url;
      if (!commit && !cb) {
        return this.axiosInstance[axiosInstanceName].get(endURL, { params }, { config });
      } else {
        return this.axiosInstance[axiosInstanceName]
          .get(endURL, { params }, { config })
          .then(({ data }) => {
            this.thenCallback(data, commit, cb);
          });
      }
    } catch (err) {
      console.error(err);
    }
  }
  getBaseUrlApi(apiName) {
    return this.URLS[apiName];
  }
}
