import { baseColors } from '../base/baseColors';
const statusEnum = {
  1: 'active',
  2: 'build',
  3: 'deleted',
  4: 'error',
  5: 'paused',
  6: 'rescued',
  7: 'resized',
  8: 'soft_deleted',
  9: 'stopped',
  10: 'suspended',
  11: 'shutoff',
  12: 'shelved_offloaded',
  13: 'inuse',
  14: 'reserved',
  15: 'available',
  16: 'down',
  17: 'verify_resize',
  18: 'reboot',
  19: 'hard_reboot',
  20: 'deleting',
  21: 'resize',
  22: 'creating',
  23: 'attaching',
  24: 'detaching',
  25: 'maintenance',
  26: 'awaitingtransfer',
  27: 'error_deleting',
  28: 'backingup',
  29: 'restoringbackup',
  30: 'error_backingup',
  31: 'error_restoring',
  32: 'error_extending',
  33: 'downloading',
  34: 'uploading',
  35: 'retyping',
  36: 'extending',
  37: 'shelved',
  38: 'saving',
  39: 'queued',
  40: 'error_managing',
  41: 'migrating',
  42: 'importing',
  43: 'rebuild',
  44: 'rescue',
  45: 'pending_create',
  46: 'pending_delete',
  47: 'pending_update',
  48: 'offline',
  49: 'online',
  50: 'degraded',
  51: 'password',
};
const stateColor = {
  active: baseColors.success,
  online: baseColors.success,
  password: baseColors.success,
  inuse: baseColors.success,
  available: baseColors.success,
  attaching: baseColors.success,
  deleted: baseColors.error,
  error_extending: baseColors.error,
  stopped: baseColors.error,
  error_restoring: baseColors.error,
  error_managing: baseColors.error,
  error: baseColors.error,
  error_backingup: baseColors.error,
  build: baseColors.warning,
  migrating: baseColors.warning,
  importing: baseColors.warning,
  extending: baseColors.warning,
  saving: baseColors.warning,
  uploading: baseColors.warning,
  retyping: baseColors.warning,
  backingup: baseColors.warning,
  rebuild: baseColors.warning,
  downloading: baseColors.warning,
  restoringbackup: baseColors.warning,
  queued: baseColors.warning,
  creating: baseColors.warning,
  awaitingtransfer: baseColors.warning,
  maintenance: baseColors.warning,
  detaching: baseColors.warning,
  verify_resize: baseColors.warning,
  rescued: baseColors.warning,
  rescue: baseColors.warning,
  resized: baseColors.warning,
  down: baseColors.error,
  error_deleting: baseColors.error,
  soft_deleted: baseColors.error,
  suspended: baseColors.error,
  paused: baseColors.warning,
  shutoff: baseColors.error,
  offline: baseColors.error,
  shelved: baseColors.warning,
  shelved_offloaded: baseColors.error,
  deleting: baseColors.error,
  degraded: baseColors.error,
  pending_delete: baseColors.error,
  reserved: baseColors.warning,
  reboot: baseColors.warning,
  resize: baseColors.warning,
  hard_reboot: baseColors.warning,
  pending_create: baseColors.warning,
  pending_update: baseColors.warning,
};

export class OPState {
  /**
   *
   * @param {string} str
   */
  constructor(str) {
    this._keys = Object.keys(stateColor);
    if (this.isValid(str)) this._init(str);
    else throw new Error(`Unknown state '${str}' in OPState`);
  }

  isValid(str) {
    this._isValid = this._keys.includes(str);
    return this._isValid;
  }

  getKeyByState(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  _init(str) {
    const code = this.getKeyByState(statusEnum, str);
    this.code = parseInt(code);
    this.key = statusEnum[code];
    this.color = stateColor[this.key];
  }

  // get color() {
  //   return stateColor[this.key];
  // }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this._isValid;
  }
}
