export default {
  methods: {
    getLocalizedPeriod(value) {
      const isYears = value % 12 === 0;
      const key = isYears ? 'year_c' : 'month_c';
      const period = isYears ? value / 12 : value;
      return this.$tc(`period.${key}`, period);
    },
  },
};
