<template>
  <div class="recovery">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="recovery__content">
        <div class="recovery__desc standart-text">
          {{ $t('desc') }}
        </div>
        <div class="recovery__form" @keyup.enter="submit()">
          <base-input
            v-model="username"
            use-reactive-validation
            class="recovery__field"
            @change="onChange"
          >
            {{ $t('login') }}
          </base-input>
        </div>
        <div v-if="error" class="recovery__error standart-text error-color">
          {{ error }}
        </div>
        <div class="recovery__footer">
          <!--            :disabled="!isValid"-->
          <base-button
            :loading="isSending"
            class="recovery__submit"
            @click="submit()"
            @keyup.enter.stop
          >
            <!--            @click="submit()"-->
            {{ $t('submit') }}
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
import setFocus from '@/mixins/setFocus';
import showSuccess from '../mixins/showSuccess';
export default {
  name: 'RecoveryLogin',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showSuccess],
  data() {
    return {
      isLoading: true,
      isSending: false,
      username: '',
      error: '',
    };
  },
  computed: {
    // isValid() {
    //   return this.fields.every(i => !i.required || (i.required && i.value));
    // },
    getModel() {
      const model = this.$store.state.moduleReg.model;
      return model;
    },
    login() {
      return this.getModel.username;
    },
  },
  mounted() {
    this.username = this.login;
  },
  methods: {
    onChange(event) {
      this.username = event.target.value;
    },
    // fetch() {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch('fetch', 'recovery')
    //     .then(data => {
    //       if (data.required) this.setRequiredFields(data.required);
    //     })
    //     .finally(() => (this.isLoading = false));
    // },
    // setRequiredFields(obj) {
    //   this.fields.forEach(i => {
    //     if (obj[i.name] && obj[i.name] === 'true') i.required = true;
    //   });
    // },
    alert() {
      alert(`${this.username}`);
    },
    submit() {
      delete this.getModel.username;
      this.isSending = true;
      const payload = {
        ...this.getModel,
        username: this.username,
      };
      this.$store
        .dispatch('send', payload)
        .then(() => {
          this.showSuccess();
        })
        .catch(e => {
          this.error = e.msg;
        })
        .finally(() => (this.isSending = false));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "desc": "Для восстановления пароля укажите логин пользователя.",
      "login": "Логин",
      "submit": "Восстановить пароль",
      "success": {
        "title": "Запрос успешно отправлен",
        "text": "Для восстановления пароля следуйте инструкциям в письме, отправленном на почту, указанную при регистрации.",
        "ok": "Хорошо"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.recovery {
  &__desc {
    margin-bottom: 1.5rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
