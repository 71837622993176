import VPSService from './pages/VPSService.vue';
import VirtualService from './pages/VirtualService.vue';
import container from './container.vue';

export const RouteServices = {
  path: '/services',
  name: 'Services',
  component: container,
  redirect: {
    name: 'ServicesMain',
  },
  children: [
    {
      path: 'VPS-service',
      name: 'ServicesVPS',
      component: VPSService,
    },
    {
      path: 'Virtual-service',
      name: 'ServicesVirtual',
      component: VirtualService,
    },
  ],
};
