<template>
  <div class="header-sidebar">
    <div class="header-sidebar__inner">
      <header-cross-burger :open="open" class="header-sidebar__burger" @click="toggleSidebar" />
      <transition name="slide-fade">
        <main-logo v-show="showLogo" :to="{ name: 'start' }" class="header-sidebar__logo" />
      </transition>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/components/MainLogo/MainLogo.vue';
import HeaderCrossBurger from '@/components/Header/HeaderCrossBurger/HeaderCrossBurger';
import breakpoints from '@/utils/breakpoints';
export default {
  name: 'HeaderSidebar',
  components: { MainLogo, HeaderCrossBurger },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    showLogo() {
      return this.open || this.screenWidth >= breakpoints.sm;
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit('change', !this.open);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.header-sidebar {
  height: 100%;
  background-color: var(--sidebar-theme);

  &__inner {
    flexy(flex-start, center);
    padding: 1rem;

    +breakpoint(sm-and-up) {
      padding: 1.25rem 1.5rem;
    }
  }

  &__burger {
    flex: 0 0 auto;
    +breakpoint(lg-and-up) {
      margin-left: -6px;
      margin-right: 6px;
    }
  }

  &__logo {
    margin: -1px 0;
    margin-left: 1rem;

    +breakpoint(lg-and-up) {
      margin-left: 1.5rem;
    }
  }
}
</style>
