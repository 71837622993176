<template>
  <div class="allowed-user-menu">
    <div class="allowed-user-menu__nav">
      <div
        v-for="item in list"
        :key="item.key"
        :disabled="item.disabled || false"
        class="allowed-user-menu__item"
        :class="{ [`allowed-user-menu__item--${item.color}`]: !!item.color }"
        @click="onClick(item)"
      >
        {{ $t(`nav.${item.key}`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllowedUserContextMenu',
  props: {
    list: {
      type: Array,
      default: () => [],
      validator: arr => arr.every(i => !!i.key),
    },
  },
  methods: {
    onClick(item) {
      this.$emit('click', item);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "edit": "Настройки",
        "rights": "Права доступа",
        "suspend": "Выключить",
        "resume": "Включить",
        "delete": "Удалить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$aum-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$aum-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.allowed-user-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $aum-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $aum-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.allowed-user-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
