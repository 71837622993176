// export const postpaid = value => value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
export const postpaid = [
  'vdc2',
  'vdc3',
  'acronis',
  'antiddosgcore',
  'cdnedge',
  'clouds',
  'stack',
  'cdn',
];

export function filterObject(obj, callback) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => {
      return callback(val, key);
    })
  );
}

export function filterObjectStorage(obj, callback) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => {
      return callback(key, val);
    })
  );
}

export function findAllByKeyIncluded(obj, keyToFind) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      // key === keyToFind
      keyToFind.includes(key)
        ? acc.concat(value)
        : typeof value === 'object'
        ? acc.concat(findAllByKeyIncluded(value, keyToFind))
        : acc,
    []
  );
}
