/**
 * @typedef IspToolCondition
 * @type {Object}
 * @prop {string} name
 * @prop {string} value
 */

import { isNaN } from 'lodash';

/**
 * Class for isp tool
 */
export class IspTool {
  /**
   * @constructor
   * @param {Object} args
   * @param {string} args.func
   * @param {string} args.name
   * @param {string=} args.type
   * @param {Array<IspToolCondition>=} args.show
   * @param {Array<IspToolCondition>=} args.hide
   */
  constructor(args) {
    if (this.isValid(args)) this._init(args);
    else throw new Error(`Tool's data for '${args.name}' is not valid`);
  }

  isValid(args) {
    this.isValid = !!(args.func && args.name);
    return this.isValid;
  }

  _init(args) {
    this.func = args.func;
    this.name = args.name;
    if (args.type) this.type = args.type;
    if (args.show) this.show = this._transformConditions(args.show);
    if (args.hide) this.hide = this._transformConditions(args.hide);
  }

  /**
   * Check if show tool for given instance
   * @param {object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfShowTool(instance) {
    return !this.show || this.show.some(cond => this.checkCondition(cond, instance));
  }
  /**
   * Check if hide tool for given instance
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfHideTool(instance) {
    return this.hide && this.hide.some(cond => this.checkCondition(cond, instance));
  }
  /**
   * Check if is show and isn't hide
   * @param {Object} instance - instance for check
   * @public
   */
  isEnable(instance) {
    return this.checkIfShowTool(instance) && !this.checkIfHideTool(instance);
  }

  /**
   * Transform conditions list for our app
   * @param {Array<IspToolCondition>} list
   */
  _transformConditions(list) {
    return list.map(i => ({
      name: i.name,
      value: i.name === 'status' && !isNaN(parseInt(i.value)) ? parseInt(i.value) : i.value,
    }));
  }

  /**
   * Check single condition by it's type
   * @param {IspToolCondition} cond
   * @param {Object} instance
   * @private
   */
  checkCondition(cond, instance) {
    return instance[cond.name] == cond.value;
  }
}

/**
 * Abstract class for collection of billmanager's action
 */
export class IspTools {
  constructor(args) {
    this._required = [];
    this._type = Object;
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed required actions in IspTools');
  }

  /**
   * Check if all required data is passed
   */
  isValid(args) {
    this.isValid = this._required.every(prop => !!args[prop]);
    return this.isValid;
  }

  /**
   * Private initial method
   * @private
   */
  _init(args) {
    for (let prop in args) {
      this[prop] = new IspTool(args[prop]);
    }
  }

  /**
   * Check if show tool for given instance
   * @param {string} tool - tool's key
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfShowTool(tool, instance) {
    return instance instanceof this._type ? this[tool].checkIfShowTool(instance) : false;
  }
  /**
   * Check if hide tool for given instance
   * @param {string} tool - tool's key
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfHideTool(tool, instance) {
    return instance instanceof this._type ? this[tool].checkIfHideTool(instance) : true;
  }
}
