import { GETTERS } from './utils/getters.js';
import { STATE } from './utils/state.js';
import { MUTATIONS } from './modules/domains/mutations.js';
import { ACTIONS } from './modules/domains/actions.js';
import moduleDomainsOrder from './modules/order';
import moduleDomainsDomains from './modules/domains';
import moduleDomainsDnsHost from './modules/dnshost';
import moduleDomainsContact from './modules/contact';
import moduleDnshostOrder from '@/layouts/Domains/store/modules/dnshost/modules/order';

const moduleDomains = {
  namespaced: true,
  //state: STATE,
  //mutations: MUTATIONS,
  //getters: GETTERS,
  //actions: ACTIONS,
  modules: {
    moduleDomainsOrder,
    moduleDomainsDomains,
    moduleDomainsDnsHost,
    moduleDomainsContact,
    moduleDnshostOrder,
  },
};

export default moduleDomains;
