import { baseListState } from '@/store/utils/baseListState';
import { baseListGetters } from '@/store/utils/baseListGetters';
import { baseListMutations } from '@/store/utils/baseListMutations';
import actions from './actions';
import { BillingPaymentMethod } from '@/models/BillMgr/Billing';
import { BillMgrTools } from '@/models/BillMgr/Tools';

const getters = {
  ...baseListGetters,
  cardList: state => {
    return state.list.filter(i => i.module === 'pmyandexcheckout');
  },
  isViewCardList: state => {
    return state.viewAddCard;
  },
};
const mutations = {
  ...baseListMutations,
  setViewCardList: (state, payload) => {
    // console.log('1');
    try {
      state.viewAddCard = payload;
    } catch (e) {
      console.error(e);
    }
  },
};

const modulePayment = {
  namespaced: true,
  state: {
    ...baseListState(),
    viewAddCard: false,
    itemClass: BillingPaymentMethod,
    toolsClass: BillMgrTools,
  },
  getters: getters,
  // getters: {
  //   ...baseListGetters,
  //   ...getters,
  // },
  // mutations: { ...baseListMutations, ...mutations },
  mutations: mutations,
  actions,
};

export default modulePayment;
