import sortAddons from '@/utils/sortAddons';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';

export default class BillMgrPricelist extends BaseConfigurator {
  constructor(args) {
    // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    super(args, BillMgrAddon);
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    const required = ['id', 'name', 'filter_info', 'price'];
    return required.every(i => !!args[i]);
  }

  _init(args) {
    for (let prop of Object.keys(args)) {
      this[prop] = args[prop];
    }
    this._info = null;
  }

  get cost() {
    if (!this.filter_info.cost) return 0;
    return parseFloat(this.filter_info.cost);
  }

  get fullCost() {
    if (!this.filter_info.full_cost) return 0;
    return parseFloat(this.filter_info.full_cost);
  }

  get priceByPeriod() {
    return this.price.period;
  }

  getAddonsKeys(fields) {
    return Object.keys(fields).sort(sortAddons);
  }
}
