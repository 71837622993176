import { formDataParser } from '@/utils/parsers/formDataParser';
import DnsMgrApi from '@/services/api/DnsMgrApi';

export const ACTIONS = {
  fetchList({ commit, getters }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const dnsHost = getters.getCurrentHost;
      const tools = getters.getHostTools;
      const url = '/dnsmgr';
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        elid: dnsHost.elid,
        auth: dnsHost.auth,
        func: tools.record.func,
        ...payload,
      };
      DnsMgrApi.get(url, { params })
        .then(data => {
          if (data && data.elem) {
            commit(
              'SET_LIST',
              data.elem.map(i => ({ ...i, dnsHost }))
            );
          }
          if (data && data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateList({ commit, getters }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const dnsHost = getters.getCurrentHost;
      const tools = getters.getHostTools;
      const url = '/dnsmgr';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        elid: dnsHost.elid,
        auth: dnsHost.auth,
        func: tools.record.func,
        ...payload,
      };
      DnsMgrApi.get(url, { params })
        .then(data => {
          commit(
            'UPDATE_LIST',
            data.elem.map(i => ({ ...i, dnsHost }))
          );
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async ({ commit, getters }, id) => {
    try {
      await commit('SET_CURRENT', id);
      const record = await getters['GET_CURRENT']();
      return record;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetail({ commit }, id) {
    const params = {
      elid: id,
      func: 'domain.edit',
    };
    return new Promise((resolve, reject) => {
      DnsMgrApi.get('/dnsmgr', { params })
        .then(async data => {
          const { customfields, slist, model } = data;
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      //auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'domain.edit';
    const formData = formDataParser(params);
    return DnsMgrApi.post('/dnsmgr', formData);
  },
  fetchDnsMgrToolAction(_ctx, payload) {
    const params = {
      show_metadata: 'on',
      ...payload,
    };
    return DnsMgrApi.get('/dnsmgr', { params });
  },
  sendDnsMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    //if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return DnsMgrApi.post('/dnsmgr', formData);
  },
  // rest store
  reset({ commit }) {
    commit('SET_LIST', []);
    commit('SET_CURRENT', '');
    commit('SET_IS_LOADING', true);
  },
};
