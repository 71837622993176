<template>
  <div class="table-item-orders">
    <base-table :columns="columns" :list="paginList" class="table-item-orders__table">
      <!--      @sort-change="onSortChange"-->
      <base-table-row v-for="item in paginList" :key="item.id" :item="item" :columns="columns">
        <template #date>
          <span class="note-color">{{ $d(item.createdate, 'short') }}</span>
        </template>
        <template #title>
          {{ item.title }}
        </template>
        <template #cost>
          <span class="note-color">{{ $n(item.cost, 'currency') }}</span>
        </template>
        <template #status>
          <main-label :color="item.status.color" theme="plain">
            <!--            {{ $t(`status.${item.status.key}`) }}-->
            {{ item.status_text ? item.status_text : $t(`status.${item.status.key}`) }}
          </main-label>
        </template>
        <template #docs>
          <span class="note-color">{{ item.instruction }}</span>
        </template>
      </base-table-row>
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="finance"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import NewPagination from '@/components/Pagination/NewPagination';
export default {
  name: 'TableItemOrders',
  components: {
    BaseTable,
    BaseTableRow,
    MainLabel,
    NewPagination,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepOptions: [5, 10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 4,
      },
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleAll/GET_SORTED_LIST'];
    },
    orders() {
      return this.$store.getters['moduleBilling/moduleOrders/GET_SORTED_LIST'];
    },
    reformatList() {
      const list = [];
      this.list.forEach(item => {
        this.orders.forEach(order => {
          if (item.id === order.id) {
            item.itemtype = order.itemtype;
            item.status = Object.assign({}, order.status);
            list.push(item);
          }
        });
      });
      return list;
    },
    reformatOrders() {
      return this.orders
        .filter(x => !this.list.map(id => id.id).includes(x.id))
        .filter(x => x.status.code === 3);
    },
    sort() {
      return this.$store.state.moduleBilling.moduleOrders.moduleItemlist.sort;
    },
    paginList() {
      if (this.pagin.step === 'all') return this.reformatOrders;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.reformatOrders.slice(start, end);
    },
    showPagination() {
      return this.reformatOrders.length > this.stepOptions[0];
    },
    grouppedList() {
      const collection = {};
      this.reformatOrders.reduce((acc, item) => {
        if (!acc[item.id]) acc[item.id] = { list: [], cost: 0 };
        acc[item.id].list.push(item);
        acc[item.id].cost += item.service.cost;
        return acc;
      }, collection);
      // console.log(collection);
      return collection;
    },
  },
  watch: {
    // sort: {
    //   handler(val) {
    //     this.updateSort(val);
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    // this.updateSort(this.sort);
    // console.log(this.grouppedList);
  },
  methods: {
    // onSortChange(payload) {
    //   let { prop, order } = payload;
    //   if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
    //   this.$store.commit('moduleBilling/moduleOrders/moduleItemlist/SET_SORT', { prop, order });
    // },
    // updateSort(sort) {
    //   const col = this.columns.find(i => i.sort && i.sort.prop === sort.prop);
    //   col.sort.order = sort.order;
    // },
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.table-item-orders {
  &__table {
    max-width: 100%;
    +breakpoint(md-and-up) {
      //max-width: calc(100% - 146px);
      max-width: 100%;
    }
  }
  &__pagination {
    margin-top: 1.5rem;
  }
}
</style>
