import container from './container.vue';

export const RouteVirtual = {
  path: '/pleskshared',
  name: 'virtual',
  redirect: { name: 'virtualMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Виртуальный хостинг Plesk',
    navigation: {
      service: {
        key: 'virtual-hosting',
        // icon: 'el-icon-s-help',
        icon: 'el-icon-s-grid',
        order: 4,
      },
    },
    access: ['mainmenuservice.pleskshared'],
  },
  children: [
    {
      path: '',
      name: 'virtualMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Виртуальный хостинг Plesk',
      },
      component: () => import(/* webpackChunkName: "virtual-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'virtualOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Создание виртуального хостинга Plesk',
      },
      component: () =>
        import(/* webpackChunkName: "virtual-order" */ './pages/Order/VirtualOrder.vue'),
      children: [
        {
          path: '/pleskshared/order',
          redirect: { name: 'virtualOrder' },
        },
        {
          path: '/pleskshared/order/:id/:period',
          name: 'virtualOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "virtual-order-details" */ './pages/Order/VirtualOrderDetails.vue'
            ),
        },
        {
          path: '/pleskshared/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'virtualOrderDetails' },
        },
        {
          path: '/pleskshared/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'virtualOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'virtualDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "virtual-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewVirtual' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "virtual-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewVirtual',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "virtual-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configVirtual',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "virtual-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupVirtual',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "virtual-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyVirtual',
        },
      ],
    },
  ],
};
