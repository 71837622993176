import moduleOrders from './modules/orders';
import modulePayers from './modules/payers';
import moduleDocs from './modules/docs';
import moduleHistory from './modules/history';
import modulePayment from './modules/payment';

const moduleBilling = {
  namespaced: true,
  modules: {
    moduleOrders,
    modulePayers,
    moduleDocs,
    moduleHistory,
    modulePayment,
  },
};

export default moduleBilling;
