export const monthRuToNumber = {
  янв: 1,
  январь: 1,
  января: 1,
  фев: 2,
  февраль: 2,
  февраля: 2,
  мар: 3,
  март: 3,
  марта: 3,
  апр: 4,
  апрель: 4,
  апреля: 4,
  май: 5,
  мая: 5,
  июн: 6,
  июнь: 6,
  июня: 6,
  июл: 7,
  июль: 7,
  июля: 7,
  авг: 8,
  август: 8,
  августа: 8,
  сен: 9,
  сентябрь: 9,
  сентября: 9,
  окт: 10,
  октябрь: 10,
  октября: 10,
  ноя: 11,
  ноябрь: 11,
  ноября: 11,
  дек: 12,
  декабрь: 12,
  декабря: 12,
};

/**
 * parse ugly formatted date like '01 янв 2021 г.' or '26 января 2021 г.' to js Date object
 * @param {String} str - ugly date string, required
 * @returns {Date} Date
 */
export function parseDateRu(str) {
  if (!str) throw new Error('str params is required');
  const trash = ' г.';
  let arr = str.replace(trash, '').split(' ');
  let day = parseInt(arr[0]);
  let month = monthRuToNumber[arr[1]] - 1;
  let year = parseInt(arr[2]);
  return new Date(year, month, day);
}
