// import { OPState } from '@/models/OP/OPState';
// import { OPTask } from '@/models/OP/OPTask';
// import { OPBalancerStatusOperating, OPBalancerStatusProvisioning } from '@/models/OP/OPBalancer';

export class OPObject extends Object {
  /*************
   *
   * i - чтобы можно было отличить и добавить валидацию на сущность, если это понадобится в дальнейшем
   * _source - отвечает за это
   *
   * *****************/
  constructor(args, i) {
    super(args);
    this.source = 'object';
    this._init(args, i);
    this._headers = [];
  }
  /*
   * из разных модулей опенстака прилетает разный формат даты
   *     .000000 -> не отображает нормально часовой пояс
   *     Z -> отображает нормально
   * поэтому приводим к единому виду
   *
   * */
  parseDate(args) {
    let date = args ? args.split('.') : null;
    let parsedDate;
    if (date) {
      parsedDate = date && date.length > 1 ? date[0] + 'Z' : date[0];
    } else parsedDate = null;
    return parsedDate ? new Date(parsedDate) : null;
  }

  _init(args) {
    Object.keys(args).forEach(k => {
      if (args[k] === args.last_modified) {
        this.lastModified = this.parseDate(args[k]);
      } else this[k] = args[k];
    });
  }

  set headers(headers) {
    this._headers = headers;
    Object.keys(headers).forEach(k => {
      if (headers[k] === headers.date) {
        this[k] = this.parseDate(headers[k]);
      } else if (headers[k] === headers['last-modified']) {
        this.lastModified = this.parseDate(headers[k]);
      } else if (k === 'x-container-bytes-used-actual') {
        this.bytes = +headers[k];
      } else if (k.includes('x-')) {
        this[k] = headers[k];
      }
    });
  }
  get headers() {
    return this._headers;
  }

  updateResources(list) {
    list.forEach(i => {
      // console.log(i);
      if (this[i.intname]) this[i.intname].value = i.value;
    });
  }

  update(args) {
    this._init(args);
  }
}
