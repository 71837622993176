export const formDataParser = obj => {
  let formData = new FormData();
  Object.keys(obj).forEach(item => {
    formData.append(item, obj[item]);
  });
  return formData;
};

export const parserMultipart = obj => {
  let s = '';
  for (let pair of obj.entries()) {
    if (typeof pair[1] == 'string') {
      s += (s ? '&' : '') + encode(pair[0]) + '=' + encode(pair[1]);
    }
  }
  return s;
};

export const encode = s => {
  return encodeURIComponent(s).replace(/%20/g, '+');
};
