import BillMgrApi from '@/services/api/BillMgrApi';
import store from '@/store';
import qs from 'qs';
// import { postpaid } from '@/utils/postpaid';

function isNeedTermOfUseAgreement(e) {
  return e === 'gdpr_termsofuse';
}

async function sendBillMgrAction(params) {
  return store.dispatch('sendBillMgrToolAction', params);
}
const defaults = {
  vds_total: 0,
  pleskshared_total: 0,
  billing: 0,
  vdc3_total: 0,
  vhost_total: 0,
  vhost: 0,
  backups: 0,
  clienttickets: 0,
  rsnx_partners: 0,
  acronis_total: 0,
  dummy_ssl_rsnx_oa_migrate_total: 0,
  saas_services: 0,
  rps_total: 0,
  licplesk_total: 0,
  licvz_total: 0,
  antiddosgcore_total: 0,
  dedic_total: 0,
  colocation_total: 0,
  domain_total: 0,
  dnshost_total: 0,
  antiddosgcore: 0,
  levels: [],
  category: [],
  phone: '',
  newTickets: 0,
};

const ModuleStart = {
  namespaced: true,
  reseller: false,
  state: {
    ...defaults,
  },
  mutations: {
    setProp: (state, { prop, value }) => {
      // console.log(prop, value);
      if (Object(state).hasOwnProperty(prop)) state[prop] = value;
      {
        // console.log(state[prop], prop, ' === ', value);
        state[prop] = value;
      }
    },
    setItems: (state, { prop, value }) => {
      // console.log('prop', prop, 'value', value);
      if (Object(state).hasOwnProperty(prop)) state[prop] = value;
      {
        // console.log(state[prop], prop, ' === ', value);
        state[prop] = value;
      }
    },
    setReseller: (state, reseller) => {
      state.reseller = reseller === 'on';
    },
    clearCategory: state => {
      state.category = [];
    },
    setLengts: (state, length) => {
      state.clienttickets = length;
    },
    SET_NEW_TICKETS: (state, newTickets) => {
      try {
        state.newTickets = newTickets.length;
      } catch (e) {
        console.error(e);
      }
    },
  },
  actions: {
    fetchAppInfo({ commit }, payload = {}) {
      // console.log('desktop');
      const params = { func: 'desktop' };

      return new Promise((resolve, reject) => {
        BillMgrApi.get('', { params, ...payload })
          .then(data => {
            if (data && data.user && data.user.ignoreitemmax)
              commit('setReseller', data.user.ignoreitemmax);
            // console.log('dataStart', data);

            // if (data && data.itemcount) {
            //   // console.log('---------------------------');
            //   Object.keys(data.itemcount).forEach(i => {
            //     // commit('setProp', { prop: i, value: parseInt(data.elem[0][i]) });
            //     commit('setProp', { prop: i, value: data.itemcount[i] });
            //   });
            // }
            const str = data.toString();
            if (str.includes('activationsend')) {
              // console.log('data', qs.parse(data.ok.v));
              const prepareParams = qs.parse(data);
              const params = {
                func: prepareParams.func,
                ...qs.parse(prepareParams.redirect),
              };
              // console.log(params);
              resolve(params);
            } else {
              // if (data.itemcount) {
              // Object.keys(data.itemcount).forEach(i => {
              // commit('setProp', { prop: i, value: parseInt(data.itemcount[i]) });
              // console.log(i);
              // });
              // }
              // commit('setProp', { prop: 'levels', value: data.levels });
              resolve(data);
            }
          })
          // .then(data => console.log('dataFetch', data))
          //TODO
          .catch(data => {
            // console.log('catch', data, data.type);
            if (isNeedTermOfUseAgreement(data.type)) {
              sendBillMgrAction({
                func: data.type,
                auth: data.auth,
                field_2: 'on',
                field_3: 'on',
                field_5: 'on',
              }).then(data => {
                // console.log(data.ok);
                if (data.ok === 'true') {
                  const params = { func: 'desktop' };
                  return new Promise(resolve => {
                    BillMgrApi.get('', { params }).then(data => {
                      // console.log('dataStart', data);
                      // if (data.itemcount) {
                      //   Object.keys(data.itemcount).forEach(i => {
                      // commit('setProp', { prop: i, value: parseInt(data.itemcount[i]) });
                      // });
                      // }
                      // commit('setProp', { prop: 'levels', value: data.levels });
                      resolve(data);
                    });
                  });
                }
              });
            } else {
              // console.log(data);
              this.setError(data);
            }
          })
          .catch(e => reject(e));
      });
    },
    fetchDasboardInfo({ commit }, payload = {}) {
      // const params = { func: 'dashboard.services' };
      const params = { func: payload.func };
      return new Promise((resolve, reject) => {
        BillMgrApi.get('', { params })
          .then(data => {
            // console.log(data.elem);
            if (data && data.elem) {
              Object.keys(data.elem).forEach(i => {
                // console.log(data.elem[i], i);
                // console.log(Object.keys(data.elem[i]).length, i);
                // if (Object.keys(data.elem[i]).length > 4) {
                if (Object.keys(data.elem[i]).includes('fraudphoneverified')) {
                  // console.log('qwqwqwqw', Object.keys(data.elem[i]));
                  Object.keys(data.elem[i]).forEach(item => {
                    commit('setProp', { prop: item, value: data.elem[i][item] });
                  });
                } else
                  commit('setItems', {
                    prop: data.elem[i].intname,
                    value: data.elem[i].items_total,
                  });
              });
            }
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
    fetchDesktop({ commit }, payload = {}) {
      const params = { func: 'desktop' };
      return new Promise((resolve, reject) => {
        BillMgrApi.get('', { params, ...payload })
          .then(data => {
            // console.log(data.project);
            if (data.project) {
              commit('moduleProviders/setCurrent', data.project, { root: true });
            }
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
    fetchAllTicketsLength({ commit }) {
      const params = {
        func: 'clientticket',
      };
      return new Promise((rs, rj) => {
        BillMgrApi.get('', { params })
          .then(data => {
            if (data.elem && data.elem.length) {
              commit('setLengts', data.elem.length);
              commit(
                'SET_NEW_TICKETS',
                data.elem.filter(status => status.tstatus_orig === '100_client')
              );
              rs(data);
            }
          })
          .catch(err => rj(err));
      });
    },
    reset({ state, commit, rootState }) {
      commit('clearCategory');
      rootState.moduleSupport.allIncident = [];
      rootState.moduleSupport.allTickets = [];
      rootState.moduleSupport.newTickets = [];
      Object.keys(state).forEach(i => {
        commit('setProp', { prop: i, value: defaults[i] });
      });
    },
  },
  getters: {
    services: (state, _getters, _rootState, rootGetters) => {
      // console.log(_rootState);
      return {
        vps: +state.vds || 0,
        hosting: +state.pleskshared || 0,
        saas_services: +state.saas_services || 0,
        billing: rootGetters['moduleBilling/moduleDocs/modulePayment/unpayed'].length,
        clouds: +state.vdc2 || 0,
        incedents: rootGetters['moduleSupport/incedents'],
        // incedents: getIncedents(),
        stack: +state.vdc3 || 0,
        tickets: +state.clienttickets || 0,
        backups: +state.backups || 0,
        partners: +state.rsnx_partners || 0,
        acronis: +state.acronis || 0,
        idprotect: +state.idprotect || 0,
        all: +rootGetters['moduleAll/GET_COUNT'],
        ssl: +state.dummy_ssl_rsnx_oa_migrate || 0,
        microsoft_office: +state.microsoft_office || 0,
        virtuozzo: +state.licvz || 0,
        plesk: +state.licplesk || 0,
        microsoft: +state.licmic || 0,
        zmail: +state.zmail || 0,
        vhost: +state.vhost || 0,
        ftpbacup: +state.ftpbacup || 0,
        bitrix: +state.bitrix_license || 0,
        soft: +state.soft || 0,
        dnshost: +state.dnshost || 0,
        cdn: +state.cdnedge || 0,
        rps: +state.rps || 0,
        dedic: +state.dedic || 0,
        colocation: state.colocation || 0,
        domain: +state.domain || 0,
        antiddosgcore: +state.antiddosgcore || 0,
      };
    },
    reseller: state => state.reseller,
    phone: state => state.phone,
  },
};

export default ModuleStart;
