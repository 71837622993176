<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Stack',
  beforeRouteLeave(_to, _from, next) {
    next();
  },
};
</script>

<style lang="stylus" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
</style>
