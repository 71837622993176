<template>
  <div class="security">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading && !isReady" class="security__loader" />
      <div v-else class="security__content">
        <!--        <card-block :title="$t('password.title')" class="security__block">-->
        <card-block :title="titleWithLogin" class="security__block">
          <security-password
            class="security__card"
            @ready-state-change="onReadyStateChange('password', $event)"
            @form-data-change="onFormDataChange('password', $event)"
          />
        </card-block>
        <card-block
          v-if="profile.totp"
          :title="$t('totp.title')"
          class="security__block security__block--small"
        >
          <security-totp :profile="profile" class="security__totp">
            <div class="standart-text">
              {{ $t('totp.desc') }}
            </div>
            <base-button slot="bodyEnd">
              {{ $t('totp.btn') }}
            </base-button>
          </security-totp>
        </card-block>
        <card-block :title="$t('access.title')" class="security__block security__block--small">
          <security-access-limit
            class="security__card"
            @ready-state-change="onReadyStateChange('access', $event)"
            @form-data-change="onFormDataChange('access', $event)"
          />
        </card-block>
      </div>
    </transition>
  </div>
</template>

<!--$t(`${this.isPast ? 'wasActiveUnder' : 'isActiveUnderOff'}`, { date: this.$d(this.tariff.expiredate,-->
<!--'middle'), cost: this.tariff.cost, })-->

<script>
import CardBlock from '../components/CardBlock';
import SecurityPassword from '../components/SecurityPassword';
import SecurityAccessLimit from '../components/SecurityAccessLimit';
import SecurityTotp from '../components/SecurityTotp';
import mixin from '../../mixins/index';
import askUnsavedPageLeave from '@/mixins/askUnsavedPageLeave';
export default {
  name: 'Security',
  components: {
    CardBlock,
    SecurityPassword,
    SecurityAccessLimit,
    SecurityTotp,
  },
  mixins: [mixin, askUnsavedPageLeave],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      readyStates: {
        password: false,
        totp: true,
        access: false,
      },
      dataChanges: {
        password: false,
        totp: false,
        access: false,
      },
    };
  },
  computed: {
    isReady() {
      return Object.values(this.readyStates).every(v => v);
    },
    hasUnsavedChanges() {
      return Object.values(this.dataChanges).some(v => v);
    },
    titleWithLogin() {
      return this.$t('password.title', {
        name: this.profile.name,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      this.askUnsavedPageLeave()
        .then(() => next())
        .catch(() => {})
        .finally(() => this.$modals.close());
    } else next();
  },
  methods: {
    onReadyStateChange(name, value) {
      this.readyStates[name] = value;
    },
    onFormDataChange(name, value) {
      this.dataChanges[name] = value;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "password": {
        "title": "Смена пароля для логина {name}"
      },
      "totp": {
        "title": "Двухфакторная авторизация",
        "desc": "Такой вид авторизации обеспечивает более надежную защиту вашего аккаунта и данных. При каждом входе вам будет приходить смс код, подтверждающий личность",
        "btn": "Включить"
      },
      "access": {
        "title": "Ограничение доступа",
        "btn": "Сохранить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.security {
  &__block {
    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 2.5rem;
      }
    }

    &--small {
      max-width: 735px;
    }
  }
}
</style>

<style lang="scss" scoped>
.security {
  /*
  display: flex;
  flex-direction: column;
  &-title {
    margin-top: 20px;
    &:nth-child(1) {
      margin-top: 40px;
    }
  }*/
  &-card {
    /* margin-top: 20px;
    display: flex;
    align-items: flex-end;
    min-width: 1008px;
    justify-content: space-between;
    flex-direction: row;
    */
    &-controllers {
      display: flex;
      flex-direction: row;
      max-width: 600px;
      flex-wrap: wrap;
      &__elem {
        &:nth-child(2) {
          margin-left: 10px;
        }
        &:nth-child(3) {
          margin-top: 24px;
        }
      }
    }
    &__text {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--title);
    }

    & input {
      margin-top: 10px;
      width: 240px;
    }
  }
}
</style>
