import { GETTERS } from './getters.js';
import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import moduleVhostOrder from './modules/order';

const moduleVhost = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
  modules: {
    moduleVhostOrder,
  },
};

export default moduleVhost;
