import { DnsHostTariff } from '@/models/BillMgr/DnsHostTariff';
import { DnsHostTariffTools } from '@/models/BillMgr/Tools';
import { DnsHost } from '@/models/DnsMgr/DnsHost';
import { DnsHostTools } from '@/models/DnsMgr/DnsMgrTools';
import Vue from 'vue';
const sortOrders = ['asc', 'desc'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list.map(i => new DnsHost(i));
    } catch (e) {
      console.error(e);
    }
  },
  SET_DNS_LIST: (state, list) => {
    try {
      state.dnsList = list.map(i => new DnsHostTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new DnsHost(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  UPDATE_DNS_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.dnsList.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new DnsHostTariff(i.data));
    state.dnsList = [...filteredOldList, ...newList];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff =
      state.dnsList.find(i => +i.id === innerId) || state.dnsList.find(i => i.id === innerId); // TODO как это вообще возможно)
    if (tariff && tariff instanceof DnsHostTariff) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_OTHER_INFO_ABOUT_DOMAIN: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.elid === innerId);
    if (tariff && tariff instanceof DnsHost) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new DnsHostTariffTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_DNSMGR_TOOLS: (state, value) => {
    try {
      state.dnsMgrTools = new DnsHostTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_DNS_AUTH: (state, value) => {
    state.dnsAuth = value;
  },
  RESET: state => {
    state.list = [];
    state.current = '';
    state.isLoading = true;
    state.sort = {
      prop: 'title',
      order: 'asc',
    };
    state.tools = {};
    state.dnsList = [];
    state.dnsMgrTools = {};
    state.dnsAuth = '';
  },
};
