<template>
  <div class="billing-payers">
    <page-block
      :title="this.$t('title')"
      class="billing-payers__block billing-payers__block--payers"
    >
      <transition name="fade" mode="out-in">
        <base-loader v-if="isLoading" />
        <base-empty v-else-if="!isLoading && !list.length" :title="$t('empty.title')" narrow>
          {{ $t('empty.text') }}
          <base-button v-if="toolNew" slot="link" @click="add">
            {{ $t('empty.add') }}
          </base-button>
        </base-empty>
        <base-table v-else :list="list" :columns="tableHead">
          <payers-table-row v-for="item in list" :key="item.id" :columns="tableHead" :item="item" />
        </base-table>
      </transition>
    </page-block>
  </div>
</template>

<script>
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty.vue';
import BaseTable from '@/components/Table/BaseTable.vue';
import PayersTableRow from '../components/PayersTableRow.vue';
import { isEqual } from 'lodash';
import stepper from '@/mixins/billmgr/wizard';
export default {
  name: 'BillingPayers',
  components: { BaseEmpty, BaseTable, PayersTableRow },
  mixins: [stepper],
  data() {
    return {
      tableHead: [
        {
          key: 'title',
          label: this.$t('tableHead.title'),
        },
        {
          key: 'phone',
          label: this.$t('tableHead.phone'),
        },
        {
          key: 'type',
          label: this.$t('tableHead.type'),
        },
        {
          key: 'reconciliation',
        },
        {
          key: 'more',
        },
      ],
    };
  },
  computed: {
    list() {
      //return [];
      return this.$store.getters['moduleBilling/modulePayers/GET_SORTED_LIST'];
    },
    isLoading() {
      return this.$store.state.moduleBilling.modulePayers.isLoading;
    },
    tools() {
      return this.$store.state.moduleBilling.modulePayers.tools;
    },
    toolNew() {
      if (!this.tools || !this.tools.new) return null;
      return this.tools.new;
    },
  },
  watch: {
    toolNew(val, old) {
      // console.log('val', val, 'old', old);
      // if (val && !isEqual(val, old)) this.startFunc = val.func;
      this.startFunc = val.func;
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleBilling/modulePayers/fetchList');
    },
    add() {
      this.runWizard()
        .then(this.fetchList)
        .catch(() => {});
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Плательщики",
    "empty": {
      "title": "В данный момент у вас нет ни одного плательщика.",
      "text": "Плательщик — лицо, которое указывается в договорах на оказание услуг. Для одного аккаунта может быть создан один плательщик.",
      "add": "Добавить плательщика"
    },
    "tableHead": {
      "title": "Плательщик: ФИО или название компании",
      "phone": "Телефон",
      "type": "Тип"
    },
    "modal": {
      "res": {
        "success": "Запрос выполняется. Пожалуйста, дождитесь обновления данных",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
