<template>
  <div class="base-basket-header">
    <div class="base-basket-header__title" :class="`${size}-title`">
      {{ title ? title : $t('title') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBasketHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['standart', 'medium', 'big'].includes(val),
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Корзина"
  }
}
</i18n>
