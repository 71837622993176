import NewTicket from '@/layouts/Support/component/NewTicket';
import NewTicketCrm from '@/layouts/Support/component/NewTicketCrm';
import Vue from 'vue';

export default {
  computed: {
    list() {
      return this.$store.getters['moduleSupport/GET_ALL_TICKETS'] || [];
    },
    isLoading() {
      return this.$store.state.moduleSupport.isLoading;
    },
  },
  mounted() {
    // this.$store.dispatch('moduleSupport/fetchServiceList');
    // this.$store.dispatch('moduleSupport/fetchAllTickets');
    // this.$store.dispatch('moduleSupport/resetFiles');
  },
  methods: {
    fetch() {
      return this.$store.dispatch('moduleSupport/fetchAllTickets');
    },
    askUnsavedPageLeaveT(props = {}) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'PageLeave',
          ...props,
          title: !props.title ? this.$t('unsaved.title') : props.title,
          text: !props.text ? this.$t('unsaved.text') : props.text,
          onClose: () => reject(),
          onDismiss: () => reject(),
          footer: {
            confirm: {
              props: { title: this.$t('unsaved.confirm') },
              on: { click: () => resolve() },
            },
            cancel: {
              props: { title: this.$t('unsaved.cancel') },
              on: {
                click: () => {
                  this.$modals.close();
                  reject();
                },
              },
            },
          },
        });
      });
    },
    addNewTicket(props = {}) {
      this.$emit('click', 'click');
      let self = null;
      let hasChanges = false;
      this.$modals.open({
        name: 'TicketModal',
        // size: 'small',
        // size: 'medium',
        size: 'large',
        component: NewTicket,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('new_ticket'),
        on: {
          change: val => {
            // console.log('val');
            hasChanges = val;
          },
        },
        onOpen: instance => (self = instance),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
                // this.$modals.close({ name: 'PageLeave' });
                // this.$modals.close({ name: 'SettingsModal' });
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        onClose: () => {
          this.$store.dispatch('moduleSupport/fetchAllTickets');
          this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
        },
      });
    },
    addNewTicketCrm(props = {}) {
      this.$emit('click', 'click');
      let self = null;
      let hasChanges = false;
      this.$modals.open({
        name: 'TicketModal',
        // size: 'small',
        // size: 'medium',
        size: 'large',
        component: NewTicketCrm,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('new_ticket'),
        on: {
          change: val => {
            // console.log('val');
            hasChanges = val;
          },
        },
        onOpen: instance => (self = instance),
        onDismiss: async () => {
          if (!hasChanges) return true;
          else {
            let res = false;
            await this.askUnsavedPageLeaveT()
              .then(() => {
                res = true;
                // this.$modals.close({ name: 'PageLeave' });
                // this.$modals.close({ name: 'SettingsModal' });
              })
              .catch(() => {
                res = false;
              });
            return res;
          }
        },
        onClose: () => {
          // this.$store.dispatch('moduleSupport/fetchAllTickets');
          // this.$store.dispatch('moduleSupport/resetFiles');
          self = null;
        },
      });
    },
  },
};
