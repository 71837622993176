import { UserProfile } from '@/models/BillMgr/UserProfile';
import { AllowedUserTools } from '@/models/BillMgr/Tools';
import { isEmpty } from 'lodash';
import Vue from 'vue';

export default {
  SET_PROFILE_INFO: (state, data) => {
    if (state.profile instanceof UserProfile) state.profile.update(data);
    else state.profile = data.id ? new UserProfile(data) : {};
  },
  SET_PARTNERS: (state, data) => {
    state.partners = data;
  },
  SET_RESELLER: (state, data) => {
    state.partners_group = data;
  },
  SET_PAYERS: (state, data) => {
    try {
      state.payers = data;
    } catch (e) {
      console.error(e);
    }
  },
  SET_REFERALS: (state, data) => {
    state.referals = data;
  },
  UPDATE_PROFILE_INFO: (state, { prop, value }) => {
    if (prop && value != undefined) {
      Vue.set(state.profile, prop, value);
    }
  },
  RESET_PROFILE_INFO: state => {
    state.profile = {};
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new AllowedUserTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_ACCESS: (state, value) => {
    if (!value || isEmpty(value)) state.access = null;
    else {
      state.access = {};
      for (let item of Object.keys(value)) {
        Vue.set(state.access, item, true);
        for (let sub of value[item]) {
          Vue.set(state.access, `${item}.${sub.name}`, true);
        }
      }
    }
  },
  SET_PHONE_VALIDATION_RESULT: state => {
    state.profile.need_phone_validate = false;
  },
};
