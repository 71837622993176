import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import { VpsIpTariff } from '@/models/BillMgr/VpsIpTariff';
import { VpsTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
import { BillMgrStatus } from '@/models/BillMgr/status';
const sortOrders = ['asc', 'desc'];
const vpsModes = ['grid', 'table'];
function updateObjectInArray(array, id, updatedObject) {
  const objectToUpdate = array.find(obj => obj.id === id).status;

  if (objectToUpdate) {
    Object.assign(objectToUpdate, updatedObject);
  }

  return array;
}

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      if (list) state.list = list.map(i => new VpsTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  updateVpsStatus: (state, payload) => {
    try {
      const status = new BillMgrStatus(
        payload.vps.in_pay === 'on'
          ? 6
          : payload.vps.employeesuspend === 'on'
          ? 8
          : payload.vps.abusesuspend === 'on'
          ? 7
          : payload.vps.status
      );
      // const newVps = new VpsTariff(payload.vps);
      // console.log(payload.vps.pricelist, status);
      updateObjectInArray(state.list, payload.id, status);
    } catch (e) {
      console.error(e);
    }
  },
  SET_IP_LIST: (state, list) => {
    try {
      state.ipList = list.map(i => new VpsIpTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  SET_MODEL: (state, model) => {
    try {
      state.model = model;
    } catch (e) {
      console.error(e);
    }
  },
  SET_PAYMENT_CARDS: (state, payload) => {
    state.isPaymentCard = payload;
  },
  UPDATE_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new VpsTariff(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && tariff instanceof VpsTariff) {
      if (value.pricelist) console.log(value.pricelist);
      // console.log(tariff, prop, value);
      Vue.set(tariff, prop, value);
    }
  },
  setPleskPanel: (state, value) => {
    state.list.filter(x => x.id === value)[0]
      ? (state.list.filter(x => x.id === value)[0].isPlesk = true)
      : null;
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_KEY: (state, value) => {
    state.key = value;
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new VpsTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  setVpsBackup: (state, value) => {
    state.vpsBackup = value;
  },
  setNameServers: (state, value) => {
    state.vpsName = value;
  },
  setIpList: (state, value) => {
    // console.log(value);
    state.ip = value;
    // console.log(state.ip);
  },
  setNodeList: (state, value) => {
    state.nodeList = value;
    // console.log(state.nodeList);
  },
};
