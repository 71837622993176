import { VhostTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
import { VhostTariff } from '@/models/BillMgr/VhostTariff';
import { BillMgrStatus } from '@/models/BillMgr/status';
const sortOrders = ['asc', 'desc'];
const vpsModes = ['grid', 'table'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list.map(i => new VhostTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new VhostTariff(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  SET_STATUS: (state, payload) => {
    state.list.filter(x => x.id === payload.id)[0].status = new BillMgrStatus(payload.status);
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  setPleskPanel: (state, value) => {
    state.list.filter(x => x.id === value)[0].isPlesk = true;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && tariff instanceof VhostTariff) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new VhostTools(value);
    } catch (e) {
      console.error(e);
    }
  },
};
