<template>
  <!--  <div class="page">-->
  <!--    <transition name="slide-fade">-->
  <!--      <router-view />-->
  <!--    </transition>-->
  <!--  </div>-->
  <div class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'SSLContainer',
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleSSL/reset');
    next();
  },
  mounted() {
    this.$store.dispatch('moduleSSL/fetchSSL');
  },
};
</script>

<style lang="stylus" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
</style>
