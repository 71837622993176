import { STATE } from '../../../../utils/state';
import { GETTERS } from '../../../../utils/getters';
import { MUTATIONS } from './mutations';
import { ACTIONS } from './actions';

const moduleDnsRecords = {
  namespaced: true,
  state: {
    ...STATE(),
    sort: {
      prop: 'rtype_orig',
      order: 'asc',
    },
  },
  getters: {
    ...GETTERS,
    getCurrentHost: (_state, _getters, _rootState, rootGetters) => {
      return rootGetters['moduleDomains/moduleDomainsDnsHost/GET_CURRENT']('domain');
    },
    getHostTools: (_state, _getters, rootState) => {
      return rootState.moduleDomains.moduleDomainsDnsHost.dnsMgrTools;
    },
  },
  mutations: MUTATIONS,
  actions: ACTIONS,
};

export default moduleDnsRecords;
