import { GETTERS } from './getters.js';
import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import moduleRentOrder from './modules/order';

const moduleRent = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
  modules: {
    moduleRentOrder,
  },
};

export default moduleRent;
