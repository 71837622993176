<template>
  <nav-simple :list="nav" wide class="header-creation-nav" @click.native="onClick" />
</template>

<script>
import NavSimple from '@/components/NavSimple/NavSimple.vue';
export default {
  name: 'HeaderCreationNav',
  components: { NavSimple },
  data() {
    return {
      navRusonyx: [
        {
          title: this.$t('vps'),
          to: { name: 'vpsOrder' },
        },
        {
          title: this.$t('virtual'),
          to: { name: 'virtualOrder' },
        },
        {
          title: this.$t('cloud'),
          to: { name: 'Stack' },
        },
        {
          title: this.$t('domain'),
          to: { name: 'domainsOrder' },
        },
        {
          title: this.$t('ssl'),
          to: { name: 'sslOrder' },
        },
        {
          title: this.$t('licbitrix'),
          to: { name: 'bitrixOrder' },
        },
        // {
        //   title: this.$t('licvz'),
        //   to: { name: 'virtuozzoOrder' },
        // },
        {
          title: this.$t('licplesk'),
          to: { name: 'pleskOrder' },
        },
        // {
        //   title: this.$t('mo365'),
        //   to: { name: 'mo365Order' },
        // },
        // {
        //   title: this.$t('rentServer'),
        //   to: { name: 'rentOrder' },
        // },
        {
          title: this.$t('acronis'),
          disabled: this.$store.getters['moduleStart/services'].acronis > 0,
          to: { name: 'acronisOrder' },
        },
      ],
      navInfobox: [
        {
          title: this.$t('vps'),
          to: { name: 'vpsOrder' },
        },
        {
          title: this.$t('virtual'),
          to: { name: 'vhostOrder' },
        },
        {
          title: this.$t('cloud'),
          to: { name: 'Stack' },
        },
        {
          title: this.$t('domain'),
          to: { name: 'domainsOrder' },
        },
        {
          title: this.$t('ssl'),
          to: { name: 'sslOrder' },
        },
        {
          title: this.$t('licbitrix'),
          to: { name: 'bitrixOrder' },
        },
        // {
        //   title: this.$t('licvz'),
        //   to: { name: 'virtuozzoOrder' },
        // },
        {
          title: this.$t('licplesk'),
          to: { name: 'pleskOrder' },
        },
        // {
        //   title: this.$t('mo365'),
        //   to: { name: 'mo365Order' },
        // },
        // {
        //   title: this.$t('rentServer'),
        //   to: { name: 'rentOrder' },
        // },
        {
          title: this.$t('acronis'),
          disabled: this.$store.getters['moduleStart/services'].acronis > 0,
          to: { name: 'acronisOrder' },
        },
      ],
      navCaravan: [
        {
          title: this.$t('cloud'),
          to: { name: 'Stack' },
        },
        {
          title: this.$t('domain'),
          to: { name: 'domainsOrder' },
        },
        {
          title: this.$t('ssl'),
          to: { name: 'sslOrder' },
        },
        // {
        //   title: this.$t('licmic'),
        //   to: { name: 'microsoftOrder' },
        // },
        // {
        //   title: this.$t('license'),
        //   to: { name: 'BuyLicense' },
        // },
        {
          title: this.$t('acronis'),
          disabled: this.$store.getters['moduleStart/services'].acronis > 0,
          to: { name: 'acronisOrder' },
        },
      ],
      navZenon: [
        {
          title: this.$t('vps'),
          to: { name: 'vpsOrder' },
        },
        {
          title: this.$t('virtual'),
          to: { name: 'vhostOrder' },
        },
        {
          title: this.$t('cloud'),
          to: { name: 'Stack' },
        },
      ],
    };
  },
  computed: {
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
    nav() {
      if (this.providerCurrent === '2') return this.navCaravan;
      else if (this.providerCurrent === '3') return this.navRusonyx;
      else if (this.providerCurrent === '4') return this.navZenon;
      else return this.navInfobox;
    },
    providerCurrent() {
      return this.$store.getters['moduleProviders/provider'];
    },
  },
  mounted() {},
  methods: {
    onClick(e) {
      if (e.target.tagName === 'A' && e.target.target !== '_blank') {
        this.$emit('route-change');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "vps": "VPS сервер",
    "virtual": "Виртуальный хостинг",
    "cloud": "Облачный сервер",
    "domain": "Зарегистрировать домен",
    "ssl": "Купить SSL сертификат",
    "licbitrix": "Купить лицензию Bitrix",
    "licmic": "Купить лицензию Офис",
    "licvz": "Купить лицензию Virtuozzo",
    "licplesk": "Купить лицензию Plesk",
    "mo365": "Лицензии Офис",
    "rentServer": "Аренда физических серверов",
    "acronis": "Облачный бэкап"
  }
}
</i18n>

<style lang="stylus" scoped>
.header-creation-nav {}
</style>
