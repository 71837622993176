import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';

export const ACTIONS = {
  fetchList({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'service_profile',
        out: 'bjson',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          if (data.elem && data.elem.length) commit('SET_LIST', data.elem);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateList({ dispatch }, payload = {}) {
    const params = {
      update: true,
      ...payload,
    };
    return dispatch('fetchList', params);
  },
  fetchContact({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'service_profile.edit',
        out: 'bjson',
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          if (data.elem && data.elem.length) commit('SET_LIST', data.elem);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  // updateList({ commit }, payload) {
  //   commit('SET_IS_LOADING', true);
  //   return new Promise((resolve, reject) => {
  //     const url = '/billmgr/';
  //     const params = {
  //       show_metadata: 'on',
  //       out: 'sjson',
  //       p_cnt: 1000,
  //       ...payload,
  //     };
  //     BillMgrApi.get(url, { params })
  //       .then(data => {
  //         if (data.elem && data.elem.length) commit('UPDATE_LIST', data.elem);
  //         resolve(data);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       })
  //       .finally(() => {
  //         commit('SET_IS_LOADING', false);
  //       });
  //   });
  // },
  setCurrent: async ({ commit, getters }, id) => {
    try {
      await commit('SET_CURRENT', id);
      const tariff = await getters['GET_CURRENT']();
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  setModel(state, value) {
    state.model = value;
  },
  fetchDetail({ commit, dispatch }, id) {
    const params = {
      elid: id,
      func: 'domain.edit',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const {
            customfields,
            slist,
            // groups,
            model,
          } = data;
          //const details = await dispatch('fetchTariffCalc', model);
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              //details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'domain.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchBillMgrToolAction(_ctx, { func, id }) {
    const params = {
      func,
      elid: id,
      newwindow: 'yes',
    };
    return BillMgrApi.get('/billmgr', { params });
  },
  sendBillMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  // rest store
  reset({ commit }) {
    commit('SET_LIST', []);
    commit('SET_CURRENT', '');
    commit('SET_IS_LOADING', true);
  },
};
