<template>
  <div>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2929 2L7.64745 5.64545L7.29398 5.99891L7.64736 6.35247L11.293 9.99991L10 11.2929L6.35355 7.64645L6 7.29289L5.64645 7.64645L2 11.2929L0.707107 10L4.35355 6.35355L4.70711 6L4.35355 5.64645L0.707107 2L2 0.707107L5.64645 4.35355L6 4.70711L6.35355 4.35355L10 0.707107L11.2929 2Z"
        :fill="color"
        :stroke="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => 'white',
    },
  },
};
</script>
