export const STATE = {
  list: [],
  current: '',
  isLoading: true,
  sort: {
    prop: 'id',
    order: 'desc',
  },
  mode: 'grid',
  tools: {},
};
