<template>
  <div class="auth">
    <div class="auth__form" @keyup.enter="auth">
      <recover-password :recovery-info="formData" class="security__card" />
    </div>
    <div v-if="error" class="auth__error standart-text error-color">
      {{ error }}
    </div>
  </div>
</template>

<script>
import RecoverPassword from '@/layouts/LoginPage/components/RecoverPassword';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import showErrorModal from '@/mixins/showErrorModal';
import qs from 'qs';
import setFocus from '@/mixins/setFocus';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import showSuccess from '../mixins/showSuccess';
export default {
  name: 'ChangePassword',
  components: {
    RecoverPassword,
  },
  mixins: [setFocus, billMgrToolActions, showSuccess, showErrorModal],
  data() {
    return {
      formData: {},
      initialBackup: '',
      secret: undefined,
      userid: undefined,
      confirm: undefined,
      password: undefined,
      modal: null,
      readyStates: {
        password: false,
        totp: true,
        access: false,
      },
      dataChanges: {
        password: false,
        totp: false,
        access: false,
      },
      error: '',
    };
  },
  computed: {
    lastRoute() {
      return this.$store.state.moduleRoutes.last;
    },
    hasChanges() {
      return this.initialBackup !== Object.values(this.formData).join('');
    },
    isValid() {
      return this.formData.password === this.formData.confirm;
    },
    getModel() {
      const model = this.$store.state.moduleReg.model;
      return model;
    },
  },
  watch: {},
  mounted() {
    setTimeout(() => this.setFocus(), 500);
    this.formData = this.getModel;
  },
  methods: {
    // onReadyStateChange(name, value) {
    //   this.readyStates[name] = value;
    // },
    // onFormDataChange(name, value) {
    //   this.dataChanges[name] = value;
    // },
    redirect() {
      if (this.lastRoute) {
        if (this.lastRoute.path !== '/login/') this.$router.push(this.lastRoute);
        this.$store.commit('moduleRoutes/SET_LAST', null);
        this.$router.push({ name: 'start' }).catch(() => {});
      } else this.$router.push({ name: 'start' }).catch(() => {});
    },
    initModal(name, props = {}) {
      this.$modals.open({
        name,
        onOpen: instance => (this.modal = instance),
        onClose: () => (this.modal = null),
        component: BaseLoader,
        ...props,
      });
    },
    setError(data) {
      const e = data.e ? data.e : data;
      this.error = e.msg;
      if (e.object === 'badpassword') {
        this.fields.forEach(i => {
          if (i.name === 'username' || i.name === 'password') i.invalid = true;
        });
      }
    },
    clearError() {
      this.error = '';
      this.fields.forEach(i => (i.invalid = false));
    },
    sendBillMgrAction(params) {
      return this.$store.dispatch('sendBillMgrToolAction', params);
    },
    // setInitialFormData(list) {
    //   this.initialBackup = '';
    //   this.formData = list.reduce((acc, item) => {
    //     acc[item.name] = item.value;
    //     this.initialBackup += item.value;
    //     return acc;
    //   }, {});
    //   this.isReady = true;
    // },
  },
  beforeRouteLeave(_to, _from, next) {
    localStorage.removeItem('key');
    next();
  },
  beforeRouteEnter(_to, _from, next) {
    const bmUrl = window.location.href;
    // console.log(bmUrl);
    next(vm => {
      if (bmUrl.includes('?')) {
        const bmRequest = qs.parse(bmUrl.split('?')[1].split('#')[0]);
        if (bmRequest.func && bmRequest.secret && bmRequest.func === 'recovery.change') {
          vm.formData = bmRequest;
          const model = vm.$store.getters.GET_MODEL;
        }
      }
    });
  },
};
</script>

<i18n>
  {
    "ru": {
      "username": "Имя пользователя",
      "password": "Пароль",
      "confirm": "Подтверждение",
      "signin": "Войти",
      "success": {
        "title": "Регистрация",
        "text": "Вы успешно подтвердили регистрацию в личном кабинете",
        "ok": "Продолжить"
      },
      "totp": {
        "title": "Двухэтапная аутентификация",
        "desc": "Укажите 6-значный код, сгенерированный приложением Google Authenticator"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.auth {
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
