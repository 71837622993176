<template>
  <div class="nav-simple" :class="classes">
    <components
      :is="item.tag"
      v-for="item in listInternal"
      :key="item.key"
      v-bind="item.attrs"
      :disabled="item.disabled"
      class="nav-simple__link standart-text"
    >
      {{ item.title }}
    </components>
  </div>
</template>

<script>
export default {
  name: 'NavSimple',
  props: {
    list: {
      type: Array,
      default: () => [],
      validator: arr => {
        return arr.every(item => (!!item.key || !!item.title) && (!!item.to || !!item.href));
      },
    },
    direction: {
      type: String,
      default: 'vertical',
      validator: value => ['vertical', 'horizontal'].includes(value),
    },
    theme: {
      type: String,
      default: 'default',
      validator: value => ['default', 'dark', 'light'].includes(value),
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const base = 'nav-simple';
      return {
        [`${base}--${this.direction}`]: !!this.direction,
        [`${base}--${this.theme}`]: !!this.theme,
        [`${base}--wide`]: this.wide,
      };
    },
    listInternal() {
      return this.list.map(item => {
        const isExternal = !item.to && !!item.href;
        const link = {
          title: item.title ? item.title : this.$t(`nav.${item.key}`),
          attrs: item.attrs || {},
          disabled: item.disabled || false,
        };
        if (isExternal) {
          link.tag = 'a';
          link.attrs.href = item.href;
          link.attrs.target = '_blank';
        } else {
          link.tag = 'router-link';
          link.attrs.to = item.to;
        }
        return link;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
// global variables
@require '~@/assets/styles/vars/variables';
// local variables
$directions = {
  vertical: {
    flexdir: column,
    gutterdir: top,
    gutter: 1.25rem,
    wide: 2.5rem,
  },
  horizontal: {
    flexdir: row,
    gutterdir: left,
    gutter: 1.5rem,
    wide: 2.5rem,
  },
}
$themes = {
  dark: {
    base: $color-dark.black,
  },
  light: {
    base: $color-light.white,
  },
  default: {
    base: var(--text),
  }
}
// component styles
.nav-simple {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  for $key, $value in $directions {
    &--{$key} {
      flex-direction: $value.flexdir;
    }
  }

  &__link {
    text-decoration: none;
    transition: color $anim-base-duration ease;

    &:focused {
      outline: none;
    }

    for $key, $value in $themes {
      ^[0]--{$key} & {
        color: $value.base;

        &:hover {
          color: $value.hover ? $value.hover : $value.base;
        }
      }
    }

    &[disabled=disabled] {
      pointer-events: none;
      opacity: 0.5;
    }

    & + & {
      for $key, $value in $directions {
        ^[0]--{$key} & {
          margin-{$value.gutterdir}: $value.gutter;
        }
        ^[0]--{$key}^[0]--wide & {
          margin-{$value.gutterdir}: $value.wide;
        }
      }
    }

  }
}
</style>
