export class BillMgrMeasure {
  constructor(value, str) {
    this.value = parseInt(value) || 0;
    this.measure = str || '';
  }

  toString(str) {
    let value = new Intl.NumberFormat().format(this.value);
    if (str) return `${value} ${str}`;
    else if (this.measure) return `${value} ${this.measure}`;
    else return `${value}`;
  }

  toNumber() {
    return this.value;
  }

  toBoolean() {
    return !!this.value;
  }
}
