<template>
  <div class="avatar-resizer">
    <vue-avatar
      ref="editor"
      :width="width"
      :height="height"
      :rotation="intRotation"
      :scale="intScale"
      :border-radius="width / 2"
      :border="0"
      :image="value"
      class="avatar-resizer__editor"
      @select-file="change"
    />
    <div class="avatar-resizer__controls">
      <div class="avatar-resizer__control">
        <div class="avatar-resizer__label standart-title">
          {{ $t('scale') }}
        </div>
        <base-slider
          v-model="intScale"
          :min="1"
          :max="3"
          :step="0.02"
          tooltip="none"
          :contained="false"
          class="avatar-resizer__slider"
        />
      </div>
      <div class="avatar-resizer__control">
        <div class="avatar-resizer__label standart-title">
          {{ $t('rotation') }}
        </div>
        <base-slider
          v-model="intRotation"
          :min="0"
          :max="360"
          :step="1"
          tooltip="none"
          :contained="false"
          class="avatar-resizer__slider"
        />
      </div>
    </div>
    <base-button class="avatar-resizer__btn" @click="save">{{ $t('save') }}</base-button>
  </div>
</template>

<script>
import { VueAvatar } from 'vue-avatar-editor-improved';
import BaseSlider from '@/components/BaseSlider/BaseSlider.vue';
export default {
  name: 'AvatarResizer',
  components: { VueAvatar, BaseSlider },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    value: {
      type: String,
      default: '',
    },
    scale: {
      type: Number,
      default: 1,
      validator: val => val >= 1 && val <= 3,
    },
    rotation: {
      type: Number,
      default: 0,
      validator: val => val >= 0 && val <= 360,
    },
  },
  data() {
    return {
      intScale: this.scale,
      intRotation: this.rotation,
    };
  },
  methods: {
    change(filelist) {
      this.$emit('change', filelist[0]);
    },
    save() {
      this.$emit('save', {
        canvas: this.$refs.editor.getImageScaled(),
        scale: this.intScale,
        rotation: this.intRotation,
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "scale": "Масштаб",
      "rotation": "Поворот",
      "radius": "Радиус",
      "save": "Готово"
    }
  }
</i18n>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.avatar-resizer {
  text-align: center;

  &__editor {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    zoom: 1.5;
  }
  &__controls {
    margin: 1.5rem 0;

    +breakpoint(sm-and-up) {
      margin: 2.5rem 0;
    }
  }
  &__control {
    text-align: left;
    & + & {
      margin-top: 2rem;
    }
  }

  &__label {
    margin-bottom: 1rem;
  }

}
</style>
