import BillMgrApi from '@/services/api/BillMgrApi';

export const ACTIONS = {
  fetchItemsList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        func: 'item_all',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          if (data.elem && data.elem.length) commit('SET_LIST', data.elem);
          if (data.metadata && data.metadata.toolbtndata)
            commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchOrdersList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (payload.hasOwnProperty('silent')) delete payload.silent;
    if (!silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        func: 'order',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          const { list } = data;
          if (list && list.inpaylist) {
            commit('moduleInpaylist/SET_LIST', list.inpaylist);
          }
          if (list && list.itemlist) {
            commit('moduleItemlist/SET_LIST', list.itemlist);
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchList({ commit, dispatch }) {
    commit('SET_IS_LOADING', true);
    return Promise.all([
      dispatch('fetchItemsList', { silent: true }),
      dispatch('fetchOrdersList', { silent: true }),
    ]).finally(() => commit('SET_IS_LOADING', false));
  },
  updateList({ commit }, payload) {
    //console.log('updateList', 'Orders');
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = 'billmgr';
      const params = {
        show_metadata: 'on',
        newface: 'on',
        func: 'item_all',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          if (data.elem && data.elem.length) commit('UPDATE_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async ({ commit, getters }, id) => {
    try {
      await commit('SET_CURRENT', id);
      const tariff = await getters['GET_CURRENT']();
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  // rest store
  reset({ commit }) {
    commit('SET_LIST', []);
    commit('SET_CURRENT', '');
    commit('SET_IS_LOADING', true);
  },
};
