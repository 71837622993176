var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services"},[_c('page-title',{staticClass:"service-main-title",attrs:{"text":"Создание виртуального хостинга"}}),_c('h4',{staticClass:"service-mini-title"},[_vm._v("Выберите тариф")]),_c('transition-group',{staticClass:"service-container-content",attrs:{"name":"slide-fade"}},_vm._l((_vm.datasetForTariffContainer),function(item){return _c('service-card',{key:item.id,ref:item.id,refInFor:true,staticClass:"service-container-content__card",attrs:{"v-p-s":false,"config":item},on:{"selected":_vm.getTariff},nativeOn:{"click":function($event){return _vm.getInfoAboutVirtual.apply(null, arguments)}}})}),1),_c('h4',{staticClass:"service-mini-title"},[_vm._v("Дополнительно")]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.cache.config)?_c('div',[_c('div',{staticClass:"service-container-additions"},[_c('slider-block',{staticClass:"service-slider",attrs:{"config":{
            title: 'Публичные IPv4-адреса',
            label: 'Ipv4',
            cost: '100',
          },"slider-config":{
            min: 1,
            max: 15,
          }},on:{"sliderValue":_vm.getSliderValueIPv}}),_c('h4',{staticClass:"service-mini-title"},[_vm._v("Итого")]),_c('view-banner',{staticClass:"service-view",attrs:{"titles":_vm.titles,"values":_vm.valuesForView}}),_c('div',{staticClass:"service-controllers"},[_c('div',{staticClass:"service-controllers-row"},[_c('span',{staticClass:"service-text-strong"},[_vm._v("000 ₽")]),_c('span',{staticClass:"service-text"},[_vm._v("При оплате за")]),_c('selector',{staticClass:"service-controllers-row__select"})],1),_c('div',{staticClass:"service-controllers-row"},[_c('main-button',{attrs:{"description":`Оплатить ${5}}`}}),_c('Summary',{attrs:{"text":"В корзину"}})],1)])],1)]):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.cache.config)?_c('div',{staticClass:"active-service-card shadow-box service-card serice-card_type_small service-card_position_fixed",class:{ active: _vm.isActive }},[_vm._v(" "+_vm._s(_vm.cache.config.title)+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }