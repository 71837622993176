export default {
  methods: {
    askUnsavedPageLeave(props = {}) {
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'PageLeave',
          ...props,
          title: !props.title ? this.$t('unsaved.title') : props.title,
          text: !props.text ? this.$t('unsaved.text') : props.text,
          onClose: () => reject(),
          footer: {
            confirm: {
              props: { title: this.$t('unsaved.confirm') },
              on: { click: () => resolve() },
            },
            cancel: {
              props: { title: this.$t('unsaved.cancel') },
              on: { click: () => reject() },
            },
          },
        });
      });
    },
  },
};
