<template>
  <div class="multiple-block">
    <div v-if="label || $slots.default" class="multiple-block__label standart-title">
      <slot>{{ label }}</slot>
      <span v-if="$attrs.required" class="multiple-block__label-asterisk">*</span>
      <plain-button
        v-if="hint"
        v-tooltip="{ content: hint, placement: 'top-start', container: false }"
        icon="help"
        size="small"
        color="dim"
        tabindex="-1"
        class="multiple-block__hint"
      />
    </div>
    <div v-tooltip="config.disabled ? tooltipSettings : null" class="multiple-block__fields">
      <!--        :readonly="!(filter && lic === 'ISP')"-->
      <base-checkbox
        v-for="opt in options"
        :key="opt.k"
        v-model="internalValue"
        :readonly="readOnlyStatus(opt)"
        :value="opt.k"
        :disabled="config.disabled"
        class="multiple-block__checkbox"
      >
        {{ opt.v === 'По электронной почте' ? 'ЭДО' : opt.v }}
      </base-checkbox>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import MultipleComponent from '@/models/base/components/multiple';
export default {
  name: 'MultipleBlock',
  components: { BaseCheckbox },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof MultipleComponent || typeof obj.value !== 'undefined',
    },
    intname: {
      type: String,
      default: null,
    },
    view: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    lic: {
      type: String,
      default: 'none',
    },
    filter: {
      type: Boolean,
      default: false,
    },
    idLic: {
      type: String,
      default: '',
    },
    cost: {
      type: Number,
      default: 0,
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => [1, 3, 6, 12].includes(parseInt(val)),
    },
    periodKey: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.config.value ? this.config.value.split(',') : [],
      initInternalValue: [],
    };
  },
  computed: {
    isDockerOff() {
      return !['177', '178'].includes(this.idLic);
    },
    // readOnlyStatus() {
    //   console.log()
    //   if (!(this.filter && this.lic && this.lic === 'ISP') && !this.view) {
    //     return true;
    //   } else return false;
    // },
    tooltipSettings() {
      return {
        content: this.$t('restrictclientchange.on'),
        placement: 'top-start',
      };
    },
    options() {
      return this.config.options;
    },
  },
  watch: {
    idLic(newVal, oldVal) {
      // if (newVal)
      if (
        this.isDockerOff &&
        this.intname === 'isp_vds_additional_resources' &&
        newVal !== '38' &&
        this.internalValue.find(x => x === 'isp_vds_additional_resource_docker')
      ) {
        // console.log(newVal, oldVal, this.internalValue, this.config.value);
        // console.log('-------------');
        // console.log(this.internalValue.filter(x => x !== 'isp_vds_additional_resource_docker'));
        const newInternalValue = this.internalValue.filter(
          x => x !== 'isp_vds_additional_resource_docker'
        );
        this.config.value = newInternalValue.join(',');
        this.internalValue = newInternalValue;

        this.$emit('change', {
          value: this.config.value,
          sum: this.cost,
        });
      }
    },
    filter(val) {
      //хардкод для слежки за лицензий ISP
      if (!val && this.intname.includes('isp')) {
        // console.log('cms---------');

        this.internalValue = [];
        this.config.value = [];
        this.$emit('change', {
          value: [],
          sum: this.cost,
        });
        // } else if (
        //   val &&
        //   this.intname === 'cms' &&
        //   this.internalValue.value !== this.initInternalValue.value
        // ) {
        // console.log('cms');
      }
    },
    // config: function (newconfig) {
    //   if (this.internalValue.join(',') != newconfig.value)
    //     this.internalValue = newconfig.value.split(',');
    // },
    'config.value': function (newValue) {
      // console.log(newValue);
      // console.log(newValue, this.internalValue);
      if (
        this.internalValue.length > 0 &&
        newValue.length > 0 &&
        this.internalValue.join(',') !== newValue
      )
        this.internalValue = newValue.split(',');
    },
    internalValue: function (value) {
      this.onChange(value);
    },
  },
  mounted() {
    this.initInternalValue = this.internalValue;
  },
  methods: {
    readOnlyStatus(item) {
      if (this.lic && this.lic === 'none') {
        return true;
      } else if (this.lic && this.lic === 'Plesk') {
        return true;
      } else if (item.k.includes('_docker') && this.isDockerOff) {
        return true;
      } else if (!(this.filter && this.lic && this.lic === 'ISP') && !this.view) {
        return true;
      } else return false;
    },
    onChange(value) {
      this.config.value = value.join(',');
      // console.log(value);
      this.internalValue = value;
      this.$emit('change', {
        value: this.config.value,
        sum: this.cost,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.multiple-block {
  &__label {
    flexy(flex-start, baseline);
    margin-bottom: 10px;
    color: var(--text);
    transition: color $anim-base-duration ease, opacity $anim-base-duration ease;

    ^[0]--disabled &,
    ^[0]--readonly & {
      opacity: 0.2;
    }

    &-asterisk {
      color: $error-color;
      margin-left: 0.125rem;
    }
    &-end {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }

  &__fields {
    flexy(flex-start, center)
flex-wrap: wrap
  }

  &__checkbox {
      margin-top:  0.75rem
      margin-left: 1.15rem;
    & + & {
  }
  }
}
</style>
