<template>
  <div class="base-table__wrapper" :class="`base-table--${theme}`">
    <div v-if="$slots.before" class="base-table__before">
      <slot name="before"></slot>
    </div>
    <div class="base-table__inner">
      <div class="base-table" :class="classes">
        <div
          v-if="columns.length && columns.some(i => i.label)"
          class="base-table__head"
          :style="headerStyles"
        >
          <div
            v-for="(col, i) in columns"
            :key="i"
            class="base-table__head-col"
            :class="getColClasses(col)"
            :style="col.style ? col.style : null"
            @click="onClick(col)"
          >
            {{ col.label ? col.label : '' }}
            <!--            <svg-icon v-if="col.hint" name="help" class="base-table__head-col-help" />-->
            <svg-icon v-if="col.sort" name="triangle" class="base-table__head-col-arrow" />
          </div>
        </div>
        <div class="base-table__body">
          <template v-if="list.length">
            <slot></slot>
          </template>
          <div
            v-else-if="!list.length && !$slots.loading && !loading"
            class="base-table__empty standart-text"
          >
            <slot name="empty">{{ empty ? empty : $t('empty') }}</slot>
          </div>
        </div>
      </div>
      <div v-if="$slots.loading || loading" class="base-table__loading">
        <slot name="loading">
          <base-loader />
        </slot>
      </div>
    </div>
    <div v-if="$slots.after" class="base-table__after">
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    sticked: {
      type: [Boolean, Number, String],
      default: false,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'default',
      validator: val => ['default', 'plain'].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      base: 'base-table',
    };
  },
  computed: {
    classes() {
      return {
        [`${this.base}--sticked`]: this.sticked,
      };
    },
    headerStyles() {
      let top = 0;
      if (typeof this.sticked === 'number') {
        top = this.sticked;
      } else if (typeof this.sticked === 'string') {
        top = parseInt(this.sticked);
      }
      const obj = {
        top: `${top}px`,
      };
      return this.sticked || this.sticked === 0 ? obj : {};
    },
  },
  methods: {
    getColClasses(col) {
      const sortable = col.sort && col.sort.prop && col.sort.order;
      const obj = {
        [`${this.base}-col--${col.key}`]: true,
        [`${this.base}__head-col--sortable`]: sortable,
      };
      if (sortable && this.sort) {
        obj[`${this.base}__head-col--${col.sort.order}`] = sortable;
        obj[`${this.base}__head-col--sorted`] = col.sort.prop === this.sort.prop;
      }
      return obj;
    },
    onClick(col) {
      if (col.sort) {
        this.$emit('sort-change', col.sort);
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "empty": "Элементов не найдено"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.base-table {
  width: 100%;
  display: table;

  &-col {
    &--prolongcost {
      max-width: 350px !important;
      width: 350px !important;
    }
  }
  &__wrapper {
    width: 100%;
    padding: 0.75rem;
    border-radius: $border-radius-base;
    background: var(--main-card-bg);
    color: var(--main-card-color);
    transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease, box-shadow $anim-base-duration ease;

    ^[0]--default& {
      border: 1px solid var(--main-card-border);

      &:hover {
        box-shadow: var(--main-card-shadow);
      }
    }
    ^[0]--plain& {
      padding: 0;
    }
  }

  &__loading {
    width: 100%;
  }

  &__inner {
    padding-bottom: 3px;
    margin-bottom: -3px;
    overflow-x: auto;
  }

  &__before {
    margin-bottom: 1.5rem;
    padding: 0.75rem 0.75rem 0;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }
  &__after {
    margin-top: 1.5rem;
    padding: 0 0.75rem 0.75rem;

    +breakpoint(sm-and-up) {
      margin-bottoptom: 2.5rem;
    }
  }

  &__head {
    display: table-row;
    margin: -0.75rem -0.75rem 0;
    color: $color-light.medium;
    white-space: nowrap;

    ^[0]--sticked & {
      position: sticky;
      background: var(--main-card-bg);
      z-index: 2;
    }

    &-col {
      display: table-cell;
      //padding: 0.75rem;
      padding: 0.75rem 1rem;

      &--sortable {
        cursor: pointer;
        white-space: nowrap;
      }
      &-help {

      }
      &-arrow {
        vertical-align: middle;
        margin-left: 2px;
        transition: transform 0.5s ease, opacity 0.3s ease;
        transform-origin: center;

        +breakpoint(ml-and-up) {
          opacity: 0;
        }

        ^[-1]--sortable:hover &,
        ^[-1]--sorted & {
          opacity: 1;
        }
        ^[-1]--asc & {
          transform: rotate(-180deg);
        }
        ^[-1]--desc & {
          transform: rotate(0);
        }
      }
    }
  }

  &__body {
    margin: 0 -0.75rem -0.75rem;
    min-width: min-content;
    display: table-row-group;
  }

  &__empty {
    display: table-cell;
    padding: 0.75rem;
  }
}
</style>
