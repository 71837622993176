import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrStatus, BillMgrStatusSpecial } from './status';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';

export class SaasTariff extends BaseConfigurator {
  constructor(args) {
    super(args, BillMgrAddon);
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = args.id;
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    this.autosuspend = args.autosuspend || 'off';
    this.change_pricelist = args.change_pricelist;
    this.name = args.name;
    this.adminEmail = '';
    this.costsuffix = args.cost_suffix;
    this.pricelist = args.pricelist;
    this.orderinfo = args.orderinfo;
    this.cost = parseCost(args.cost);
    this.period = args.period;
    this.period_cost = args.period_cost || '';
    this.costperiod = args.costperiod;
    this.item_cost = args.item_cost;
    this.datacentername = args.datacentername;
    this.expiredate = Date.parse(args.expiredate) || parseDateRu(args.expiredate);
    this.createdate = Date.parse(args.createdate) || parseDateRu(args.createdate);
    this.order_disabled = args.order_disabled;
    this.prolong_available = args.prolong_available || 'off';
    this.subaccount = args.subaccount;
    this.transition = args.transition;
    this.in_pay = args.in_pay || 'off';
    // this.status = new BillMgrStatus(this.in_pay === 'on' ? 6 : args.status);
    this.status = new BillMgrStatus(
      this.in_pay === 'on'
        ? 6
        : this.employeesuspend === 'on'
        ? 8
        : this.abusesuspend === 'on'
        ? 7
        : args.status
    );
    this.specialstatus = new BillMgrStatusSpecial(args.specialstatus);
    this._history = [];
    // autoprolong
    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_period = args.autoprolong_period || args.autoprolong_id || 'null';
    this.autoprolong_name = args.autoprolong || args.autoprolong_name || '';
    this.isPlesk = false;
    this.promise = args.promise || 'null';
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    return {
      // datacentername: this.datacentername,
      cost: this.cost,
    };
  }

  get title() {
    return this.name;
  }

  get hasPanel() {
    if (!this.info || !this.info.customfields) return false;
    const { username, userpassword } = this.info.customfields;
    const vals = { yes: true, no: false };
    return username && vals[username.added] && userpassword && vals[userpassword.added];
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }

  set info({ customfields, slist, model, details }) {
    this._info = {
      customfields,
      slist,
      model,
      details: null,
    };
    if (details && details.main.period_cost) this.period_cost = details.main.period_cost;
    if (customfields && customfields.admin_email.value)
      this.adminEmail = customfields.admin_email.value;
    if (customfields) {
      this._info.details = {
        main: details.main,
      };
    }
  }

  get info() {
    return this._info;
  }

  get addons() {
    return [
      {
        label: 'E-mail администратора услуги:',
        value: this.adminEmail,
      },
    ];
    // if (!this.info || !this.info.customfields) return null;
    // return this.info.customfields;
  }

  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }
}
