import { formDataParser } from '@/utils/parsers/formDataParser';
import VmRougeApi from '@/services/api/VmRougeApi';
import BillMgrApi from '@/services/api/BillMgrApi';
import MyRsxExtApi from '@/services/api/MyRsxExtApi';

export const ACTIONS = {
  fetchPleskPanel({ commit, dispatch }, payload = {}) {
    const params = {
      func: 'panel_button',
      ...payload,
    };
    return BillMgrApi.get('billmgr', { params })
      .then(data => {
        if (data.ok && data.ok.type === 'url' && data.ok.v) {
          commit('setPleskPanel', payload.id);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  reinstallOs({ getters, dispatch }, payload) {
    return dispatch('fetchBillMgrToolAction', payload);
  },
  fetchStoredMethods({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/stored_methods_for_item';
      const params = {
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          // console.log(data);
          // commit('SET_LIST', data.elem);
          // commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchList({ commit }, payload = {}) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vds';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST', data.elem);
          commit('SET_TOOLS', data.metadata.toolbtndata);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vds';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('UPDATE_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async ({ commit, getters }, id) => {
    try {
      await commit('SET_CURRENT', id);
      const tariff = await getters['GET_CURRENT_VPS'];
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetail({ commit, dispatch }, { id, detail = false }) {
    // const currentId = { id;
    const params = {
      elid: id,
      func: 'vds.edit',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { customfields, slist, groups, model } = data;
          const details = await dispatch('fetchTariffCalc', model);

          if (detail) commit('updateVpsStatus', { id: id, vps: model });
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              groups,
              details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchAddons({ commit }, id) {
    return new Promise((resolve, reject) => {
      MyRsxExtApi.get(`addons/${id}`)
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', { prop: 'addonsExt', value: data });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchHistory({ commit }, id) {
    const params = {
      func: 'service.history',
      elid: id,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'history',
            value: data.elem,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  sendReinstallOs(_ctx, payloads) {
    const params = {
      func: 'vmrogue_reinstall_os',
      out: 'bjson',
      sok: 'ok',
      auth: localStorage.getItem('token'),
      ...payloads,
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData);
  },
  fetchTariffCalc(_ctx, model) {
    const params = {
      ...model,
      func: 'pricelist.calculate',
      auth: localStorage.getItem('token'),
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchBillMgrToolAction(_ctx, payload) {
    const params = {
      ...payload,
      newface: 'on',
    };
    if (payload.id) params.elid = payload.id;
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          // console.log(data);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  sendBillMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  updateDetail(_ctx, payload) {
    // console.log(payload);
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'vds.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  // auth vm-rouge
  getToken({ state, dispatch }, vps) {
    if (!state.tools.gotoserver.isEnable(vps)) return Promise.reject();
    return new Promise((resolve, reject) => {
      dispatch('fetchBillMgrToolAction', {
        func: state.tools.gotoserver.func,
        id: vps.id,
      })
        .then(data => {
          if (data.ok.type === 'url' && data.ok.v) {
            const arr = data.ok.v.split('/token-login/');
            if (arr[0] === VmRougeApi.defaults.baseURL && arr[1]) resolve(arr[1]);
            else reject(`Unknown service ${arr[0]} or value format ${data.v}`);
          } else {
            reject(`Unknown data type: ${data.ok.type}, value: ${data.ok.v}`);
          }
        })
        .catch(e => reject(e));
    });
  },
  getVMApiKey(_ctx, token) {
    if (!token) throw new Error('Missed required prop: "token"');
    return new Promise((resolve, reject) => {
      VmRougeApi.get(`/api/get-api-key/${token}`)
        // .then(data => resolve(data))
        .then(data => {
          resolve(data);
        })
        // .catch(e => reject(e));
        .catch(e => {
          reject(e);
        });
    });
  },
  getVmKey({ commit, dispatch }, vps) {
    dispatch('getToken', vps).then(token => {
      dispatch('getVMApiKey', token)
        .then(data => {
          commit('SET_KEY', data.api_key);
          localStorage.setItem('vmrogue', data.api_key);
        })
        .catch(e => console.error(e));
    });
  },
  // vm actions
  setServerAction({ commit }, { id, serverId, action = '', method = 'post', params = {} }) {
    if (!serverId) throw new Error('Missed required prop: "serverid"');
    let url = `/instances/${serverId}`;
    if (action) url += `/${action}`;
    return new Promise((resolve, reject) => {
      VmRougeApi[method](url, params)
        .then(({ data }) => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'serverState',
            value: data.State,
            id,
          });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchServerInfo(_ctx, { serverId, action = '', params = {} }) {
    if (!serverId) throw new Error('Missed required param "serverId"');
    // console.log('fetchServerInfo', serverId);
    let url = `/instances/${serverId}`;
    if (action) url += `/${action}`;
    return new Promise((resolve, reject) => {
      VmRougeApi.get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchBackupNameList({ commit }) {
    let url = '/instances';
    return new Promise((resolve, reject) => {
      VmRougeApi.get(url)
        .then(({ data }) => {
          commit('setNameServers', data.Items);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchVpsCountInfo({ commit, dispatch }, { serverId }) {
    let url = `/instances/${serverId}`;
    return new Promise((resolve, reject) => {
      VmRougeApi.get(url)
        .then(({ data }) => {
          commit('setVpsBackup', data.VpsBackup);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchBackupInfo(_ctx, { serverId }) {
    let url = `/tasks/${serverId}`;
    return new Promise((resolve, reject) => {
      VmRougeApi.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setBackupState({ commit, dispatch }, { serverId }) {
    dispatch('fetchBackupInfo', { serverId }).then(({ data }) => {
      commit('SET_STATE_BACKUP', data.State);
    });
  },
  setServerInfo({ commit, dispatch }, { serverId, id }) {
    if (!serverId) throw new Error('Missed required param "serverId"');
    dispatch('fetchServerInfo', { serverId }).then(data => {
      commit('SET_OTHER_INFO_ABOUT_TARIFF', {
        prop: 'serverInfo',
        value: data,
        id,
      });
    });
  },
  fetchId({ commit, dispatch }, id) {
    commit('SET_IS_LOADING', true);
    const params = {
      elid: id,
      func: 'service.ip',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('SET_IP_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  editIdDetail({ commit, dispatch }, payload) {
    commit('SET_IS_LOADING', true);
    const params = {
      elid: payload.elid,
      plid: payload.plid,
      func: 'service.ip.edit',
    };
    // console.log(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('SET_MODEL', data.model);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setIpList({ commit }, payload) {
    // console.log(payload);
    commit('setIpList', payload);
  },
  setPaymentCard({ commit }, payload) {
    commit('SET_PAYMENT_CARDS', payload);
  },
  setModel({ commit }, payload) {
    commit('SET_MODEL', payload);
  },
  // reset store
  reset({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_IS_LOADING', true);
        commit('SET_CURRENT', '');
        commit('SET_LIST', []);
        commit('SET_KEY', '');
        commit('setNameServers', []);
        commit('SET_IP_LIST', []);
        resolve();
      }, delay);
    });
  },
};
