import { SSLTariff } from '@/models/BillMgr/SSLTariff';
import { SSLTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
const vpsModes = ['grid', 'table'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list.map(i => new SSLTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new SSLTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new SSLTariff(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SSL: (state, payload) => {
    state.ssl = payload.pricelist;
    state.datacenter = payload.datacenter[0].name;
  },
  SET_CURRENT_SSL: (state, payload) => {
    state.currentSSL = new SSLTariff(payload);
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && tariff instanceof SSLTariff) {
      Vue.set(tariff, prop, value);
    }
  },
};
