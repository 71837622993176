<template>
  <div class="base-table-row">
    <div
      v-for="(col, i) in columns"
      :key="i"
      class="base-table-row__col"
      :class="[`base-table-row__col--${col.key}`, colClass]"
      :style="col.style ? col.style : null"
    >
      <slot :name="col.key"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTableRow',
  inheritAttrs: false,
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    colClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.base-table-row {

  //display: flex;
  //align-items: stretch;
  display: table-row;
  min-height: (1.25rem + 1.5rem);

  &__col {
    flex: 0 0 auto;
    //display: flex;
    //align-items: center;
    display: table-cell;
    vertical-align: middle;
    //vertical-align: -webkit-baseline-middle;
    padding: 0.75rem;
    margin-right: 0;
  }
}
</style>
