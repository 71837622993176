<template>
  <component :is="tag" v-bind="rootAttrs" class="avatar" :class="classes" v-on="$listeners">
    <!--    <transition name="fade">-->
    <!--    <div class="avatar__id">{{ '#' + id }}</div>-->
    <div v-if="src">
      <img :src="src" v-bind="imgAttrs" class="avatar__img" />
    </div>
    <div v-else class="avatar__empty">
      <base-loader v-if="loading" class="avatar__loading" />
      <svg-icon v-else name="profile-noava" class="avatar__empty-icon" />
    </div>
    <!--    </transition>-->
  </component>
</template>

<script>
export default {
  name: 'Avatar',
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: null,
    },
    shape: {
      type: String,
      default: '',
      // validator: value => ['square', 'rounded', 'circle'].includes(value),
    },
    size: {
      type: String,
      default: 'small',
      validator: value => ['small', 'big'].includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      linkElementAttrs: ['href', 'target', 'title'],
      imgElementAttrs: ['alt', 'width', 'height', 'srcset'],
    };
  },
  computed: {
    tag() {
      return this.to ? 'router-link' : this.$attrs.href ? 'a' : 'div';
    },
    rootAttrs() {
      const obj = {};
      const attrs = this.linkElementAttrs.reduce((acc, item) => {
        if (this.$attrs[item]) acc[item] = this.$attrs[item];
        return acc;
      }, {});
      if (this.to) obj.to = this.to;
      return { ...obj, ...attrs };
    },
    imgAttrs() {
      return this.imgElementAttrs.reduce((acc, item) => {
        if (this.$attrs[item]) acc[item] = this.$attrs[item];
        return acc;
      }, {});
    },
    classes() {
      const base = 'avatar';
      return {
        [`${base}--${this.shape}`]: !!this.shape,
        [`${base}--${this.size}`]: !!this.size,
        [`${base}--empty`]: !this.src,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$av-sizes = {
  small: 1.25rem,
  big: 2.5rem,
}

.avatar {
  display: block;
  position: relative;
  text-decoration: none;

  &__img {
    display: block;
    max-size(100%);
    object-fit: contain;
  }

  &--square,
  &--rounded,
  &--circle {
    padding-bottom: 100%;
    overflow: hidden;

    ^[0]--empty& {
      padding-bottom: 0;
    }

    & ^[0]__img,
    & ^[0]__empty {
      absolute(0px, 0px);
      size(100%);
      object-fit: cover;
    }
  }

  &--square {
    border-radius: 0;
  }

  &--rounded {
    border-radius: $border-radius-base;
  }

  &--circle {
    border-radius: 50%;
  }

  &__id {
    absolute(38px);
    right: -9px;
    flexy(center, center);
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.1;
    z-index: 1000;
    +breakpoint(ms-and-down) {
      absolute(29px);
      font-size: 0.7rem;
    }
  }

  &__empty {
    flexy(center, center);
    size(100%);
    border-radius: inherit;
    background: var(--avatar-bg);

    &-icon {
      color: var(--avatar-color);

      for $key, $value in $av-sizes {
        ^[0]--{$key} & {
          font-size: $value;
        }
      }
    }
  }
}
</style>
