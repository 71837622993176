import { PROD } from '../../../../public/config';

export const IS_PROD = process.env.NODE_ENV === 'production';
export const urlCaravan = `https://${PROD}.caravan.ru`;
export const urlAstraStack = `https://${PROD}.astracloud.ru`;
export const urlRusonyx = `https://${PROD}.rusonyx.ru`;
export const urlInfobox = `https://${PROD}.infobox.ru`;
export const urlZenon = `https://${PROD}.zenon.net`;
export const bmCaravan = 'https://bill.caravan.ru';
export const bmAstraStack = 'https://bill.astracloud.ru';
export const bmInfobox = 'https://bill.infobox.ru';
export const bmRusonyx = 'https://bill.rusonyx.ru';
export const bmZenon = 'https://bill.zenon.net';
export const currentProvider = {
  // для политики
  [bmCaravan]: 'field_2',
  // [urlCaravan]: 'field_2',
  [bmRusonyx]: 'field_3',
  [bmZenon]: 'field_4',
  // [urlRusonyx]: 'field_3',
  [bmInfobox]: 'field_5',
  [bmAstraStack]: 'field_6',
  // [urlInfobox]: 'field_5',
};

export const hostToProvider = {
  // определяем по хосту адрес биллменеджера
  [urlRusonyx]: bmRusonyx,
  [urlInfobox]: bmInfobox,
  [urlAstraStack]: bmAstraStack,
  [urlCaravan]: bmCaravan,
  [urlZenon]: bmZenon,
};
export const urlToProject = {
  [urlRusonyx]: 3,
  [urlInfobox]: 5,
  [urlAstraStack]: 6,
  [urlCaravan]: 2,
  [urlZenon]: 4,
};

export const urlProvider = {
  // отвечает за переключение между провайдерами - урл где находится приложение - для прода - mystage -> myprod
  3: urlRusonyx,
  6: urlAstraStack,
  4: urlZenon,
  5: urlInfobox,
  2: urlCaravan,
};
export const projectToHost = {
  // проект
  [bmAstraStack]: 6,
  [bmRusonyx]: 3,
  [bmInfobox]: 5,
  [bmZenon]: 4,
  [bmCaravan]: 2,
};
export const currentHost = {
  [bmAstraStack]: urlAstraStack,
  [bmRusonyx]: urlRusonyx,
  [bmInfobox]: urlInfobox,
  [bmZenon]: urlZenon,
  [bmCaravan]: urlCaravan,
};

export const projectToUrlBM = {
  // по проекту определяем куда стучаться в биллменеджер
  6: bmAstraStack,
  3: bmRusonyx,
  5: bmInfobox,
  4: bmZenon,
  2: bmCaravan,
};

export const dev = IS_PROD ? 'stat' : 'full_price';

export function findAllByKey(obj, keyToFind) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      key === keyToFind
        ? acc.concat(value)
        : typeof value === 'object'
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc,
    []
  );
}
