<template>
  <!--  <div class="base-loader"></div>-->
  <div :class="classes"></div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    size: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return this.size ? 'base-loader__sized' : 'base-loader__default';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-loader__default {
  font-size: 2.5rem;
  //font-size: var(this.baseSize);
  line-height: 1;
  margin: 1em auto;
  circle(1em);
  color: var(--loader-color);
  border: 2px solid currentColor;
  border-left-color: var(--loader-border);
  animation: rotation 1s linear infinite;
}
.base-loader__sized {
  font-size: 1rem;
  //font-size: var(this.baseSize);
  line-height: 1;
  //margin: 1em auto;
  margin: auto;
  circle(1em);
  //margin-left: 1.75rem;
  color: var(--loader-color);
  border: 2px solid currentColor;
  border-left-color: var(--loader-border);
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
