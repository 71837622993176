export default {
  computed: {
    provider() {
      return this.$store.state.moduleProviders.current;
    },
  },
  watch: {
    provider() {
      if (this.onProviderChange && typeof this.onProviderChange === 'function')
        this.onProviderChange();
    },
  },
  mounted() {
    // if (!this.onProviderChange || typeof this.onProviderChange !== 'function') {
    //   console.warn('Missed required function "onProviderChange"');
    // }
  },
};
