<template>
  <img v-if="type === 'img'" :src="final" v-bind="$attrs" v-on="$listeners" />
  <svg-icon v-else size="os" :name="src" class="base-btn__icon" />
</template>
<!--  <img v-if="type==='svg'" :src="final" v-bind="$attrs" v-on="$listeners" />-->

<script>
/**
 * Component for async fetching and resolving by webpack images in 'assets/images', because by default it can't
 */
export default {
  name: 'AssetImg',
  props: {
    src: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'img',
    },
  },
  data() {
    return {
      final: null,
    };
  },
  watch: {
    src: async function (val) {
      if (val && this.type === 'img') await this.fetch(val);
    },
  },
  async beforeMount() {
    // if (this.src) await this.fetch(this.src);
  },
  methods: {
    async fetch(img) {
      if (this.type === 'img') this.final = await require(`@/assets/images/${img}`);
    },
  },
};
</script>
