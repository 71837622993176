<template>
  <div class="services">
    <page-title class="service-main-title" text="Создание VPS сервера" />
    <h4 class="service-mini-title">Выберите тариф</h4>

    <transition-group class="service-container-content" name="slide-fade">
      <service-card
        v-for="item in datasetForTariffContainer"
        :ref="item.id"
        :key="item.id"
        class="service-container-content__card"
        :config="item"
        @selected="getTariff"
      >
      </service-card>
    </transition-group>

    <h4 class="service-mini-title">Дополнительно</h4>
    <transition name="slide-fade">
      <div v-if="cache.config">
        <div class="service-container-additions">
          <slider-block
            :config="{
              title: 'Публичные IPv4-адреса',
              label: 'Ipv4',
              cost: '100',
            }"
            :slider-config="{
              min: 1,
              max: 15,
            }"
            @sliderValue="getSliderValueIPv"
          ></slider-block>
          <slider-block
            class="service-slider"
            :config="{ title: 'VPS Бэкапы', cost: '1200', label: '' }"
            :slider-config="{
              min: 0,
              max: 5,
            }"
            @sliderValue="getSliderValueBackup"
          ></slider-block>
          <div class="standart-input-block service-input-block">
            <span>Операционная система</span>
            <input v-model="OC" readonly />
          </div>
          <div class="standart-input-block service-input-block">
            <span>Лицензия Plesk</span>
            <selector
              :list="paramsForSelectors[0]"
              class="service-input-block__select"
              @value="addPlesk"
            ></selector>
          </div>
          <div class="standart-input-block service-input-block">
            <span>CMS</span>
            <selector
              :list="paramsForSelectors[1]"
              class="service-input-block__select"
              @value="addCMS"
            ></selector>
          </div>
          <div class="standart-input-block service-input-block">
            <span>Имя домена</span>
            <input />
          </div>
          <div class="standart-input-block service-input-block">
            <span>Идентификатор</span>
            <input v-model="identification" />
          </div>
        </div>

        <h4 class="service-mini-title">Итого</h4>
        <view-banner :titles="titles" :values="valuesForView" class="service-view"></view-banner>

        <div class="service-controllers">
          <div class="service-controllers-row">
            <span class="service-text-strong">{{ cache.config.cost }} ₽</span>
            <span class="service-text">При оплате за</span>
            <selector class="service-controllers-row__select"></selector>
          </div>
          <div class="service-controllers-row">
            <main-button :description="`Оплатить ${calculateAll}`" @click.native="buyTariff" />
            <Summary text="В корзину" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const titles = [
  'Тариф',
  'Дисковое пространство',
  'Оперативная память',
  'Количество ядер',
  'Операционная система',
  'Публичные IPv4-адреса',
  'Лицензия Plesk',
  'VPS Бэкап',
  'CMS',
];

const values = [
  'l ultra',
  '56000 Mib',
  '8 gb',
  '4 cpu',
  'Windows Server',
  'address',
  'Plesk',
  'one',
  'Bitrix',
];
import getTariff from '../mixins/index.js';
import ServiceCard from '../components/ServiceCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import Selector from '@/components/Select/Select.vue';
import ViewBanner from '@/components/ViewBanner/ViewBanner.vue';
import MainButton from '@/components/Buttons/MainButton.vue';
import Summary from '@/components/Buttons/SummaryButton.vue';

export default {
  components: {
    SliderBlock,
    Selector,
    ServiceCard,
    ViewBanner,
    MainButton,
    Summary,
  },
  mixins: [getTariff],
  data() {
    return {
      OC: 'CentOs 8',
      params: [],
      titles: titles,
      values: values,
      identification: '',
      // cache: {
      //   element: undefined,
      //   config: undefined
      // },
      plesk: {},
      cms: {},
      IPv: '',
      backup: '',
    };
  },
  computed: {
    datasetForTariffContainer() {
      // console.log(this.$store.getters["GET_ALL_TARIFFS"]);
      return this.$store.getters['GET_ALL_TARIFFS'];
    },
    paramsForSelectors() {
      return this.$store.getters['GET_ADDONS_FOR_TARIFF'];
    },
    calculateAll() {
      return this.plesk.cost + this.cache.config.cost;
    },
    valuesForView() {
      const { CPU, MEMORY, RAM, cost, id, price, title, trial } = this.cache.config;
      return [
        title,
        MEMORY || 'Не выбрано',
        RAM || 'Не выбрано',
        CPU || 'Не выбрано',
        this.OC || 'Не выбрано',
        this.IPv || 'Не выбрано',
        this.plesk.value || 'Не выбрано',
        this.backup || 'Не выбрано',
        this.cms.value || 'Не выбрано',
      ];
    },
  },
  methods: {
    getAddons(config) {
      this.$store
        .dispatch('SET_ADDONS_FOR_TARIFF', config)
        .then(data => {})
        .catch(err => {
          console.log(err);
        });
    },
    addCMS(value) {
      this.cms = {
        cost: value.cost,
        value: value.v,
        k: value.k,
      };
    },
    addPlesk(value) {
      this.plesk = {
        cost: value.cost,
        value: value.v,
        k: value.k,
      };
    },
    getSliderValueBackup(value) {
      this.backup = value;
    },
    getSliderValueIPv(value) {
      this.IPv = value;
    },
    buyTariff() {
      const addons = {
        plesk: this.plesk.k,
        identityName: this.identification,
        cms: this.cms.k,
        IPv: this.IPv,
        backup: this.backup,
        config: this.cache.config,
      };
      this.$store.dispatch('BUY_TARIFF', addons);
    },
  },
};
</script>

<style lang="scss">
.services {
  width: 100%;
  display: flex;
  flex-direction: column;
  & .service {
    &-controllers {
      margin-top: 24px;
      &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:nth-child(2) {
          margin-top: 24px;
          max-width: 390px;
          justify-content: space-between;
          margin-bottom: 120px;
        }
      }
    }

    &-view {
      background: transparent;
      border: none;
      box-shadow: none;
    }
    &-slider {
      margin-bottom: 20px;
    }
    &-mini-title {
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
    }
    &-title {
      margin-top: 40px;
    }
    &-input-block {
      max-width: 735px;
      &:nth-child(1) {
        margin-top: 20px;
      }
    }
    &-container {
      &-content {
        max-width: 1090px;
        min-height: 412px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__card {
          margin-top: 20px;
        }
      }
      &-additions {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.service-text-strong {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--text);
}
.service-text {
  margin-left: 6px;
  margin-right: 6px;
  color: var(--text);
}

.active-service-card {
  background: #0f68e4;
  color: #ffffff;
}

@media (max-width: 480px) {
  .configurator-block-slider {
    width: 250px;
  }
  .services .service-controllers-row {
    flex-direction: column;
    align-items: flex-start;
    & .summary-btn {
      margin-top: 12px;
    }
  }

  .selector-input {
    min-width: 240px;
  }
  .service-text {
    margin: 0px;
  }
  .vue-slide-bar {
    width: 245px;
  }
}
</style>
