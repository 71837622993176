import { GETTERS } from './getters.js';
import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import moduleBitrixOrder from './modules/order';

const moduleBitrix = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
  modules: {
    moduleBitrixOrder,
  },
};

export default moduleBitrix;
