<template>
  <div class="select">
    <div class="selector-wrapper">
      <input v-model="value" class="selector-input" readonly @click="visibility" />
    </div>
    <div class="selector-wrapper__arrow" :class="{ rotate: visible }">
      <arrow></arrow>
    </div>
    <div v-show="visible" class="select-tooltip">
      <span
        v-for="(item, index) in list"
        :key="index"
        class="select-tooltip__item standart-text"
        @click="visibility(item.k)"
      >
        {{ item.v }}
      </span>
    </div>
  </div>
</template>

<script>
import arrow from '@/components/Select/arrow.vue';
export default {
  name: 'CustomSelect',
  components: {
    arrow,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      value: 'Выберите значение',
      visible: false,
    };
  },
  mounted() {
    console.warn('DEPRECATED');
  },
  methods: {
    visibility(k) {
      let element = this.list.find(item => item.k == k);
      if (!element) {
        this.visible = !this.visible;
      } else {
        this.visible = !this.visible;
        this.value = element.v;
        // console.log(element);
        this.$emit('value', element);
      }
    },
  },
};
</script>

<style lang="scss">
.select {
  position: relative;
  &-tooltip {
    z-index: 40;
    position: absolute;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 220px;
    box-sizing: border-box;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow-y: auto;
    background-color: var(--background-theme);
    border: 1px solid var(--login-border-input);
    border-radius: 6px;
    & span {
      cursor: pointer;
      color: var(--title);
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      opacity: 1;
      transition: background-color 1s ease;
      &:hover {
        opacity: 0.5;
        background-color: var(--main-theme);
      }
    }
  }
}
.selector {
  &-input {
    min-width: 284px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    cursor: pointer;
  }
  &-wrapper {
    position: relative;
    // width: 100%;
    & input {
      width: 100%;
    }
    &__arrow {
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 20px;
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }
}
.rotate {
  transform: rotate(360deg);
}
</style>
