<template>
  <component :is="tag" :to="to" :class="styles" v-on="$listeners">
    <span class="btn__description">
      <slot>{{ description }}</slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'MainButton',

  props: {
    inline: {
      type: String,
      default: '',
    },
    typeName: {
      type: String,
      default: 'classic',
    },
    description: {
      type: String,
      default: '',
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    styles() {
      return `btn btn_type_${this.typeName}`;
    },
    handler() {
      return this.clickCallback;
    },
    inlineStyles() {
      return this.inline;
    },
    tag() {
      return this.to ? 'router-link' : 'button';
    },
  },
  mounted() {
    console.warn('DEPRECATED');
  },
};
</script>

<style lang="scss">
// $blue: #0f68e4;
// $white: #ffffff;
// $green: #4cbf86;
.btn {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 112px;
  text-decoration: none;
  width: auto;
  height: 50px;
  padding: 15px 24px 15px 24px;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  border: 0;

  &:focus {
    outline: none;
  }

  &_type {
    &_classic {
      background-color: var(--main-btn-color);
      span {
        color: var(--text-btn-color);
      }
    }

    &_transparent {
      width: 110px;
      border: 1px solid var(--main-btn-color);
      background-color: var(--text-btn-color);
      span {
        color: var(--main-btn-color);
      }
    }
    &_for-header {
      width: 116px;
      height: 40px;
      background-color: var(--success-btn-color);
      span {
        color: var(--text-btn-color);
      }
    }
  }

  &__description {
    // font-family: Graphik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
