export default class ListComponent {
  constructor({ value, options, readonly, required, k }) {
    this.value = value;
    this.k = k;
    this.optionsRaw = options;
    this.options = this.getMappedOptions(options);
    this.disabled = readonly && readonly === 'yes';
    this.required = required && required === 'yes';
  }

  get internalValue() {
    return this.options.find(i => i.k === this.value) || '';
  }

  set internalValue(obj) {
    this.value = obj.k;
  }

  getMappedOptions(list) {
    // console.log(list);
    return list.map(opt => {
      const obj = {
        k: opt[this.k],
        v: opt.name,
        image: opt.image || '',
      };
      if (this.k === 'paymethod' && !opt[this.k]) obj.k = 'fromsubaccount';
      return obj;
    });
  }
}
