<template>
  <div class="sidebar-nav">
    <div v-if="title || $slots.title" class="sidebar-nav__title standart-text">
      <slot name="title">{{ title }}</slot>
    </div>
    <div v-if="list.length" class="sidebar-nav__list">
      <router-link
        v-for="item in list"
        :key="item.key"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        :to="item.to"
      >
        <a
          :href="href"
          class="sidebar-nav__link standart-text"
          :class="{ ['sidebar-nav__link--active']: isActive || isExactActive }"
          @click="isExactActive ? $emit('exact-click', route) : navigate"
        >
          <span class="sidebar-nav__link-text">
            {{ $t(`nav.${item.key}`) }}
          </span>
          <i v-if="item.icon" class="sidebar-nav__link-icon" :class="item.icon" />
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarNav',
  props: {
    title: {
      type: String,
      default: null,
    },
    list: {
      type: Array,
      default: () => ({}),
      validator: list => {
        return list.every(item => !!item.to && !!item.key);
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.sidebar-nav {
  margin-bottom: 54px;
  &__list {
    position: relative;
  }
  &__title {
    color: var(--sidebar-text);
    margin-bottom: 1em;
    font-weight: 700;
    transition: color $anim-base-duration ease;
  }
  &__link {
    flexy(space-between, center);
    padding: 6px 24px;
    margin: 0 -24px;
    color: var(--sidebar-text);
    text-decoration: none;
    transition: all $anim-base-duration ease;

    &:hover {
      background: rgba(#fff, 0.1);
    }

    &--active,
    &--active:hover {
      background: rgba(#fff, 0.2);
    }

    & + & {
      margin-top: 8px;
    }

    &-text,
    &-icon {
      opacity: 0.6;
      pointer-events: none;
    }
    &-icon {
      font-size: 20px;
      margin-right: -0.25em;
    }
  }
}
</style>
