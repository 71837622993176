import SliderComponent from '@/models/base/components/slider';
import CheckboxComponent from '@/models/base/components/checkbox';
import SelectComponent from '@/models/base/components/select';
import TextComponent from '@/models/base/components/text';
import MsgComponent from '@/models/base/components/msg';
import RadioComponent from '@/models/base/components/radio';
import MultipleComponent from './components/multiple';
import ListComponent from './components/list';
import LinkComponent from '@/models/base/components/link';
import AmountComponent from './components/amount';
import PaymethodListComponent from './components/paymethodList';

function getComponentByType(type) {
  const components = {
    slider: SliderComponent,
    checkbox: CheckboxComponent,
    select: SelectComponent,
    multiple: MultipleComponent,
    text: TextComponent,
    textarea: TextComponent,
    msg: MsgComponent,
    amount: AmountComponent,
    radio: RadioComponent,
    hidden: TextComponent,
    list: ListComponent,
    data: TextComponent,
    htmldata: TextComponent,
    paymethodlist: PaymethodListComponent,
    link: LinkComponent,
  };
  if (components[type]) return components[type];
  else throw new Error(`Unknown component type: "${type}"`);
}

export default class BaseAddon {
  constructor(args) {
    let component = getComponentByType(args.type);
    this.label = args.label ? this.parseLabel(args.label) : '';
    this.hint = args.hint || '';
    this.type = args.type;
    this.period = args.period;
    this.intname = args.intname || args.name;
    this.name = args.name;
    this.k = args.k || null;
    this.setvalues = args.setvalues || '';
    if (args.prefixselect) this.prefixselect = args.prefixselect;
    if (args.propwidth) this.propwidth = args.propwidth;
    if (args.check) this.check = args.check;
    this.config = new component(args);
  }

  get formDataName() {
    return this.config.formDataName ? this.config.formDataName : this.k ? this.k : this.name;
  }

  get formDataNames() {
    return this.config.formDataNames || null;
  }

  get clicked_button() {
    return this.config.clicked_button || null;
  }

  parseLabel(value) {
    let internal = value;
    if (internal.includes('RUB')) internal = internal.replace('RUB', '₽');
    if (internal.includes('<')) internal = this.sanitize(internal);
    return internal;
  }

  sanitize(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    let res = tmp.textContent || tmp.innerText || '';
    res.replace('\u200B', ''); // zero width space
    res = res.trim();
    return res;
  }
}
