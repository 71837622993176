<template>
  <div class="page">
    <router-view :loading="isLoading"></router-view>
  </div>
</template>

<script>
import { UserProfile } from '@/models/BillMgr/UserProfile';
export default {
  name: 'Profile',
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
  },
  watch: {
    profile: function (val, old) {
      if (!(old instanceof UserProfile)) {
        this.fetchUserParams();
      }
    },
  },
  mounted() {
    if (this.profile instanceof UserProfile && !this.profile.hasUsrParams) {
      this.fetchUserParams();
    } else this.isLoading = false;
  },
  methods: {
    fetchUserParams() {
      if (this.access['customer.usrparam']) {
        this.$store
          .dispatch('moduleProfile/fetchUserParams')
          .then(() => {
            this.$store.dispatch('moduleProfile/setUserInfo', this.profile.id);
          })
          .then(() => this.$store.dispatch('moduleProfile/setPartnersLink'))
          .then(() => this.$store.dispatch('moduleProfile/getReferalsLink'))
          .finally((this.isLoading = false));
      } else {
        this.$store
          .dispatch('moduleProfile/setUserInfo', this.profile.id)
          .finally((this.isLoading = false));
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
