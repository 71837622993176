import BillMgrPricelist from './BillMgrPricelist';

export default class ZmailPricelist extends BillMgrPricelist {
  constructor(args) {
    super(args);
  }

  get specs() {
    if (!this.info || !this.info.customfields) return {};
  }
}
