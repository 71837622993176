<template>
  <div class="main-layout__breadcrumbs-path">
    <div class="main-layout__breadcrumbs-item">
      <div v-for="(pathitem, i) in computedPath" :key="i">
        <!--...Home...-->
        <div
          v-if="
            i + 2 < computedPath.length && pathitem.meta.isLink && pathitem.name === 'Main-Layout'
          "
          class="main-layout__breadcrumbs-path-item"
        >
          <plain-button
            icon="home-icon"
            color="primary"
            size="small"
            class="main-layout__breadcrumbs-path-home"
          />
          <plain-button
            :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
            color="primary"
          >
            {{ pathItemLink(pathitem) }}
          </plain-button>
          <plain-button icon="right-icon" size="minimal" />
        </div>
        <!--...Domain...-->
        <div
          v-if="
            $route.path === '/domains' ||
            $route.path === '/domains/order' ||
            $route.path === '/domains/contact' ||
            $route.path === '/domains/'
          "
        >
          <div
            v-if="
              i + 3 < computedPath.length && pathitem.meta.isLink && pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div v-else-if="!pathitem.meta.isLink" class="main-layout__breadcrumbs-path-item">
            {{ pathItem(pathitem) }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
        </div>
        <!--...Stack...-->
        <div v-else-if="$route.path.includes('stack')">
          <div
            v-if="
              i + 2 < computedPath.length &&
              pathitem.meta.isLink &&
              ($route.path === '/stack' || $route.path === '/stack/') &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div
            v-else-if="
              i + 2 < computedPath.length &&
              pathitem.meta.isLink &&
              $route.path !== '/stack' &&
              $route.path !== '/stack/' &&
              pathitem.name !== 'Main-Layout' &&
              pathitem.name !== 'Balancer'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div
            v-else-if="
              i + 2 < computedPath.length &&
              pathitem.meta.isLink &&
              $route.path.includes('server/') &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div
            v-else-if="
              i + 1 < computedPath.length &&
              pathitem.meta.isLink &&
              ($route.path.includes('network') || $route.path.includes('service')) &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{
                name: $route.path.includes('/network/private/')
                  ? 'viewStackPrivate'
                  : $route.path.includes('/network/public/')
                  ? 'viewStackPublic'
                  : $route.path.includes('/service/balancer/')
                  ? 'viewBalancer'
                  : $route.path.includes('/network/router/')
                  ? 'viewStackRouter'
                  : $route.path.includes('/network/firewall/')
                  ? 'viewStackFirewall'
                  : pathitem.link
                  ? pathitem.redirect
                  : pathitem.redirect,
              }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <!--Stack: Rule -->
          <div v-else-if="!pathitem.meta.isLink && pathitem.name === 'Rule'">
            <div v-if="inProgress" class="main-layout__breadcrumbs-path-load">
              <plain-button
                :to="{
                  name: $route.path.includes('/service/balancer/')
                    ? 'containerRuleStart'
                    : pathitem.link
                    ? pathitem.redirect
                    : pathitem.redirect,
                }"
                color="primary"
              >
                {{ pathItemLink(pathitem) }}
              </plain-button>
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
              {{ pathitem.meta.name }}
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
            </div>
            <div v-else-if="!inProgress" class="main-layout__breadcrumbs-path-item">
              <plain-button
                :to="{
                  name: $route.path.includes('/service/balancer/')
                    ? 'containerRuleStart'
                    : pathitem.link
                    ? pathitem.redirect
                    : pathitem.redirect,
                }"
                color="primary"
              >
                {{ pathItemLink(pathitem) }}
              </plain-button>
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
              {{ pathitem.meta.name }}
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
            </div>
          </div>
          <div
            v-else-if="
              i + 1 < computedPath.length &&
              pathitem.meta.isLink &&
              ($route.path.includes('stat') || $route.path.includes('quotas')) &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <!--Stack: Servers/ Disk/ Private/ Firewall/ Router/ Public-->
          <div
            v-else-if="
              !pathitem.meta.isLink &&
              (pathitem.name === 'Server' ||
                pathitem.name === 'Disk' ||
                pathitem.name === 'FirewallView' ||
                pathitem.name === 'RouterView' ||
                pathitem.name === 'PortPublicView' ||
                pathitem.name === 'SubnetPublicView' ||
                pathitem.name === 'PortView' ||
                pathitem.name === 'SubnetView' ||
                pathitem.name === 'BalancerView' ||
                pathitem.name === 'containerRuleStart' ||
                pathitem.name === 'RuleServers' ||
                pathitem.name === 'RuleAlgorithm' ||
                pathitem.name === 'RuleCheck' ||
                pathitem.name === 'RuleConnect' ||
                pathitem.name === 'RuleHeaders' ||
                pathitem.name === 'PoolView')
            "
          >
            <div v-if="inProgress" class="main-layout__breadcrumbs-path-load">
              {{ pathItem(pathitem) }}
              <plain-button v-if="i + 2 !== computedPath.length" icon="right-icon" size="minimal" />
              {{ pathitem.meta.name }}
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
            </div>
            <div v-else-if="!inProgress" class="main-layout__breadcrumbs-path-item">
              {{ pathItem(pathitem) }}
              <plain-button v-if="i + 2 !== computedPath.length" icon="right-icon" size="minimal" />
              {{ pathitem.meta.name }}
              <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
            </div>
          </div>
          <!--Stack: Other -->
          <div v-else-if="!pathitem.meta.isLink" class="main-layout__breadcrumbs-path-item">
            {{ pathItem(pathitem) }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
        </div>
        <!--...Support...-->
        <div v-else-if="$route.path.includes('support')">
          <div
            v-if="
              i + 3 < computedPath.length &&
              pathitem.meta.isLink &&
              $route.path !== '/support/tickets/' &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div
            v-if="
              i + 1 < computedPath.length &&
              pathitem.meta.isLink &&
              ($route.path.includes('/support/tickets/') ||
                $route.path.includes('/support/archive/')) &&
              pathitem.name !== 'Main-Layout'
            "
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div
            v-else-if="
              !pathitem.meta.isLink &&
              ($route.path.includes('/support/tickets/') ||
                $route.path.includes('/support/archive/'))
            "
            class="main-layout__breadcrumbs-path-item"
          >
            {{ pathItem(pathitem) }}
            <plain-button icon="right-icon" size="minimal" />
            {{ ticketId }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
          <div v-else-if="!pathitem.meta.isLink" class="main-layout__breadcrumbs-path-item">
            {{ pathItem(pathitem) }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
        </div>
        <!--...All...-->
        <div v-else-if="$route.path === '/all' || $route.name === 'PartnersAstra'">
          <div
            v-if="i + 1 < computedPath.length && pathitem.meta.isLink"
            class="main-layout__breadcrumbs-path-item"
          >
            <plain-button
              icon="home-icon"
              color="primary"
              size="small"
              class="main-layout__breadcrumbs-path-home"
            />
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div v-else-if="!pathitem.meta.isLink" class="main-layout__breadcrumbs-path-item">
            {{ pathItem(pathitem) }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
        </div>
        <!--...Other...-->
        <div v-else>
          <div
            v-if="
              i + 2 < computedPath.length && pathitem.meta.isLink && pathitem.name !== 'Main-Layout'
            "
            class="main-layout__breadcrumbs-path-item"
          >
            <plain-button
              :to="{ name: pathitem.link ? pathitem.redirect : pathitem.redirect }"
              color="primary"
            >
              {{ pathItemLink(pathitem) }}
            </plain-button>
            <plain-button icon="right-icon" size="minimal" />
          </div>
          <div v-else-if="!pathitem.meta.isLink" class="main-layout__breadcrumbs-path-item">
            <plain-button
              v-if="computedPath.length === 2"
              disabled="true"
              icon="home-icon"
              color="primary"
              size="small"
              class="main-layout__breadcrumbs-path-home"
            />
            {{ pathItem(pathitem) }}
            <plain-button v-if="i + 1 !== computedPath.length" icon="right-icon" size="minimal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

export default {
  name: 'BreadCrumbs',
  data() {
    return {
      inProgress: true,
      namePath: 'Загрузка...',
      namePathRule: 'Загрузка...',
      path: '',
      computedPath: '',
    };
  },

  computed: {
    currentRouterPath() {
      return this.$route;
    },
    ticketId() {
      let supportTicketId = this.path.split('/');
      return supportTicketId[3] ? supportTicketId[3] : 'null';
    },
    current() {
      return this.$store.getters['moduleDomains/moduleDomainsDomains/GET_CURRENT']();
    },
    currentDomain() {
      return this.current.title;
    },
    currentDomainDns() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.current;
    },
  },
  watch: {
    currentRouterPath: {
      handler: function (event) {
        this.path = this.$route.path;
        const splittedPath = this.$route.path.split('/');
        const refactoringPath = [];

        this.currentRouterPath.matched.forEach((x, i) => {
          refactoringPath.push({
            i: i,
            meta: x.meta,
            // link: x.name !== 'tariffStack',
            link: x.name,
            id:
              x.path && !splittedPath.includes(x.path.split('/').at(-1))
                ? splittedPath[i]
                : splittedPath[i],
            name: x.name,
            path:
              x.path && splittedPath.includes(x.path.split('/').at(-1))
                ? splittedPath[i]
                : splittedPath[i],
            redirect: x.redirect ? x.redirect.name : '',
          });
        });
        let newPath = [];
        newPath = refactoringPath;
        // newPath = refactoringPath.filter((item, index) => {
        //   return item.redirect &&
        //     this.currentRouterPath.matched[index].path.split('/').at(-1) === item.path
        //     ? item.redirect !== refactoringPath[index + 1].name
        //     : item;
        // });
        const removeLink = [
          // 'NetworkType',
          // 'containerServer',
          // 'viewStack',
          // 'containerDisk',
          // 'containerImage',
          // 'containerSnapshot',
          // 'containerAccess',
          // 'Firewall',
          'rentDetail',
          'BillingMain',
          // 'SupportMain',
          'ProfileMain',
          // 'BillingHistory',
        ];
        this.computedPath = newPath
          .filter(x => x.link)
          .filter(x => !removeLink.includes(x.name))
          .filter(x => x.meta.isShow);
      },
      immediate: true,
    },
  },
  methods: {
    getNameRule() {
      let nameId = this.path.split('/');
      if (
        this.$store.state.moduleStack.pools &&
        this.$store.state.moduleStack.pools.find(x => x.id === nameId[7]) &&
        !!this.$store.state.moduleStack.pools.find(x => x.id === nameId[7]).name
      ) {
        this.inProgress = false;
        return (this.namePathRule = this.$store.state.moduleStack.pools.find(
          x => x.id === nameId[7]
        ).name);
      } else {
        return this.namePathRule;
      }
    },
    getName() {
      let nameId = this.path.split('/');
      if (
        this.$store.state.moduleStack.volumes &&
        this.$store.state.moduleStack.volumes.find(x => x.id === nameId[4]) &&
        !!this.$store.state.moduleStack.volumes.find(x => x.id === nameId[4]).name
      ) {
        this.inProgress = false;
        return (this.namePath = this.$store.state.moduleStack.volumes.find(
          disk => disk.id === nameId[4]
        ).name);
      } else if (
        this.$store.state.moduleStack.firewalls &&
        this.$store.state.moduleStack.firewalls.find(x => x.id === nameId[5]) &&
        !!this.$store.state.moduleStack.firewalls.find(x => x.id === nameId[5]).name
      ) {
        this.inProgress = false;
        // console.log(nameId);
        return (this.namePath = this.$store.state.moduleStack.firewalls.find(
          x => x.id === nameId[5]
        ).name);
      } else if (
        this.$store.state.moduleStack.balancers &&
        this.$store.state.moduleStack.balancers.find(x => x.id === nameId[5]) &&
        !!this.$store.state.moduleStack.balancers.find(x => x.id === nameId[5]).name
      ) {
        this.inProgress = false;
        // console.log(nameId);
        return (this.namePath = this.$store.state.moduleStack.balancers.find(
          x => x.id === nameId[5]
        ).name);
      } else if (
        this.$store.state.moduleStack.routers &&
        this.$store.state.moduleStack.routers.find(x => x.id === nameId[5]) &&
        !!this.$store.state.moduleStack.routers.find(x => x.id === nameId[5]).name
      ) {
        this.inProgress = false;
        // console.log(nameId);
        return (this.namePath = this.$store.state.moduleStack.routers.find(
          x => x.id === nameId[5]
        ).name);
      } else if (
        this.$store.state.moduleStack.subnets &&
        this.$store.state.moduleStack.subnets.find(x => x.id === nameId[5]) &&
        !!this.$store.state.moduleStack.subnets.find(x => x.id === nameId[5]).name
      ) {
        this.inProgress = false;
        // console.log(nameId);
        return (this.namePath = this.$store.state.moduleStack.subnets.find(
          x => x.id === nameId[5]
        ).name);
      } else if (
        this.$store.state.moduleStack.networks &&
        this.$store.state.moduleStack.networks.find(x => x.id === nameId[5]) &&
        !!this.$store.state.moduleStack.networks.find(x => x.id === nameId[5]).name
      ) {
        this.inProgress = false;
        // console.log(nameId);
        return (this.namePath = this.$store.state.moduleStack.networks.find(
          x => x.id === nameId[5]
        ).name);
      } else if (
        this.$store.state.moduleStack.servers &&
        this.$store.state.moduleStack.servers.find(x => x.id === nameId[4]) &&
        !!this.$store.state.moduleStack.servers.find(x => x.id === nameId[4]).name
      ) {
        this.inProgress = false;
        return (this.namePath = this.$store.state.moduleStack.servers.find(
          x => x.id === nameId[4]
        ).name);
      } else {
        return this.namePath;
      }
    },
    pathItemLink(pathLink) {
      if (this.$route.path.includes('stack')) {
        return pathLink.name === 'Rule'
          ? this.getName()
          : pathLink.name === 'tariffStack'
          ? pathLink.meta.name + pathLink.id
          : pathLink.meta.name;
      } else
        return pathLink.meta.name
          ? pathLink.meta.name
          : pathLink.id && pathLink.name.includes('Detail')
          ? pathLink.id
          : pathLink.name;
    },
    pathItem(path) {
      if (this.$route.path.includes('domains')) {
        return path.meta.name
          ? path.meta.name
          : path.id && path.name === 'container'
          ? path.id
          : path.id && path.name === 'dnshostDomain'
          ? this.currentDomainDns
          : path.id && path.name === 'domainDetail'
          ? this.currentDomain
          : path.name;
      } else if (this.$route.path.includes('stack')) {
        return path.name === 'SubnetView' ||
          path.name === 'PortView' ||
          path.name === 'containerRuleStart' ||
          path.name === 'BalancerView' ||
          path.name === 'PortPublicView' ||
          path.name === 'SubnetPublicView' ||
          path.name === 'RouterView' ||
          path.name === 'FirewallView' ||
          path.name === 'Server' ||
          path.name === 'containerRuleStart' ||
          path.name === 'BalancerView' ||
          path.name === 'Disk' ||
          path.name === 'Rule'
          ? this.getName()
          : path.name === 'RuleServers' ||
            path.name === 'RuleAlgorithm' ||
            path.name === 'RuleCheck' ||
            path.name === 'RuleConnect' ||
            path.name === 'RuleHeaders' ||
            path.name === 'PoolView'
          ? this.getNameRule()
          : path.meta.name
          ? path.meta.name
          : path.id
          ? path.id
          : path.name;
      } else {
        return path.name.includes('CurrentTicket') || path.name.includes('ArchivTicket')
          ? path.meta.name
          : path.meta.name
          ? path.meta.name
          : path.id
          ? path.id
          : path.name;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.main-layout {
  &__breadcrumbs {

    width: 100%;
    min-width: 100%;
    padding: 1.5rem 1rem;

  +breakpoint(sm-and-up) {
    padding: 0 1.25rem 0;
  }
  +breakpoint(md-and-up) {
    padding: 0 $gutter-outside 0;
  }
    &-path {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      box-sizing: border-box;
      flex-direction: row;
      min-height: 100%;
      padding: 0.75rem 1rem 0.5rem;
      //overflow-x: auto;
      display: flex;

      &-load{
        color: #878b99;
        padding: 0.25rem;
        margin: -0.25rem;
      }
      &-item{
        display: flex ;
        padding: 0.25rem;
        margin: -0.25rem;
      }
      &-home{
        display: flex;
        align-items: baseline;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        margin: -0.25rem;
      }

    +breakpoint(sm-and-up) {
      padding: 0.75rem 1.25rem 0;
    }
    +breakpoint(md-and-up) {
      padding: 0.75rem $gutter-outside 0;
    }
    }
    &-item{
      margin: 0 auto;
      width: 85rem;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
