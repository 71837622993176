/**
 * @typedef IspExpenseCondition
 * @type {Object}
 * @prop {string} locale_name
 * @prop {string} value
 */

import { isNaN } from 'lodash';
import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { format, toDate } from 'date-fns';

/**
 * Class for isp tool
 */
export class IspExpense {
  /**
   * @constructor
   * @param {Object} args
   * @param {string} args.intname
   * @param {string} args.locale_name
   * @param {string=} args.type
   * @param {Array<IspExpenseCondition>=} args.show
   * @param {Array<IspExpenseCondition>=} args.hide
   */
  constructor(args) {
    if (this.isValid(args)) this._init(args);
    else throw new Error(`Tool's data for '${args.locale_name}' is not valid`);
  }

  isValid(args) {
    this.isValid = !!(args.intname && args.locale_name);
    return this.isValid;
  }

  _init(args) {
    this.itemName = args.intname;
    this.paymentId = args.id;
    this.itemId = args.item;
    this.id = args.main_item;
    this.measure = args.measure;
    this.notpayd = args.notpayd;
    this.payments = args.payments;

    // this.date =
    this.name = args.locale_name;
    this.time = args.realdate_locale_time;
    this.tax = args.taxamount;
    // this.date = parseDateRu(args.realdate_l);
    this.date = toDate(parseDateRu(args.realdate_l) - 1);
    this.formatDate = format(this.date, 'dd.MM.yyyy');
    this.sum = parseCost(args.amount);
    this.value = parseCost(args.value);
    if (args.type) this.type = args.type;
    // if (args.show) this.show = this._transformConditions(args.show);
    // if (args.hide) this.hide = this._transformConditions(args.hide);
  }

  /**
   * Check if show tool for given instance
   * @param {object} instance - instance for check
   * @returns boolean
   * @public
   */
  // checkIfShowTool(instance) {
  //   return !this.show || this.show.some(cond => this.checkCondition(cond, instance));
  // }
  /**
   * Check if hide tool for given instance
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  // checkIfHideTool(instance) {
  //   return this.hide && this.hide.some(cond => this.checkCondition(cond, instance));
  // }
  /**
   * Check if is show and isn't hide
   * @param {Object} instance - instance for check
   * @public
   */
  // isEnable(instance) {
  //   return this.checkIfShowTool(instance) && !this.checkIfHideTool(instance);
  // }

  /**
   * Transform conditions list for our app
   * @param {Array<IspToolCondition>} list
   */
  // _transformConditions(list) {
  //   return list.map(i => ({
  //     name: i.name,
  //     value: i.name === 'status' && !isNaN(parseInt(i.value)) ? parseInt(i.value) : i.value,
  //   }));
  // }

  /**
   * Check single condition by it's type
   * @param {IspToolCondition} cond
   * @param {Object} instance
   * @private
   */
  // checkCondition(cond, instance) {
  //   return instance[cond.name] == cond.value;
  // }
}

/**
 * Abstract class for collection of billmanager's action
 */
export class IspExpenses {
  constructor(args) {
    this._required = [];
    this._type = Object;
    if (this.isValid(args)) this._init(args);
    else throw new Error('Missed required actions in IspExpenses');
  }

  /**
   * Check if all required data is passed
   */
  isValid(args) {
    this.isValid = this._required.every(prop => !!args[prop]);
    return this.isValid;
  }

  /**
   * Private initial method
   * @private
   */
  _init(args) {
    for (let prop in args) {
      this[prop] = new IspExpense(args[prop]);
    }
  }

  /**
   * Check if show tool for given instance
   * @param {string} tool - tool's key
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfShowTool(tool, instance) {
    return instance instanceof this._type ? this[tool].checkIfShowTool(instance) : false;
  }
  /**
   * Check if hide tool for given instance
   * @param {string} tool - tool's key
   * @param {Object} instance - instance for check
   * @returns boolean
   * @public
   */
  checkIfHideTool(tool, instance) {
    return instance instanceof this._type ? this[tool].checkIfHideTool(instance) : true;
  }
}
