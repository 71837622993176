import { render, staticRenderFns } from "./BaseEmpty.vue?vue&type=template&id=509d0c9d&scoped=true"
import script from "./BaseEmpty.vue?vue&type=script&lang=js"
export * from "./BaseEmpty.vue?vue&type=script&lang=js"
import style0 from "./BaseEmpty.vue?vue&type=style&index=0&id=509d0c9d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509d0c9d",
  null
  
)

export default component.exports