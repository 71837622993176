<template>
  <div class="login-page">
    <div class="login-page__content">
      <main-logo class="login-page__logo" />
      <div v-if="topNav.length" class="login-page__nav-top">
        <router-link
          v-for="item in topNav"
          :key="item.key"
          :to="item.to"
          class="login-page__nav-link standart-text"
          exact-active-class="active"
        >
          {{ $t(`nav.${item.key}`) }}
        </router-link>
      </div>
      <div v-else class="login-page__title standart-heading">
        {{ $t(`titles.${$route.name}`) }}
      </div>
      <transition name="slide-fade-auth" mode="out-in">
        <main-card class="login-page__card">
          <router-view></router-view>
        </main-card>
      </transition>
      <div class="login-page__nav-bottom">
        <router-link
          v-for="item in bottomNav"
          :key="item.key"
          :to="item.to"
          class="login-page__nav-link standart-text"
        >
          {{ $t(`nav.${item.key}`) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/components/MainLogo/MainLogo';
import MainCard from '@/components/MainCard/MainCard';
import { IS_PROD, urlToProject } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'LoginPage',
  components: {
    MainLogo,
    MainCard,
  },

  data() {
    return {
      topNavRaw: [
        {
          key: 'signin',
          to: { name: 'Auth' },
          show: ['Auth', 'Reg'],
        },
        {
          key: 'signup',
          to: { name: 'Reg' },
          show: ['Auth', 'Reg'],
        },
      ],
      topNavRawAstra: [
        {
          key: 'signin',
          to: { name: 'Auth' },
          show: ['Auth', 'Reg'],
        },
      ],
      bottomNavRaw: [
        {
          key: 'hasacc',
          to: { name: 'Auth' },
          show: ['Reg'],
        },
        {
          key: 'forgotpass',
          to: { name: 'Recovery' },
          show: ['Auth', 'AuthSupport'],
        },
        {
          key: 'remempass',
          to: { name: 'Auth' },
          show: ['Recovery', 'RecoveryLogin'],
        },
        {
          key: 'login',
          to: { name: 'Auth' },
          show: ['Activation', 'ChangePassword'],
        },
        {
          key: 'forgotmail',
          to: { name: 'AuthSupport' },
          show: ['Recovery', 'RecoveryLogin'],
        },
        {
          key: 'rememall',
          to: { name: 'Auth' },
          show: ['AuthSupport'],
        },
      ],
    };
  },

  computed: {
    topNav() {
      return window.location.href.includes('astracloud')
        ? this.getNav(this.topNavRawAstra)
        : this.getNav(this.topNavRaw);
    },
    bottomNav() {
      return this.getNav(this.bottomNavRaw);
    },
    // providerAstraCloud() {
    //   return this.$store.state.moduleProviders.current === '6';
    // },
    // provider() {
    //   return IS_PROD
    //     ? urlToProject[window.location.origin]
    //     : this.$store.getters['moduleProviders/provider'];
    // return this.$store.getters['moduleProviders/current'];
    // },
  },
  mounted() {
    localStorage.removeItem('token');
    this.$store.dispatch('moduleProfile/reset');
  },
  methods: {
    getNav(nav) {
      return nav.filter(i => i.show.includes(this.$route.name));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "signin": "Вход в личный кабинет",
        "signup": "Регистрация",
        "hasacc": "Уже есть аккаунт?",
        "forgotpass": "Забыли пароль?",
        "forgotmail": "Я не помню свою почту",
        "remempass": "Я вспомнил пароль",
        "rememmail": "Я вспомнил почту",
        "rememall": "Я вспомнил почту и пароль",
        "login": "Войти"
      },
      "titles": {
        "Recovery": "Забыли пароль?",
        "RecoveryLogin": "Восстановление пароля",
        "AuthSupport": "Забыли почту?",
        "Activation": "Подтверждение регистрации",
        "ChangePassword": "Восстановление пароля"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.login-page {
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 1rem;
  background: var(--login-background);
  flexy(center, center);
  //background: url('~@/assets/images/2024.jpg') no-repeat;
  //background-position: center;
  //background-size: cover;

  //height: 400px;
  //width: 400px;

  &__content {
    flex: 0 1 402px;
  }

  &__logo {
    max-width: 137px;
    margin: 0 auto 2.5rem;
  }

  &__card {
    min-height: min-content;
  }

  &__nav {
    &-link {
      color: var(--login-text);
      text-decoration: none;

      &.active {
        font-weight: $font-weight-bold;
      }
    }
    &-top {
      flexy(center, center);
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-bottom: 2.5rem;
      }

      & ^[0]__nav-link {
        margin: 0 0.5rem;

        +breakpoint(sm-and-up) {
          margin: 0 1.25rem;
        }
      }
    }
    &-bottom {
      flexy(flex-start, center, nowrap, column);
      margin-top: 0.75rem;

      & ^[0]__nav-link {
        margin: 0.75rem;
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--login-text);

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }
}
.card-entry {
  margin-top: 40px;
  max-width: 402px;
  min-width: 360px;
  // box-sizing: border-box;
  height: auto;
  // background: #ffffff;
  // border: 1px solid #dfe1eb;
  // box-sizing: border-box;
  // box-shadow: 0px 9px 45px -20px rgba(14, 42, 87, 0.407015);
  // border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.card-entry-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;

  span {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: var(--text);
  }
}
.card-entry-block:nth-child(1) {
  margin-top: 0px;
}

.button-wrapper.btn {
  margin: auto;
  margin-top: 40px;

  &_size_big {
    width: 192px;
  }
  &_size_small {
    width: 164px;
  }
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
//   opacity: 0;
// }
.text {
  margin-top: 8px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;

    color: var(--text);
  }
}
.text.log-text {
  text-align: left;
}
</style>
