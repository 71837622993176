<template>
  <div
    v-if="basket"
    ref="wrapper"
    tabindex="0"
    class="action-popup"
    :class="classes"
    @keyup.esc="close"
  >
    <div class="action-popup__activator" @click="toggle">
      <slot name="activator"></slot>
    </div>
    <transition :name="transition" appear>
      <div v-show="activeComputed" class="action-popup__popup" :class="popupClass">
        <slot></slot>
      </div>
    </transition>
  </div>
  <div
    v-else
    ref="wrapper"
    tabindex="0"
    class="action-popup"
    :class="classes"
    @blur.self="close"
    @keyup.esc="close"
  >
    <div class="action-popup__activator" @click="toggle">
      <slot name="activator"></slot>
    </div>
    <transition :name="transition" appear>
      <div v-show="activeComputed" class="action-popup__popup" :class="popupClass">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ActionPopup',
  props: {
    active: {
      type: Boolean,
      default: null,
    },
    openBasket: {
      type: String,
      default: 'close',
    },
    transition: {
      type: String,
      default: 'fade',
    },
    position: {
      type: String,
      default: 'default',
      validator: value => ['default', 'over'].includes(value),
    },
    popupClass: {
      type: String,
      default: '',
    },
    basket: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'medium'].includes(val),
    },
  },
  data() {
    return {
      activeInternal: this.active || false,
      // activeInternal: this.active || true,
    };
  },
  computed: {
    activeComputed() {
      return this.active === null ? this.activeInternal : this.active;
    },
    classes() {
      const prefix = 'action-popup';
      return {
        [`${prefix}--pos_${this.position}`]: !!this.position,
        [`${prefix}--size_${this.size}`]: !!this.size,
      };
    },
  },
  watch: {
    active(val) {
      // console.log(val);
      if (val && this.$refs.wrapper) this.$refs.wrapper.focus();
    },
    openBasket(val) {
      // console.log(val);
      if (val === 'close' && this.basket) this.activeInternal = false;
    },
  },
  beforeDestroy() {
    this.activeInternal = false;
  },
  methods: {
    toggle(e) {
      // console.log(e);
      if (this.activeInternal) this.close(e);
      else this.open();
    },
    open() {
      // console.log('open');
      this.activeInternal = true;
      this.$emit('opened');
      this.$refs.wrapper.focus();
      // console.log(this.$refs.wrapper);
      if (this.active !== null) {
        // console.log('notActive');
        this.$emit('update:active', this.activeInternal);
      }
    },
    close(e) {
      // console.log('close');
      if (this.activeInternal) e.stopPropagation();
      this.activeInternal = false;
      this.$emit('closed');
      if (this.active !== null) {
        this.$emit('update:active', this.activeInternal);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$ap-sizes = {
  small: {
    min: 225px,
    max: 225px,
  },
  medium: {
    min: 288px,
    max: 400px,
  },
}
.action-popup {
  position: relative;

  &:focus {
    outline: none;
  }

  &__popup {
    position: absolute;
    min-width: 225px;
    padding: 10px 0;
    border-radius: $border-radius-small;
    background: var(--main-theme);
    color: var(--main-theme-opposite);
    box-shadow: 0px 9px 45px -20px rgba(14, 42, 87, 0.41);
    z-index: 3;

    ^[0]--pos_default & {
      top: 100%;
      right: -10px;
      margin-top: 0.5rem;

      &::before {
        content: '';
        display: block;
        size(10px);
        background: inherit;
        position: absolute;
        top: -6px;
        right: 24px;
        transform: rotate(45deg);
      }
    }
    ^[0]--pos_over & {
      top: 0;
      left: 0;
    }

    for $key, $size in $ap-sizes {
      ^[0]--size_{$key} & {
        min-width: $size.min;

        +breakpoint(sm-and-up) {
          min-width: $size.max;
        }
      }
    }
  }
}
</style>
