import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrStatus } from './status';

export class AllTariff extends Object {
  constructor(args) {
    // console.log(args);
    super(args);
    this._info = null;
    if (this.isValid(args)) {
      try {
        this._init(args);
      } catch (e) {
        console.error(e);
      }
    }
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = args.id;
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    this.pricelist = args.pricelist;
    this.in_pay = args.in_pay || false;
    this.cost = parseCost(args.cost) || 0;
    this.name = args.name || '';
    this.period_id = args.period_id || 1;
    this.period = args.period_name || '';
    this.expiredate = Date.parse(args.expiredate);
    this.expiredate_l = args.real_expiredate_l || '';
    this.prolong_available = args.prolong_available || 'off';

    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_name = args.autoprolong_name || args.autoprolong || '';
    this.autoprolong_period = this.setPeriod(this.autoprolong_name);
    // console.log(this.employeesuspend, this.abusesuspend);
    this.status = new BillMgrStatus(
      this.in_pay === 'on'
        ? 6
        : this.employeesuspend === 'on'
        ? 8
        : this.abusesuspend === 'on'
        ? 7
        : args.status
    );
    // this.autoprolong_period = (() => {
    //   // console.log(args);
    //   switch (args.autoprolong) {
    //     case '1':
    //       return 'месяц';
    //     case '3':
    //       return '3 месяца';
    //     case '6':
    //       return 'полгода';
    //     case '9':
    //       return '9 месяцев';
    //     case '12':
    //       return 'год';
    //     case '24':
    //       return '2 года';
    //     case '36':
    //       return '3 года';
    //     default:
    //       return 'нет';
    //   }
    // })();
    this.stored_method = args.stored_method;
  }

  setPeriod(val) {
    if (val === '1') {
      return 'месяц';
    } else if (val === '3') {
      return '3 месяца';
    } else if (val === '6') {
      return 'полгода';
    } else if (val === '9') {
      return '9 месяцев';
    } else if (val === '12') {
      return 'год';
    } else if (val === '24') {
      return '2 года';
    } else if (val === '36') {
      return '3 года';
    } else return 'нет';
  }

  update(args) {
    this._init(args);
  }

  get title() {
    return this.domain || this.pricelist;
  }

  get hasPanel() {
    if (!this.info || !this.info.customfields) return false;
    const { username, userpassword } = this.info.customfields;
    const vals = { yes: true, no: false };
    return username && vals[username.added] && userpassword && vals[userpassword.added];
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }
  set info({ customfields, slist, model }) {
    this._info = {
      customfields,
      slist,
      model,
      details: null,
    };
  }

  get info() {
    return this._info;
  }

  get addons() {
    if (!this.info || !this.info.details || !this.info.details.addons) return null;
    return this.info.details.addons;
  }

  get pricelistId() {
    if (!this.info || !this.info.model || !this.info.model.pricelist) return null;
    return parseInt(this.info.model.pricelist);
  }
}
