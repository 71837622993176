import { baseColors } from '../base/baseColors';
const statusEnum = {
  1: 'ONLINE',
  2: 'DRAINING',
  3: 'OFFLINE',
  4: 'DEGRADED',
  5: 'ERROR',
  6: 'NO_MONITOR',
  7: 'ACTIVE',
  8: 'DELETED',
  9: 'PENDING_CREATE',
  10: 'PENDING_UPDATE',
  11: 'PENDING_DELETE',
};
const stateColor = {
  ONLINE: baseColors.success,
  ACTIVE: baseColors.success,
  DELETED: baseColors.error,
  ERROR: baseColors.error,
  DEGRADED: baseColors.error,
  OFFLINE: baseColors.error,
  DRAINING: baseColors.warning,
  NO_MONITOR: baseColors.warning,
  PENDING_CREATE: baseColors.warning,
  PENDING_UPDATE: baseColors.warning,
  PENDING_DELETE: baseColors.warning,
};

export class OPBalancerStatusOperating {
  /**
   *
   * @param {string} str
   */
  constructor(str) {
    this._keys = Object.keys(stateColor);
    if (this.isValid(str)) this._init(str);
    else throw new Error(`Unknown state '${str}' in OPBalancerStatusOperating`);
  }

  isValid(str) {
    this._isValid = this._keys.includes(str);
    return this._isValid;
  }

  getKeyByState(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  _init(str) {
    const code = this.getKeyByState(statusEnum, str);
    this.code = parseInt(code);
    this.key = statusEnum[code];
    this.color = stateColor[this.key];
  }

  // get color() {
  //   return stateColor[this.key];
  // }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this._isValid;
  }
}
export class OPBalancerStatusProvisioning {
  /**
   *
   * @param {string} str
   */
  constructor(str) {
    this._keys = Object.keys(stateColor);
    if (this.isValid(str)) this._init(str);
    else throw new Error(`Unknown state '${str}' in OPBalancerStatusProvisioning`);
  }

  isValid(str) {
    this._isValid = this._keys.includes(str);
    return this._isValid;
  }

  getKeyByState(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  _init(str) {
    const code = this.getKeyByState(statusEnum, str);
    this.code = parseInt(code);
    this.key = statusEnum[code];
    this.color = stateColor[this.key];
  }

  // get color() {
  //   return stateColor[this.key];
  // }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this._isValid;
  }
}
