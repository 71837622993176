import { baseColors } from '../base/baseColors';
const taskEnum = {
  1: 'нет',
  2: 'building',
  3: 'image_snapshotting',
  4: 'image_backingup',
  5: 'updating_password',
  6: 'pausing',
  7: 'unpausing',
  8: 'suspending',
  9: 'resuming',
  10: 'deleting',
  11: 'stopping',
  12: 'starting',
  13: 'rescuing',
  14: 'unrescuing',
  15: 'rebooting',
  16: 'rebuilding',
  17: 'powering_on',
  18: 'powering_off',
  19: 'resizing',
  20: 'resize_reverting',
  21: 'resize_confirming',
  22: 'scheduling',
  23: 'block_device_mapping',
  24: 'networking',
  25: 'spawning',
  26: 'resize_prep',
  27: 'resize_migrating',
  28: 'resize_migrated',
  29: 'resize_finish',
  30: 'reboot_started',
  31: 'reboot_started_hard',
  32: 'powering-off',
  33: 'powering-on',
  34: 'rebooting_hard',
};
const taskColor = {
  // building: baseColors.success,
  // none: baseColors.success,
  // image_snapshotting: baseColors.success,
  // reboot_started_hard: baseColors.success,
  // image_backingup: baseColors.success,
  // 'powering-off': baseColors.success,
  // 'powering-on': baseColors.success,
  // pausing: baseColors.success,
  // unpausing: baseColors.success,
  // suspending: baseColors.success,
  // resuming: baseColors.success,
  // deleting: baseColors.success,
  // stopping: baseColors.success,
  // starting: baseColors.success,
  // rescuing: baseColors.success,
  // unrescuing: baseColors.success,
  // rebooting: baseColors.success,
  // rebooting_hard: baseColors.success,
  // rebuilding: baseColors.success,
  // powering_on: baseColors.success,
  // powering_off: baseColors.success,
  // resizing: baseColors.success,
  // resize_reverting: baseColors.success,
  // resize_confirming: baseColors.success,
  // block_device_mapping: baseColors.success,
  // networking: baseColors.success,
  // spawning: baseColors.success,
  // reboot_started: baseColors.success,
  // scheduling: baseColors.success,
  // resize_prep: baseColors.success,
  // resize_migrating: baseColors.success,
  // resize_migrated: baseColors.success,
  // resize_finish: baseColors.success,
};

export class OPTask {
  /**
   *
   * @param {string} str
   */
  constructor(str) {
    // console.log(Object.keys(taskColor));
    // this._keys = Object.keys(taskColor);
    // if (this.isValid(str)) this._init(str);
    this._init(str);
    // else throw new Error(`Unknown task '${str}' in OPTask`);
  }

  // isValid(str) {
  // this._isValid = this._keys.includes(str);
  // }

  getKeyByTask(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  _init(str) {
    // const code = this.getKeyByTask(taskEnum, str);
    // this.code = parseInt(code);
    // this.key = taskEnum[code];
    // this.color = taskColor[this.key];
    this.key = str.toLowerCase();
    this.code = 1;
    this.color = baseColors.warning;
  }

  // get color() {
  //   return taskColor[this.key];
  // }

  toString() {
    return this.key;
  }

  toNumber() {
    return this.code;
  }

  toBoolean() {
    return this._isValid;
  }
}
