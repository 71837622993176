import BillMgrApi from '@/services/api/BillMgrApi';

export const ACTIONS = {
  fetchList({ commit }, lic) {
    commit('SET_IS_LOADING', true);
    return new Promise((rs, rj) => {
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
      };
      BillMgrApi.get(`api2/billmgr/${lic}`, { params })
        .then(data => {
          // console.log(data.elem, lic);
          if (data.elem) {
            commit(`${lic}`, data.elem);
            // commit('setLicName', lic);
          }
          commit('SET_TOOLS', data.metadata.toolbtndata);
          // commit(lic, data.elem);
          rs(data);
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchMyLic({ commit }, lic) {
    return new Promise((rs, rj) => {
      BillMgrApi.get(`api2/billmgr/${lic}`)
        .then(data => {
          if (!data.elem.length) {
            commit(`${lic}`, data.elem);
            // commit('setLicName', lic);
          }
          rs(data);
        })
        .catch(err => rj(err));
    });
  },
  currentLic({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const params = {
      elid: payload.id,
      out: 'bjson',
      func: `${payload.lic}.edit`,
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(date => {
          commit('setCurrentLic', date.model);
          rs(data => console.log(data.model, 'data'));
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  resetLicense({ commit }) {
    commit('restoreLicense');
  },
};
