import { GETTERS } from '../../utils/getters';
import { state } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import moduleDnsRecords from './modules/records';

const moduleDomainsDnsHost = {
  namespaced: true,
  state,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
  modules: {
    moduleDnsRecords,
  },
};

export default moduleDomainsDnsHost;
