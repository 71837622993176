<template>
  <div class="totp-code">
    <div v-if="$slots.default || desc" class="totp-code__desc standart-text">
      <slot> {{ desc }} </slot>
    </div>
    <div class="totp-code__field totp-code__field--code">
      <base-input
        :value="formData.code"
        required
        use-reactive-validation
        :tooltip="$t('codeHint')"
        :minlength="length"
        :maxlength="length"
        type="tel"
        :custom-error-messages="{ tooShort: $tc('tooShort', length) }"
        class="totp-code__input"
        @input="onCodeChange"
        @keyup.enter="submit"
      >
        {{ $t('code') }}
      </base-input>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'TotpCode',
  components: { BaseInput },
  props: {
    desc: {
      type: String,
      default: '',
    },
    length: {
      type: Number,
      default: 6,
    },
    doSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        code: '',
      },
    };
  },
  computed: {
    isValid() {
      return !!this.formData.code && this.formData.code.length === this.length;
    },
  },
  watch: {
    isValid(val) {
      this.$emit('ready', val);
    },
    doSubmit(val) {
      if (val) this.submit();
    },
  },
  methods: {
    onCodeChange(value) {
      this.formData.code = value;
    },
    submit() {
      if (this.isValid) {
        this.$emit('submit', this.formData.code);
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "code": "Код подтверждения",
      "codeHint": "6-значный числовой пароль, сгенерированный приложением Google Authenticator",
      "tooShort": "Введите 0 цифр | Введите 1 цифру | Введите {count} цифры | Введите {count} цифр"
    }
  }
</i18n>

<style lang="stylus" scoped>
.totp-code {
  &__desc {
    margin-bottom: 1.5rem;
  }
}
</style>
