export default class NoticeItem {
  constructor(args, required = ['id', 'cdate', 'subject', 'noticegroup', 'readed']) {
    this._required = required;
    if (this.isValid(args)) this.init(args);
    else console.error('Missed required props in NoticeItem');
  }

  isValid(args) {
    return this._required.every(prop => args[prop]);
  }

  _init(args) {
    for (let prop in args) {
      this[prop] = args[prop];
    }
  }

  init(args) {
    this._init(args);
    this.date = new Date(this.cdate);
  }
}
