export const STATE = {
  list: [],
  ipList: [],
  current: '',
  isLoading: true,
  sort: {
    prop: 'id',
    order: 'desc',
  },
  mode: 'grid',
  key: '',
  tools: {},
  vpsBackup: 0,
  vpsName: [],
  model: {},
  ip: {},
  isPaymentCard: false,
  nodeList: [],
  currentPort: [],
};
