import { GETTERS } from './getters.js';
import { STATE } from './state.js';
import { MUTATIONS } from './mutations.js';
import { ACTIONS } from './actions.js';
import moduleDedicOrder from './modules/order';

const moduleDedic = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
  modules: {
    moduleDedicOrder,
  },
};

export default moduleDedic;
