<template>
  <div id="app">
    <router-view></router-view>
    <base-modal />
  </div>
</template>
<script>
import ScrollbarWidth from '@/utils/scrollbar-width';
import breakpoints from '@/utils/breakpoints';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'App',
  data() {
    return {
      themes: ['light', 'dark'],
    };
  },
  metaInfo() {
    return {
      title: this.title,
      link: [
        { rel: 'shortcut icon', href: `${window.location.origin}/favicon${this.faviconPath}.ico` },
      ],
      // title: this.title,
      // all titles will be injected into this template
      // titleTemplate: '%s | My Awesome Webapp'
    };
  },
  computed: {
    faviconPath() {
      console.log(window.location.origin);
      if (IS_PROD) {
        if (window.location.href.includes('rusonyx')) {
          return '-r';
        } else if (window.location.href.includes('astracloud')) {
          return '-a';
        } else if (window.location.href.includes('caravan')) {
          return '-c';
        } else return '-r';
      } else {
        if (this.$store.state.moduleApp.host.includes('caravan')) {
          return '-c';
        } else if (this.$store.state.moduleApp.host.includes('astracloud')) {
          return '-a';
        } else return '-r';
      }
    },
    title() {
      if (IS_PROD) {
        if (window.location.href.includes('rusonyx')) {
          return 'Rusonyx';
        } else if (window.location.href.includes('astracloud')) {
          return 'AstraCloud';
        } else if (window.location.href.includes('caravan')) {
          return 'Caravan';
        } else if (window.location.href.includes('zenon')) {
          return 'Zenon';
        } else return 'Infobox';
      } else {
        if (this.$store.state.moduleApp.host.includes('caravan')) {
          return 'Caravan';
        } else if (this.$store.state.moduleApp.host.includes('infobox')) {
          return 'Infobox';
        } else if (this.$store.state.moduleApp.host.includes('astracloud')) {
          return 'AstraCloud';
        } else if (this.$store.state.moduleApp.host.includes('zenon')) {
          return 'Zenon';
        } else return 'Rusonyx';
      }
    },
    theme() {
      return this.$store.state.moduleApp.theme;
    },
    themeSwitcher() {
      return this.theme === 'dark';
    },
    isModalOpened() {
      return this.$store.state.moduleApp.isModalOpened;
    },
    isSidebarOpened() {
      return this.$store.state.moduleApp.isSidebarOpened;
    },
    scrollbarWidth() {
      return this.$store.state.moduleApp.screen.scrollbar;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isScrollable() {
      return !this.isModalOpened && !(this.isSidebarOpened && this.screenWidth < breakpoints.md);
    },
  },
  watch: {
    isScrollable: function (value) {
      this.toggleScrollState(!value);
    },
    theme: function (value) {
      // console.log(value);
      this.setThemeToDOM(value);
    },
  },
  created() {
    this.setThemeToDOM(this.theme);
    // console.log(this.theme);
    this.$modals.$on('modals:opened', this.onModalsOpen);
    this.$modals.$on('modals:destroyed', this.onModalsDestroy);
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  methods: {
    setScreenSize() {
      const width = Math.min(screen.availWidth, window.innerWidth);
      const height = Math.min(screen.availHeight, window.innerHeight);
      const orientation = window.matchMedia('(orientation: portrait)').matches
        ? 'portrait'
        : 'landscape';
      const scrollbar = ScrollbarWidth();
      this.$store.commit('moduleApp/SET_SCREEN', { width, height, orientation, scrollbar });
    },
    toggleTheme(value) {
      this.$store.commit('moduleApp/SET_THEME', this.themes[+value]);
    },
    toggleScrollState(value) {
      document.body.style.overflowY = value ? 'hidden' : '';
      if (document.body.scrollHeight > document.documentElement.clientHeight) {
        document.body.style.paddingRight = value ? `${this.scrollbarWidth}px` : 0;
      }
    },
    setThemeToDOM(value) {
      let themeElement = document.getElementById('id-to-link');
      themeElement.setAttribute('href', `/${this.theme}.css`);
      document.body.dataset.theme = value;
    },
    onModalsOpen() {
      if (!this.isModalOpened) this.$store.commit('moduleApp/SET_IS_MODAL_OPENED', true);
    },
    onModalsDestroy() {
      this.$store.commit('moduleApp/SET_IS_MODAL_OPENED', false);
    },
  },
};
</script>
