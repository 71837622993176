<template>
  <div class="partners">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" class="personal__loader" />
      <card-block v-else :title="$t('title')" class="partners__block">
        <main-card class="partners__card">
          <div class="partners__desc standart-text">
            {{ $t('text_1') }}
            <br />
            <br />
            {{ $t('text_2') }}
            {{ $t('text_3') }}
            <br />
            <br />
            {{ $t('text_4') }}
          </div>
          <br />
          <div v-for="(item, key) in partners" :key="key" class="l-col">
            <div v-if="item.type !== 'select'">
              <base-input
                :type="item.type"
                :value="item.value"
                :name="item.name"
                :label="item.label"
                class="partners__field"
                :readonly="true"
                :info="true"
              >
              </base-input>
            </div>
          </div>
          <br />
          <!--          <div v-if="referals && provider === '3'" class="partners__links">-->
          <div v-if="referals" class="partners__links">
            <base-button color="error" target="_blank" @click="linkTo">
              {{ $t('partners') }}
            </base-button>
          </div>
        </main-card>
      </card-block>
    </transition>
  </div>
</template>

<script>
import CardBlock from '../components/CardBlock';
import MainCard from '@/components/MainCard/MainCard';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import providerChange from '@/mixins/providerChange';
import { IS_PROD, urlToProject } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'Partners',
  components: { CardBlock, MainCard, BaseInput },
  mixins: [providerChange],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.loading || !this.isReady;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    partners() {
      return this.$store.getters['moduleProfile/partners'];
    },
    referals() {
      if (this.$store.getters['moduleProfile/referals']) {
        return this.$store.getters['moduleProfile/referals'];
      } else return null;
    },
    isReady() {
      return this.profile && this.partners;
    },
    provider() {
      return IS_PROD
        ? urlToProject[window.location.origin]
        : this.$store.getters['moduleProviders/provider'];
      // return this.$store.getters['moduleProviders/current'];
    },
  },
  watch: {
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
  },
  mount() {
    this.$store.dispatch('moduleProfile/setPartnersLink').catch(() => {});
    this.$store.dispatch('moduleProfile/getReferalsLink').catch(() => {});
  },
  methods: {
    linkTo() {
      window.open(this.referals.v);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "partners": "Партнерская программа",
      "title": "Реферальная программа",
      "desc": "В данном разделе вы можете ознакомиться с обновлениями политики конфиденциальности, а также посмотреть список действий",
      "ref": "Что такое Реферальная программа? Все очень просто: Вы рекомендуете клиенту наши услуги или помогаете ему, к примеру, зарегистрировать сервер в Русоникс. После этого, вы начинаете получать вознаграждение в течение всего времени, пока клиент пользуется нашими услугами.",
      "text_1": "Поздравляем! Вы успешно подключены к бонусной партнёрской программе!",
      "text_2": "Используя нижеприведённые ссылки для привлечения новых клиентов к Вашей партнёрской программе, Вы сможете получать ежемесячно бонусные баллы и оплачивать ими услуги хостинга! ",
      "text_3": "Для этого достаточно, чтобы новый клиент перешёл по ссылке, зарегистрировался в системе биллинга, выбрал нужный тариф и оплатил его.",
      "text_4": "* Начисление вознаграждения осуществляется в начале месяца, следующего за отчётным."

    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.partners {
  &__card {
    +breakpoint(md-and-up) {
      max-width: 40rem;
    }
  }
  &__desc {
    //max-width: 32em;
  }

  &__links {
    margin-top: 1.5rem;
    flexy(flex-start, flex-start, nowrap, column);

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }

  &__field {
    opacity: 1.0;
  }

  &__link {
    text-align: left;
    & + & {
      margin-top: 1rem;
    }
  }

}
</style>
