export const sortObjectsByOrder = {
  // Сортировка по айпи адресам хардкод однако
  asc: (a, b) => {
    // console.log(a, b);
    const first = a[0] ? a[0].ip_address : 0;
    const second = b[0] ? b[0].ip_address : 0;
    if (first.toNumber && second.toNumber) return first.toNumber() - second.toNumber();
    if (first < second) return -1;
    else if (first > second) return 1;
    else return 0;
  },
  desc: (a, b) => {
    // console.log(a, b);

    const first = a[0] ? a[0].ip_address : 0;
    const second = b[0] ? b[0].ip_address : 0;
    if (first.toNumber && second.toNumber) return second.toNumber() - first.toNumber();
    if (first > second) return -1;
    else if (first < second) return 1;
    else return 0;
  },
};
