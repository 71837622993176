/* eslint-disable vue/no-v-html */
<template>
  <transition tag="div" name="modal">
    <div
      v-show="modals.length"
      ref="modalBackdrop"
      class="base-modal__wrapper"
      tabindex="0"
      @keyup.esc="handleEscapeKey($event)"
      @click="handleBackdropClick"
    >
      <div class="base-modal__vertical-offset">
        <div
          v-for="(modal, index) in modals"
          :key="index"
          class="base-modal"
          :class="getCssClasses(index)"
          @click.stop
        >
          <div class="base-modal__title">
            <div v-if="modal.title" class="base-modal__title-text standart-heading">
              {{ modal.title }}
            </div>
            <plain-button
              v-if="modal.dismissable"
              icon="close"
              class="base-modal__close"
              @click="dismiss()"
            />
          </div>
          <div ref="content" :data-name="modal.name" class="base-modal__content">
            <header
              v-if="modal.header"
              class="base-modal__header"
              :class="{
                [`base-modal__header--separate-${modal.header.separate || 'none'}`]: true,
                ['base-modal__header--thin']: modal.header.thin,
              }"
            >
              <component
                :is="modal.header.component"
                v-bind="modal.header.props"
                v-on="modal.header.on"
              ></component>
            </header>
            <div
              v-if="modal.component || modal.text"
              class="base-modal__body"
              :style="{ maxHeight: modal.heightLimit }"
              :class="{ ['has-limit']: !!modal.heightLimit }"
            >
              <div class="base-modal__body-inner">
                <component
                  :is="modal.component"
                  v-if="modal.component"
                  ref="components"
                  v-bind="modal.props"
                  v-on="modal.on"
                />
                <div
                  v-else-if="modal.text && modal.html && !modal.headers"
                  class="base-modal__body-text standart-text"
                >
                  <p class="larger-text" v-html="modal.text"></p>
                </div>
                <div
                  v-else-if="modal.text && modal.html && modal.headers"
                  class="base-modal__body-text big-title"
                >
                  <p class="larger-text" v-html="modal.text"></p>
                </div>
                <div v-else-if="modal.text" class="base-modal__body-text standart-text">
                  {{ modal.text }}
                </div>
              </div>
            </div>
            <div v-if="modal.footer" class="base-modal__footer">
              <div
                v-if="modal.footer.top"
                class="base-modal__footer-top"
                :class="`base-modal__footer-top--separate-${
                  modal.footer.top.separate ? modal.footer.top.separate : 'none'
                }`"
              >
                <component
                  :is="modal.footer.top.component"
                  v-bind="modal.footer.top.props"
                  v-on="modal.footer.top.on"
                ></component>
              </div>
              <div
                v-if="modal.footer.note || modal.footer.actions"
                class="base-modal__footer-actions"
                :class="{ ['base-modal__footer-actions--centered']: modal.footer.centered }"
              >
                <div
                  v-if="modal.footer.note"
                  class="base-modal__footer-note standart-text"
                  :class="{ [`${modal.footer.note.color}-color`]: !!modal.footer.note.color }"
                >
                  {{ modal.footer.note.text }}
                </div>
                <div v-if="modal.footer.actions" class="base-modal__footer-btns">
                  <template v-for="(btn, key) in modal.footer.actions">
                    <component
                      :is="btn.component"
                      v-if="btn"
                      :key="key"
                      v-bind="btn.props"
                      class="base-modal__footer-btn"
                      v-on="btn.on"
                    >
                      {{ btn.props.title || $t(`actions.${key}`) }}
                    </component>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<i18n>
  {
    "ru": {
      "actions": {
        "cancel": "Отменить",
        "confirm": "Сохранить"
      }
    }
  }
</i18n>
<script>
/* eslint-disable */
import Bus from './bus';
import { ModalInstance } from './BaseModalModel';
export default {
  name: 'BaseModal',
  data() {
    return {
      baseClass: 'base-modal',
      list: [],
    };
  },
  computed: {
    // Storage for all the modals's instances
    modals() {
      return this.list;
    },
    // Get the current window
    current() {
      return this.list[this.$last];
    },
    // Get the last element of the Modals array (the most recent Modal instance)
    $last() {
      return this.list.length - 1;
    },
    body() {
      if (typeof document === 'undefined') return false;
      return document.querySelector('body');
    },
  },
  created() {
    // Create a new Modal instance
    Bus.$on('new', options => {
      this.list.push(new ModalInstance(options));
      Bus.$emit('opened', {
        index: this.$last,
        options,
      });
      this.body.classList.add(`${this.baseClass}-open`);
      document.querySelector(`.${this.baseClass}__wrapper`).scrollTop = 0;
      this.current.onOpen(this.current);
      this.$nextTick(() => {
        this.setFocus();
      });
    });
    // When a close event is receive, close the Modal instance
    Bus.$on('close', data => {
      let index = null;
      // If an index was given on the data
      if (data && data.index) index = data.index;
      else if (data && data.name) index = this.getIndexByName(data.name);
      // If a Vue component was passed as the data
      if (data && data._isVue) {
        for (const [idx, modal] of this.$refs.components.entries()) {
          if (data === modal) {
            index = idx;
            break;
          }
        }
      }
      if (index === null)
        // Close the most recent Modal instance
        index = this.$last;
      this.close(data, index);
    });
    // Same for dismiss
    Bus.$on('dismiss', index => {
      if (index === null)
        // Close the most recent Vuedal instance
        index = this.$last;
      this.dismiss(index);
    });
  },
  methods: {
    // Remove the given index from the modals array
    splice(index = null) {
      if (index === -1) return;
      // If there's nothing to close, ignore it
      if (!this.modals.length) return;
      // If there's no index, pop() it
      this.list.splice(index, 1);
      // And if it was the last window, notify that all instances are destroyed
      if (!this.modals.length) {
        this.body.classList.remove(`${this.baseClass}-open`);
        Bus.$emit('destroyed');
      }
    },
    doClose(data = null, index) {
      // If there's nothing to close, ignore it
      if (!this.modals.length) return;
      if (!this.modals[index]) return;
      this.splice(index);
      // Firefox fix: https://github.com/javisperez/vuedals/issues/1
      const modals = document.querySelector(`.${this.baseClass}__wrapper`);
      if (modals) modals.scrollTop = 0;
    },
    getIndex(index = null, data = null) {
      let localIndex = index;
      // If the index is a function, pass the current open vuedal index
      if (index && typeof index === 'function') {
        localIndex = index(data, this.modals);
      }
      // If the index is either null or undefined
      if (typeof localIndex !== 'number') localIndex = this.$last;
      return localIndex;
    },
    // Close the modal and pass any given data
    close(data = null, index = null) {
      // Can't close if there's no modal open
      if (this.modals.length === 0) return;
      let localIndex = this.getIndex(index, data);
      const instance = this.modals[localIndex];
      // Notify the app about this window being closed
      Bus.$emit('closed', {
        index: localIndex,
        instance,
        data,
      });
      // Close callback
      if (localIndex !== false && instance) instance.onClose(data);
      this.doClose(data, localIndex);
    },
    // Dismiss the active modal
    async dismiss(index = null) {
      // Can't dismiss modal if there's no modal open
      if (this.modals.length === 0) return;
      let localIndex = this.getIndex(index);
      // console.log(localIndex)
      const instance = this.modals[localIndex];
      // Check dismiss callback result for prevention
      // console.log(instance)
      let res = await instance.onDismiss();
      if (res === false) return;
      // Notify the app about this window being closed
      Bus.$emit('dismissed', {
        index: localIndex,
        instance,
      });
      this.doClose(null, localIndex);
    },
    // Get css classes
    getCssClasses(index) {
      const modal = this.modals[index];
      const prefix = this.baseClass;
      return {
        [`${prefix}--${modal.name}`]: true,
        [`${prefix}--size_${modal.size}`]: true,
        [`${prefix}--disabled`]: index < this.$last,
        [`${prefix}--hidden-overflow`]: modal.hiddenOverflow,
        [`${prefix}--size-transition`]: modal.sizeTransition,
      };
    },
    handleEscapeKey(e) {
      if (!this.modals.length) return;
      if (this.current.escapable) this.dismiss();
    },
    handleBackdropClick(e) {
      if (!this.modals.length) {
        return;
      }
      if (this.current.closeOnBackdrop === true) {
        this.dismiss();
      }
    },
    setFocus() {
      const el = this.$refs.content
        ? this.$refs.content.find(el => el.dataset.name === this.current.name)
        : null;
      let firstInput,
        firstButton = null;
      if (el) {
        firstInput = el.querySelector('input:not([type="file"]):not([readonly=readonly])');
        firstButton = el.querySelector('button');
      }
      if (firstInput) {
        firstInput.focus();
      } else if (firstButton) {
        firstButton.focus();
      } else {
        if (!!this.$refs.modalBackdrop) this.$refs.modalBackdrop.focus();
      }
    },
    getInstanceByName(name) {
      const inst = this.list.find(item => item.name === name);
      if (!~inst) throw new Error(`Modal with name '${name}' is not found`);
      return inst;
    },
    getIndexByName(name) {
      const idx = this.list.findIndex(item => item.name === name);
      if (!~idx) throw new Error(`Modal with name '${name}' is not found`);
      return idx;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$bm-sizes = {
  small: 355px,
  medium: 545px,
  big: 640px,
  large: 960px,
}

.base-modal {
  position: relative;
  margin: auto;
  width: 100%;

  &--size-transition {
    transition: max-width 0.5s ease;
  }

  for $key, $value in $bm-sizes {
    &--size_{$key} {
      max-width: $value;
    }
  }

  &--disabled {
    position: absolute;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  &__content {
    width: 100%;
    background: var(--modal-bg);
    box-shadow: var(--modal-shadow);
    color: var(--modal-text);
    border-radius: $border-radius-base;

    ^[0]--hidden-overflow & {
      overflow: hidden;
    }
  }

  &__title {
    padding: 0 2rem 2.5rem;
    margin-top: 0.5rem;
    position: relative;

    +breakpoint(sm-and-up) {
      margin-top: 0;
    }

    &-text {
      color: var(--modal-header-color);
      text-align: center;
    }
  }

	&__close {
    position: absolute!important;
    top: 0;
    right: 0;
    color: var(--modal-header-color);

    +breakpoint(sm-and-up) {
      right: -1.5rem;
    }
	}

  &__header {
    padding: 1.5rem 1rem;

    +breakpoint(sm-and-up) {
      padding: 2.5rem 1.5rem 1.5rem;
    }

    &--thin {
      +breakpoint(sm-and-up) {
        padding: 1.5rem;
      }
    }

    &--separate-top,
    &--separate-both {
      border-top 1px solid var(--separator);
    }

    &--separate-bottom,
    &--separate-both {
      border-bottom 1px solid var(--separator);
    }
  }
  &__body {
    padding: 1.5rem 1rem;

    &.has-limit {
      overflow-y: auto;
    }

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
    }

    >>> .separator {
      height: 1px;
      background: var(--separator);
      margin: 1rem -1rem;
    }

    ^[0]__header--separate-none + &,
    ^[0]__header--separate-top + & {
      padding-top: 0;

      +breakpoint(sm-and-up) {
        padding-top: 0;
      }
    }
  }
  &__footer {

    &-top {
      padding: 1.5rem 1rem;

      +breakpoint(sm-and-up) {
        padding: 1.5rem;
      }

      &--separate-top,
      &--separate-both {
        border-top 1px solid var(--separator);
      }

      &--separate-bottom,
      &--separate-both {
        border-bottom 1px solid var(--separator);
      }
    }

    &-actions {
      flexy(flex-end, center, wrap);
      padding: 1.5rem 1rem;

      &--centered {
        justify-content: center;
      }

      +breakpoint(sm-and-up) {
        padding: 1.5rem;
        flex-wrap: nowrap;
      }

      ^[0]__footer-top--separate-top + &,
      ^[0]__footer-top--separate-none + & {
        padding-top: 0;

        +breakpoint(sm-and-up) {
          padding-top: 0;
        }
      }
    }

    &-note {
      flex: 0 1 auto;
      margin: 0 auto 0 0;

      +breakpoint(sm-and-up) {
        margin: auto 0 auto auto;
        text-align: right;
      }
    }

    &-btns {
      flexy(flex-end, center, wrap);

      ^[-1]--centered & {
        justify-content: center;
      }
      +breakpoint(ms-and-down) {
        width: 100%;
      }
      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
      }
    }
    &-note + &-btns {
      margin-top: 1rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }

    ^[0]__header + &,
    ^[0]__body + & {
      padding-top: 0;

      +breakpoint(sm-and-up) {
        padding-top: 0;
      }
    }

    &-btn {
      +breakpoint(ms-and-down) {
        width: 100%;
      }

      & + & {
        margin-top: 1.25rem;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
  }

  &__wrapper {
    background: var(--modal-backdrop);
    z-index: 1000;
    perspective: 500px;
    transition: opacity .4s ease;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    &:focus {
      outline: 0;
    }
  }

  &__vertical-offset {
    position: relative;
    margin: auto;
    padding: 1rem;
    width: 100%;
    min-height: 100%;
    display: flex;
  }
}
.modal-enter,
.modal-leave-active {
  opacity: 0;
}
.modal-enter .base-modal,
.modal-leave-active .base-modal {
  opacity: 0;
  transform: translateX(-50%) translateY(-30px) scale(0.95);
}
</style>
