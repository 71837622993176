<template>
  <div class="allowed-user-rights">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="allowed-user-rights__content">
        <div v-for="(section, idx) in list" :key="idx" class="allowed-user-rights__section">
          <div class="allowed-user-rights__section-head l-flex-2">
            <div class="medium-title l-col">
              {{ section.caption }}
            </div>
            <div class="allowed-user-rights__section-ctrl l-col">
              <base-checkbox
                :value="section.value"
                true-value="on"
                false-value="off"
                @change="onSectionChange(section, $event)"
              >
                {{ $t(`section.${section.value}`) }}
              </base-checkbox>
            </div>
          </div>
          <div class="allowed-user-rights__list">
            <div
              v-for="(item, i) in section.list"
              :key="i"
              class="allowed-user-rights__list-item l-flex-2"
            >
              <div class="allowed-user-rights__caption standart-text l-col">
                {{ item.caption }}
              </div>
              <div class="allowed-user-rights__ctrl l-col">
                <base-checkbox
                  :value="item.active"
                  true-value="on"
                  false-value="off"
                  class="allowed-user-rights__field"
                  @change="onItemChange(item, $event)"
                >
                  {{ $t(`item.${item.active}`) }}
                </base-checkbox>
                <svg-icon
                  v-if="warnings[item.name]"
                  v-tooltip="{ content: warnings[item.name], ...warnTooltipOptions }"
                  name="warn"
                  class="allowed-user-rights__warn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { AllowedUser } from '@/models/BillMgr/UserProfile';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'AllowedUserRights',
  components: { BaseCheckbox },
  mixins: [showErrorModal],
  props: {
    user: {
      type: AllowedUser,
      required: true,
    },
    func: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      raw: [],
      actions: {
        on: 'resume',
        off: 'suspend',
      },
      tools: {},
      formData: [],
      warnings: {},
      warnTooltipOptions: {
        container: false,
        classes: 'standart-text',
        placement: 'top-end',
      },
    };
  },
  computed: {
    sections() {
      return this.raw.reduce((acc, item) => {
        if (item.type === 'section' || item.type === 'spec') {
          acc.push({ ...item, list: [] });
        } else {
          acc[acc.length - 1].list.push(item);
        }
        return acc;
      }, []);
    },
    list() {
      return this.sections.map(section => ({
        ...section,
        value: section.list.every(item => item.active === 'on') ? 'on' : 'off',
      }));
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(id = this.user.id) {
      this.isLoading = true;
      this.$store
        .dispatch('moduleProfile/fetchUser', { id, func: this.func })
        .then(data => {
          if (data.elem) this.raw = data.elem;
          if (data.metadata && data.metadata.toolbtndata) {
            const btns = data.metadata.toolbtndata;
            this.tools = Object.values(this.actions).reduce((acc, item) => {
              if (btns[item]) acc[item] = btns[item];
              return acc;
            }, {});
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this.isLoading = false));
    },
    setInitialData(raw) {
      this.raw = raw.reduce((acc, item) => {
        if (item.type === 'section' || item.type === 'spec') {
          acc.push({ ...item, list: [] });
        } else {
          acc[acc.length - 1].list.push(item);
        }
        return acc;
      }, []);
    },
    onSectionChange(section, value) {
      const func = this.tools[this.actions[value]].func;
      const elid = section.list.map(item => item.name).join(', ');
      this.changeAccess(func, elid)
        .then(data => {
          if (data.ok) {
            for (let item of section.list) {
              this.raw.find(i => i.name === item.name).active = value;
            }
            this.warnings = {};
          }
        })
        .catch(e => {
          if (e.warnings && e.warnings.length) {
            this.setWarnings(e.warnings);
          } else this.showError(e);
        });
    },
    onItemChange(item, value) {
      const func = this.tools[this.actions[value]].func;
      this.changeAccess(func, item.name)
        .then(data => {
          if (data.ok) {
            this.raw.find(i => i.name === item.name).active = value;
            this.warnings = {};
          }
        })
        .catch(e => {
          if (e.warnings && e.warnings.length) {
            this.setWarnings(e.warnings);
          } else this.showError(e);
        });
    },
    changeAccess(func, elid) {
      return this.$store.dispatch('moduleProfile/updateAllowedUser', {
        func,
        id: elid,
        data: { plid: this.user.id },
      });
    },
    setWarnings(warnings) {
      this.warnings = warnings.reduce((acc, warn) => {
        acc[warn.elid] = warn.msg;
        return acc;
      }, {});
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "item": {
        "on": "Разрешено",
        "off": "Разрешить"
      },
      "section": {
        "on": "Разрешено всё",
        "off": "Разрешить всё"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights {
  &__section {
    & + & {
      margin-top: 1.5rem;
    }
  }
  &__ctrl {
    flexy(flex-start, center);
  }

  &__warn {
    flex: 0 0 1em;
    font-size: 1.25rem;
    color: $error-color;
    margin-left: 0.5rem;

    +breakpoint(sm-and-up) {
      margin-left: 1rem;
    }
  }
}
</style>
