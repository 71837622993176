<template>
  <div class="container">
    <div class="theme-switcher" :class="classes">
      <div v-if="withText" class="theme-switcher__text standart-text">
        {{ $t(`theme.${theme}`) }}
      </div>
      <base-tumbler :value="themeSwitcher" class="theme-switcher__tumbler" @change="setTheme" />
    </div>
    <!--    <plain-button v-if="provider !== '4'" class="old" :href="oldLk" color="dim" size="small">-->
    <!--      {{ $t('old') }}-->
    <!--    </plain-button>-->
  </div>
</template>

<script>
import BaseTumbler from '@/components/BaseTumbler/BaseTumbler.vue';
export default {
  name: 'ThemeSwitcher',
  components: { BaseTumbler },
  props: {
    withText: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value),
    },
  },
  data() {
    return {
      themes: ['light', 'dark'],
    };
  },
  computed: {
    theme() {
      return this.$store.state.moduleApp.theme;
    },
    themeSwitcher() {
      return this.theme === 'dark';
    },
    provider() {
      return this.$store.state.moduleProviders.current;
    },
    classes() {
      const base = 'theme-switcher';
      return {
        [`${base}--text-${this.textColor}`]: this.withText && this.textColor,
      };
    },
    oldLk() {
      let token = localStorage.getItem('token');
      return `${this.$store.state.moduleApp.host}/billmgr?` + `&auth=${token}`;
    },
  },
  methods: {
    setTheme(value) {
      let themeElement = document.getElementById('id-to-link');
      this.themeSwitcher
        ? themeElement.setAttribute('href', '/light.css')
        : themeElement.setAttribute('href', '/dark.css');
      this.$store.commit('moduleApp/SET_THEME', this.themes[+value]);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "old": "Старый личный кабинет",
      "theme": {
        "light": "Светлая тема",
        "dark": "Темная тема"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

$ts-text-colors = {
  light: $color-light.light-blue,
  dark: $color-light.medium,
}
.container {
  //flexy(flex-start,start);
  //flexy(flex-start);
  //flexy(start);
  //flex-direction column;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction column;

}
  .theme-switcher {
    //flexy(flex-start, center);
    flexy(flex-start);

    &__text {
      min-width: 90px;
      margin-right: 10px;

      for $key, $value in $ts-text-colors {
        ^[0]--text-{$key} & {
          color: $value;
        }
      }
    }
  }
  .old {
    margin-top: 0.5rem;
    margin-bottom: -1.5rem;
  }
</style>
