<template>
  <div class="recovery">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" />
      <div v-else class="recovery__content">
        <div class="recovery__desc standart-text">
          {{ $t('desc') }}
        </div>
        <div class="recovery__form" @keyup.enter="submit()">
          <base-input
            v-for="item in fields"
            :key="item.name"
            v-model="item.value"
            :name="item.name"
            :required="item.required"
            :invalid="item.invalid"
            use-reactive-validation
            class="recovery__field"
          >
            {{ $t('email') }}
          </base-input>
        </div>
        <div v-if="error" class="recovery__error standart-text error-color">
          {{ error }}
        </div>
        <div class="recovery__footer">
          <base-button
            :disabled="!isValid"
            :loading="isSending"
            class="recovery__submit"
            @click="submit()"
            @keyup.enter.stop
          >
            {{ $t('submit') }}
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
import setFocus from '@/mixins/setFocus';
import showSuccess from '../mixins/showSuccess';
export default {
  name: 'Recovery',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showSuccess],
  data() {
    return {
      isLoading: true,
      isSending: false,
      fields: [
        {
          name: 'email',
          type: 'email',
          invalid: false,
          value: '',
        },
      ],
      error: '',
    };
  },
  computed: {
    isValid() {
      return this.fields.every(i => !i.required || (i.required && i.value));
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('fetch', 'recovery')
        .then(data => {
          if (data.required) this.setRequiredFields(data.required);
        })
        .finally(() => (this.isLoading = false));
    },
    setRequiredFields(obj) {
      this.fields.forEach(i => {
        if (obj[i.name] && obj[i.name] === 'true') i.required = true;
      });
    },
    submit() {
      if (!this.isValid) return false;
      this.isSending = true;
      const formData = this.fields.reduce((acc, i) => {
        acc[i.name] = i.value;
        return acc;
      }, {});
      const payload = {
        ...formData,
        sok: 'ok',
        func: 'recovery',
      };
      this.$store
        .dispatch('send', payload)
        .then(() => {
          this.showSuccess();
        })
        .catch(e => {
          this.error = e.msg;
          this.fields.forEach(i => {
            if (i.name === e.object) i.invalid = true;
            else i.invalid = false;
          });
        })
        .finally(() => (this.isSending = false));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "desc": "Введите электронную почту, указанную во время регистрации. На неё мы вышлем ссылку на смену пароля",
      "email": "Почта",
      "submit": "Восстановить пароль",
      "success": {
        "title": "Запрос успешно отправлен",
        "text": "Для восстановления пароля следуйте инструкциям в письме, отправленном на почту, указанную при регистрации.",
        "ok": "Хорошо"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.recovery {
  &__desc {
    margin-bottom: 1.5rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
