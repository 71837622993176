import container from './container.vue';
export const RouteConstruct = {
  name: 'contaner',
  path: '/usercp',
  component: container,
  redirect: { name: 'usercp' },
  // meta: {
  //   navigation: {
  //     service: {
  //       icon: 'el-icon-edit-outline',
  //       key: 'construct',
  //       order: 20,
  //     },
  //   },
  // },
  children: [
    {
      path: '',
      name: 'usercp',
      component: () => import(/* webpackChunkName: "construct" */ './views/Construct.vue'),
    },
  ],
};
