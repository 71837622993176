<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'VPS',
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleVPS/reset');
    next();
  },
  mounted() {},
};
</script>

<style lang="stylus" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex: 1 1 100%;
}
</style>
