<template>
  <div class="auth">
    <div class="auth__form" @keyup.enter="auth">
      <base-input
        v-for="item in fields"
        :key="item.name"
        v-model="item.value"
        :name="item.name"
        :type="item.type"
        :required="item.required"
        :invalid="item.invalid"
        use-reactive-validation
        class="auth__field"
      >
        {{ $t(item.name) }}
      </base-input>
    </div>
    <div v-if="error" class="auth__error standart-text error-color">
      {{ error }}
    </div>
    <div class="auth__footer">
      <base-button
        :disabled="!isValid"
        :loading="isSending"
        class="auth__submit"
        @click="auth"
        @keyup.enter.stop
      >
        {{ $t('signin') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
import TotpCode from '@/components/TotpCode/TotpCode';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import Vue from 'vue';
import qs from 'qs';
import setFocus from '@/mixins/setFocus';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import showSuccess from '../mixins/showSuccess';
import BillMgrApi from '@/services/api/BillMgrApi';
import { currentHost, IS_PROD, urlProvider } from '@/layouts/LoginPage/mixins/currentProvider';
import router from '@/router';
export default {
  name: 'Login',
  components: {
    BaseInput,
  },
  mixins: [setFocus, billMgrToolActions, showSuccess],
  data() {
    return {
      isSending: false,
      username: undefined,
      password: undefined,
      totpModal: null,
      modal: null,
      fields: [
        {
          name: 'username',
          type: 'text',
          required: true,
          value: '',
          invalid: false,
        },
        {
          name: 'password',
          type: 'password',
          required: true,
          value: '',
          invalid: false,
        },
      ],
      error: '',
    };
  },
  computed: {
    isValid() {
      return this.fields.every(i => !i.required || (i.required && i.value));
    },
    lastRoute() {
      return this.$store.state.moduleRoutes.last;
    },
  },
  watch: {},
  mounted() {
    if (this.isRegistered) return this.showSuccess();
    setTimeout(() => this.setFocus(), 500);
  },
  methods: {
    auth() {
      //  console.log('auth');
      this.isSending = true;
      if (!this.isValid) return false;
      this.clearError();
      const payload = this.fields.reduce((acc, i) => {
        acc[i.name] = i.value;
        return acc;
      }, {});
      this.$store
        .dispatch('authUser', payload)
        .then(data => {
          if (data && data.type === 'url') {
            //  console.log('url');
            localStorage.removeItem('token');
            const url = data.v;
            const host = currentHost[data.v.split('?')[0]];
            //  console.log(host);
            return new Promise((resolve, reject) => {
              BillMgrApi.get(url)
                .then(data => {
                  //  console.log(data);
                  const { auth } = data.model;
                  //  console.log(auth);
                  localStorage.setItem('token', auth);
                  const test = `${host}/#usercp/` + `auth=${auth}`;
                  // const test = IS_PROD
                  //   ? `${host}/#/usercp/` + `auth=${auth}`
                  //   : 'http://localhost:8080/#/usercp/' + `auth=${auth}`;
                  // localStorage.setItem('auth', test);
                  //  console.log(test);
                  localStorage.setItem('auth', test);
                  IS_PROD
                    ? window.open(localStorage.getItem('auth'), '_self')
                    : router.push({ name: 'start' }).catch(() => {});

                  // window.open(test, '_blank');
                  // if (!auth) reject(new Error('Missed auth token'));
                })
                // .then(reject => // console.log('--------reject', reject))
                .catch(e => {
                  this.showError(e);
                  reject(e);
                });
            });
            // const url = data.v.replace('https://bill', 'https://my');
            //  console.log(url);
            // window.open(url, '_self');
          }
          if (/activationsend/.test(data)) {
            localStorage.setItem('activationsend', 'on');
            this.$store.commit('moduleApp/SET_MAIL', this.fields[0].value);
          }
        })
        .then(this.redirect)
        .catch(data => {
          if (this.isNeedTotpConfirm(data.e)) {
            const payload = qs.parse(data.e.v);
            if (data.auth) payload.auth = data.auth;
            this.doTotpConfirm(payload);
          } else if (this.isNeedTermOfUseAgreement(data.e)) {
            this.setError(data);
            this.doTermOfUseAgreement(data.e.msg, { auth: data.auth, func: data.e.type });
          } else {
            this.setError(data);
          }
        })
        .finally(() => {
          this.isSending = false;
          if (localStorage.getItem('activationsend')) {
            localStorage.removeItem('activationsend');
            localStorage.setItem('tokenRecover', localStorage.getItem('token'));
            this.$router.push({ name: 'Activation' }).catch(() => {});
          }
          this.$gtm.trackEvent({
            event: '_event_entrance', // Event type [default = 'interaction'] (Optional)
            category: 'Login',
            action: 'click',
            label: 'Auth',
            value: 4000,
            noninteraction: false, // Optional
          });
        });
    },
    redirect() {
      // console.log('redirect', this.$route.name);
      // if (this.lastRoute) {
      if (this.lastRoute && this.lastRoute.name !== 'Incidents') {
        // console.log('this.lastRoute', this.lastRoute);
        if (this.lastRoute.path !== '/login/')
          this.$router.push(this.lastRoute).catch(() => {
            // console.log('lastRoute');
          });
        this.$store.commit('moduleRoutes/SET_LAST', null);
        this.$router.push({ name: 'start' }).catch(() => {
          // console.log('catch1');
        });
      } else if (this.lastRoute && this.lastRoute.name === 'Incidents') {
        this.$router.push(this.lastRoute).catch(() => {
          // console.log('lastRouteIncedent');
        });
      } else
        this.$router
          .push({ name: 'start' })
          // .then(data => {
          //   console.log(data, this.$route.name);
          // })
          .catch(e => {
            console.log(e);
            // console.log('catch2', this.$route.name);
          });
    },
    isNeedTotpConfirm(e) {
      return e && e.type === 'form' && e.v === 'func=totp.confirm';
    },
    isNeedTermOfUseAgreement(e) {
      return e && e.type === 'gdpr_termsofuse';
    },
    doTotpConfirm(payload = {}) {
      const params = { ...payload };
      this.initTotpModal();
      this.totpModal.on.submit = val => {
        Vue.set(this.totpModal.footer.confirm.props, 'loading', true);
        params.qrcode = val;
        this.$store
          .dispatch('totpConfirm', params)
          .then(() => {
            console.log('then');
            this.redirect();
            this.$modals.close();
          })
          .catch(e => {
            this.setError(e);
            this.$modals.close();
          });
      };
    },
    doTermOfUseAgreement(title, payload = {}) {
      this.initModal('TermOfUse', { title });
      this.fetchBillMgrAction(payload).then(data => {
        const config = new BaseConfigurator({ ...data, customfields: data.fields });
        let formData = {};
        Vue.set(this.modal, 'props', { period: 1, configurator: config });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: { title: this.$t('submit'), disabled: true, loading: false },
            on: {
              click: () => {
                this.sendBillMgrAction({
                  func: payload.func,
                  auth: payload.auth,
                  ...formData,
                }).then(() => {
                  this.redirect();
                  this.$modals.close();
                });
              },
            },
          },
          cancel: {
            on: {
              click: this.$modals.close,
            },
          },
        });
        Vue.set(this.modal, 'on', {
          init: data => (formData = data),
          change: data => (formData = data),
          ready: data => {
            formData = data;
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            formData = data;
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
        });
      });
    },
    initTotpModal() {
      this.$modals.open({
        name: 'TotpConfirm',
        title: this.$t('totp.title'),
        onOpen: instance => (this.totpModal = instance),
        onClose: () => (this.totpModal = null),
        component: TotpCode,
        props: { desc: this.$t('totp.desc') },
        on: {
          ready: val => {
            this.totpModal.footer.confirm.props.disabled = !val;
          },
        },
        footer: {
          confirm: {
            props: { title: this.$t('submit'), disabled: true },
            on: { click: () => Vue.set(this.totpModal.props, 'doSubmit', true) },
          },
          cancel: { on: { click: () => this.$modals.close() } },
        },
      });
    },
    initModal(name, props = {}) {
      this.$modals.open({
        name,
        onOpen: instance => (this.modal = instance),
        onClose: () => (this.modal = null),
        component: BaseLoader,
        ...props,
      });
    },
    setError(data) {
      const e = data.e ? data.e : data;
      this.error = e.msg;
      if (e.object === 'badpassword') {
        this.fields.forEach(i => {
          if (i.name === 'username' || i.name === 'password') i.invalid = true;
        });
      }
    },
    clearError() {
      this.error = '';
      this.fields.forEach(i => (i.invalid = false));
    },
    sendBillMgrAction(params) {
      return this.$store.dispatch('sendBillMgrToolAction', params);
    },
  },

  beforeRouteLeave(_to, _from, next) {
    localStorage.removeItem('key');
    next();
  },

  beforeRouteEnter(_to, _from, next) {
    const bmUrl = window.location.href;
    //  console.log('login');
    next(vm => {
      vm.$store.commit('moduleSupport/SET_NEW_TICKETS', []);
      vm.$store.commit('moduleSupport/SET_ALL_TICKETS', []);
      //  console.log(bmUrl);
      if (bmUrl.includes('?')) {
        const bmRequest = qs.parse(bmUrl.split('?')[1].split('#')[0]);
        if (bmRequest.func && bmRequest.key && bmRequest.func === 'register.activate') {
          vm.sendBillMgrAction({
            func: bmRequest.func,
            key: bmRequest.key,
            obj: bmRequest.obj,
          })
            .then(data => {
              if (data.func && data.func === 'register.activate' && data.ok) {
                window.history.replaceState({}, 'document.title', '/' + '#/login');
                vm.showSuccess();
              }
            })
            .finally(() => (window.location.href = window.location.origin + window.location.hash));
        } else if (bmRequest.func && bmRequest.secret && bmRequest.func === 'recovery.change') {
          vm.$store.commit('SET_MODEL', bmRequest);
          window.history.replaceState({}, 'document.title', '/' + '#/login/password');
          vm.$router.push({ name: 'ChangePassword' });
        } else if (bmRequest.func && bmRequest.func === 'recovery.name') {
          //  console.log(bmRequest);
          vm.$store.commit('SET_MODEL', bmRequest);
          window.history.replaceState({}, 'document.title', '/' + '#/login/recovery/login');
          vm.$router.push({ name: 'RecoveryLogin' });
        } else {
          //  console.log('else', qs.parse(bmUrl.split('?')[1].split('#')[0]));
        }
      } else {
        // console.log(bmUrl);
      }
    });
  },
};
</script>

<i18n>
  {
    "ru": {
      "username": "Имя пользователя",
      "password": "Пароль",
      "signin": "Войти",
      "success": {
        "title": "Регистрация",
        "text": "Вы успешно подтвердили регистрацию в личном кабинете",
        "ok": "Продолжить"
      },
      "totp": {
        "title": "Двухэтапная аутентификация",
        "desc": "Укажите 6-значный код, сгенерированный приложением Google Authenticator"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.auth {
  &__field {
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
