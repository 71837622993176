<template>
  <main-card class="security-access-limit" body-align="top">
    <div class="security-access-limit__form">
      <template v-for="item in fields">
        <base-checkbox
          v-if="item.type === 'checkbox'"
          :key="item.key"
          :name="item.name"
          :value="formData[item.name]"
          :true-value="item.trueValue"
          :false-value="item.falseValue"
          class="security-access-limit__field"
          :class="{ ['security-access-limit__field--full']: item.key === 'secureip' }"
          @change="onChange(item.name, $event)"
        >
          {{ $t(`fields.${item.key}.label`) }}
        </base-checkbox>
        <template v-else-if="item.type === 'radio'">
          <base-radio
            v-for="opt in item.options"
            :key="`${item.key}-${opt.v}`"
            v-model="formData[item.name]"
            :name="item.name"
            :value="opt.k"
            class="security-access-limit__field"
          >
            {{ $t(`fields.${opt.k}.label`) }}
          </base-radio>
        </template>
        <base-input
          v-else-if="
            item.type === 'textarea' && item.enable
              ? formData[item.enable.name] === item.enable.value
              : true
          "
          :key="item.key"
          :type="item.type"
          :value="item.value"
          :required="item.required"
          :placeholder="item.hint || ''"
          use-reactive-validation
          class="security-access-limit__field security-access-limit__field--textarea"
          @validate-input="onChange(item.name, $event.target.value)"
          @destroy="onChange(item.name, initialBackup[item.name])"
        >
          {{ $t(`fields.${item.key}.label`) }}
        </base-input>
      </template>
    </div>
    <base-button
      slot="bodyEnd"
      :loading="isLoading"
      :disabled="!hasChanges || !isValid"
      class="security-access-limit__btn"
      @click="submit"
    >
      {{ $t('submit') }}
    </base-button>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import mixin from '../../mixins/index';
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { isEqual } from 'lodash';
export default {
  name: 'SecurityAccessLimit',
  components: { MainCard, BaseCheckbox, BaseRadio, BaseInput },
  mixins: [mixin],
  data() {
    return {
      formData: {},
      initialBackup: {},
      isReady: false,
      isLoading: false,
    };
  },
  computed: {
    fields() {
      if (!this.profile || !this.profile.accessLimitSettings) return [];
      return this.profile.accessLimitSettings;
    },
    hasChanges() {
      return !isEqual(this.formData, this.initialBackup);
    },
    isValid() {
      return this.fields.every(i => {
        if (!i.required) return true;
        else if (i.required && !i.enable) return !!this.formData[i.name];
        else
          return this.formData[i.enable.name] === i.enable.value ? !!this.formData[i.name] : true;
      });
    },
  },
  watch: {
    fields(val) {
      this.setInitialFormData(val);
    },
    isReady(val) {
      this.$emit('ready-state-change', val);
    },
    hasChanges(val) {
      this.$emit('form-data-change', val);
    },
  },
  mounted() {
    if (this.fields.length) this.setInitialFormData(this.fields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    setInitialFormData(list) {
      this.formData = list.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {});
      this.initialBackup = { ...this.formData };
      this.isReady = true;
    },
    clear() {
      this.formData = {};
      this.initialBackup = {};
    },
    onChange(name, val) {
      this.formData[name] = val;
    },
    getFinalData() {
      return this.fields.reduce((acc, item) => {
        if (!item.enabled) acc[item.name] = this.formData[item.name];
        else
          acc[item.name] =
            this.formData[item.enable.name] === item.enable.value
              ? this.formData[item.name]
              : this.initialBackup[item.name];
        return acc;
      }, {});
    },
    submit() {
      const data = this.getFinalData();
      if (this.isValid) {
        this.isLoading = true;
        const pass = this.formData.passwd;
        this.updateProfileInfo(data, 'usrparam')
          .then(() => {
            this.$emit('submit', data);
            this.clear();
          })
          .catch(e => this.showError(e))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields": {
        "atany": {
          "label": "Доступ с любого IP"
        },
        "atallow": {
          "label": "Доступ только с определенных IP"
        },
        "addr": {
          "label": "Доверительные IP"
        },
        "secureip": {
          "label": "Привязать сессию к IP"
        }
      },
      "submit": "Сохранить"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.security-access-limit {
  &__form {
    flexy(flex-start, normal, wrap);
    margin: -0.5rem;

    +breakpoint(sm-and-up) {
      margin: -0.75rem;
    }
  }

  &__field {
    flex: 1 1 100%;
    margin: 0.5rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 auto;
      margin: 0.75rem;
    }

    &--full {
      +breakpoint(sm-and-up) {
        flex: 0 1 100%;
      }
    }
    &--textarea {
      flex: 1 1 100%;

      +breakpoint(sm-and-up) {
        flex: 0 1 22rem;
      }
    }
  }
}
</style>
