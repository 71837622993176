<template>
  <div class="page">
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ServiceVPS',
  mounted() {
    this.$store.dispatch('SET_ALL_TARIFFS', {
      onlyavailable: 'on',
      itemtype: 'vds',
      tariffpage: 'basic',
    });
  },
};
</script>

<style lang="scss"></style>
