import container from './container.vue';

export const RouteSaas = {
  path: '/saas_services',
  name: 'saas_services',
  redirect: { name: 'saasMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'SaaS Сервисы',
    navigation: {
      service: {
        key: 'saas_services',
        // icon: 'el-icon-s-help',
        icon: 'el-icon-view',
        order: 2,
      },
    },
    access: ['mainmenuservice.saas_services'],
  },
  children: [
    {
      path: '',
      name: 'saasMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'SaaS Сервисы',
      },
      component: () => import(/* webpackChunkName: "saas-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'saasOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'SaaS Сервисы',
      },
      component: () => import(/* webpackChunkName: "saas-order" */ './pages/Order/SaasOrder.vue'),
      children: [
        {
          path: '/saas/order',
          redirect: { name: 'saasOrder' },
        },
        {
          path: '/saas/order/:id/:period',
          name: 'saasOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "saas-order-details" */ './pages/Order/SaasOrderDetails.vue'
            ),
        },
        {
          path: '/saas/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'saasOrderDetails' },
        },
        {
          path: '/saas/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'saasOrderDetails' },
        },
        {
          path: '/saas/order/:id/:period/2', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'saasOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'saasDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "saas-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewSaas' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "saas-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewSaas',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "saas-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historySaas',
        },
      ],
    },
  ],
};
