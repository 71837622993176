import { baseListState } from '@/store/utils/baseListState';
import { baseListGetters } from '@/store/utils/baseListGetters';
import { baseListMutations } from '@/store/utils/baseListMutations';
import actions from './actions';
import {
  BillingDocsContract,
  BillingDocsCustomdocs,
  BillingDocsInvoice,
  BillingDocsPayment,
} from '@/models/BillMgr/Billing';
import { BillMgrTools } from '@/models/BillMgr/Tools';

const itemtypeToClass = {
  payment: BillingDocsPayment,
  invoice: BillingDocsInvoice,
  contract: BillingDocsContract,
  customdocs: BillingDocsCustomdocs,
};

const modulePayment = {
  namespaced: true,
  state: {
    ...baseListState({ prop: 'date', order: 'desc' }),
    itemClass: BillingDocsPayment,
    toolsClass: BillMgrTools,
    func: 'payment',
  },
  getters: {
    ...baseListGetters,
    unpayed: state => state.list.filter(item => item.status_orig == 1),
  },
  mutations: baseListMutations,
  actions,
};
const modulePaymentBlock = {
  namespaced: true,
  state: {
    ...baseListState({ prop: 'date', order: 'desc' }),
    itemClass: BillingDocsPayment,
    toolsClass: BillMgrTools,
    func: 'payment',
  },
  getters: {
    ...baseListGetters,
    unpayed: state => state.listPaymentBlock.filter(item => item.status_orig == 1),
  },
  mutations: baseListMutations,
  actions,
};

const moduleCustomdocs = {
  namespaced: true,
  state: {
    ...baseListState({ prop: 'date', order: 'desc' }),
    itemClass: BillingDocsCustomdocs,
    toolsClass: BillMgrTools,
    func: 'customdocs',
  },
  getters: baseListGetters,
  mutations: baseListMutations,
  actions,
};

const moduleContract = {
  namespaced: true,
  state: {
    ...baseListState({ prop: 'date', order: 'desc' }),
    itemClass: BillingDocsContract,
    toolsClass: BillMgrTools,
    func: 'contract',
  },
  getters: baseListGetters,
  mutations: baseListMutations,
  actions,
};

const moduleDocs = {
  namespaced: true,
  modules: {
    modulePayment,
    modulePaymentBlock,
    moduleCustomdocs,
    moduleContract,
  },
};

export default moduleDocs;
