<template>
  <div class="sidebar-select">
    <div v-if="title || $slots.title" class="sidebar-select__title standart-text">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <base-select
      v-if="value"
      :options="options"
      :value="value"
      :track-by="trackBy"
      :label="label"
      size="small"
      theme="plain"
      :searchable="false"
      :allow-empty="false"
      :show-labels="false"
      class="sidebar-select__select"
      @select="onChange"
    />
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect.vue';
export default {
  name: 'SidebarSelect',
  components: { BaseSelect },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: 'key',
    },
    label: {
      type: String,
      default: 'value',
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.sidebar-select {

  &__title {
    font-weight: $font-weight-bold;
  }

  &__select {
    margin: 0 -1.5rem -0.5rem;
  }

  >>> .multiselect {
    cursor: pointer;
    &__select {
      width: $sidebar-width-min;
    }
    &__tags {
      padding-right: $sidebar-width-min;
      padding-left: 1.5rem;
    }
    &__single {
      padding-left: 0;
    }
    &__option {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    &__content {
      &-wrapper {
        border-radius: 0;
        border-color: var(--sidebar-separator) !important;
      }
    }
  }
}
</style>
