import container from './container.vue';
export const RouteSupport = {
  name: 'Support',
  path: '/support',
  component: container,
  redirect: { name: 'OpenTicket' },
  meta: {
    isShow: true,
    isLink: true,
    name: 'Поддержка',
    navigation: {
      account: {
        key: 'tickets',
        // icon: 'el-icon-phone-outline',
        icon: 'el-icon-help',
        order: 3,
      },
      miniProfile: {
        key: 'tickets',
        order: 3,
      },
    },
    access: ['support', 'customer'],
  },
  children: [
    {
      path: '',
      name: 'SupportMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Поддержка',
      },
      redirect: { name: 'OpenTicket' },
      component: () => import(/* webpackChunkName: "support-main" */ './views/Support.vue'),

      children: [
        {
          path: 'tickets',
          name: 'OpenTicket',
          component: () =>
            import(/* webpackChunkName: "support-ticket" */ './views/OpenTicket.vue'),
          meta: {
            isShow: true,
            isLink: false,
            name: 'Список открытых запросов',
            access: ['support.clientticket'],
          },
        },
        {
          path: 'archive',
          name: 'ArchiveRequest',
          component: () =>
            import(/* webpackChunkName: "support-arhive" */ './views/ArchiveRequest.vue'),
          meta: {
            isShow: true,
            isLink: false,
            name: 'Архив обращений',
            access: ['support.clientticket_archive'],
          },
        },
        {
          path: 'incident',
          name: 'Incidents',
          component: () =>
            import(/* webpackChunkName: "support-incedent" */ './views/Incidents.vue'),
          props: true,
          meta: {
            isShow: true,
            isLink: false,
            name: 'Инциденты',
            access: ['support.incident_docs'],
          },
        },
      ],
    },
    //.......................Начать отсюда...................
    {
      path: 'tickets/:id',
      name: 'CurrentTicket',
      props: true,
      component: () =>
        import(/* webpackChunkName: "TicketDetails-Ticket" */ './views/CurrentTicket.vue'),
      meta: {
        isShow: true,
        isLink: false,
        name: 'Список открытых запросов',
        access: ['support.clientticket'],
      },
    },
    {
      path: 'archive/:id',
      name: 'ArchivTicket',
      props: true,
      component: () =>
        import(/* webpackChunkName: "TicketDetails-Arhiv" */ './views/ArchivTicket.vue'),
      meta: {
        isShow: true,
        isLink: false,
        name: 'Архив обращений',
        access: ['support.clientticket_archive'],
      },
    },
  ],
};
